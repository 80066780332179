/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import logo from 'assets/img/covirtua-logo.png';
import { cvVioletColor } from 'styles/variables';
import { defaultFont } from 'assets/jss/app.jsx';
import PropTypes from 'prop-types';
import Header from 'components/Header/Header';

const styles = () => ({
  warningCard: {
    position: 'relative',
    maxWidth: '75%',
    margin: 'auto',
    marginTop: '50px',
    border: 'solid 2px',
    borderColor: cvVioletColor,
    borderRadius: '15px',
  },
  logo: {
    position: 'relative',
    padding: '15px 15px',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'rgba(180, 180, 180, 0.3)',
    },
  },
  logoLink: {
    ...defaultFont,
    color: cvVioletColor,
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      // color: "#FFFFFF"
    },
  },
  logoImage: {
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px',
  },
  img: {
    width: '35px',
    top: '18px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  logoTextFirst: {
    color: cvVioletColor,
  },
  warningMessage: {
    position: 'relative',
    margin: 'auto',
    width: '80%',
    textAlign: 'center',
    padding: '50px',
  },
});

export const reasons = {
  unknown: -1,
  isNotAllowed: 0,
  noLicence: 1,
  licenceExpired: 2,
  momentaryShutdown: 3,
};

class UnauthorizedAccess extends Component {
  getReason(t, reason) {
    switch (reason) {
      case reasons.isNotAllowed:
        return t(`Votre statut ne vous permet pas d'accéder à l'application.`);
      case reasons.noLicence:
        return t(`Vous ne possédez pas encore de licence.`);
      case reasons.licenceExpired:
        return t(`Vous ne possédez pas de licence en cours de validité.`);
      case reasons.momentaryShutdown:
        return t(`Cette partie de l'application est actuellement en cours de maintenance.`);
      default:
        return t(`Une erreur s'est produite`);
    }
  }

  render() {
    const { classes, t, reason, errorMsg } = this.props;
    return (
      <div>        <Header
        routes={null}
        handleDrawerToggle={null}
        keycloak={false}
        displayConnection={false}
        userTypes={null}
      />
        <div className={classes.warningCard}>
          <div className={classes.logo}>
            <a href="https://www.covirtua.com/" className={classes.logoLink}>
              <div className={classes.logoImage}>
                <img src={logo} alt="logo" className={classes.img} />
              </div>
              <span className={classes.logoTextFirst}>Co</span>virtua
            </a>
          </div>
          <div className={classes.warningMessage}>
            <Typography variant="h4">{t(`Vous n'avez pas accès à cette page.`)}</Typography>
            <Typography variant="subtitle1">{t(`Raison : ${this.getReason(t, reason)}`)}</Typography>
            {errorMsg &&
            (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging') && (
              <Typography variant="subtitle1">Developers only : {errorMsg}</Typography>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UnauthorizedAccess.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  reason: PropTypes.string,
  errorMsg: PropTypes.string,
};

UnauthorizedAccess.defaultProps = {
  reason: reasons.unknown,
  errorMsg: null,
};

export default withTranslation()(withStyles(styles)(UnauthorizedAccess));

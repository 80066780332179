import _ from 'lodash';
import ImportantDevices from '@material-ui/icons/ImportantDevices';
import DateRange from '@material-ui/icons/DateRange';
import { ActivityWrapperPatientView } from 'activities';
import Program from '../views/Activities/Program';
import activityRoutes from './activity';
import Roles from '../utils/roles';

const mainRoutes = [
  {
    redirect: true,
    path: '/patients',
    to: '/',
    navbarName: 'Redirect',
    featureFlag: 'app_menu_patient_directory_access',
  },

  {
    path: '/activity',
    sidebarName: 'nodisplay',
    navbarName: '',
    icon: ImportantDevices,
    component: ActivityWrapperPatientView,
    requiredRoles: [Roles.ADMIN_ROLE, Roles.USER_ROLE],
    featureFlag: '',
  },
  {
    path: '/program',
    sidebarName: 'MainMenu_Program',
    navbarName: 'MainMenu_Program',
    icon: DateRange,
    component: Program,
    requiredRoles: [Roles.ADMIN_ROLE, Roles.USER_ROLE, Roles.SUPERVISOR_ROLE],
    featureFlag: 'app_menu_program_access',
  },
];

export default _.concat(mainRoutes, activityRoutes);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { trad as enENTrad } from './en-EN';
import { trad as frFRTrad } from './fr-FR';
import { trad as emptyTrad } from './empty';

const resources = {
  en_EN: { translation: enENTrad },
  fr_FR: { translation: frFRTrad },
  empty: { translation: emptyTrad },
};

// console.log(resources);

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr_FR',
  fallbackLng: 'fr_FR',
  debug: false,
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});
export default i18n;

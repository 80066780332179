/* eslint-disable import/first */
/* eslint-disable no-used-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import $RefParser from '@apidevtools/json-schema-ref-parser';
import theme from 'styles/theme';
import _ from 'lodash';
import { logger } from 'logger';
import { indexRoutes, patientOnSiteRoutes } from './routes/index';
import Sentry from './sentry';
import './i18n/i18n-init';
import AssetResolver from './utils/AssetResolver';

window.API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
window.ACCOUNT_API_URL = process.env.REACT_APP_ACCOUNT_API_URL || "http://localhost:3003";
window.ACTIVITY_SERVER_URL = process.env.REACT_APP_ACTIVITY_SERVER_URL || 'http://localhost:8000';

const hist = createBrowserHistory();

Sentry(React);

AssetResolver.loadManifestAs().then(() => {
  const featureFlagsProm = fetch(window.AssetResolver.getAsset(`/appAssets/config/featureFlagMatchingTable.json`))
    .then((res) => {
      res
        .json()
        .then((featureFlagsConfig) => {
          window.featureFlagsMatchingTable = featureFlagsConfig;
        })
        .catch((parseError) => {
          logger.error(parseError);
        });
    })
    .catch((configError) => {
      logger.error(configError);
    });

  let targetRoutes = [];
  const configProm = import(`./config/${process.env.REACT_APP_NODE_ENV}`)
    .then((result) => {
      window.config = result.default;
    })
    .catch((configError) => {
      logger.error(`no config file found for environment : ${process.env.REACT_APP_NODE_ENV}`);
      logger.error(configError);
    })
    .finally(() => {
      if (
        window.config.services.front.patient.site.url === window.location.origin ||
        _.startsWith(window.location.pathname, '/seance')
      ) {
        targetRoutes = patientOnSiteRoutes;
      } else {
        targetRoutes = indexRoutes;
      }
    });

  const themeName = 'standard';
  const themeProm = fetch(window.AssetResolver.getAsset(`/activitiesAssets/_common/themes/${themeName}.json`))
    .then((res) => {
      res
        .text()
        .then((themetext) => {
          const themeJson = JSON.parse(themetext, (k, v) => {
            if (typeof v === 'string' && (Number.isFinite(v) || v.substring(0, 2) === '0x')) return parseInt(v, 16);
            return v;
          });
          $RefParser.dereference(themeJson, (err) => {
            if (err) {
              console.error(err);
            } else {
              const resultingTheme = themeJson.theme;
              window.activityTheme = resultingTheme;
            }
          });
        })
        .catch((parseError) => {
          logger.error(parseError);
        });
    })
    .catch((themeError) => {
      logger.error(themeError);
    });

  Promise.all([featureFlagsProm, configProm, themeProm])
    // .then((result) => {})
    .catch((configError) => {
      logger.error(configError);
    })
    .finally(() => {
      ReactDOM.render(
        <div>
          <CssBaseline />
          <Router history={hist}>
            <MuiThemeProvider theme={theme}>
              <Switch>
                {targetRoutes.map((prop) => (
                  <Route path={prop.path} component={prop.component} key={prop.path} />
                ))}
              </Switch>
            </MuiThemeProvider>
          </Router>
        </div>,

        document.getElementById('root'),
      );
    });
});

import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import { GET_CONFIGURATIONS } from 'graphql/queries';
import ConfigForm from './ConfigForm';

const styles = (theme) => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  stepsFooter: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class Config extends React.Component {
  render() {
    const { classes, handleStartActivity, slug, configModel } = this.props;

    return (
      <div className={classes.root}>
        {/* fetchPolicy should be set to cache-and-network, but bug of query sent twice. To be corrected in apollographQL 3.4 */}
        <Query query={GET_CONFIGURATIONS} variables={{ activitySlug: slug }} fetchPolicy="no-cache">
          {({ loading, error, data }) => {
            if (loading) return 'Loading...';
            if (error) return `Error! ${error.message}`;

            return (
              <div>
                <ConfigForm
                  configs={data.findByActivitySlug}
                  handleStartActivity={handleStartActivity}
                  configModel={configModel}
                />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Config.propTypes = {
  slug: PropTypes.string.isRequired,
  configModel: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleStartActivity: PropTypes.func.isRequired,
};

export default withStyles(styles)(Config);

// @formatter:off
/* eslint-disable dot-notation */
const trad = {
  activities: {
    barrage: {
      // use the activity slug
      htmlDescription: '',
      config: {
        gridType: 'Type de grille',
        disposition: {
          fieldName: 'Disposition',
          grid: 'Grille',
          scattered: 'Eparpillé',
        },
        numberOfTurns: {
          fieldName: 'Nombre de tours',
        },
        allotedTime: {
          fieldName: 'Temps imparti',
          noLimit: 'Pas de limite',
          _3minutes: '3 minutes',
          _5minutes: '5 minutes',
          _10minutes: '10 minutes',
        },
        typeOfLetter: {
          fieldName: 'Type de lettres',
          uppercase: 'MAJUSCULES',
          lowercase: 'Minuscules',
          uppercaseAndLowercase: 'MAJUSCULES et minuscules',
        },
        shapeType: {
          fieldName: 'Type de formes',
          letters: 'Lettres',
          geometricShapes: 'Formes géométriques',
          pictograms: 'Pictogrammes',
          photos: 'Photographies',
        },
        nbrOfRandomElements: {
          fieldName: "Nombre d'éléments aléatoires",
        },
        barrageType: {
          fieldName: 'Type de barrage',
          simple: 'Barrage simple',
          double: 'Barrage double',
          triple: 'Barrage triple',
          quadruple: 'Barrage quadruple',
          quintuple: 'Barrage quintuple',
        },
        targetDisplay: {
          fieldName: 'Affichage de la cible',
          temporary: 'Affichage temporaire',
          permanent: 'Affichage permanent',
        },
        targetFrequency: {
          fieldName: 'Fréquence de la cible',
          _5percent: '5% des éléments',
          _10percent: '10% des éléments',
          _15percent: '15% des éléments',
          _20percent: '20% des éléments',
        },
      },
    },
  },
  front: {
    activityWrapper: {
      onScreen: 'Sur écran',
      supervision: 'Supervision',
      minutes: 'mins',
      configName: 'Nom de la configuration',
      configNameRequired: 'Nom de la configuration requis',
      savedConfiguration: 'Configuration',
      save: 'Sauver',
      start: 'Démarrer',
      confirmQuit: "Êtes-vous certain de vouloir stopper l'activité en cours ?",
      patientCodeLabel: 'Code de connexion',
      patientLinkLabel: 'Communiquez le lien suivant à votre patient',
      patientConnected: 'Patient connecté',
      patientNotConnected: 'Patient non connecté',
      patientView: 'Vue Patient',
      notes: 'Notes',
      patientCodeJoinLabel: 'Code de la séance',
      patientJoin: 'Joindre',
    },
  },
};

trad['AddPatient'] = '';
trad['SolutionName'] = '';
trad['TherapistApplicationName'] = '';
trad['Common_Colon'] = '';
trad['Common_Previous'] = '';
trad['Common_Next'] = '';
trad['Common_Exit'] = '';
trad['Common_Save'] = '';
trad['Common_Record'] = '';
trad['Common_OK'] = '';
trad['Common_PointTaken'] = '';
trad['Common_Validate'] = '';
trad['Common_Cancel'] = '';
trad['Common_NoThanks'] = '';
trad['Common_Back'] = '';
trad['Common_Delete'] = '';
trad['Common_Modify'] = '';
trad['Common_Confirm'] = '';
trad['Common_Yes'] = '';
trad['Common_No'] = '';
trad['Common_File'] = '';
trad['Common_Open'] = '';
trad['Common_Close'] = '';
trad['Common_Print'] = '';
trad['Common_Start'] = '';
trad['Common_Program'] = '';
trad['Common_Interrupt'] = '';
trad['Common_Continue'] = '';
trad['Common_Export'] = '';
trad['Common_Displayed'] = '';
trad['Common_Hidden'] = '';
trad['Common_Min'] = '';
trad['Common_Max'] = '';
trad['Common_Escape'] = '';
trad['Common_Space'] = '';
trad['Common_Return'] = '';
trad['Common_Left'] = '';
trad['Common_Up'] = '';
trad['Common_Down'] = '';
trad['Common_Right'] = '';
trad['Common_UnitSecond'] = '';
trad['Common_UnitSecond§Short'] = '';
trad['Common_UnitMinute'] = '';
trad['Common_UnitGram'] = '';
trad['Common_UnitKiloGram'] = '';
trad['Common_UnitCentiLitre'] = '';
trad['Common_UnitCost'] = '';
trad['Common_UnitLitre'] = '';
trad['Common_UnitMeter'] = '';
trad['Common_UnitSlices'] = '';
trad['Common_UnitSheets'] = '';
trad['Common_UnitUnits'] = '';
trad['Common_CopyRight'] = '';
trad['Common_CopyRightReserved'] = '';
trad['Common_UserRight'] = '';
trad['Common_Patient'] = '';
trad['Common_Date'] = '';
trad['Common_Day'] = '';
trad['Common_Days_Monday'] = '';
trad['Common_Days_Tuesday'] = '';
trad['Common_Days_Wednesday'] = '';
trad['Common_Days_Thursday'] = '';
trad['Common_Days_Friday'] = '';
trad['Common_Days_Saturday'] = '';
trad['Common_Days_Sunday'] = '';
trad['Common_Etc'] = '';
trad['Common_Description'] = '';
trad['Common_Comments'] = '';
trad['Common_None'] = '';
trad['Common_UnknownDoctor'] = '';
trad['Common_DateTimeFormatFull'] = '';
trad['Common_DateFormatFull'] = '';
trad['Common_Among'] = '';
trad['Common_Percent'] = '';
trad['Common_QuestionMark'] = '';
trad['Common_DateSeparator'] = '';
trad['Common_HelpToolTip'] = '';
trad['Common_HomeToolTip'] = '';
trad['Reporting_ActivityReportTitle'] = '';
trad['Reporting_ActivityPrintName'] = '';
trad['Reporting_ActivityFileName'] = '';
trad['Reporting_SessionReportTitle'] = '';
trad['Reporting_SessionPrintName'] = '';
trad['Reporting_SessionFileName'] = '';
trad['Main_Version'] = '';
trad['Main_DemoMode'] = '';
trad['Main_About'] = '';
trad['Main_Contact'] = '';
trad['Main_Profile'] = '';
trad['Main_Disconnect'] = '';
trad['Main_SessionCode'] = '';
trad['Main_SessionCodeInvite'] = '';
trad['Main_SessionJoin'] = '';
trad['Main_AtHomeLinkInvite'] = '';
trad['Main_AtHomeLinkInvite_toolTip'] = '';
trad['Main_LinkCopied'] = '';
trad['MainMenu_History'] = '';
trad['MainMenu_Statistics'] = '';
trad['MainMenu_MyPatients'] = '';
trad['MainMenu_PatientFile'] = '';
trad['MainMenu_Dashboard'] = '';
trad['MainMenu_Activities'] = '';
trad['VHistory_NoHistory'] = '';

trad['VBetweenSessions_Dashboard_OnBy'] = '';
trad['VConfig_Mic'] = '';
trad['VConfig_MicToolTip'] = '';
trad['VConfig_Webcam'] = '';
trad['VConfig_WebcamToolTip'] = '';
trad['VConfig_Welcome'] = '';
trad['VConfig_Title'] = '';
trad['VConfig_ReminderHow'] = '';
trad['VConfig_Language'] = '';
trad['VConfig_ErrorWebcam'] = '';
trad['VConfig_ErrorMic'] = '';
trad['VConfig_WebcamFPS'] = '';
trad['VExperience_Notepad'] = '';
trad['VFrontPage_SelectPatient'] = '';
trad['VFrontPage_CreatePatient'] = '';
trad['VFrontPage_SeeAll'] = '';
trad['VMainMenu_TabProject'] = '';
trad['VMainMenu_TabBottomUp'] = '';
trad['VMainMenu_TabTopDown'] = '';
trad['VMainMenu_TabTools'] = '';
trad['VMainMenu_PictureBookTitle'] = '';
trad['VMainMenu_PictureBookDesc'] = '';
trad['VMainMenu_HomeToolTip'] = '';
trad['VMainMenuCB_DReminderSelf'] = '';
trad['VMainMenuCB_DReminderOther'] = '';
trad['VMainMenuCB_DReminderDate'] = '';
trad['VMainMenuCB_DReminderTitle'] = '';
trad['VPatient_PatientCreation'] = '';
trad['VPatient_NewPatient'] = '';
trad['VPatient_Anonymize'] = '';
trad['VPatient_ID'] = '';
trad['VPatient_PatientEdition'] = '';
trad['VPatient_PatientDetail'] = '';
trad['VPatient_AgeYears'] = '';
trad['VPatient_AgeYearsMonths'] = '';
trad['VPatient_Identity'] = '';
trad['VPatient_Identity_eMr'] = '';
trad['VPatient_Identity_eMme'] = '';
trad['VPatient_Identity_eMlle'] = '';
trad['VPatient_IdentityFirstName'] = '';
trad['VPatient_IdentityLastName'] = '';
trad['VPatient_IdentityBirthdate'] = '';
trad['VPatient_IdentityStudies'] = '';
trad['VPatient_IdentityStudies_e1t'] = '';
trad['VPatient_IdentityStudies_e1'] = '';
trad['VPatient_IdentityStudies_e2t'] = '';
trad['VPatient_IdentityStudies_e2'] = '';
trad['VPatient_IdentityStudies_e3t'] = '';
trad['VPatient_IdentityStudies_e3'] = '';
trad['VPatient_IdentityStudies_e4t'] = '';
trad['VPatient_IdentityStudies_e4'] = '';
trad['VPatient_IdentityStudies_e5t'] = '';
trad['VPatient_IdentityStudies_e5'] = '';
trad['VPatient_IdentityStudies_1duration'] = '';
trad['VPatient_IdentityStudies_2duration'] = '';
trad['VPatient_IdentityStudies_3duration'] = '';
trad['VPatient_IdentityStudies_4duration'] = '';
trad['VPatient_IdentityStudies_5duration'] = '';
trad['VPatient_IdentityStudies_6duration'] = '';
trad['VPatient_IdentityGender'] = '';
trad['VPatient_IdentityGender_eFemale'] = '';
trad['VPatient_IdentityGender_eMale'] = '';
trad['VPatient_IdentityLaterality'] = '';
trad['VPatient_IdentityLaterality_eLeft'] = '';
trad['VPatient_IdentityLaterality_eRight'] = '';
trad['VPatient_IdentityContact'] = '';
trad['VPatient_IdentityContactInfo'] = '';
trad['VPatient_IdentityContactInfoLandline'] = '';
trad['VPatient_IdentityContactInfoCell'] = '';
trad['VPatient_IdentityContactInfoCellIncorrect'] = '';
trad['VPatient_IdentityContactInfoFax'] = '';
trad['VPatient_IdentityContactInfoMail'] = '';
trad['VPatient_IdentityContactInfoMailInvalid'] = '';
trad['VPatient_IdentityContactInfoAdress'] = '';
trad['VPatient_IdentityContactInfoRelationship'] = '';
trad['VPatient_IdentityContactInfoRelationshipNb'] = '';
trad['VPatient_Condition'] = '';
trad['VPatient_Conditions'] = '';
trad['VPatient_NoCondition'] = '';
trad['VPatient_NoConditionAdd'] = '';
trad['VPatient_NoConditionSummary'] = '';
trad['VPatient_AddCondition'] = '';
trad['VPatient_AddConditionSummary'] = '';
trad['VPatient_ConditionSeverity'] = '';
trad['VPatient_ConditioSeverity_eLeger'] = '';
trad['VPatient_ConditionSeverity_eModere'] = '';
trad['VPatient_ConditionSeverity_eSevere'] = '';
trad['VPatient_ConditionNeuro'] = '';
trad['VPatient_ConditionNeuroStroke'] = '';
trad['VPatient_ConditionNeuroTrauma'] = '';
trad['VPatient_ConditionNeuroPlace_eLeft'] = '';
trad['VPatient_ConditionNeuroPlace_eRight'] = '';
trad['VPatient_ConditionNeuroTumor'] = '';
trad['VPatient_ConditionNeuroOther'] = '';
trad['VPatient_ConditionNeurodegenerative'] = '';
trad['VPatient_ConditionNeurodegenerativeAlzheimer'] = '';
trad['VPatient_ConditionNeurodegenerativeAlzheimer_ePreDementiel'] = '';
trad['VPatient_ConditionNeurodegenerativeAlzheimer_eLeger'] = '';
trad['VPatient_ConditionNeurodegenerativeAlzheimer_eModere'] = '';
trad['VPatient_ConditionNeurodegenerativeAlzheimer_eAvance'] = '';
trad['VPatient_ConditionNeurodegenerativeParkinson'] = '';
trad['VPatient_ConditionNeurodegenerativeParkinson_e1'] = '';
trad['VPatient_ConditionNeurodegenerativeParkinson_e1_5'] = '';
trad['VPatient_ConditionNeurodegenerativeParkinson_e2'] = '';
trad['VPatient_ConditionNeurodegenerativeParkinson_e2_5'] = '';
trad['VPatient_ConditionNeurodegenerativeParkinson_e3'] = '';
trad['VPatient_ConditionNeurodegenerativeParkinson_e4'] = '';
trad['VPatient_ConditionNeurodegenerativeParkinson_e5'] = '';
trad['VPatient_ConditionNeurodegenerativeSEP'] = '';
trad['VPatient_ConditionNeurodegenerativeSEPType_eProgressivePrimaire'] = '';
trad['VPatient_ConditionNeurodegenerativeSEPType_eProgressiveSecondaire'] = '';
trad['VPatient_ConditionNeurodegenerativeSEPType_eProgressiveRecurrente'] = '';
trad['VPatient_ConditionNeurodegenerativeSEPType_eCIS'] = '';
trad['VPatient_ConditionNeurodegenerativeOther'] = '';
trad['VPatient_ConditionDevelopmental'] = '';
trad['VPatient_ConditionDevelopmentalAutism'] = '';
trad['VPatient_ConditionDevelopmentalDyscalculia'] = '';
trad['VPatient_ConditionDevelopmentalDysgraphia'] = '';
trad['VPatient_ConditionDevelopmentalDyslexia'] = '';
trad['VPatient_ConditionDevelopmentalDysphasia'] = '';
trad['VPatient_ConditionDevelopmentalDyspraxia'] = '';
trad['VPatient_ConditionDevelopmentalAHDH'] = '';
trad['VPatient_ConditionOther'] = '';
trad['VPatient_ConditionOtherGenetic'] = '';
trad['VPatient_ConditionOtherOther'] = '';
trad['VPatient_ConditionCare'] = '';
trad['VPatient_ConditionComment'] = '';
trad['VPatient_ConditionType'] = '';
trad['VPatient_ConditionPlace'] = '';
trad['VPatient_Condition1stSymptom'] = '';
trad['VPatient_ConditionStage'] = '';
trad['VPatient_AdditionalContact'] = '';
trad['VPatient_NoAdditionalContact'] = '';
trad['VPatient_NoAdditionalContactAdd'] = '';
trad['VPatient_NoAdditionalContactSummary'] = '';
trad['VPatient_AddAdditionalContact'] = '';
trad['VPatient_AddAdditionalContactSummary'] = '';
trad['VPatient_NoCare'] = '';
trad['VPatient_NoCareSummary'] = '';
trad['VPatient_NoCareAdd'] = '';
trad['VPatient_AddCare'] = '';
trad['VPatient_AddCareSummary'] = '';
trad['VPatient_CareNb'] = '';
trad['VPatient_CareTitle'] = '';
trad['VPatient_CareBegin'] = '';
trad['VPatient_CareEnd'] = '';
trad['VPatient_CareRunning'] = '';
trad['VPatient_AddMotricitySummary'] = '';
trad['VPatient_NoMotricitySummary'] = '';
trad['VPatient_Motricity'] = '';
trad['VPatient_MotricityLeft'] = '';
trad['VPatient_MotricityLeftShort'] = '';
trad['VPatient_MotricityRight'] = '';
trad['VPatient_MotricityRightShort'] = '';
trad['VPatient_MotricityCause'] = '';
trad['VPatient_MotricityWheelchair'] = '';
trad['VPatient_MotricityWheelchairShort'] = '';
trad['VPatient_MotricityWarning'] = '';
trad['VPatient_Help'] = '';
trad['VPatient_Comments'] = '';
trad['VPatientList_Title'] = '';
trad['VPatientList_FollowedBy'] = '';
trad['VPatientList_Therapists'] = '';
trad['VPatientList_AllTherapists'] = '';
trad['VPatientList_Filter'] = '';
trad['VPatientList_FilterAll'] = '';
trad['VPatientList_FilterOwned'] = '';
trad['VPatientList_Group'] = '';
trad['VPatientList_GroupDate'] = '';
trad['VPatientList_GroupDateThisWeek'] = '';
trad['VPatientList_GroupDateLastWeek'] = '';
trad['VPatientList_GroupDatePreviousLastWeek'] = '';
trad['VPatientList_GroupDateEarlier'] = '';
trad['VPatientList_GroupName'] = '';
trad['VPatientList_Edit'] = '';
trad['shortcut_VPatientList_Edit'] = '';
trad['VPatientList_View'] = '';
trad['shortcut_VPatientList_View'] = '';
trad['VPatientList_Delete'] = '';
trad['VPatientList_Assign'] = '';
trad['VPatientList_Create'] = '';
trad['shortcut_VPatientList_Create'] = '';
trad['VPatientList_Select'] = '';
trad['shortcut_VPatientList_Select'] = '';
trad['VPatientList_DeleteConfirmSingle'] = '';
trad['VPatientList_DeleteConfirmMultiple'] = '';
trad['VPatientList_DeleteConfirm'] = '';
trad['VPatientList_TherapistAssignmentTitle'] = '';
trad['VPatientList_TherapistAssignment'] = '';
trad['VPatientList_NoTherapistAssignment'] = '';
trad['VPatientList_DisplayAllPatients'] = '';
trad['VPatientList_FilterbyTherapist'] = '';
trad['VPatientList_OrderDate'] = '';
trad['VPatientList_OrderAlpha'] = '';
trad['VPictureBookEdit_Words'] = '';
trad['VPictureBookEdit_Objects'] = '';
trad['VPictureBookEdit_Pictures'] = '';
trad['VPictureBookEdit_Category'] = '';
trad['VPictureBookEdit_SubCategory'] = '';
trad['VPictureBookEdit_Syllables'] = '';
trad['VPictureBookEdit_Length'] = '';
trad['VPictureBookEdit_Length_Short'] = '';
trad['VPictureBookEdit_Length_Long'] = '';
trad['VPictureBookEdit_Frequency'] = '';
trad['VPictureBookEdit_Complexity'] = '';
trad['VPictureBookEdit_Structure'] = '';
trad['VPictureBookEdit_Tone'] = '';
trad['VPictureBookEdit_ToneBegining'] = '';
trad['VPictureBookEdit_ToneMiddle'] = '';
trad['VPictureBookEdit_ToneEnd'] = '';
trad['VPictureBookEdit_Age'] = '';
trad['VPictureBookEdit_Add'] = '';
trad['VPictureBookEdit_DisplayFilters'] = '';
trad['VPictureBookEdit_DeleteFilters'] = '';
trad['VPictureBookEdit_EmptyBookCaption'] = '';
trad['VPictureBookEdit_DeletePage'] = '';
trad['VPReport_Therapist'] = '';
trad['VPReport_Date'] = '';
trad['VUserSelection_Title'] = '';
trad['VUserSelection_Edition'] = '';
trad['VUserSelection_Edition_eLiberal'] = '';
trad['VUserSelection_Edition_eInstitution'] = '';
trad['VUserSelection_Waiting'] = '';
trad['VUserSelection_WaitingThanks'] = '';
trad['PAdditionalData_DataToInstall'] = '';
trad['PAdditionalData_InstallBut'] = '';
trad['PAdditionalData_Installed'] = '';
trad['PAdditionalData_NoDataInstalledTitle'] = '';
trad['PAdditionalData_NoDataInstalledCaption'] = '';
trad['PAdditionalData_AdditionalDataError'] = '';
trad['PAdditionalData_AdditionalDataAvailable'] = '';
trad['PAdditionalData_NoAdditionalDataAvailable'] = '';
trad['PAdditionalData_AdditionalDataServerErrorTitle'] = '';
trad['PAdditionalData_AdditionalDataServerErrorCaption1'] = '';
trad['PAdditionalData_AdditionalDataServerErrorCaption2'] = '';
trad['PCalendar_Month'] = '';
trad['PConnectionSummary_ToolTipConnected'] = '';
trad['PConnectionSummary_ToolTipInterrupted'] = '';
trad['PConnectionSummary_ToolTipDisconnected'] = '';
trad['PConnectionSummary_ShowPatientTitle'] = '';
trad['PConnectionSummary_ShowPatientCaption'] = '';
trad['PConnectionSummary_ShowPatientOK'] = '';
trad['PConnectionSummary_ShowPatientCancel'] = '';
trad['PGraph_InsignificantTitle'] = '';
trad['PGraph_InsignificantCaptionBeforeIcon'] = '';
trad['PGraph_InsignificantCaptionAfterIcon'] = '';
trad['PGraph_Hypotheses'] = '';
trad['PLogin_EMail'] = '';
trad['PLogin_Password'] = '';
trad['PLogin_LostPassword'] = '';
trad['PLogin_Register'] = '';
trad['PLogin_Connect'] = '';
trad['shortcut_PLogin_Connect'] = '';
trad['PExerciseDetail_SnapshotsHeader'] = '';
trad['PExerciseDetail_ExerciseDescTimeUnit'] = '';
trad['PExerciseDetail_NoExerciseResult'] = '';
trad['PExerciseDetail_WarningConnectTitle'] = '';
trad['PExerciseDetail_WarningConnectCaption'] = '';
trad['PExerciseDetail_WarningConnectLink'] = '';
trad['PExerciseDetail_NoAdditionalDataTitle'] = '';
trad['PExerciseDetail_NoAdditionalDataCaption'] = '';
trad['PExerciseDetail_IconScreen'] = '';
trad['PExerciseDetail_IconImmersion'] = '';
trad['PExerciseDetail_IconPaper'] = '';
trad['PExerciseDetail_IconSideBySide'] = '';
trad['PExerciseDetail_IconSupervision'] = '';
trad['PExerciseDetail_History'] = '';
trad['PExerciseFilter_Choose'] = '';
trad['PExerciseFilter_Precision'] = '';
trad['PExerciseFilter_Executives'] = '';
trad['PExerciseFilter_Executives_Plan'] = '';
trad['PExerciseFilter_Executives_Anticipate'] = '';
trad['PExerciseFilter_Executives_Inhibition'] = '';
trad['PExerciseFilter_Executives_Decision'] = '';
trad['PExerciseFilter_Executives_Reasoning'] = '';
trad['PExerciseFilter_Attention'] = '';
trad['PExerciseFilter_Memory'] = '';
trad['PExerciseFilter_Memory_Work'] = '';
trad['PExerciseFilter_Memory_Semantic'] = '';
trad['PExerciseFilter_Memory_Episodic'] = '';
trad['PExerciseFilter_Gnosis'] = '';
trad['PExerciseFilter_Language'] = '';
trad['PExercisePreset_Creator'] = '';
trad['PExercisePreset_CreationDate'] = '';
trad['PExercisePreset_CreationBy'] = '';
trad['PExercisePreset_SelectPreset'] = '';
trad['PExercisePreset_HeaderConfigurations'] = '';
trad['PExercisePreset_CovirtuaPresetToolTip'] = '';
trad['PExercisePreset_OwnPreset'] = '';
trad['PExercisePreset_CovirtuaPreset'] = '';
trad['PExercisePreset_TeamPreset'] = '';
trad['PExerciseResult_Comment'] = '';
trad['PExerciseResult_DateToolTip'] = '';
trad['PPatientSummary_PatientToolTip'] = '';
trad['PProtocole_Name'] = '';
trad['PProtocole_NextActivity'] = '';
trad['PProtocole_CurrentStep'] = '';
trad['PProtocole_Steps'] = '';
trad['PProtocole_Displayed'] = '';
trad['PProtocole_Start'] = '';
trad['PProtocole_End'] = '';
trad['PProtocole_Thérapist'] = '';
trad['PProtocole_Conditions'] = '';
trad['PProtocole_Running'] = '';
trad['PProtocole_Aborted'] = '';
trad['PProtocole_Ended'] = '';
trad['PSession_Comment'] = '';
trad['PSession_Notes'] = '';
trad['PSession_Series'] = '';
trad['PSession_SeeAll'] = '';
trad['PSession_Replay'] = '';
trad['PSession_DateFormat'] = '';
trad['PSession_Today'] = '';
trad['PSession_Yesterday'] = '';
trad['PSession_With'] = '';
trad['PSession_DaysShort'] = '';
trad['PUserSummary_Welcome'] = '';
trad['PUserSummary_ProfileToolTip'] = '';
trad['PUserSummary_HelpToolTip'] = '';
trad['PUserSummary_SettingsToolTip'] = '';
trad['PUserSummary_LogoutToolTip'] = '';
trad['PUserSummary_HelpTitle'] = '';
trad['PUserSummary_HelpContent'] = '';
trad['PUserSummary_HelpStop'] = '';
trad['PUserEdition_TitleCreate'] = '';
trad['PUserEdition_TitleEdit'] = '';
trad['PUserEdition_FirstUserGuidance'] = '';
trad['PUserCreation_FirstName'] = '';
trad['PUserCreation_LastName'] = '';
trad['PUserCreation_UserTitle'] = '';
trad['PUserCreation_UserTitle_eOrthophoniste'] = '';
trad['PUserCreation_UserTitle_eErgotherapeute'] = '';
trad['PUserCreation_UserTitle_eNeuropsychologue'] = '';
trad['PUserCreation_UserTitle_ePsychomotricien'] = '';
trad['PUserCreation_UserTitle_eMedecin'] = '';
trad['PUserCreation_UserTitle_eAnimateur'] = '';
trad['PUserCreation_UserTitle_eEtudiant'] = '';
trad['PUserCreation_UserTitle_eChercheur'] = '';
trad['PUserCreation_UserTitle_eAutre'] = '';
trad['PUserCreation_AdditionalInfosGuidance'] = '';
trad['PUserCreation_Gender'] = '';
trad['PUserCreation_Gender_eFemale'] = '';
trad['PUserCreation_Gender_eMale'] = '';
trad['PUserCreation_Avatar'] = '';
trad['PUserCreation_TelemetryCheckbox'] = '';
trad['PUserCreation_TelemetryDescription'] = '';
trad['PUserCreation_ResultsSharingCheckbox'] = '';
trad['PUserCreation_ResultsSharingDescription'] = '';
trad['DAbout_Title'] = '';
trad['DAbout_Team'] = '';
trad['DAbout_Dependances'] = '';
trad['DAbout_Conditions'] = '';
trad['DAbout_ConditionsDetail'] = '';
trad['DAbout_ConditionsLink'] = '';
trad['DCreatePreset_Title'] = '';
trad['DCreatePreset_Caption'] = '';
trad['DCreatePreset_Name'] = '';
trad['DCreatePreset_Description'] = '';
trad['DCreatePreset_ErrorNameMissing'] = '';
trad['DCreatePreset_ErrorExistingName'] = '';
trad['DCreateProgram_Title'] = '';
trad['DCreateProgram_Caption'] = '';
trad['DCreateProgram_Description'] = '';
trad['DCreateProgram_ErrorNameMissing'] = '';
trad['DSessionEnd_Title'] = '';
trad['DSessionEnd_Intro'] = '';
trad['DSessionEnd_Desc'] = '';
trad['DSessionEnd_Notes'] = '';
trad['DSessionEnd_Reminder'] = '';
trad['DSessionEnd_EndPrint'] = '';
trad['DSessionEnd_EndExport'] = '';
trad['DPrint_Title'] = '';
trad['DPrint_Caption'] = '';
trad['DExtract_Title'] = '';
trad['DExtract_FileName'] = '';
trad['DVariableChange_Title'] = '';
trad['DVariableChange_Caption'] = '';
trad['FBAdditionalDataCB_ZipInstall'] = '';
trad['FBAdditionalData_XmlInstall'] = '';
trad['MBAdditionalData_DownloadingTitle'] = '';
trad['MBAdditionalData_DownloadingCaption'] = '';
trad['MBAdditionalData_UnzipTitle'] = '';
trad['MBAdditionalData_UnzipCaption'] = '';
trad['MBAdditionalData_InstalledTitle'] = '';
trad['MBAdditionalData_InstalledCaption'] = '';
trad['MBExercise_Snapshot'] = '';
trad['MBExercisePreset_SaveCovirtuaPresetTitle'] = '';
trad['MBExercisePreset_SaveCovirtuaPresetCaption'] = '';
trad['MBExercisePreset_DeletePresetTitle'] = '';
trad['MBExercisePreset_DeletePresetCaption'] = '';
trad['MBExercisePreset_CreateCovirtuaPresetTitle'] = '';
trad['MBExercisePreset_CreateCovirtuaPresetCaption'] = '';
trad['MBExercisePanel_MissingDataTitle'] = '';
trad['MBExercisePanel_MissingDataCaption'] = '';
trad['MBExperience_CancelTitle'] = '';
trad['MBExperience_CancelCaption'] = '';
trad['MBConfiguration_StorageMissingTitle'] = '';
trad['MBConfiguration_StorageMissingCaption'] = '';
trad['MBConfiguration_NetworkTitle'] = '';
trad['MBConfiguration_NetworkCaption'] = '';
trad['MBConfiguration_StorageChangeTitle'] = '';
trad['MBConfiguration_StorageChangeCaption'] = '';
trad['MBLicense_WrongMachineTitle'] = '';
trad['MBLicense_WrongMachineCaption'] = '';
trad['MBLicense_ExpiredTitle'] = '';
trad['MBLicense_ExpiredCaption'] = '';
trad['MBMain_SupportTitle'] = '';
trad['MBMain_SupportCaption'] = '';
trad['MBMain_SupportOK'] = '';
trad['MBMain_DisconnectTitle'] = '';
trad['MBMain_DisconnectCaption'] = '';
trad['MBMain_DisconnectOK'] = '';
trad['MBMain_ClientDisconnectTitle'] = '';
trad['MBMain_ClientDisconnectCaption'] = '';
trad['MBMain_DemoCaption'] = '';
trad['MBMain_DemoOK'] = '';
trad['FBConfig_DataStorageRepCaption'] = '';
trad['MBConfiguration_ChangeTitle'] = '';
trad['MBConfiguration_ChangeCaption'] = '';
trad['MBPictureBookEdit_DemoModeTitle'] = '';
trad['MBPictureBookEdit_DemoModeCaption'] = '';
trad['MBProtocole_LaunchTitle'] = '';
trad['MBProtocole_LaunchCaption'] = '';
trad['MBProtocole_InterruptTitle'] = '';
trad['MBProtocole_InterruptCaption'] = '';
trad['MBUserSelection_DeleteTitle'] = '';
trad['MBUserSelection_DeleteCaption'] = '';
trad['MBVersionHelper_UpdateTitle'] = '';
trad['MBVersionHelper_UpdateCaptionIntro'] = '';
trad['MBVersionHelper_UpdateCaptionShared'] = '';
trad['MBVersionHelper_UpdateCaptionClosing'] = '';
trad['MBVersionHelper_UpdateOKCaption'] = '';
trad['MBVersionHelper_UpdateDoneCaption'] = '';
trad['MBVersionHelper_UpdateDoneOKCaption'] = '';
trad['Error_Title'] = '';
trad['Error_Code'] = '';
trad['Error_Link'] = '';
trad['Error_001001001'] = '';
trad['Error_001001002'] = '';
trad['Error_001001003'] = '';
trad['Error_001001004'] = '';
trad['Error_001001005'] = '';
trad['Error_001001006'] = '';
trad['Error_001002001'] = '';
trad['Error_001002002'] = '';
trad['Error_001002003'] = '';
trad['Error_001002004'] = '';
trad['Error_001002005'] = '';
trad['Error_001002006'] = '';
trad['Error_001003001'] = '';
trad['Error_001003002'] = '';
trad['Error_001004001'] = '';
trad['Error_001004002'] = '';
trad['Error_001004003'] = '';
trad['Error_001004004'] = '';
trad['Error_001004005'] = '';
trad['Error_001004006'] = '';
trad['Error_001004007'] = '';
trad['Error_001004008'] = '';
trad['Error_001004009'] = '';
trad['Error_001004010'] = '';
trad['Error_001004011'] = '';
trad['PLoginCB_NetworkError'] = '';
trad['PLoginCB_AuthentError'] = '';
trad['PLoginCB_InvalidProducts'] = '';
trad['PLoginCB_NoTokenAvailable'] = '';
trad['PLoginCB_LicenceExpired'] = '';
trad['PLoginCB_Success'] = '';
trad['Protocols_TransitionSuccess'] = '';
trad['Protocols_TransitionFailure'] = '';
trad['Protocols_TransitionFirstCondition'] = '';
trad['Protocols_TransitionNextTransition'] = '';
trad['Protocols_TransitionOperationDifferent'] = '';
trad['Protocols_TransitionOperationEqual'] = '';
trad['Protocols_TransitionOperationGreater'] = '';
trad['Protocols_TransitionOperationGreaterEq'] = '';
trad['Protocols_TransitionOperationLesser'] = '';
trad['Protocols_TransitionOperationLesserEq'] = '';
trad['Protocols_Transition_minPCSuccessDesc'] = '';
trad['Protocols_Memory_Name'] = '';
trad['Protocols_Memory_FullName'] = '';
trad['Protocols_Memory_Summary'] = '';
trad['Protocols_Memory_SuccessDesc'] = '';
trad['Protocols_Memory_Success80Desc'] = '';
trad['Protocols_Memory_Step0'] = '';
trad['Protocols_Memory_Step0Desc'] = '';
trad['Protocols_Memory_Step0Act1Desc'] = '';
trad['Protocols_Memory_Step1'] = '';
trad['Protocols_Memory_Step1Desc'] = '';
trad['Protocols_Memory_EvalStep1'] = '';
trad['Protocols_Memory_EvalStep1Desc'] = '';
trad['Protocols_Memory_Step2'] = '';
trad['Protocols_Memory_Step2Desc'] = '';
trad['Protocols_Memory_EvalStep2'] = '';
trad['Protocols_Memory_EvalStep2Desc'] = '';
trad['Protocols_Memory_Step3'] = '';
trad['Protocols_Memory_Step3Desc'] = '';
trad['Protocols_Memory_EvalStep3'] = '';
trad['Protocols_Memory_EvalStep3Desc'] = '';
trad['Categories_Attention'] = '';
trad['Categories_Inhibition'] = '';
trad['Categories_Language'] = '';
trad['Categories_Evolex'] = '';
trad['Categories_Memory'] = '';
trad['Categories_House'] = '';
trad['Categories_Downtown'] = '';
trad['Common_UserManual'] = '';
trad['Common_Configuration'] = '';
trad['Common_Control'] = '';
trad['Common_Control_Accept'] = '';
trad['Common_Control_AcceptToolTip'] = '';
trad['Common_Control_Appear'] = '';
trad['Common_Control_AppearToolTip'] = '';
trad['Common_Control_AutoCorrect'] = '';
trad['Common_Control_AutoCorrectToolTip'] = '';
trad['Common_Control_Cancel'] = '';
trad['Common_Control_CancelToolTip'] = '';
trad['Common_Control_Center'] = '';
trad['Common_Control_CenterToolTip'] = '';
trad['Common_Control_Change'] = '';
trad['Common_Control_ChangeToolTip'] = '';
trad['Common_Control_Disappear'] = '';
trad['Common_Control_DisappearToolTip'] = '';
trad['Common_Control_FlipBack'] = '';
trad['Common_Control_FlipBackToolTip'] = '';
trad['Common_Control_Hide'] = '';
trad['Common_Control_HideToolTip'] = '';
trad['Common_Control_Pause'] = '';
trad['Common_Control_PauseToolTip'] = '';
trad['Common_Control_Reject'] = '';
trad['Common_Control_RejectToolTip'] = '';
trad['Common_Control_RejectOneToolTip'] = '';
trad['Common_Control_Resume'] = '';
trad['Common_Control_ResumeToolTip'] = '';
trad['Common_Control_RoadBookHide'] = '';
trad['Common_Control_RoadBookHideToolTip'] = '';
trad['Common_Control_RoadBookShow'] = '';
trad['Common_Control_RoadBookShowToolTip'] = '';
trad['Common_Control_SetCamera'] = '';
trad['Common_Control_SetCameraToolTip'] = '';
trad['Common_Control_SetCamera_eFree'] = '';
trad['Common_Control_SetCamera_eTop'] = '';
trad['Common_Control_SetCamera_ePatient'] = '';
trad['Common_Control_Show'] = '';
trad['Common_Control_ShowToolTip'] = '';
trad['Common_Control_Skip'] = '';
trad['Common_Control_SkipToolTip'] = '';
trad['Common_Control_Start'] = '';
trad['Common_Control_StartToolTip'] = '';
trad['Common_Control_Startover'] = '';
trad['Common_Control_Terminate'] = '';
trad['Common_Control_TerminateToolTip'] = '';
trad['Common_Control_Validate'] = '';
trad['Common_Control_ValidateToolTip'] = '';
trad['Common_Control_ValidateOneToolTip'] = '';
trad['Common_Control_ValidateSupported'] = '';
trad['Common_Control_ValidateSupportedToolTip'] = '';
trad['Common_Control_ValidateCategory'] = '';
trad['Common_Control_ValidateCategoryToolTip'] = '';
trad['shortcut_Common_Control_Accept'] = '';
trad['shortcut_Common_Control_Appear'] = '';
trad['shortcut_Common_Control_Change'] = '';
trad['shortcut_Common_Control_Center'] = '';
trad['shortcut_Common_Control_FlipBack'] = '';
trad['shortcut_Common_Control_HideRoadbook'] = '';
trad['shortcut_Common_Control_Pause'] = '';
trad['shortcut_Common_Control_Reject'] = '';
trad['shortcut_Common_Control_SetCamera'] = '';
trad['shortcut_Common_Control_Skip'] = '';
trad['shortcut_Common_Control_StartOver'] = '';
trad['shortcut_Common_Control_Validate'] = '';
trad['shortcut_Common_Control_ValidateSupported'] = '';
trad['Common_Parameter_Tab_AutoDistractors'] = '';
trad['Common_Parameter_Tab_Average'] = '';
trad['Common_Parameter_Tab_Categories'] = '';
trad['Common_Parameter_Tab_CategoriesToolTip'] = '';
trad['Common_Parameter_Tab_Configs'] = '';
trad['Common_Parameter_Tab_ConfigsToolTip'] = '';
trad['Common_Parameter_Tab_Display'] = '';
trad['Common_Parameter_Tab_Distractors'] = '';
trad['Common_Parameter_Tab_General'] = '';
trad['Common_Parameter_Tab_GPSConfig'] = '';
trad['Common_Parameter_Tab_Help'] = '';
trad['Common_Parameter_Tab_Interf'] = '';
trad['Common_Parameter_Tab_Items'] = '';
trad['Common_Parameter_Tab_Layout'] = '';
trad['Common_Parameter_Tab_Mode'] = '';
trad['Common_Parameter_Tab_Roadmap'] = '';
trad['Common_Parameter_Tab_ShoppingCart'] = '';
trad['Common_Parameter_Tab_SMSSending'] = '';
trad['Common_Parameter_Tab_Specs'] = '';
trad['Common_Parameter_Tab_SpecsToolTip'] = '';
trad['Common_Parameter_Tab_Targets'] = '';
trad['Common_Parameter_Tab_Tools'] = '';
trad['Common_Parameter_Tab_Topology'] = '';
trad['Common_Parameter_Tab_Surroundings'] = '';
trad['Common_Parameter_Tab_Surroundings§ToolTip'] = '';
trad['Common_Parameter_Tab_View'] = '';
trad['Common_Parameter_eIndifferent'] = '';
trad['Common_Parameter_eWith'] = '';
trad['Common_Parameter_eWithout'] = '';
trad['Common_Parameter_AllowPatient'] = '';
trad['Common_Parameter_AllowPatientToolTip'] = '';
trad['Common_Parameter_AlternateContent'] = '';
trad['Common_Parameter_AlternateDocument'] = '';
trad['Common_Parameter_Alternative_MapComplexity'] = '';
trad['Common_Parameter_Alternative_Scenario'] = '';
trad['Common_Parameter_Alternative_TransportComplexity'] = '';
trad['Common_Parameter_AllowNext'] = '';
trad['Common_Parameter_AllowNext§Reduced'] = '';
trad['Common_Parameter_AllowNext§Short'] = '';
trad['Common_Parameter_AllowNext§ToolTip'] = '';
trad['Common_Parameter_Anim'] = '';
trad['Common_Parameter_Anim§Reduced'] = '';
trad['Common_Parameter_Anim§Short'] = '';
trad['Common_Parameter_Anim§ToolTip'] = '';
trad['Common_Parameter_AnimType'] = '';
trad['Common_Parameter_AnimType'] = '';
trad['Common_Parameter_AnimTypeDesc'] = '';
trad['Common_Parameter_AnimTypeToolTip'] = '';
trad['Common_Parameter_AnimType_eNone'] = '';
trad['Common_Parameter_AnimType_eMoving'] = '';
trad['Common_Parameter_Announcement'] = '';
trad['Common_Parameter_AnswerType'] = '';
trad['Common_Parameter_AnswerTypeToolTip'] = '';
trad['Common_Parameter_AnswerType_eGuidee'] = '';
trad['Common_Parameter_AnswerType_eLibre'] = '';
trad['Common_Parameter_AutoDistractor'] = '';
trad['Common_Parameter_AutoDistractor§Reduced'] = '';
trad['Common_Parameter_AutoDistractor§Short'] = '';
trad['Common_Parameter_AutoDistractor§ToolTip'] = '';
trad['Common_Parameter_AutoDistractorActive'] = '';
trad['Common_Parameter_AutoDistractor_eNone'] = '';
trad['Common_Parameter_AutoDistractor_eNoneToolTip'] = '';
trad['Common_Parameter_AutoDistractor_eMultiple'] = '';
trad['Common_Parameter_AutoDistractor_eMultipleToolTip'] = '';
trad['Common_Parameter_AutoDistractor_eAudio'] = '';
trad['Common_Parameter_AutoDistractor_eAudio§Short'] = '';
trad['Common_Parameter_AutoDistractor_eAudio§ToolTip'] = '';
trad['Common_Parameter_AutoDistractor_eVideo'] = '';
trad['Common_Parameter_AutoDistractor_eVideo§Short'] = '';
trad['Common_Parameter_AutoDistractor_eVideo§ToolTip'] = '';
trad['Common_Parameter_AutoDistractor_eSimulTask'] = '';
trad['Common_Parameter_AutoDistractor_eSimulTask§Short'] = '';
trad['Common_Parameter_AutoDistractor_eSimulTask§ToolTip'] = '';
trad['Common_Parameter_AutoDistractorFrequency'] = '';
trad['Common_Parameter_AutoDistractorFrequency§Reduced'] = '';
trad['Common_Parameter_AutoDistractorFrequency§Short'] = '';
trad['Common_Parameter_AutoDistractorFrequency§ToolTip'] = '';
trad['Common_Parameter_AutodistractorFrequency_eLowToolTip'] = '';
trad['Common_Parameter_AutodistractorFrequency_eMediumToolTip'] = '';
trad['Common_Parameter_AutodistractorFrequency_eHighToolTip'] = '';
trad['Common_Parameter_Autozoom'] = '';
trad['Common_Parameter_AutozoomDesc'] = '';
trad['Common_Parameter_AutozoomToolTip'] = '';
trad['Common_Parameter_Background'] = '';
trad['Common_Parameter_Background§Reduced'] = '';
trad['Common_Parameter_Background§ShortShort'] = '';
trad['Common_Parameter_Background§ToolTip'] = '';
trad['Common_Parameter_Begin'] = '';
trad['Common_Parameter_BeginDesc'] = '';
trad['Common_Parameter_BeginToolTip'] = '';
trad['Common_Parameter_Cancel'] = '';
trad['Common_Parameter_Cancel§Reduced'] = '';
trad['Common_Parameter_Cancel§Short'] = '';
trad['Common_Parameter_Cancel§ToolTip'] = '';
trad['Common_Parameter_Cancel_eSimple'] = '';
trad['Common_Parameter_Cancel_eSimple§Short'] = '';
trad['Common_Parameter_Cancel_eSimple§ToolTip'] = '';
trad['Common_Parameter_Cancel_eDouble'] = '';
trad['Common_Parameter_Cancel_eDouble§Short'] = '';
trad['Common_Parameter_Cancel_eDouble§ToolTip'] = '';
trad['Common_Parameter_Cancel_eTriple'] = '';
trad['Common_Parameter_Cancel_eTriple§Short'] = '';
trad['Common_Parameter_Cancel_eTriple§ToolTip'] = '';
trad['Common_Parameter_Categories_Mother'] = '';
trad['Common_Parameter_Cancel_eQuadruple'] = '';
trad['Common_Parameter_Cancel_eQuadruple§Short'] = '';
trad['Common_Parameter_Cancel_eQuadruple§ToolTip'] = '';
trad['Common_Parameter_Categories_N1'] = '';
trad['Common_Parameter_Categories_N2'] = '';
trad['Common_Parameter_Categories_N3'] = '';
trad['Common_Parameter_CategoriesSelection'] = '';
trad['Common_Parameter_CategoriesSelection§ToolTip'] = '';
trad['Common_Parameter_Category'] = '';
trad['Common_Parameter_Category§Reduced'] = '';
trad['Common_Parameter_Category§ToolTip'] = '';
trad['Common_Parameter_Category_eAnimals'] = '';
trad['Common_Parameter_Category_eAnimalsToolTip'] = '';
trad['Common_Parameter_Category_eVegetables'] = '';
trad['Common_Parameter_Category_eDress'] = '';
trad['Common_Parameter_Category_eJobs'] = '';
trad['Common_Parameter_Category_ePlants'] = '';
trad['Common_Parameter_Category_ePlantsToolTip'] = '';
trad['Common_Parameter_Category_eRandom'] = '';
trad['Common_Parameter_Category_eRandomToolTip'] = '';
trad['Common_Parameter_CharListDesc'] = '';
trad['Common_Parameter_CharList'] = '';
trad['Common_Parameter_CharListToolTip'] = '';
trad['Common_Parameter_Char_eLetters'] = '';
trad['Common_Parameter_Char_eNumbers'] = '';
trad['Common_Parameter_Char_eLettersNumbers'] = '';
trad['Common_Parameter_Char_eLettersNumbersShort'] = '';
trad['Common_Parameter_Colors_eWhite'] = '';
trad['Common_Parameter_Colors_eRed'] = '';
trad['Common_Parameter_Colors_eGreen'] = '';
trad['Common_Parameter_Colors_eBlue'] = '';
trad['Common_Parameter_Colors_eYellow'] = '';
trad['Common_Parameter_Colors_eBlack'] = '';
trad['Common_Parameter_Colors_ePink'] = '';
trad['Common_Parameter_Colors_eGrey'] = '';
trad['Common_Parameter_Colors_ePurple'] = '';
trad['Common_Parameter_Colors_eOrange'] = '';
trad['Common_Parameter_ColorUse'] = '';
trad['Common_Parameter_ColorUse§Reduced'] = '';
trad['Common_Parameter_ColorUse§Short'] = '';

trad['Common_Parameter_ColorUse_eNone'] = '';
trad['Common_Parameter_ColorUse_eNone§ToolTip'] = '';
trad['Common_Parameter_ColorUse_eOne'] = '';
trad['Common_Parameter_ColorUse_eOne§ToolTip'] = '';
trad['Common_Parameter_ColorUse_eTwo'] = '';
trad['Common_Parameter_ColorUse_eTwo§ToolTip'] = '';
trad['Common_Parameter_ColorUse_eThree'] = '';
trad['Common_Parameter_ColorUse_eThree§ToolTip'] = '';
trad['Common_Parameter_ColorUse_eAll'] = '';
trad['Common_Parameter_ColorUse_eAll§ToolTip'] = '';
trad['Common_Parameter_Complexity§Reduced'] = '';
trad['Common_Parameter_Complexity§Short'] = '';
trad['Common_Parameter_Complexity_eVerySimple'] = '';
trad['Common_Parameter_Complexity_eSimple'] = '';
trad['Common_Parameter_Complexity_eAverage'] = '';
trad['Common_Parameter_Complexity_eComplex'] = '';
trad['Common_Parameter_Complexity_eHard'] = '';
trad['Common_Parameter_Complexity_eVeryHard'] = '';
trad['Common_Parameter_Configs'] = '';
trad['Common_Parameter_ConfigsDesc'] = '';
trad['Common_Parameter_Config_e2x2'] = '';
trad['Common_Parameter_Config_e4Circles'] = '';
trad['Common_Parameter_Config_e2x3'] = '';
trad['Common_Parameter_Config_e6Circles'] = '';
trad['Common_Parameter_Config_e3x3'] = '';
trad['Common_Parameter_Config_e10Circles'] = '';
trad['Common_Parameter_Config_e10Triangles'] = '';
trad['Common_Parameter_Config_e4x4'] = '';
trad['Common_Parameter_Config_e16Triangles'] = '';
trad['Common_Parameter_Config_e5x5'] = '';
trad['Common_Parameter_Config_eCube3x3'] = '';
trad['Common_Parameter_Config_e20Triangles'] = '';
trad['Common_Parameter_CountTarget'] = '';
trad['Common_Parameter_CountTarget§Reduced'] = '';
trad['Common_Parameter_CountTarget§Short'] = '';
trad['Common_Parameter_CountTarget§ToolTip'] = '';
trad['Common_Parameter_CountTarget_eNumbers'] = '';
trad['Common_Parameter_CountTarget_eNumbers§Short'] = '';
trad['Common_Parameter_CountTarget_eNumbers§ToolTip'] = '';
trad['Common_Parameter_CountTarget_eSquares'] = '';
trad['Common_Parameter_CountTarget_eSquares§Short'] = '';
trad['Common_Parameter_CountTarget_eSquares§ToolTip'] = '';
trad['Common_Parameter_CouplingRule'] = '';
trad['Common_Parameter_CouplingRule§Reduced'] = '';
trad['Common_Parameter_CouplingRule§ToolTip'] = '';
trad['Common_Parameter_CouplingRule_eIdentityWordShort'] = '';
trad['Common_Parameter_CouplingRule_eIdentityWordToolTip'] = '';
trad['Common_Parameter_CouplingRule_eIdentityCharShort'] = '';
trad['Common_Parameter_CouplingRule_eIdentityCharToolTip'] = '';
trad['Common_Parameter_CouplingRule_eAssociation'] = '';
trad['Common_Parameter_CouplingRule_eAssociation§Reduced'] = '';
trad['Common_Parameter_CouplingRule_eAssociation§ToolTip'] = '';
trad['Common_Parameter_CouplingRule_eIdentity'] = '';
trad['Common_Parameter_CouplingRule_eIdentity§Reduced'] = '';
trad['Common_Parameter_CouplingRule_eIdentity§ToolTip'] = '';
trad['Common_Parameter_CouplingRuleAssociations'] = '';
trad['Common_Parameter_CouplingRuleAssociations§Reduced'] = '';
trad['Common_Parameter_CouplingRuleAssociations§ToolTip'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eUpperLower'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eUpperLower§Short'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eUpperLower§ToolTip'] = '';
trad['Common_Parameter_CouplingRuleAssociations_ePicName'] = '';
trad['Common_Parameter_CouplingRuleAssociations_ePicName§Short'] = '';
trad['Common_Parameter_CouplingRuleAssociations_ePicName§ToolTip'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eNumSymb'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eNumSymb§ToolTip'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eColName'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eColName§Short'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eColName§ToolTip'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eNumOp'] = '';
trad['Common_Parameter_CouplingRuleAssociations_eNumOpToolTip'] = '';
trad['Common_Parameter_DefineInstructionsDesc'] = '';
trad['Common_Parameter_DefineInstructionsToolTip'] = '';
trad['Common_Parameter_DefineInstruction1'] = '';
trad['Common_Parameter_DefineInstruction2'] = '';
trad['Common_Parameter_DefineInstruction3'] = '';
trad['Common_Parameter_DefineInstruction4'] = '';
trad['Common_Parameter_DefineInstruction5'] = '';
trad['Common_Parameter_DefineInstruction6'] = '';
trad['Common_Parameter_DefineInstruction_1L'] = '';
trad['Common_Parameter_DefineInstruction_2L'] = '';
trad['Common_Parameter_DefineInstruction_3L'] = '';
trad['Common_Parameter_DefineInstruction_1R'] = '';
trad['Common_Parameter_DefineInstruction_2R'] = '';
trad['Common_Parameter_DefineInstruction_3R'] = '';
trad['Common_Parameter_DefineInstruction_R1'] = '';
trad['Common_Parameter_DefineInstruction_R2'] = '';
trad['Common_Parameter_DefineInstruction_R3'] = '';
trad['Common_Parameter_Depth'] = '';
trad['Common_Parameter_Depth§Reduced'] = '';
trad['Common_Parameter_Depth§Short'] = '';
trad['Common_Parameter_Depth§ToolTip'] = '';
trad['Common_Parameter_Depth_eN1'] = '';
trad['Common_Parameter_Depth_eN1§Short'] = '';
trad['Common_Parameter_Depth_eN2'] = '';
trad['Common_Parameter_Depth_eN2§Short'] = '';
trad['Common_Parameter_Depth_eN3'] = '';
trad['Common_Parameter_Depth_eN3§Short'] = '';
trad['Common_Parameter_Depth_eN4'] = '';
trad['Common_Parameter_Depth_eN4§Short'] = '';
trad['Common_Parameter_Display'] = '';
trad['Common_Parameter_Display§Reduced'] = '';
trad['Common_Parameter_Display§Short'] = '';
trad['Common_Parameter_Display§ToolTip'] = '';
trad['Common_Parameter_Display_PhonoHelpDesc'] = '';
trad['Common_Parameter_Display_PhonoHelp'] = '';
trad['Common_Parameter_Display_SemanticHelpDesc'] = '';
trad['Common_Parameter_Display_SemanticHelp'] = '';
trad['Common_Parameter_Display_eTextPict'] = '';
trad['Common_Parameter_Display_eTextPictSingle'] = '';
trad['Common_Parameter_Display_eTextPict§Short'] = '';
trad['Common_Parameter_Display_eTextPict§ToolTip'] = '';
trad['Common_Parameter_Display_eTextOnly'] = '';
trad['Common_Parameter_Display_eTextOnlySingle'] = '';
trad['Common_Parameter_Display_eTextOnly§Reduced'] = '';
trad['Common_Parameter_Display_eTextOnly§Short'] = '';
trad['Common_Parameter_Display_eTextOnly§ToolTip'] = '';
trad['Common_Parameter_Display_ePictOnly'] = '';
trad['Common_Parameter_Display_ePictOnlySingle'] = '';
trad['Common_Parameter_Display_ePictOnly§Reduced'] = '';
trad['Common_Parameter_Display_ePictOnly§Short'] = '';
trad['Common_Parameter_Display_ePictOnly§ToolTip'] = '';
trad['Common_Parameter_DisplayCategories'] = '';
trad['Common_Parameter_DisplayCategories§Reduced'] = '';
trad['Common_Parameter_DisplayCategories§Short'] = '';
trad['Common_Parameter_DisplayCategories§ToolTip'] = '';
trad['Common_Parameter_DisplayCategories_eNone'] = '';
trad['Common_Parameter_DisplayCategories_eNone§Short'] = '';
trad['Common_Parameter_DisplayCategories_eNone§ToolTip'] = '';
trad['Common_Parameter_DisplayCategories_eNumber'] = '';
trad['Common_Parameter_DisplayCategories_eNumber§Short'] = '';
trad['Common_Parameter_DisplayCategories_eNumber§ToolTip'] = '';
trad['Common_Parameter_DisplayCategories_eName'] = '';
trad['Common_Parameter_DisplayCategories_eName§Short'] = '';
trad['Common_Parameter_DisplayCategories_eName§ToolTip'] = '';
trad['Common_Parameter_DisplayPersistence'] = '';
trad['Common_Parameter_DisplayPersistenceDesc'] = '';
trad['Common_Parameter_DisplayPersistenceToolTip'] = '';
trad['Common_Parameter_DisplaySolution'] = '';
trad['Common_Parameter_DisplaySolutionToolTip'] = '';
trad['Common_Parameter_DisplayRight'] = '';
trad['Common_Parameter_DisplayRight§Reduced'] = '';
trad['Common_Parameter_DisplayRight§ToolTip'] = '';
trad['Common_Parameter_DisplayRight_eVisible'] = '';
trad['Common_Parameter_DisplayRight_eVisible§Short'] = '';
trad['Common_Parameter_DisplayRight_eVisible§ToolTip'] = '';
trad['Common_Parameter_DisplayRight_eVisibleShaded'] = '';
trad['Common_Parameter_DisplayRight_eVisibleShaded§Short'] = '';
trad['Common_Parameter_DisplayRight_eVisibleShaded§ToolTip'] = '';
trad['Common_Parameter_DisplayRight_eHidden'] = '';
trad['Common_Parameter_DisplayRight_eHidden§Short'] = '';
trad['Common_Parameter_DisplayRight_eHidden§ToolTip'] = '';
trad['Common_Parameter_DisplayRight_eHiddenShaded'] = '';
trad['Common_Parameter_DisplayRight_eHiddenShaded§Short'] = '';
trad['Common_Parameter_DisplayRight_eHiddenShaded§ToolTip'] = '';
trad['Common_Parameter_DisplayRight_eRemoved'] = '';
trad['Common_Parameter_DisplayRight_eRemoved§ToolTip'] = '';
trad['Common_Parameter_DisplayTime'] = '';
trad['Common_Parameter_DisplayTime§Reduced'] = '';
trad['Common_Parameter_DisplayTime§Short'] = '';
trad['Common_Parameter_DisplayTime§ToolTip'] = '';
trad['Common_Parameter_DisplayTime_eVerySlow'] = '';
trad['Common_Parameter_DisplayTime_eVerySlow§ToolTip'] = '';
trad['Common_Parameter_DisplayTime_eSlow'] = '';
trad['Common_Parameter_DisplayTime_eSlowToolTip'] = '';
trad['Common_Parameter_DisplayTime_eNormal'] = '';
trad['Common_Parameter_DisplayTime_eNormalToolTip'] = '';
trad['Common_Parameter_DisplayTime_eManual'] = '';
trad['Common_Parameter_DisplayTime_eManual§Short'] = '';
trad['Common_Parameter_DisplayTime_eManual§ToolTip'] = '';
trad['Common_Parameter_DistractingInformation'] = '';
trad['Common_Parameter_DistractingInformationToolTip'] = '';
trad['Common_Parameter_Duration'] = '';
trad['Common_Parameter_Duration§Reduced'] = '';
trad['Common_Parameter_Duration§Short'] = '';
trad['Common_Parameter_Duration§ToolTip'] = '';
trad['Common_Parameter_Duration_e1'] = '';
trad['Common_Parameter_Duration_e3'] = '';
trad['Common_Parameter_Duration_e5'] = '';
trad['Common_Parameter_Duration_e10'] = '';
trad['Common_Parameter_Duration_e15'] = '';
trad['Common_Parameter_Duration_e20'] = '';
trad['Common_Parameter_Duration_e30'] = '';
trad['Common_Parameter_Duration_eNone'] = '';
trad['Common_Parameter_Duration_eNone§Short'] = '';
trad['Common_Parameter_End'] = '';
trad['Common_Parameter_EndDesc'] = '';
trad['Common_Parameter_EndToolTip'] = '';
trad['Common_Parameter_FlipBackTimer'] = '';
trad['Common_Parameter_FlipBackTimer§Reduced'] = '';
trad['Common_Parameter_FlipBackTimer§ToolTip'] = '';
trad['Common_Parameter_FlipBackTimer_eQuick'] = '';
trad['Common_Parameter_FlipBackTimer_eQuick§Short'] = '';
trad['Common_Parameter_FlipBackTimer_eQuick§ToolTip'] = '';
trad['Common_Parameter_FlipBackTimer_eAverage'] = '';
trad['Common_Parameter_FlipBackTimer_eAverage§Short'] = '';
trad['Common_Parameter_FlipBackTimer_eAverage§ToolTip'] = '';
trad['Common_Parameter_FlipBackTimer_eSlow'] = '';
trad['Common_Parameter_FlipBackTimer_eSlow§Short'] = '';
trad['Common_Parameter_FlipBackTimer_eSlow§ToolTip'] = '';
trad['Common_Parameter_FlipBackTimer_eManual'] = '';
trad['Common_Parameter_FlipBackTimer_eManual§Short'] = '';
trad['Common_Parameter_FlipBackTimer_eManual§ToolTip'] = '';
trad['Common_Parameter_Frequency'] = '';
trad['Common_Parameter_Frequency_eDisabled'] = '';
trad['Common_Parameter_Frequency_eLow'] = '';
trad['Common_Parameter_Frequency_eMedium'] = '';
trad['Common_Parameter_Frequency_eHigh'] = '';
trad['Common_Parameter_GameSpeed'] = '';
trad['Common_Parameter_GameSpeed§Reduced'] = '';
trad['Common_Parameter_GameSpeed§Short'] = '';
trad['Common_Parameter_GameSpeed§ToolTip'] = '';
trad['Common_Parameter_GameSpeed_eSlow'] = '';
trad['Common_Parameter_GameSpeed_eSlowToolTip'] = '';
trad['Common_Parameter_GameSpeed_eModerate'] = '';
trad['Common_Parameter_GameSpeed_eModerateToolTip'] = '';
trad['Common_Parameter_GameSpeed_eFast'] = '';
trad['Common_Parameter_GameSpeed_eFastToolTip'] = '';
trad['Common_Parameter_GameSpeed_eVeryFast'] = '';
trad['Common_Parameter_GameSpeed_eVeryFastToolTip'] = '';
trad['Common_Parameter_Goal'] = '';
trad['Common_Parameter_GoalDesc'] = '';
trad['Common_Parameter_Goal_eAudio'] = '';
trad['Common_Parameter_Goal_eNoAudio'] = '';
trad['Common_Parameter_Guiding'] = '';
trad['Common_Parameter_GuidingDesc'] = '';
trad['Common_Parameter_GuidingToolTip'] = '';
trad['Common_Parameter_Guiding_eFree'] = '';
trad['Common_Parameter_Guiding_eFreeShort'] = '';
trad['Common_Parameter_Guiding_eFreeToolTip'] = '';
trad['Common_Parameter_Guiding_eGuided'] = '';
trad['Common_Parameter_Guiding_eGuidedShort'] = '';
trad['Common_Parameter_Guiding_eGuidedToolTip'] = '';
trad['Common_Parameter_IngredientsQuantity'] = '';
trad['Common_Parameter_IngredientsQuantityToolTip'] = '';
trad['Common_Parameter_InstructionType'] = '';
trad['Common_Parameter_InstructionTypeToolTip'] = '';
trad['Common_Parameter_InstructionType_eText'] = '';
trad['Common_Parameter_InstructionType_eAudio'] = '';
trad['Common_Parameter_InstructionIndexDesc'] = '';
trad['Common_Parameter_InstructionIndexToolTip'] = '';
trad['Common_Parameter_InstructionStrike'] = '';
trad['Common_Parameter_InstructionStrikeDesc'] = '';
trad['Common_Parameter_InstructionStrikeToolTip'] = '';
trad['Common_Parameter_Interf'] = '';
trad['Common_Parameter_InterfToolTip'] = '';
trad['Common_Parameter_Interf_Multiple'] = '';
trad['Common_Parameter_Interf_MultipleToolTip'] = '';
trad['Common_Parameter_Interf_NoneToolTip'] = '';
trad['Common_Parameter_InterfNumber'] = '';
trad['Common_Parameter_InterfNumber§Reduced'] = '';
trad['Common_Parameter_InterfNumber§Short'] = '';
trad['Common_Parameter_InterfNumber§ToolTip'] = '';
trad['Common_Parameter_InterfNumberActive'] = '';
trad['Common_Parameter_InterfNumber_Type'] = '';
trad['Common_Parameter_InterfNumber_Type§Reduced'] = '';
trad['Common_Parameter_InterfNumber_Type§Short'] = '';
trad['Common_Parameter_InterfNumber_Type§ToolTip'] = '';
trad['Common_Parameter_InterfNumber_Type_eDigit'] = '';
trad['Common_Parameter_InterfNumber_Type_eWords'] = '';
trad['Common_Parameter_InterfNumber_Type_eWordsDigits'] = '';
trad['Common_Parameter_InterfNumber_Multiple'] = '';
trad['Common_Parameter_InterfNumber_Multiple§Reduced'] = '';
trad['Common_Parameter_InterfNumber_Multiple§Short'] = '';
trad['Common_Parameter_InterfNumber_Multiple§ToolTip'] = '';
trad['Common_Parameter_InterfNumber_Multiple_eOne'] = '';
trad['Common_Parameter_InterfNumber_Multiple_eOne§Short'] = '';
trad['Common_Parameter_InterfNumber_Multiple_eFew'] = '';
trad['Common_Parameter_InterfNumber_Multiple_eSeveral§Short'] = '';
trad['Common_Parameter_InterfNumber_Multiple_eMany'] = '';
trad['Common_Parameter_InterfNumber_Multiple_eMany§Short'] = '';
trad['Common_Parameter_InterfNumber_Multiple_eAll'] = '';
trad['Common_Parameter_InterfNumber_Multiple_eAll§Short'] = '';
trad['Common_Parameter_InterfNumber_Animate'] = '';
trad['Common_Parameter_InterfNumber_Animate§Reduced'] = '';
trad['Common_Parameter_InterfNumber_Animate§Short'] = '';
trad['Common_Parameter_InterfNumber_Animate§ToolTip'] = '';
trad['Common_Parameter_InterfNumber_Delay'] = '';
trad['Common_Parameter_InterfNumber_Delay§Reduced'] = '';
trad['Common_Parameter_InterfNumber_Delay§Short'] = '';
trad['Common_Parameter_InterfNumber_Delay§ToolTip'] = '';
trad['Common_Parameter_InterfVoice'] = '';
trad['Common_Parameter_InterfVoice§Reduced'] = '';
trad['Common_Parameter_InterfVoice§Short'] = '';
trad['Common_Parameter_InterfVoice§ToolTip'] = '';
trad['Common_Parameter_InterfVoiceActive'] = '';
trad['Common_Parameter_InterfShape'] = '';
trad['Common_Parameter_InterfShape§Reduced'] = '';
trad['Common_Parameter_InterfShape§Short'] = '';
trad['Common_Parameter_InterfShape§ToolTip'] = '';
trad['Common_Parameter_InterfShapeActive'] = '';
trad['Common_Parameter_InterfShape_Freq'] = '';
trad['Common_Parameter_InterfShape_Freq§Reduced'] = '';
trad['Common_Parameter_InterfShape_Freq§Short'] = '';
trad['Common_Parameter_InterfShape_Freq§ToolTip'] = '';
trad['Common_Parameter_InterfColor'] = '';
trad['Common_Parameter_InterfColor§Reduced'] = '';
trad['Common_Parameter_InterfColor§Short'] = '';
trad['Common_Parameter_InterfColor§ToolTip'] = '';
trad['Common_Parameter_InterfColorActive'] = '';
trad['Common_Parameter_InterfColor_Multiple'] = '';
trad['Common_Parameter_InterfColor_Multiple§Reduced'] = '';
trad['Common_Parameter_InterfColor_Multiple§Short'] = '';
trad['Common_Parameter_InterfColor_Multiple§ToolTip'] = '';
trad['Common_Parameter_ItemProximity'] = '';
trad['Common_Parameter_ItemProximity§Reduced'] = '';
trad['Common_Parameter_ItemProximity§Short'] = '';
trad['Common_Parameter_ItemProximity§ToolTip'] = '';
trad['Common_Parameter_ItemProximity_eClose'] = '';
trad['Common_Parameter_ItemProximity_eClose§ToolTip'] = '';
trad['Common_Parameter_ItemProximity_eRemote'] = '';
trad['Common_Parameter_ItemProximity_eRemote§ToolTip'] = '';
trad['Common_Parameter_ItemSize'] = '';
trad['Common_Parameter_ItemSize§Reduced'] = '';
trad['Common_Parameter_ItemSize§Short'] = '';
trad['Common_Parameter_ItemSize§ToolTip'] = '';
trad['Common_Parameter_ItemSize_eSmall'] = '';
trad['Common_Parameter_ItemSize_eSmall§ToolTip'] = '';
trad['Common_Parameter_ItemSize_eMedium'] = '';
trad['Common_Parameter_ItemSize_eMedium§ToolTip'] = '';
trad['Common_Parameter_ItemSize_eLarge'] = '';
trad['Common_Parameter_ItemSize_eLarge§ToolTip'] = '';
trad['Common_Parameter_ItemType'] = '';
trad['Common_Parameter_ItemType§Reduced'] = '';
trad['Common_Parameter_ItemType§Short'] = '';
trad['Common_Parameter_ItemType§ToolTip'] = '';
trad['Common_Parameter_ItemType_eChars'] = '';
trad['Common_Parameter_ItemType_eChars§Reduced'] = '';
trad['Common_Parameter_ItemType_eChars§Short'] = '';
trad['Common_Parameter_ItemType_eChar_Type'] = '';
trad['Common_Parameter_ItemType_eChar_Type§ToolTip'] = '';
trad['Common_Parameter_ItemType_eChar_Type_ePunctuation'] = '';
trad['Common_Parameter_ItemType_eChar_Type_ePunctuationDesc'] = '';
trad['Common_Parameter_ItemType_eChar_Type_eSpecial'] = '';
trad['Common_Parameter_ItemType_eChar_Type_eSpecialDesc'] = '';
trad['Common_Parameter_ItemType_eLetters'] = '';
trad['Common_Parameter_ItemType_eLetters§Reduced'] = '';
trad['Common_Parameter_ItemType_eLetters§Short'] = '';
trad['Common_Parameter_ItemType_eLetters_Type'] = '';
trad['Common_Parameter_ItemType_eLetters_Type§Reduced'] = '';
trad['Common_Parameter_ItemType_eLetters_Type§Short'] = '';
trad['Common_Parameter_ItemType_eLetters_Type§ToolTip'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eLower'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eLower§Short'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eUpper'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eUpper§Short'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eBoth'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eBoth§Short'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eEither'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eEither§Short'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_ePonctuation'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_ePonctuation§Short'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eSpecial'] = '';
trad['Common_Parameter_ItemType_eLetters_Type_eSpecial§Short'] = '';
trad['Common_Parameter_ItemType_eNumbers'] = '';
trad['Common_Parameter_ItemType_eNumbers§Reduced'] = '';
trad['Common_Parameter_ItemType_eNumbers§Short'] = '';
trad['Common_Parameter_ItemType_ePictures'] = '';
trad['Common_Parameter_ItemType_ePictures§Short'] = '';
trad['Common_Parameter_ItemType_ePhotos'] = '';
trad['Common_Parameter_ItemType_ePhotos§Short'] = '';
trad['Common_Parameter_ItemType_eShapes'] = '';
trad['Common_Parameter_ItemType_eShapes§Reduced'] = '';
trad['Common_Parameter_ItemType_eShapes§Short'] = '';
trad['Common_Parameter_ItemType_eWords'] = '';
trad['Common_Parameter_ItemType_eWords§Short'] = '';
trad['Common_Parameter_KeepFirstCard'] = '';
trad['Common_Parameter_KeepFirstCard§Reduced'] = '';
trad['Common_Parameter_KeepFirstCard§ToolTip'] = '';
trad['Common_Parameter_Layout'] = '';
trad['Common_Parameter_Layout§Reduced'] = '';
trad['Common_Parameter_Layout§Short'] = '';
trad['Common_Parameter_Layout§ToolTip'] = '';
trad['Common_Parameter_Layout_eRandom'] = '';
trad['Common_Parameter_Layout_eRandom§ToolTip'] = '';
trad['Common_Parameter_Layout_eGrid'] = '';
trad['Common_Parameter_Layout_eGrid§Short'] = '';
trad['Common_Parameter_Layout_eGrid§ToolTip'] = '';
trad['Common_Parameter_Layout_eGridSize'] = '';
trad['Common_Parameter_Layout_eGridSize§Reduced'] = '';
trad['Common_Parameter_Layout_eGridSize§Short'] = '';
trad['Common_Parameter_Layout_eGridSize§ToolTip'] = '';
trad['Common_Parameter_Layout_eGridSize_e3x3'] = '';
trad['Common_Parameter_Layout_eGridSize_e5x5'] = '';
trad['Common_Parameter_Layout_eGridSize_e7x7'] = '';
trad['Common_Parameter_Layout_eGridSize_e7x10'] = '';
trad['Common_Parameter_Layout_eGridSize_e7x15'] = '';
trad['Common_Parameter_Layout_eDice'] = '';
trad['Common_Parameter_Layout_eDice§Short'] = '';
trad['Common_Parameter_Layout_eDice§ToolTip'] = '';
trad['Common_Parameter_Layout_eRow'] = '';
trad['Common_Parameter_Layout_eRow§Short'] = '';
trad['Common_Parameter_Layout_eRow§ToolTip'] = '';
trad['Common_Parameter_Layout_eColumn'] = '';
trad['Common_Parameter_Layout_eColumn§Short'] = '';
trad['Common_Parameter_Layout_eColumn§ToolTip'] = '';
trad['Common_Parameter_Length'] = '';
trad['Common_Parameter_Length§Reduced'] = '';
trad['Common_Parameter_Length§Short'] = '';
trad['Common_Parameter_Length§ToolTip'] = '';
trad['Common_Parameter_Length_eShort'] = '';
trad['Common_Parameter_Length_eLong'] = '';
trad['Common_Parameter_LimitByAge'] = '';
trad['Common_Parameter_LimitByAge§Reduced'] = '';
trad['Common_Parameter_LimitByAge§Short'] = '';
trad['Common_Parameter_LimitByAge§ToolTip'] = '';
trad['Common_Parameter_MapAvailable'] = '';
trad['Common_Parameter_MapAvailableDesc'] = '';
trad['Common_Parameter_MapAvailableToolTip'] = '';
trad['Common_Parameter_Music'] = '';
trad['Common_Parameter_Music§Reduced'] = '';
trad['Common_Parameter_Music§Short'] = '';
trad['Common_Parameter_Music§ToolTip'] = '';
trad['Common_Parameter_NbArticles'] = '';
trad['Common_Parameter_NbArticles§ToolTip'] = '';
trad['Common_Parameter_NbArticles_e1'] = '';
trad['Common_Parameter_NbArticles_e2'] = '';
trad['Common_Parameter_NbArticles_e3'] = '';
trad['Common_Parameter_NbArticles_e4'] = '';
trad['Common_Parameter_NbArticles_e5'] = '';
trad['Common_Parameter_NbArticles_e6'] = '';
trad['Common_Parameter_NbArticles_e7'] = '';
trad['Common_Parameter_NbArticles_e8'] = '';
trad['Common_Parameter_NbArticles_e9'] = '';
trad['Common_Parameter_NbArticles_e10'] = '';
trad['Common_Parameter_NbCards'] = '';
trad['Common_Parameter_NbCardsShort'] = '';
trad['Common_Parameter_NbCardsToolTip'] = '';
trad['Common_Parameter_NbCards_e4'] = '';
trad['Common_Parameter_NbCards_e6'] = '';
trad['Common_Parameter_NbCards_e8'] = '';
trad['Common_Parameter_NbCards_e12'] = '';
trad['Common_Parameter_NbCards_e16'] = '';
trad['Common_Parameter_NbCards_e20'] = '';
trad['Common_Parameter_NbCards_e24'] = '';
trad['Common_Parameter_NbCards_e30'] = '';
trad['Common_Parameter_NbCards_e36'] = '';
trad['Common_Parameter_NbCards_e42'] = '';
trad['Common_Parameter_NbCategories'] = '';
trad['Common_Parameter_NbCategories§Reduced'] = '';
trad['Common_Parameter_NbCategories§Short'] = '';
trad['Common_Parameter_NbCategories§ToolTip'] = '';
trad['Common_Parameter_NbCategories_e2'] = '';
trad['Common_Parameter_NbCategories_e3'] = '';
trad['Common_Parameter_NbColors'] = '';
trad['Common_Parameter_NbColors§Reduced'] = '';
trad['Common_Parameter_NbColors§Short'] = '';
trad['Common_Parameter_NbColors§ToolTip'] = '';
trad['Common_Parameter_NbColors_e0'] = '';
trad['Common_Parameter_NbColors_e1'] = '';
trad['Common_Parameter_NbColors_e2'] = '';
trad['Common_Parameter_NbConfigs§Reduced'] = '';
trad['Common_Parameter_NbConfigs§Short'] = '';
trad['Common_Parameter_NbConfigs_e1'] = '';
trad['Common_Parameter_NbConfigs_e2'] = '';
trad['Common_Parameter_NbConfigs_e3'] = '';
trad['Common_Parameter_NbInterfColor§Reduced'] = '';
trad['Common_Parameter_NbInterfColor'] = '';
trad['Common_Parameter_NbInterfColor§Short'] = '';
trad['Common_Parameter_NbInterfColor§ToolTip'] = '';
trad['Common_Parameter_NbInstructionsDesc'] = '';
trad['Common_Parameter_NbInstructions'] = '';
trad['Common_Parameter_NbInstructionsToolTip'] = '';
trad['Common_Parameter_NbInstructionsShort'] = '';
trad['Common_Parameter_NbInstructions_e2'] = '';
trad['Common_Parameter_NbInstructions_e3'] = '';
trad['Common_Parameter_NbInstructions_e4'] = '';
trad['Common_Parameter_NbInstructions_e5'] = '';
trad['Common_Parameter_NbInstructions_e6'] = '';
trad['Common_Parameter_NbItems'] = '';
trad['Common_Parameter_NbItems§Reduced'] = '';
trad['Common_Parameter_NbItems§Short'] = '';
trad['Common_Parameter_NbItems§ToolTip'] = '';
trad['Common_Parameter_NbItemsUnit'] = '';
trad['Common_Parameter_NbItems_e1'] = '';
trad['Common_Parameter_NbItems_e2'] = '';
trad['Common_Parameter_NbItems_e3'] = '';
trad['Common_Parameter_NbItems_e4'] = '';
trad['Common_Parameter_NbItems_e5'] = '';
trad['Common_Parameter_NbItems_e6'] = '';
trad['Common_Parameter_NbItems_e7'] = '';
trad['Common_Parameter_NbItems_e8'] = '';
trad['Common_Parameter_NbItems_e10'] = '';
trad['Common_Parameter_NbItems_e12'] = '';
trad['Common_Parameter_NbItems_e15'] = '';
trad['Common_Parameter_NbItems_e16'] = '';
trad['Common_Parameter_NbItems_e20'] = '';
trad['Common_Parameter_NbItems_e24'] = '';
trad['Common_Parameter_NbItems_e25'] = '';
trad['Common_Parameter_NbItems_e30'] = '';
trad['Common_Parameter_NbItems_e36'] = '';
trad['Common_Parameter_NbItems_e42'] = '';
trad['Common_Parameter_NbItemTypes'] = '';
trad['Common_Parameter_NbItemTypes§Reduced'] = '';
trad['Common_Parameter_NbItemTypes§Short'] = '';
trad['Common_Parameter_NbItemTypes§ToolTip'] = '';
trad['Common_Parameter_NbItemTypes_e2'] = '';
trad['Common_Parameter_NbItemTypes_e3'] = '';
trad['Common_Parameter_NbItemTypes_e4'] = '';
trad['Common_Parameter_NbItemTypes_e5'] = '';
trad['Common_Parameter_NbRuns'] = '';
trad['Common_Parameter_NbRuns§Reduced'] = '';
trad['Common_Parameter_NbRuns§Short'] = '';
trad['Common_Parameter_NbRuns§ToolTip'] = '';
trad['Common_Parameter_NbRuns_eNone'] = '';
trad['Common_Parameter_NbRuns_e1'] = '';
trad['Common_Parameter_NbRuns_e3'] = '';
trad['Common_Parameter_NbRuns_e5'] = '';
trad['Common_Parameter_NbRuns_e7'] = '';
trad['Common_Parameter_NbRuns_e10'] = '';
trad['Common_Parameter_NbRuns_e15'] = '';
trad['Common_Parameter_NbRuns_e20'] = '';
trad['Common_Parameter_NbRuns_e24'] = '';
trad['Common_Parameter_NbRuns_e25'] = '';
trad['Common_Parameter_NbRuns_e30'] = '';
trad['Common_Parameter_NbRuns_e35'] = '';
trad['Common_Parameter_NbRuns_e40'] = '';
trad['Common_Parameter_NbRuns_e50'] = '';
trad['Common_Parameter_NbRuns_e60'] = '';
trad['Common_Parameter_NbRuns_e75'] = '';
trad['Common_Parameter_NbRuns_e80'] = '';
trad['Common_Parameter_NbRuns_e100'] = '';
trad['Common_Parameter_NbSuggestions'] = '';
trad['Common_Parameter_NbSuggestions§Reduced'] = '';
trad['Common_Parameter_NbSuggestions§Short'] = '';
trad['Common_Parameter_NbSuggestions§ToolTip'] = '';
trad['Common_Parameter_NbSuggestions_e2'] = '';
trad['Common_Parameter_NbSuggestions_e3'] = '';
trad['Common_Parameter_NoConfiguration'] = '';
trad['Common_Parameter_Recipe'] = '';
trad['Common_Parameter_RecipeToolTip'] = '';
trad['Common_Parameter_RemoveAlcool'] = '';
trad['Common_Parameter_RemoveAlcoolToolTip'] = '';
trad['Common_Parameter_Roundabouts'] = '';
trad['Common_Parameter_Roundabouts§Reduced'] = '';
trad['Common_Parameter_Roundabouts§Short'] = '';
trad['Common_Parameter_Roundabouts§ToolTip'] = '';
trad['Common_Parameter_RunMode'] = '';
trad['Common_Parameter_Scenario'] = '';
trad['Common_Parameter_Scenario§Reduced'] = '';
trad['Common_Parameter_Scenario§Short'] = '';
trad['Common_Parameter_Scenario§ToolTip'] = '';
trad['Common_Parameter_SelectedWord'] = '';
trad['Common_Parameter_SelectedWordShort'] = '';
trad['Common_Parameter_SelectedWordDesc'] = '';
trad['Common_Parameter_SelectedWordToolTip'] = '';
trad['Common_Parameter_SelectedCategoryDesc'] = '';
trad['Common_Parameter_SelectedCategory'] = '';
trad['Common_Parameter_SelectedCategoryToolTip'] = '';
trad['Common_Parameter_SelectedColors'] = '';
trad['Common_Parameter_SelectedColors§Reduced'] = '';
trad['Common_Parameter_SelectedColors§Short'] = '';
trad['Common_Parameter_SelectedColors§ToolTip'] = '';
trad['Common_Parameter_SelectedGeometricShapes'] = '';
trad['Common_Parameter_SelectedGeometricShapes§Reduced'] = '';
trad['Common_Parameter_SelectedGeometricShapes§Short'] = '';
trad['Common_Parameter_SelectedGeometricShapes§ToolTip'] = '';
trad['Common_Parameter_SelectedTransportationShapes'] = '';
trad['Common_Parameter_SelectedTransportationShapes§Reduced'] = '';
trad['Common_Parameter_SelectedTransportationShapes§Short'] = '';
trad['Common_Parameter_SelectedTransportationShapes§ToolTip'] = '';
trad['Common_Parameter_Shape'] = '';
trad['Common_Parameter_Shape§Reduced'] = '';
trad['Common_Parameter_Shape§Short'] = '';
trad['Common_Parameter_Shape§ToolTip'] = '';
trad['Common_Parameter_Shape_eGeometry'] = '';
trad['Common_Parameter_Shape_eGeometry§Short'] = '';
trad['Common_Parameter_Shape_eGeometry_eSquare'] = '';
trad['Common_Parameter_Shape_eGeometry_eCircle'] = '';
trad['Common_Parameter_Shape_eGeometry_eTriangle'] = '';
trad['Common_Parameter_Shape_eGeometry_eStar'] = '';
trad['Common_Parameter_Shape_eGeometry_eMoon'] = '';
trad['Common_Parameter_Shape_eGeometry_eCross'] = '';
trad['Common_Parameter_Shape_eGeometry_eRhombus'] = '';
trad['Common_Parameter_Shape_eTransportation'] = '';
trad['Common_Parameter_Shape_eTransportation§Short'] = '';
trad['Common_Parameter_Shape_eTransportation_eCar'] = '';
trad['Common_Parameter_Shape_eTransportation_ePlane'] = '';
trad['Common_Parameter_Shape_eTransportation_eBike'] = '';
trad['Common_Parameter_Shape_eTransportation_eBoat'] = '';
trad['Common_Parameter_Shape_eTransportation_eBus'] = '';
trad['Common_Parameter_Shape_eTransportation_eTruck'] = '';
trad['Common_Parameter_Shape_eTransportation_eBalloon'] = '';
trad['Common_Parameter_Shape_eTransportation_eRocket'] = '';
trad['Common_Parameter_Shape_eTransportation_eVan'] = '';
trad['Common_Parameter_Shape_eTransportation_eHelicopter'] = '';
trad['Common_Parameter_Shape_eNone'] = '';
trad['Common_Parameter_Shape_eNone§Short'] = '';
trad['Common_Parameter_ShapeListDesc'] = '';
trad['Common_Parameter_ShapeList'] = '';
trad['Common_Parameter_ShapeListToolTip'] = '';
trad['Common_Parameter_Single'] = '';
trad['Common_Parameter_Single§Reduced'] = '';
trad['Common_Parameter_Single§Short'] = '';
trad['Common_Parameter_Single§ToolTip'] = '';
trad['Common_Parameter_Single_eDifferent'] = '';
trad['Common_Parameter_Single_eDifferent§Short'] = '';
trad['Common_Parameter_Single_eDifferent§ToolTip'] = '';
trad['Common_Parameter_Single_eSymmetry'] = '';
trad['Common_Parameter_Single_eSymmetry§Short'] = '';
trad['Common_Parameter_Single_eSymmetry§ToolTip'] = '';
trad['Common_Parameter_SkipOnError'] = '';
trad['Common_Parameter_SkipOnErrorDesc'] = '';
trad['Common_Parameter_SkipOnErrorToolTip'] = '';
trad['Common_Parameter_SoundComplexity'] = '';
trad['Common_Parameter_SoundComplexity§Reduced'] = '';
trad['Common_Parameter_SoundComplexity§Short'] = '';
trad['Common_Parameter_SoundComplexity§ToolTip'] = '';
trad['Common_Parameter_SoundVolume'] = '';
trad['Common_Parameter_SoundFirst'] = '';
trad['Common_Parameter_SoundFirst§Reduced'] = '';
trad['Common_Parameter_SoundFirst§Short'] = '';
trad['Common_Parameter_SoundFirst§ToolTip'] = '';
trad['Common_Parameter_SoundLast'] = '';
trad['Common_Parameter_SoundLast§Reduced'] = '';
trad['Common_Parameter_SoundLast§Short'] = '';
trad['Common_Parameter_SoundLast§ToolTip'] = '';
trad['Common_Parameter_SpeakerClipsFrequency'] = '';
trad['Common_Parameter_SpeakerClipsFrequency§Reduced'] = '';
trad['Common_Parameter_SpeakerClipsFrequency§Short'] = '';
trad['Common_Parameter_SpeakerClipsFrequency§ToolTip'] = '';
trad['Common_Parameter_TargetDisplay'] = '';
trad['Common_Parameter_TargetDisplay§Reduced'] = '';
trad['Common_Parameter_TargetDisplay§Short'] = '';
trad['Common_Parameter_TargetDisplay§ToolTip'] = '';
trad['Common_Parameter_TargetDisplay_eTemp'] = '';
trad['Common_Parameter_TargetDisplay_eTemp§Short'] = '';
trad['Common_Parameter_TargetDisplay_ePerm'] = '';
trad['Common_Parameter_TargetDisplay_ePerm§Short'] = '';
trad['Common_Parameter_TargetFrequency'] = '';
trad['Common_Parameter_TargetFrequency§Reduced'] = '';
trad['Common_Parameter_TargetFrequency§Short'] = '';
trad['Common_Parameter_TargetFrequency§ToolTip'] = '';
trad['Common_Parameter_TargetFrequency_e5'] = '';
trad['Common_Parameter_TargetFrequency_e5§Short'] = '';
trad['Common_Parameter_TargetFrequency_e10'] = '';
trad['Common_Parameter_TargetFrequency_e10§Short'] = '';
trad['Common_Parameter_TargetFrequency_e15'] = '';
trad['Common_Parameter_TargetFrequency_e15§Short'] = '';
trad['Common_Parameter_TargetFrequency_e20'] = '';
trad['Common_Parameter_TargetFrequency_e20§Short'] = '';
trad['Common_Parameter_Termination'] = '';
trad['Common_Parameter_TerminationDesc'] = '';
trad['Common_Parameter_TerminationToolTip'] = '';
trad['Common_Parameter_Termination_eDuration'] = '';
trad['Common_Parameter_Termination_eDurationToolTip'] = '';
trad['Common_Parameter_Termination_eRuns'] = '';
trad['Common_Parameter_Termination_eRunsToolTip'] = '';
trad['Common_Parameter_TotalPrice'] = '';
trad['Common_Parameter_TotalPriceDesc'] = '';
trad['Common_Parameter_TotalPriceToolTip'] = '';
trad['Common_Parameter_TrafficLight'] = '';
trad['Common_Parameter_TrafficLight§Reduced'] = '';
trad['Common_Parameter_TrafficLight§Short'] = '';
trad['Common_Parameter_TrafficLight§ToolTip'] = '';
trad['Common_Parameter_Traffic'] = '';
trad['Common_Parameter_Traffic§Reduced'] = '';
trad['Common_Parameter_Traffic§Short'] = '';
trad['Common_Parameter_Traffic§ToolTip'] = '';
trad['Common_Parameter_Traffic_eNone'] = '';
trad['Common_Parameter_Traffic_eNoneDesc'] = '';
trad['Common_Parameter_Traffic_eSparce'] = '';
trad['Common_Parameter_Traffic_eSparceDesc'] = '';
trad['Common_Parameter_Traffic_eMedium'] = '';
trad['Common_Parameter_Traffic_eMediumDesc'] = '';
trad['Common_Parameter_Traffic_eDense'] = '';
trad['Common_Parameter_Traffic_eDenseDesc'] = '';
trad['Common_Parameter_TransportComplexity_eSimple'] = '';
trad['Common_Parameter_TransportComplexity_eSimpleToolTip'] = '';
trad['Common_Parameter_TransportComplexity_eMedium'] = '';
trad['Common_Parameter_TransportComplexity_eMediumToolTip'] = '';
trad['Common_Parameter_TransportComplexity_eComplete'] = '';
trad['Common_Parameter_TransportComplexity_eCompleteToolTip'] = '';
trad['Common_Parameter_Vegetation'] = '';
trad['Common_Parameter_VegetationDesc'] = '';
trad['Common_Parameter_Vegetation_eNone'] = '';
trad['Common_Parameter_Vegetation_eNoneDesc'] = '';
trad['Common_Parameter_Vegetation_eRandom'] = '';
trad['Common_Parameter_Vegetation_eRandomDesc'] = '';
trad['Common_Parameter_Vegetation_eFull'] = '';
trad['Common_Parameter_Vegetation_eFullDesc'] = '';
trad['Common_Parameter_VoiceGPS'] = '';
trad['Common_Parameter_VoiceGPSToolTip'] = '';
trad['Common_Parameter_WordFrequency'] = '';
trad['Common_Parameter_WordFrequency§Reduced'] = '';
trad['Common_Parameter_WordFrequency§Short'] = '';
trad['Common_Parameter_WordFrequency§ToolTip'] = '';
trad['Common_Parameter_WordFrequency_eRare'] = '';
trad['Common_Parameter_WordFrequency_eAverage'] = '';
trad['Common_Parameter_WordFrequency_eFrequent'] = '';
trad['Common_Parameter_WordsSelection'] = '';
trad['Common_Parameter_WordsSelection§ToolTip'] = '';
trad['Common_Result_Answer'] = '';
trad['Common_Result_AttentionError'] = '';
trad['Common_Result_AttentionErrorShort'] = '';
trad['Common_Result_AutoCorrect'] = '';
trad['Common_Result_AvgMemorizationTime'] = '';
trad['Common_Result_AvgMemorizationTime§Short'] = '';
trad['Common_Result_AvgMemorizationTime§ToolTip'] = '';
trad['Common_Result_AvgTime'] = '';
trad['Common_Result_AvgTime§Short'] = '';
trad['Common_Result_AvgTime§ToolTip'] = '';
trad['Common_Result_AvgViews'] = '';
trad['Common_Result_AvgViews§ToolTip'] = '';
trad['Common_Result_AvgViews§Short'] = '';
trad['Common_Result_Challenges'] = '';
trad['Common_Result_Crashes'] = '';
trad['Common_Result_Budget'] = '';
trad['Common_Result_Budget§Reduced'] = '';
trad['Common_Result_Budget§Short'] = '';
trad['Common_Result_Due'] = '';
trad['Common_Result_Error'] = '';
trad['Common_Result_Error§Reduced'] = '';
trad['Common_Result_Error§Short'] = '';
trad['Common_Result_Error§ToolTip'] = '';
trad['Common_Result_ErrorDistractor'] = '';
trad['Common_Result_Failed'] = '';
trad['Common_Result_FaultySelections'] = '';
trad['Common_Result_FaultySelections§ToolTip'] = '';
trad['Common_Result_FullResult'] = '';
trad['Common_Result_Graph'] = '';
trad['Common_Result_GraphToolTip'] = '';
trad['Common_Result_Items'] = '';
trad['Common_Result_LeftErrors'] = '';
trad['Common_Result_Level'] = '';
trad['Common_Result_LevelShort'] = '';
trad['Common_Result_LevelToolTip'] = '';
trad['Common_Result_MissedPairs'] = '';
trad['Common_Result_MissedPairs§ToolTip'] = '';
trad['Common_Result_MissedTraficLight'] = '';
trad['Common_Result_MissedStops'] = '';
trad['Common_Result_NbSuccess'] = '';
trad['Common_Result_NbSuccess§Short'] = '';
trad['Common_Result_PCSuccess'] = '';
trad['Common_Result_PCSuccess§Reduced'] = '';
trad['Common_Result_PCSuccess§Short'] = '';
trad['Common_Result_PCSuccess§ToolTip'] = '';
trad['Common_Result_PlayedCards'] = '';
trad['Common_Result_PlayedTurns'] = '';
trad['Common_Result_Right'] = '';
trad['Common_Result_NbRuns'] = '';
trad['Common_Result_NbRuns§Short'] = '';
trad['Common_Result_Score'] = '';
trad['Common_Result_ScoreToolTip'] = '';
trad['Common_Result_SequencesDescription'] = '';
trad['Common_Result_SequencesDescription§Short'] = '';
trad['Common_Result_SequencesDescription§ToolTip'] = '';
trad['Common_Result_SuccessRate'] = '';
trad['Common_Result_SuccessRate§Reduced'] = '';
trad['Common_Result_SuccessRate§Short'] = '';
trad['Common_Result_SuccessRate§ToolTip'] = '';
trad['Common_Result_Success'] = '';
trad['Common_Result_SuccessShort'] = '';
trad['Common_Result_SuccessToolTip'] = '';
trad['Common_Result_Success_eFailure'] = '';
trad['Common_Result_Success_eFailure§Short'] = '';
trad['Common_Result_Success_eAverage'] = '';
trad['Common_Result_Success_eAverage§Short'] = '';
trad['Common_Result_Success_eSuccess'] = '';
trad['Common_Result_Success_eSuccess§Short'] = '';
trad['Common_Result_SuccessLevel'] = '';
trad['Common_Result_SuccessLevel§Short'] = '';
trad['Common_Result_SuccessLevel§ToolTip'] = '';
trad['Common_Result_SuccessLevel_eFailure'] = '';
trad['Common_Result_SuccessLevel_eFailure§Short'] = '';
trad['Common_Result_SuccessLevel_eAverage'] = '';
trad['Common_Result_SuccessLevel_eAverage§Short'] = '';
trad['Common_Result_SuccessLevel_eSuccess'] = '';
trad['Common_Result_SuccessLevel_eSuccess§Short'] = '';
trad['Common_Result_Successes'] = '';
trad['Common_Result_Successes§Short'] = '';
trad['Common_Result_Support_Semantic'] = '';
trad['Common_Result_Support_Semantic§Short'] = '';
trad['Common_Result_Support_Semantic§ToolTip'] = '';
trad['Common_Result_Support_Phono'] = '';
trad['Common_Result_Support_PhonoToolTip'] = '';
trad['Common_Result_Support'] = '';
trad['Common_Result_PCSupportedSuccess'] = '';
trad['Common_Result_PCSupportedSuccessShort'] = '';
trad['Common_Result_PCSupportedSuccessToolTip'] = '';
trad['Common_Result_SupportedTime'] = '';
trad['Common_Result_SupportedTimeShort'] = '';
trad['Common_Result_SupportedTimeToolTip'] = '';
trad['Common_Result_TotalDistance'] = '';
trad['Common_Result_TotalPrice'] = '';
trad['Common_Result_TotalPriceDesc'] = '';
trad['Common_Result_TotalTime'] = '';
trad['Common_Result_TotalTime§Reduced'] = '';
trad['Common_Result_TotalTime§Short'] = '';
trad['Common_Result_TotalTime§ToolTip'] = '';
trad['Common_Result_Turns'] = '';
trad['Common_Result_TurnsShort'] = '';
trad['Common_Result_TurnsToolTip'] = '';
trad['Common_Result_UnitDistance'] = '';
trad['Common_Result_UnitDistanceShort'] = '';
trad['Common_Result_UnitDistanceToolTip'] = '';
trad['Common_Result_UnitTime'] = '';
trad['Common_Result_UnitTimeShort'] = '';
trad['Common_Result_UnitTimeToolTip'] = '';
trad['Common_Result_UnexpectedStops'] = '';
trad['Common_Result_UnexpectedStops§ToolTip'] = '';
trad['Common_Result_UnsupportedTime'] = '';
trad['Common_Result_UnsupportedTimeShort'] = '';
trad['Common_Result_UnsupportedTimeToolTip'] = '';
trad['Common_ConfigurationView_Configuration'] = '';
trad['Common_ConfigurationView_Modify'] = '';
trad['Common_ConfigurationView_Caption_CriteriaError'] = '';
trad['Common_ConfigurationView_Caption_SeriesSpecError'] = '';
trad['Common_ConfigurationView_Caption_CategoriesError'] = '';
trad['Common_ConfigurationView_Caption_ShapesError'] = '';
trad['Common_ConfigurationView_Caption_ColorsError'] = '';
trad['Common_ConfigurationView_Caption_SingleSpecError'] = '';
trad['Common_ConfigurationView_Caption_EmptyRandomScenarioError'] = '';

trad['Common_ConfigurationView_Caption_InsufficientSpecError'] = '';
trad['Common_ConfigurationView_Caption_NoParameter'] = '';
trad['Common_ConfigurationView_Caption_EmptyFieldError'] = '';
trad['Common_RuntimeView_Header_AvailableDocuments'] = '';
trad['Common_RuntimeView_Header_Control'] = '';
trad['Common_RuntimeView_Header_CurrentDocument'] = '';
trad['Common_RuntimeView_Header_CurrentDocumentsControllerChange'] = '';
trad['Common_RuntimeView_Header_Supervision'] = '';
trad['Common_RuntimeView_Header_ExpectedAnswer'] = '';
trad['Common_RuntimeView_Header_Explanation'] = '';
trad['Common_RuntimeView_Header_GPS'] = '';
trad['Common_RuntimeView_Header_Hypotheses'] = '';
trad['Common_RuntimeView_Header_Journal'] = '';
trad['Common_RuntimeView_Header_Notes'] = '';
trad['Common_RuntimeView_Header_Notepad'] = '';
trad['Common_RuntimeView_Header_Map'] = '';
trad['Common_RuntimeView_Header_Objects'] = '';
trad['Common_RuntimeView_Header_Roadbook'] = '';
trad['Common_RuntimeView_Header_Runs'] = '';
trad['Common_RuntimeView_Header_State'] = '';
trad['Common_RuntimeView_Header_Stream'] = '';
trad['Common_RuntimeView_Header_Selection'] = '';
trad['Common_RuntimeView_Header_MethodologyBehavior'] = '';
trad['Common_RuntimeView_Command_Tab_Help'] = '';
trad['Common_RuntimeView_Command_Tab_Action'] = '';
trad['Common_RuntimeView_Command_Tab_Associations'] = '';
trad['Common_RuntimeView_Command_Tab_AvailableTools'] = '';
trad['Common_RuntimeView_Command_Tab_Begin'] = '';
trad['Common_RuntimeView_Command_Tab_BeginLike'] = '';
trad['Common_RuntimeView_Command_Tab_Category'] = '';
trad['Common_RuntimeView_Command_Tab_Complexity'] = '';
trad['Common_RuntimeView_Command_Tab_End'] = '';
trad['Common_RuntimeView_Command_Tab_EndLike'] = '';
trad['Common_RuntimeView_Command_Tab_Features'] = '';
trad['Common_RuntimeView_Command_Tab_Group'] = '';
trad['Common_RuntimeView_Command_Tab_Guiding'] = '';
trad['Common_RuntimeView_Command_Tab_Inventory'] = '';
trad['Common_RuntimeView_Command_Tab_Instructions'] = '';
trad['Common_RuntimeView_Command_Tab_NbSyllable'] = '';
trad['Common_RuntimeView_Command_Tab_Obstacles'] = '';
trad['Common_RuntimeView_Command_Tab_ObstaclesToolTip'] = '';
trad['Common_RuntimeView_Command_Tab_Place'] = '';
trad['Common_RuntimeView_Command_Tab_Properties'] = '';
trad['Common_RuntimeView_Command_Tab_SMS'] = '';
trad['Common_RuntimeView_Command_Tab_TimeControl'] = '';
trad['Common_RuntimeView_Command_Tab_Use'] = '';
trad['Common_RuntimeView_Command_Tab_Visibility'] = '';
trad['Common_RuntimeView_Command_Action_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_Action_DisplayProposals'] = '';
trad['Common_RuntimeView_Command_Action_DisplayExample'] = '';
trad['Common_RuntimeView_Command_Action_Node'] = '';
trad['Common_RuntimeView_Command_Association_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_Association_DisplayNode'] = '';
trad['Common_RuntimeView_Command_AvailableTools_Search'] = '';
trad['Common_RuntimeView_Command_AvailableTools_Transport'] = '';
trad['Common_RuntimeView_Command_BallSend'] = '';
trad['Common_RuntimeView_Command_BallSend§ToolTip'] = '';
trad['Common_RuntimeView_Command_BallRemove'] = '';
trad['Common_RuntimeView_Command_Begin_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_Begin_DisplayProposals'] = '';
trad['Common_RuntimeView_Command_Begin_Display'] = '';
trad['Common_RuntimeView_Command_Begin_DisplayLikeQuest'] = '';
trad['Common_RuntimeView_Command_Begin_DisplayLikeProposals'] = '';
trad['Common_RuntimeView_Command_Begin_DisplayLike'] = '';
trad['Common_RuntimeView_Command_CategoryShow'] = '';
trad['Common_RuntimeView_Command_DisplayPhonoHelpStart'] = '';
trad['Common_RuntimeView_Command_DisplayPhonoHelpEnd'] = '';
trad['Common_RuntimeView_Command_DisplaySemanticHelp'] = '';
trad['Common_RuntimeView_Command_DisplayExample'] = '';
trad['Common_RuntimeView_Command_DocumentsDisplay'] = '';
trad['Common_RuntimeView_Command_DocumentsHide'] = '';
trad['Common_RuntimeView_Command_DocumentsPrint'] = '';
trad['Common_RuntimeView_Command_DocumentsChange'] = '';
trad['Common_RuntimeView_Command_EmptyJournal'] = '';
trad['Common_RuntimeView_Command_End_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_End_DisplayProposals'] = '';
trad['Common_RuntimeView_Command_End_Display'] = '';
trad['Common_RuntimeView_Command_End_DisplayLikeQuest'] = '';
trad['Common_RuntimeView_Command_End_DisplayLikeProposals'] = '';
trad['Common_RuntimeView_Command_End_DisplayLike'] = '';
trad['Common_RuntimeView_Command_ExportMap'] = '';
trad['Common_RuntimeView_Command_FeaturesGroup'] = '';
trad['Common_RuntimeView_Command_FeaturesGroupHide'] = '';
trad['Common_RuntimeView_Command_Features'] = '';
trad['Common_RuntimeView_Command_Filter'] = '';
trad['Common_RuntimeView_Command_Group_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_Group_DisplayProposals'] = '';
trad['Common_RuntimeView_Command_Group_Display'] = '';
trad['Common_RuntimeView_Command_Group_Node'] = '';
trad['Common_RuntimeView_Command_GuidingTools'] = '';
trad['Common_RuntimeView_Command_Guiding_Suggestions'] = '';
trad['Common_RuntimeView_Command_Hide'] = '';
trad['Common_RuntimeView_Command_Mode'] = '';
trad['Common_RuntimeView_Command_Mode_eIndependant'] = '';
trad['Common_RuntimeView_Command_Mode_eSynchronized'] = '';
trad['Common_RuntimeView_Command_Notes'] = '';
trad['Common_RuntimeView_Command_NbSyllable_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_NbSyllable_DisplayProposals'] = '';
trad['Common_RuntimeView_Command_NbSyllable_Display'] = '';
trad['Common_RuntimeView_Command_Obstacle_Ambulance'] = '';
trad['Common_RuntimeView_Command_Obstacle_Ambulance§ToolTip'] = '';
trad['Common_RuntimeView_Command_Obstacle_RoadBlockAdd'] = '';
trad['Common_RuntimeView_Command_Obstacle_RoadBlockAdd§ToolTip'] = '';
trad['Common_RuntimeView_Command_Obstacle_RoadBlockRemove'] = '';
trad['Common_RuntimeView_Command_Obstacle_Ball'] = '';
trad['Common_RuntimeView_Command_Obstacle_BallToolTip'] = '';
trad['Common_RuntimeView_Command_Obstacle_Remove'] = '';
trad['Common_RuntimeView_Command_Obstacle_RemoveToolTip'] = '';
trad['Common_RuntimeView_Command_Reset'] = '';
trad['Common_RuntimeView_Command_Reset§ToolTip'] = '';
trad['Common_RuntimeView_Command_Patientview'] = '';
trad['Common_RuntimeView_Command_Place_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_Place_DisplayProposals'] = '';
trad['Common_RuntimeView_Command_Place_DisplayExample'] = '';
trad['Common_RuntimeView_Command_Place_Node'] = '';
trad['Common_RuntimeView_Command_Properties_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_Properties_DisplayProposals'] = '';
trad['Common_RuntimeView_Command_Properties_DisplayExample'] = '';
trad['Common_RuntimeView_Command_Properties_Node'] = '';
trad['Common_RuntimeView_Command_Show'] = '';
trad['Common_RuntimeView_Command_ShowAll'] = '';
trad['Common_RuntimeView_Command_ShowNone'] = '';
trad['Common_RuntimeView_Command_SimulateTime'] = '';
trad['Common_RuntimeView_Command_ShowRow'] = '';
trad['Common_RuntimeView_Command_ShowColumn'] = '';
trad['Common_RuntimeView_Command_HideRow'] = '';
trad['Common_RuntimeView_Command_HideColumn'] = '';
trad['Common_RuntimeView_Command_SuggestOptions4'] = '';
trad['Common_RuntimeView_Command_SuggestOptions2'] = '';
trad['Common_RuntimeView_Command_SuppressOptions1'] = '';
trad['Common_RuntimeView_Command_SuppressOptions2'] = '';
trad['Common_RuntimeView_Command_SupressSuggestions'] = '';
trad['Common_RuntimeView_Command_SubCategory'] = '';
trad['Common_RuntimeView_Command_Switch2Cards'] = '';
trad['Common_RuntimeView_Command_TargetDisplay'] = '';
trad['Common_RuntimeView_Command_TargetHide'] = '';
trad['Common_RuntimeView_Command_Talk'] = '';
trad['Common_RuntimeView_Command_Tooltip_ShowStream'] = '';
trad['Common_RuntimeView_Command_Tooltip_ShowList'] = '';
trad['Common_RuntimeView_Command_TrafficJam_Remove'] = '';
trad['Common_RuntimeView_Command_TrafficJam_RemoveToolTip'] = '';
trad['Common_RuntimeView_Command_Use_DisplayQuest'] = '';
trad['Common_RuntimeView_Command_Use_DisplayProposals'] = '';
trad['Common_RuntimeView_Command_Use_DisplayExample'] = '';
trad['Common_RuntimeView_Command_Use_Node'] = '';
trad['Common_RuntimeView_Command_Visibility_Center'] = '';
trad['Common_RuntimeView_Command_Visibility_CenterCaption'] = '';
trad['Common_RuntimeView_Command_Visibility_HideAll'] = '';
trad['Common_RuntimeView_Command_Visibility_See'] = '';
trad['Common_RuntimeView_Command_Visibility_SeeAll'] = '';
trad['Common_RuntimeView_Command_Visibility_SeeHideCaption'] = '';
trad['Common_RuntimeView_Command_Zoom'] = '';
trad['shortcut_Common_RuntimeView_Command_Appear'] = '';
trad['shortcut_Common_RuntimeView_Command_Center'] = '';
trad['shortcut_Common_RuntimeView_Command_DocumentsPrint'] = '';
trad['shortcut_Common_RuntimeView_Command_Mode_eIndependant'] = '';
trad['shortcut_Common_RuntimeView_Command_Mode_eSynchronized'] = '';
trad['shortcut_Common_RuntimeView_Command_Obstacle_Ambulance'] = '';
trad['shortcut_Common_RuntimeView_Command_Obstacle_RoadBlock'] = '';
trad['shortcut_Common_RuntimeView_Command_Obstacle_Ball'] = '';
trad['shortcut_Common_RuntimeView_Command_Obstacle_Remove'] = '';
trad['Common_RuntimeView_DQuit_Title'] = '';
trad['Common_RuntimeView_DQuit_eInterrupt'] = '';
trad['Common_RuntimeView_DQuit_eInterruptToolTip'] = '';
trad['Common_RuntimeView_DQuit_eAbandon'] = '';
trad['Common_RuntimeView_DQuit_eAbandonToolTip'] = '';
trad['Common_RuntimeView_DQuit_eConfig'] = '';
trad['Common_RuntimeView_DQuit_eConfigToolTip'] = '';
trad['Common_RuntimeView_State_Budget'] = '';
trad['Common_RuntimeView_State_CurrentLevel'] = '';
trad['Common_RuntimeView_State_Elapsed'] = '';
trad['Common_RuntimeView_State_FoundPairs'] = '';
trad['Common_RuntimeView_State_GameTime'] = '';
trad['Common_RuntimeView_State_LevelErrors'] = '';
trad['Common_RuntimeView_State_LevelRemainingItems'] = '';
trad['Common_RuntimeView_State_PlayedCards'] = '';
trad['Common_RuntimeView_State_RemainingPairs'] = '';
trad['Common_RuntimeView_State_Run'] = '';
trad['Common_RuntimeView_State_RunOutOf'] = '';
trad['Common_RuntimeView_State_SuccessRate'] = '';
trad['Common_RuntimeView_State_Time'] = '';
trad['Common_RuntimeView_State_VisualHelp'] = '';
trad['Common_RuntimeView_Label_CellMessage'] = '';
trad['Common_RuntimeView_Label_CellModify'] = '';
trad['Common_RuntimeView_Label_CellSend'] = '';
trad['Common_RuntimeView_Label_ConnectPatientMessage'] = '';
trad['Common_RuntimeView_Label_ConnectStatus'] = '';
trad['Common_RuntimeView_Label_ConnectStatus_eConnected'] = '';
trad['Common_RuntimeView_Label_ConnectStatus_eNotconnected'] = '';
trad['Common_RuntimeView_Label_Journal_Crash'] = '';
trad['Common_RuntimeView_Label_Journal_Error'] = '';
trad['Common_RuntimeView_Label_Journal_MissedTraficLight'] = '';
trad['Common_RuntimeView_Label_Journal_MissedStop'] = '';
trad['Common_RuntimeView_Label_Journal_Success'] = '';
trad['Common_RuntimeView_Label_Hide'] = '';
trad['Common_RuntimeView_Label_MailFrom'] = '';
trad['Common_RuntimeView_Label_MailSubject'] = '';
trad['Common_RuntimeView_Label_MailContent'] = '';
trad['Common_RuntimeView_Label_MailSend'] = '';
trad['Common_RuntimeView_Label_Objects'] = '';
trad['Common_RuntimeView_Label_Ongoing'] = '';
trad['Common_RuntimeView_Label_Pause'] = '';
trad['Common_RuntimeView_Label_Price'] = '';
trad['Common_RuntimeView_Label_Quantity'] = '';
trad['Common_RuntimeView_Label_ReceipesCookingTime'] = '';
trad['Common_RuntimeView_Label_ReceipesIngredients'] = '';
trad['Common_RuntimeView_Label_ReceipesNb'] = '';
trad['Common_RuntimeView_Label_ReceipesPreparationTime'] = '';
trad['Common_RuntimeView_Label_ReceipesPreparation'] = '';
trad['Common_RuntimeView_Label_Show'] = '';
trad['Common_RuntimeView_Label_TimeTableSearch'] = '';
trad['Common_RuntimeView_Label_TimeTableSearchNoResults'] = '';
trad['Common_RuntimeView_Label_TimeTableSearchResults'] = '';
trad['Common_RuntimeView_Label_Transportation'] = '';
trad['Common_RuntimeView_Label_TransportComplexity_Alternative'] = '';
trad['Common_RuntimeView_Label_TransportComplexity_eSimple'] = '';
trad['Common_RuntimeView_Label_TransportComplexity_eSimpleToolTip'] = '';
trad['Common_RuntimeView_Label_TransportComplexity_eMedium'] = '';
trad['Common_RuntimeView_Label_TransportComplexity_eMediumToolTip'] = '';
trad['Common_RuntimeView_Label_TransportComplexity_eComplete'] = '';
trad['Common_RuntimeView_Label_TransportComplexity_eCompleteToolTip'] = '';
trad['Common_RuntimeView_Label_AnswerSheet'] = '';
trad['Common_RuntimeView_Caption_CategoryDesc'] = '';
trad['Common_RuntimeView_Caption_Characteristics'] = '';
trad['Common_RuntimeView_Caption_ConclusionNarrative'] = '';
trad['Common_RuntimeView_Caption_ConclusionNarrative_eRight'] = '';
trad['Common_RuntimeView_Caption_ConclusionNarrative_eWrong'] = '';
trad['Common_RuntimeView_Caption_Family'] = '';
trad['Common_RuntimeView_Caption_Instruction_Memorize'] = '';
trad['Common_RuntimeView_Caption_TimeTableTitle'] = '';
trad['Common_RuntimeView_Caption_TimeTableIntro'] = '';
trad['Common_RuntimeView_Caption_TransportTitle'] = '';
trad['Common_RuntimeView_Caption_TransportIntro'] = '';
trad['Common_RuntimeView_Caption_TransportTitleLine'] = '';
trad['Common_RuntimeView_Caption_TherapistDesc'] = '';
trad['Common_RuntimeView_Caption_VendorDesc'] = '';
trad['Common_ResultView_Header_Hypotheses'] = '';
trad['Common_ResultView_Header_Results'] = '';
trad['Common_ResultView_Header_Execution'] = '';
trad['Common_ResultView_Header_CurveProgress'] = '';
trad['Common_Tutorial_Title_Invite'] = '';
trad['Common_Tutorial_Title_Demo'] = '';
trad['Common_Tutorial_Title_DemoInvite'] = '';
trad['Common_Tutorial_Title_Attempt1Help'] = '';
trad['Common_Tutorial_Title_Attempt2NoHelp'] = '';
trad['Common_Tutorial_Title_3Attempts'] = '';
trad['Common_Tutorial_Title_TutorialAbort'] = '';
trad['Common_Tutorial_Title_TutorialSuccess'] = '';
trad['Common_Tutorial_Title_Outro_ActivityEnd'] = '';
trad['Common_Tutorial_Title_Outro_Closing'] = '';
trad['Common_Tutorial_OutroCaption'] =
  'Vous désirez mettre fin à la session, vous pouvez quitter en fermant la fenêtre.';
trad['Common_Tutorial_Answer_eRight'] = '';
trad['Common_Tutorial_Answer_eWrong'] = '';
trad['Common_Tutorial_AttemptsCounter'] = '';
trad['Methodology_Title'] = '';
trad['Methodology_Goal'] = '';
trad['Methodology_Steps'] = '';
trad['Methodology_StepsCaption'] = '';
trad['Methodology_Learn'] = '';
trad['Methodology_LearnCheck'] = '';
trad['Methodology_Remember'] = '';
trad['Methodology_ExecuteStep'] = '';
trad['Methodology_ExecuteStepCheck'] = '';
trad['Methodology_VérifyStep'] = '';
trad['Methodology_VérifyStep_eOK'] = '';
trad['Methodology_VérifyStep_eKO'] = '';
trad['Methodology_VérifyGoal'] = '';
trad['Methodology_VérifyGoal_eOK'] = '';
trad['Methodology_VérifyGoal_eKO'] = '';
trad['Methodology_GoalReached'] = '';
trad['Methodology_StartOver'] = '';
trad['Methodology_StepsDisplay'] = '';
trad['Methodology_StepsDisplay_eNone'] = '';
trad['Methodology_StepsDisplay_eNoneToolTip'] = '';
trad['Methodology_StepsDisplay_eAll'] = '';
trad['Methodology_StepsDisplay_eAllToolTip'] = '';
trad['Methodology_StepsDisplay_eCurrent'] = '';
trad['Methodology_StepsDisplay_eCurrentToolTip'] = '';
trad['Methodology_StepsDisplay_eHidden'] = '';
trad['Methodology_StepsDisplay_eHiddenToolTip'] = '';
trad['EmptySheet_Title'] = '';
trad['GuidedAnswerSheet_Title'] = '';
trad['GuidedAnswerSheet_GeneralInfo'] = '';
trad['GuidedAnswerSheet_IngredientsTab'] = '';
trad['GuidedAnswerSheet_IngredientsAdd'] = '';
trad['GuidedAnswerSheet_Ingredients_Name'] = '';
trad['GuidedAnswerSheet_Ingredients_RequiredQuantity'] = '';
trad['GuidedAnswerSheet_Ingredients_AvailableQuantity'] = '';
trad['GuidedAnswerSheet_Ingredients_NeededQuantity'] = '';
trad['EvalCategory_Name'] = '';
trad['EvalCategory_FullName'] = '';
trad['EvalCategory_Summary'] = '';
trad['EvalNback_Name'] = '';
trad['EvalNback_FullName'] = '';
trad['EvalNback_Summary'] = '';
trad['EvalVerbal_Name'] = '';
trad['EvalVerbal_FullName'] = '';
trad['EvalVerbal_Summary'] = '';
trad['ShoppingList_Name'] = '';
trad['ShoppingList_FullName'] = '';
trad['ShoppingList_Summary'] = '';
trad['ShoppingList_Control_ValidateToolTip'] = '';
trad['ShoppingList_Control_AcceptToolTip'] = '';
trad['ShoppingList_Control_RejectToolTip'] = '';
trad['Planning_Name'] = '';
trad['Planning_FullName'] = '';
trad['Planning_Summary'] = '';
trad['Planning_PackageTypeMaps'] = '';
trad['Planning_PackageTypeScenario'] = '';
trad['Planning_Control_ValidateToolTip'] = '';
trad['Planning_Map_SearchLabel'] = '';
trad['Planning_PoiPanel_Title'] = '';
trad['Planning_PoiPanel_HideButton'] = '';
trad['Planning_Parameter_InfoNetworks'] = '';
trad['Planning_Parameter_InfoPOI'] = '';
trad['Planning_Parameter_Info_eNone'] = '';
trad['Planning_Parameter_Info_eComplex'] = '';
trad['Planning_Parameter_Info_eAverage'] = '';
trad['Planning_Parameter_Info_eSimple'] = '';
trad['Planning_Parameter_Info_eSolution'] = '';
trad['Driving_Control_ValidateToolTip'] = '';
trad['Driving_Control_StartoverToolTip'] = '';
trad['GPS_Name'] = '';
trad['GPS_FullName'] = '';
trad['GPS_Summary'] = '';
trad['GPS_Parameter_Duration'] = '';
trad['GPS_Parameter_Duration§Reduced'] = '';
trad['GPS_Parameter_Duration§Short'] = '';
trad['GPS_Parameter_Duration§ToolTip'] = '';
trad['Roadbook_Name'] = '';
trad['Roadbook_FullName'] = '';
trad['Roadbook_Summary'] = '';
trad['Market_Name'] = '';
trad['Market_FullName'] = '';
trad['Market_Summary'] = '';
trad['Market_ScenarioPreview_Budget'] = '';
trad['Market_ScenarioPreview_ShoppingList'] = '';
trad['Market_ScenarioPreview_MobilePhone'] = '';
trad['Market_ScenarioPreview_Msg'] = '';
trad['Market_RuntimeView_Command_MsgSend'] = '';
trad['Market_RuntimeView_Command_ViewShoppingList'] = '';
trad['Market_RuntimeView_Command_ViewPhone'] = '';
trad['Market_RuntimeView_Command_ViewPrice'] = '';
trad['Market_RuntimeView_Command_MsgSendCustom'] = '';
trad['Market_RuntimeView_Command_HideArticle§Tooltip'] = '';
trad['Market_RuntimeView_Command_SimpleMarker§Tooltip'] = '';
trad['Market_RuntimeView_Command_FullMarker§Tooltip'] = '';
trad['Market_Parameter_Music'] = '';
trad['Market_Parameter_Frequency_eLowToolTip'] = '';
trad['Market_Parameter_Frequency_eMediumToolTip'] = '';
trad['Market_Parameter_Frequency_eHighToolTip'] = '';
trad['MarketShelf_Name'] = '';
trad['MarketShelf_FullName'] = '';
trad['MarketShelf_Summary'] = '';
trad['Categorize_Name'] = '';
trad['Categorize_FullName'] = '';
trad['Categorize_Summary'] = '';
trad['Categorize_Parameter_ItemProximityToolTip'] = '';
trad['Categorize_Control_ValidateToolTip'] = '';
trad['Categorize_Control_AcceptToolTip'] = '';
trad['Categorize_Control_RejectToolTip'] = '';
trad['Categorize_Parameter_NbItemToolTip'] = '';
trad['Categorize_RuntimeView_Caption_ExplanationNarrative'] = '';
trad['Categorize_RuntimeView_Caption_Instruction_withTarget'] = '';
trad['Categorize_RuntimeView_Caption_Group'] = '';
trad['Categorize_ResultView_Caption_ExplanationNarrative'] = '';
trad['Conf_RuntimeView_Caption_Instruction_Memorize'] = '';
trad['Conf_Parameter_Complexity'] = '';
trad['Conf_Parameter_Complexity§Short'] = '';
trad['Conf_Parameter_Complexity§ToolTip'] = '';
trad['Conf_Parameter_Depth'] = '';
trad['Conf_Parameter_Depth§ToolTip'] = '';
trad['Conf_Parameter_Depth_eN1'] = '';
trad['Conf_Parameter_Depth_eN2'] = '';
trad['Conf_Parameter_Depth_eN3'] = '';
trad['Conf_Parameter_Depth_eN4'] = '';
trad['Conf_Control_SkipToolTip'] = '';
trad['CNback_Name'] = '';
trad['CNback_FullName'] = '';
trad['CNback_Summary'] = '';
trad['CNback_RuntimeView_Caption_Instruction_N1'] = '';
trad['CNback_RuntimeView_Caption_Instruction_N2'] = '';
trad['CNback_RuntimeView_Caption_Instruction_N3'] = '';
trad['CNback_RuntimeView_Caption_Instruction_N4'] = '';
trad['CAdd_Name'] = '';
trad['CAdd_FullName'] = '';
trad['CAdd_Summary'] = '';
trad['CAdd_RuntimeView_Caption_Instruction'] = '';
trad['CAdd_Parameter_NbConfigs'] = '';
trad['CAdd_Parameter_NbConfigs§ToolTip'] = '';
trad['CSub_Name'] = '';
trad['CSub_FullName'] = '';
trad['CSub_Summary'] = '';
trad['CSub_RuntimeView_Caption_Instruction'] = '';
trad['CSub_Parameter_NbConfigs'] = '';
trad['CSub_Parameter_NbConfigs§ToolTip'] = '';
trad['OddAuto_Name'] = '';
trad['OddAuto_FullName'] = '';
trad['OddAuto_Summary'] = '';
trad['GoodPair_Name'] = '';
trad['GoodPair_FullName'] = '';
trad['GoodPair_Summary'] = '';
trad['GoodPair_Parameter_NbItemsToolTip'] = '';
trad['GoodPair_Parameter_Display_Candidate'] = '';
trad['GoodPair_Parameter_Display_CandidateDesc'] = '';
trad['GoodPair_Parameter_Display_CandidateToolTip'] = '';
trad['GoodPair_Parameter_Display'] = '';
trad['GoodPair_Parameter_DisplayDesc'] = '';
trad['GoodPair_Parameter_DisplayToolTip'] = '';
trad['GoodPair_Parameter_Tab_SpecsToolTip'] = '';
trad['GoodPair_RuntimeView_Command_PrototypeShow'] = '';
trad['GoodPair_RuntimeView_Command_PrototypeHide'] = '';
trad['GoodPair_RuntimeView_Command_CategoryShow'] = '';
trad['GoodPair_RuntimeView_Command_CategoryHide'] = '';
trad['GoodPair_RuntimeView_Command_PrototypeToolTip'] = '';
trad['GoodPair_RuntimeView_Caption_ExplanationNarrativeElement'] = '';
trad['GoodPair_RuntimeView_Caption_ExplanationNarrativeAssociatedElement'] = '';
trad['Bell_Name'] = '';
trad['Bell_FullName'] = '';
trad['Bell_Summary'] = '';
trad['Bell_RuntimeView_Caption_Instruction_withTarget'] = '';
trad['Bell_RuntimeView_Caption_Instruction_withTargetandNumber'] = '';
trad['Bell_RuntimeView_Caption_Instruction_withTargetandColor'] = '';
trad['Bell_RuntimeView_Caption_Instruction_withoutTarget'] = '';
trad['Bell_RuntimeView_Caption_Instruction_withoutTargets'] = '';
trad['Memory_Name'] = '';
trad['Memory_FullName'] = '';
trad['Memory_Summary'] = '';
trad['Memory_Parameter_NbItems'] = '';
trad['Memory_Parameter_NbItems§ToolTip'] = '';
trad['Memory_Parameter_Display§ToolTip'] = '';
trad['Memory_Parameter_ItemType§ToolTip'] = '';
trad['Memory_Parameter_ItemType_eChar_Type§ToolTip'] = '';
trad['Memory_Parameter_Category§ToolTip'] = '';
trad['Memory_Parameter_ColorUse§ToolTip'] = '';
trad['Memory_Result_SupportToolTip'] = '';
trad['Memory_Result_ChallengesToolTip'] = '';
trad['Memory_RuntimeView_Caption_Instruction_NameImage'] = '';
trad['Memory_RuntimeView_Caption_Instruction_UpperLower'] = '';
trad['Memory_RuntimeView_Caption_Instruction_Peer'] = '';
trad['Memory_Parameter_ItemTypeToolTip'] = '';
trad['Memory_Result_Support§ToolTip'] = '';
trad['Memory_Result_Challenges§ToolTip'] = '';
trad['Denomination_Name'] = '';
trad['Denomination_FullName'] = '';
trad['Denomination_Summary'] = '';
trad['Denomonation_Parameter_DisplayToolTip'] = '';
trad['Denomination_Parameter_CategoryToolTip'] = '';
trad['Denomination_RuntimeView_Caption_AssociatedCardDescription'] = '';
trad['Denomination_Result_AvgRightAnswerTime'] = '';
trad['Denomination_Result_AvgRightAnswerTimeShort'] = '';
trad['Denomination_Result_AvgRightAnswerTimeToolTip'] = '';
trad['Denomination_Result_AvgWrongAnswerTime'] = '';
trad['Denomination_Result_AvgWrongAnswerTimeShort'] = '';
trad['Denomination_Result_AvgWrongAnswerTimeToolTip'] = '';
trad['Denomination_RuntimeView_Tab_SelectedCard'] = '';
trad['Denomination_RuntimeView_Tab_PhonoSupport'] = '';
trad['Denomination_RuntimeView_Tab_SemanticSupport'] = '';
trad['Singular_Name'] = '';
trad['Singular_FullName'] = '';
trad['Singular_Summary'] = '';
trad['Singular_RuntimeView_Caption_Instruction'] = '';
trad['Singular_Parameter_Tab_ItemsToolTip'] = '';
trad['Mindmap_Parameter_Tab_Specs'] = '';
trad['Mindmap_Parameter_Tab_SpecsToolTip'] = '';
trad['MindMap_Parameter_Display'] = '';
trad['MindMap_Parameter_DisplayToolTip'] = '';
trad['MindMap_Parameter_SoundComplexityToolTip'] = '';
trad['MindMap_Parameter_WordFrequencyDesc'] = '';
trad['MindMap_Parameter_WordFrequencyToolTip'] = '';
trad['MindMap_Parameter_LengthDesc'] = '';
trad['MindMap_Parameter_LengthToolTip'] = '';
trad['MindMap_Control_ValidateToolTip'] = '';
trad['MindMap_Control_RejectToolTip'] = '';
trad['MindMap_Control_Accept'] = '';
trad['MindMap_Control_AcceptToolTip'] = '';
trad['MindMap_RuntimeView_Caption_ExplanationNarrative'] = '';
trad['MindMap_RuntimeView_Caption_SuggestionsNarrative'] = '';
trad['pca_FullName'] = '';
trad['pca_RuntimeView_Caption_Instruction_Step1'] = '';
trad['pca_RuntimeView_Caption_Instruction_Step2'] = '';
trad['pca_RuntimeView_Caption_Instruction_Step3'] = '';
trad['pca_RuntimeView_Caption_Instruction_Step4'] = '';
trad['pca_RuntimeView_Caption_DoctorNext'] = '';
trad['pca_RuntimeView_Caption_SubInstruction1'] = '';
trad['pca_RuntimeView_Caption_SubInstruction2'] = '';
trad['pca_RuntimeView_Caption_SubInstruction3'] = '';
trad['pca_RuntimeView_Caption_SubInstruction4'] = '';
trad['pca_RuntimeView_Caption_SubInstruction5'] = '';
trad['pca_RuntimeView_Caption_'] = '';
trad['pca_RuntimeView_Caption_Next'] = '';
trad['pca_RuntimeView_Caption_Next'] = '';
trad['pca_RuntimeView_Caption_Next'] = '';
trad['FeaturesToWord_Name'] = '';
trad['FeaturesToWord_FullName'] = '';
trad['FeaturesToWord_Summary'] = '';
trad['FeaturesToWord_Parameter_Guiding_eGuidedToolTip'] = '';
trad['WordToFeatures_Name'] = '';
trad['WordToFeatures_FullName'] = '';
trad['WordToFeatures_Summary'] = '';
trad['WordToFeatures_Parameter_Guiding_eGuidedToolTip'] = '';
trad['Counting_Name'] = '';
trad['Counting_FullName'] = '';
trad['Counting_Summary'] = '';
trad['Counting_RuntimeView_Caption_Instruction_eSquare'] = '';
trad['Counting_RuntimeView_Caption_Instruction_eNumbers'] = '';
trad['Counting_Parameter_Tab_AutoDistractorsToolTip'] = '';
trad['Counting_Parameter_LayoutToolTip'] = '';
trad['Counting_Parameter_ShapeFrequency_eLowToolTip'] = '';
trad['Counting_Parameter_ShapeFrequency_eMediumToolTip'] = '';
trad['Counting_Parameter_ShapeFrequency_eHighToolTip'] = '';
trad['Counting_Parameter_ColorFrequency_eLowToolTip'] = '';
trad['Counting_Parameter_ColorFrequency_eMediumToolTip'] = '';
trad['Counting_Parameter_ColorFrequency_eHighToolTip'] = '';
trad['NBack_Name'] = '';
trad['NBack_FullName'] = '';
trad['NBack_Summary'] = '';
trad['NBack_Parameter_Tab_ItemsToolTip'] = '';
trad['NBack_Parameter_Tab_AutoDistractorsToolTip'] = '';
trad['NBack_Parameter_ItemType'] = '';
trad['NBack_Parameter_ItemType§ToolTip'] = '';
trad['NBack_Parameter_ItemType_eShapes§ToolTip'] = '';
trad['NBack_Parameter_ColorUse§ToolTip'] = '';
trad['NBack_Parameter_ItemType_eChars§ToolTip'] = '';
trad['NBack_Parameter_ItemType_eChar_Type§ToolTip'] = '';
trad['NBack_Parameter_AutoDistractorToolTip'] = '';
trad['NBack_Control_ValidateToolTip'] = '';
trad['NBack_Control_RejectToolTip'] = '';
trad['NBack_Control_StartOverToolTip'] = '';
trad['NBack_Control_SkipToolTip'] = '';
trad['Odd_Name'] = '';
trad['Odd_FullName'] = '';
trad['Odd_Summary'] = '';
trad['Odd_RuntimeView_Caption_Instruction'] = '';
trad['Odd_Control_ValidateToolTip'] = '';
trad['Odd_Control_RejectToolTip'] = '';
trad['Odd_Parameter_ItemProximityToolTip'] = '';
trad['Odd_Parameter_CategoryToolTip'] = '';
trad['Odd_Parameter_Layout§ToolTip'] = '';

export { trad };
// @formatter:on

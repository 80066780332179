import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({});

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, contact } = this.props;
    const { relation, email, mobile } = contact;
    return (
      <div>
        {!_.isEmpty(email) && (
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Email
            </Typography>
            <Typography component="p" gutterBottom>
              {email}
            </Typography>
          </div>
        )}
        {!_.isEmpty(email) && (
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Mobile
            </Typography>
            <Typography component="p" gutterBottom>
              {mobile}
            </Typography>
          </div>
        )}
        {!_.isEmpty(relation) && (
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Relation
            </Typography>
            <Typography component="p" gutterBottom>
              {relation}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);

import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({});

class PatientCare extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, patientCare } = this.props;
    const { description } = patientCare;
    return (
      <div>
        {!_.isEmpty(description) && (
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Description
            </Typography>
            <Typography component="p" gutterBottom>
              {description}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

PatientCare.propTypes = {
  classes: PropTypes.object.isRequired,
  patientCare: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientCare);

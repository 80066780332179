import { drawerActivityWidth, transition, container } from 'assets/jss/app.jsx';

const appLayoutStyle = (theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
  },
  mainPanel: {
    overflow: 'auto',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
    position: 'absolute',
    inset: '0',
  },
  mainPanelActivity: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerActivityWidth}px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: '10px',
    // padding: '30px 15px',
    minHeight: 'calc(100vh - 123px)',
  },
  container,
  leftContent: {
    position: 'relative',
    flex: '0 0 33.33333%',
    maxWidth: '33.33333%',
  },
});

export default appLayoutStyle;

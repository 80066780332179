/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import GridItem from 'components/Grid/GridItem.jsx';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

import { GET_PATIENT_BY_ID } from 'graphql/queries';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import UnauthorizedAccess from 'components/Authentication/UnauthorizedAccess';
import { getCurrentUserPatientId, getDoctorName } from '../../utils';


const styles = () => ({
  card: {},
});

const moodOptions = [
  { key: 1, label: "vWelcome_Mood_eHappy", picture: "/appAssets/image/mood/happy.png" },
  { key: 2, label: "vWelcome_Mood_eDiscouraged", picture: "/appAssets/image/mood/discouraged.png" },
  { key: 3, label: "vWelcome_Mood_eBored", picture: "/appAssets/image/mood/bored.png" },
  { key: 4, label: "vWelcome_Mood_eTired", picture: "/appAssets/image/mood/tired.png" },
  { key: 5, label: "vWelcome_Mood_eAngry", picture: "/appAssets/image/mood/angry.png" },
  { key: 6, label: "vWelcome_Mood_eSad", picture: "/appAssets/image/mood/sad.png" }];

const PatientWelcome = (props) => {

  const { classes, t } = props;
  const handleSetPatientUser = (patientData, mood) => {
    const patient = {
      id: patientData._id,
      firstname: patientData.firstname,
      lastname: patientData.lastname,
      permacode: patientData.permacode,
      interseance: true // a connected patient is necessarily interseance active 
    };
    sessionStorage.setItem('currentPatient', JSON.stringify(patient));
    window.logger.store({ context: "Welcome", message: "Click on mood", data: mood });
    const url = `/program`;
    setTimeout(() => {
      window.location.href = url; // OULALA
    }, 300);
  };
  return (
    <div className={classNames(classes.layout, classes.cardGrid)}>
      <Query query={GET_PATIENT_BY_ID} fetchPolicy="cache-and-network" variables={{ id: getCurrentUserPatientId() }}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress />;
          if (error)
            return <UnauthorizedAccess errorMsg={error.message} reason={error.graphQLErrors[0].internalErrorCode} />;
          if (_.isEmpty(data)) {
            return (
              <UnauthorizedAccess
                errorMsg="le patient n'est pas initialisé"
                reason={error.graphQLErrors[0].internalErrorCode}
              />
            );
          }
          window.logger.store({ context: "Welcome", message: "Displaying Mood" });
          return (
            <div>
              <Typography variant="h2" gutterBottom className={classes.headerFromH2}>
                {t("VWelcome_Hello").replace("[NAME]", getDoctorName(JSON.parse(localStorage.getItem('user'))))}
              </Typography>
              <Typography variant="h4" gutterBottom className={classes.headerFromH4}>
                {t("vWelcome_MoodInvite")}
              </Typography>
              <GridContainer spacing={40}>
                {moodOptions.map((mood) => (
                  <GridItem key={mood.key} sm={2} md={2} lg={2}>
                    <Card className={classes.card}>
                      <CardActionArea
                        // component={Link}
                        // to={`/patients/${p._id}`}
                        onClick={() => {
                          handleSetPatientUser(data.getPatient, t(mood.label));
                        }}
                        data-id={mood.key}
                      >
                        <CardMedia
                          component="img"
                          image={window.AssetResolver.getAsset(mood.picture)}
                        />
                        <CardHeader
                          title={t(mood.label)}
                          style={{ textAlign: 'center' }}
                        />
                      </CardActionArea>
                    </Card>
                  </GridItem>
                ))}
              </GridContainer>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

PatientWelcome.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

PatientWelcome.defaultProps = {
};

export default withTranslation()(withStyles(styles)(withRouter(PatientWelcome)));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardThumbnail from 'components/Card/CardThumbnail';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import { cvGreenColor, cvBrownColor } from '../../styles/variables';
import { logger } from '../../logger';

const Thumbnail = (props) => {
  const [logo, setLogo] = useState(null);
  const [renderable, setRenderable] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const { t } = props;

  const confProm = import(`@covirtua-activities/${props.slug}/build/config.js`).then((configLoad) => {
    setConfiguration(configLoad.default);
  });

  const logoProm = import(`@covirtua-activities/${props.slug}/build/logo.png`).then((logoLoad) => {
    setLogo(logoLoad.default);
  });

  Promise.all([confProm, logoProm])
    .then(() => {})
    .catch((error) => {
      logger.error(error);
    })
    .finally(() => {
      setRenderable(true);
    });

  if (!renderable) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <CardThumbnail
        avatar={logo}
        backgroundColor={
          configuration && configuration.metadata.typeSlug === 'fonctionnel' ? cvBrownColor : cvGreenColor
        }
        title={t(configuration && configuration.metadata.name)}
        linkTo={`/activities/${configuration && configuration.metadata.categorySlug}/${configuration &&
          configuration.metadata.slug}`}
      />
    </div>
  );
};

Thumbnail.propTypes = {
  slug: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Thumbnail);

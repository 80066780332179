import { createMuiTheme } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   50: '#ffe3ef',
    //   100: '#feb9d7',
    //   200: '#fe8abc',
    //   300: '#fd5ba1',
    //   400: '#fc388d',
    //   500: '#fc1579',
    //   600: '#fc1271',
    //   700: '#fb0f66',
    //   800: '#fb0c5c',
    //   900: '#fa0649',
    //   A100: '#ffffff',
    //   A200: '#ffeef2',
    //   A400: '#ffbbca',
    //   A700: '#ffa2b7',
    //   contrastDefaultColor: 'light',
    // },
    primary: {
      main: '#9d75bc',
    },
    secondary: lightBlue,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        label: {
          color: 'white',
        },
      },
    },
  },
  error: red,
  status: {
    danger: 'orange',
  },

  colors: {
    background_1: '',
    background_2: '',
    purpleDark: 'rgb(79, 48, 101)',
    purpleMedium: 'rgb(157, 117, 188)',
    purpleLight: 'rgb(206, 188, 223)',
    orangeLight: 'rgb(255,236, 210)',
    blue: 'rgb(22,242,238)',
    green: 'rgb(218,242,34)',
    grey: 'rgb(166,166,166)',
    greyLight: 'rgb(200,200,200)',
  },

  typography: {
    useNextVariants: true,
  },
});

export default theme;

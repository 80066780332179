/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from 'components/Header/Header.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import patientRoutes from 'routes/patientDashboard';
import doctorRoutes from 'routes/doctorDashboard';
import Patients from 'views/Patients/Patients';
import { getCurrentPatient } from 'utils';
import logo from 'assets/img/covirtua-logo.png';
import appLayoutStyle from 'assets/jss/layouts/appLayoutStyle.jsx';
import ProtectedRoute from 'components/Authentication/ProtectedRoute';
import openSocket from 'socket.io-client';

import _ from 'lodash';
import { ApolloProvider } from 'react-apollo';
import { getApolloClient } from 'utils/apolloClientFactory';
import Types from 'utils/types';
import PatientWelcome from 'views/Welcome/PatientWelcome';

const AppLayout = (props) => {
  const { classes, ...rest } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const keycloak = false;
  const currentPatient = getCurrentPatient();
  const [client, setClient] = useState(null);
  const [renderable, setRenderable] = useState(false);

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const dashboardRoutes = _.includes(props.userTypes, Types.DOCTOR_TYPE) ? doctorRoutes : patientRoutes;

  useEffect(() => {
    window.addEventListener('resize', resizeFunction);

    // if (e.history.location.pathname !== e.location.pathname) {
    //   // refs.mainPanel.scrollTop = 0;
    //   if (mobileOpen) {
    //     setMobileOpen(false);
    //   }
    // }
    getApolloClient().then((apolloClient) => {
      setClient(apolloClient);
      setRenderable(true);
    });

    if (_.get(currentPatient, 'id') && !window.comSocket) {
      window.comSocket = openSocket(`${window.ACTIVITY_SERVER_URL}/communication`, { autoConnect: false });
      window.activitySocket = openSocket(`${window.ACTIVITY_SERVER_URL}/activity`, { autoConnect: false });
    }
  }, []);

  const isFooter = () => window.location.pathname !== '/patients/new';
  const isHome = () => window.location.pathname === '/';
  const isLive = () => window.location.pathname.includes('/live') || window.location.pathname.includes('/activity');
  const isPatientSelected = () => currentPatient != null;
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const switchRoutes = (
    <Switch>
      {dashboardRoutes.map((prop) => {
        if (prop.redirect) {
          return <Redirect from={prop.path} to={prop.to} key={prop.path} />;
        }

        return (
          <ProtectedRoute
            userLicense={props.userLicense}
            userRoles={props.userRoles}
            userTypes={props.userTypes}
            requiredRoles={prop.requiredRoles}
            exact
            path={prop.path}
            component={prop.component}
            key={prop.path}
          />
        );
      })}
    </Switch>
  );

  return (
    renderable && (
      <ApolloProvider client={client}>
        <div className={classes.wrapper}>
          {/* Forcing display of "patient selection page" when no patient in session storage */}
          {/* But not redirecting on URL... */}
          {isHome() || !isPatientSelected() ? (
            <div>
              <div className={classes.logo}>
                <a href="https://www.covirtua.com/" className={classes.logoLink}>
                  <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                  </div>
                </a>
              </div>
              <Header
                routes={dashboardRoutes}
                handleDrawerToggle={handleDrawerToggle}
                keycloak={keycloak}
                {...rest}
                displayConnection={false}
                userTypes={props.userTypes}
              />
              <div className={classes.content}>
                <div className={classes.container}>
                  {_.includes(props.userTypes, Types.DOCTOR_TYPE) ? <Patients /> : ''}
                  {_.includes(props.userTypes, Types.PATIENT_TYPE) ? <PatientWelcome /> : ''}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {!isLive() ? (
                <span>
                  <Sidebar
                    routes={dashboardRoutes}
                    logoText="Covirtua"
                    // currentPatient={currentPatient}
                    handleDrawerToggle={handleDrawerToggle}
                    open={mobileOpen}
                    color="blue"
                    userTypes={props.userTypes}
                    {...rest}
                  />
                  <div className={classes.mainPanel}>
                    <Header
                      routes={dashboardRoutes}
                      // currentPatient={currentPatient}
                      handleDrawerToggle={handleDrawerToggle}
                      keycloak={keycloak}
                      displayConnection
                      {...rest}
                    />
                    {
                      <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes}</div>
                      </div>
                    }
                    {isFooter() ? <div /> : null}
                  </div>
                </span>
              ) : (
                <div className={classes.mainPanelLive}>
                  <Header
                    routes={dashboardRoutes}
                    currentPatient={currentPatient}
                    handleDrawerToggle={handleDrawerToggle}
                    keycloak={keycloak}
                    displayConnection
                    {...rest}
                  />
                  <div className={classes.contentLive}>
                    <GridContainer direction="row">
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ width: '100%' }}>
                          <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                            <div className={classes.container}>{switchRoutes}</div>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </ApolloProvider>
    )
  );
};

AppLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  userRoles: PropTypes.array.isRequired,
  userTypes: PropTypes.array.isRequired,
  userLicense: PropTypes.object.isRequired,
};

export default withStyles(appLayoutStyle)(AppLayout);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import connectionState from './connectionState';

function PatientConnectionStatus({ patientConnectionState }) {
  let color;
  if (patientConnectionState === connectionState.connected) color = 'green'
  else if (patientConnectionState === connectionState.notConnected) color = 'red'
  else if (patientConnectionState === connectionState.connectionLost) color = 'orange';
  const { t } = useTranslation();
  return (
    <div>
      {t('Common_RuntimeView_Label_ConnectStatus')}
      <span
        style={{
          backgroundColor: color,
          height: '15px',
          width: '15px',
          borderRadius: '50%',
          display: 'inline-block',
          verticalAlign: 'center',
          marginLeft: '15px',
        }}
      />
    </div>
  );
}

PatientConnectionStatus.propTypes = {
  patientConnectionState: PropTypes.bool.isRequired,
};

export default PatientConnectionStatus;

import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import dashboardStyle from 'assets/jss/views/dashboardStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

const ProgressChart = (props) => {
  const { dates, series, t } = props;

  const getOption = (datesList, seriesList) => {
    momentDurationFormat(moment);
    return {
      title: {
        text: t('Common_ResultView_Header_CurveProgress'),
      },
      legend: {
        data: _.map(seriesList, (s) => s.name),
      },
      toolbox: {
        right: '50px',
        feature: {
          saveAsImage: { title: t('Common_Record') },
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: datesList,
      },
      series: seriesList,
      yAxis: {},
    };
  };

  const handleLegendselectchanged = (legendsEvent, chart) => {
    if (_.filter(_.values(legendsEvent.selected), (e) => e).length === 1) {
      chart.setOption({
        yAxis: {
          name: 'test',
        },
      });
    }
  };

  return (
    <ReactEcharts
      option={getOption(dates, series)}
      style={{
        height: '100%',
        width: '100%',
      }}
      className="react_for_echarts"
      onEvents={{ legendselectchanged: handleLegendselectchanged }}
    />
  );
};

ProgressChart.propTypes = {
  t: PropTypes.func.isRequired,
  dates: PropTypes.object.isRequired,
  series: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(ProgressChart));

import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import withStyles from '@material-ui/core/styles/withStyles';
import momentDurationFormat from 'moment-duration-format';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TimelineIcon from '@material-ui/icons/Timeline';
import PlayArrow from '@material-ui/icons/PlayArrow';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/Home';
// import CommentIcon from '@material-ui/icons/Comment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import theme from 'styles/theme';
import PreviousResults from './PreviousResults';
import Details from './Details';
import Card from '../../../components/Card/Card';
import dashboardStyle from '../../../assets/jss/views/dashboardStyle';
import GridItem from '../../../components/Grid/GridItem';
import { getColor } from '../../../styles/variables';
import { tShortLabel, tToolTip } from '../../../i18n/utils';
import { logger } from '../../../logger';
import { prepareValueForDisplay } from './Formatter';

import activities from '../../list-activities.json';

const styles = () => ({
  ...dashboardStyle,
  avatar: {
    backgroundColor: theme.colors.purpleMedium,
    borderRadius: '0%',
  },
  activityCard: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
  },
  programmedActivityCard: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
    backgroundColor: theme.colors.orangeLight
  },
  pic: {
    width: 150,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
  },
  resultsInterrupted: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    backgroundColor: theme.colors.greyLight,
  },
  content: {
    flex: '1 0 auto',
  },
  contentInterrupted: {
    flex: '1 0 auto',
    backgroundColor: theme.colors.greyLight,
  },
  actions: {
    paddingTop: 10,
  },
  button: {
    margin: 2,
  },
  resultCell: {
    backgroundColor: theme.colors.purpleMedium,
    color: 'white',
  },
  drawer: {
    maxWidth: '80%',
  },
  resultHeader: {
    backgroundColor: 'white',
    color: theme.colors.purpleMedium,
  },
  contentTable: {
    maxWidth: 750,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  HomeIcon: {
    marginBottom: -5
  },
});

class HistoryCard extends React.Component {
  constructor(props) {
    super(props);
    momentDurationFormat(moment);
    this.state = {
      seriesDrawerOpen: false,
      detailsDrawerOpen: false,
      configuration: null,
      renderable: false,
      logo: '',
      dataFormatter: null,
      replayActivity: false,
    };

    const logoProm = import(`@covirtua-activities/${props.activity.slug}/build/logo.png`).then((logo) => {
      this.setState({
        logo: logo.default,
      });
    });

    const confProm = import(`@covirtua-activities/${props.activity.slug}/build/config.js`).then((configLoad) => {
      this.setState({
        configuration: configLoad.default,
      });
    });

    const dfProm = import(`@covirtua-activities/${props.activity.slug}/build/dataFormatter.js`).then(
      (dataFormatterLoad) => {
        const DF = dataFormatterLoad.default;
        this.setState({
          dataFormatter: new DF(this.props.t),
        });
      },
    );

    Promise.all([dfProm, confProm, logoProm])
      .then(() => {})
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        this.setState({
          renderable: true,
        });
      });
  }

  getDisplayableConf() {
    return _.filter(this.state.configuration.attributes, (attribute) => attribute.inSummary);
  }

  getDisplayableResults() {
    return _.filter(this.state.configuration.resultsFields, (result) => result.inSummary);
  }

  toggleNotesDial = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ notesOpen: open });
  };

  toggleDetailsDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ detailsDrawerOpen: open });
  };

  toggleSeriesDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ seriesDrawerOpen: open });
  };

  replay = () => {
    this.setState({ replayActivity: true });
  };

  proxyDataRender(activity, classes) {
    const { t } = this.props;
    // const results = JSON.parse(activity.results);

    if (!this.state.renderable) return 'Loading';

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {this.getDisplayableConf().map((field) => (
              <TableCell key={field}>
                <Tooltip disableFocusListener title={tToolTip(field, t)} classes={{ tooltip: classes.tooltip }}>
                  <div>{tShortLabel(field, t)}</div>
                </Tooltip>
              </TableCell>
            ))}

            {this.getDisplayableResults().map((field) => (
              <TableCell key={field} className={!activity.isInterrupted ? classes.resultHeader : ''}>
                <Tooltip disableFocusListener title={tToolTip(field, t)} classes={{ tooltip: classes.tooltip }}>
                  <div>{tShortLabel(field, t)}</div>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={activity._id}>
            {this.getDisplayableConf().map((entry) => (
              <TableCell component="th" scope="row" key={entry}>
                {prepareValueForDisplay(
                  t,
                  this.state.configuration,
                  entry,
                  JSON.parse(activity.configuration),
                  'short',
                )}
              </TableCell>
            ))}

            {this.getDisplayableResults().map((entry) => (
              <TableCell
                component="th"
                scope="row"
                key={entry}
                className={!activity.isInterrupted ? classes.resultCell : ''}
              >
                {prepareValueForDisplay(
                  t,
                  this.state.configuration,
                  entry,
                  JSON.parse(activity.results),
                  'short',
                  this.state.dataFormatter,
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  render() {
    const { classes, activity, t } = this.props;
    const activitiesList = activities;
    const pStyle = this.state.configuration
      ? {
          backgroundColor: getColor(activitiesList.categories[this.state.configuration.metadata.type].color),
        }
      : {};

    if (!this.state.renderable) return 'Loading';

    if (this.state.replayActivity) {
      return (
        <Redirect
          to={{
            pathname: `/activities/${this.state.configuration.metadata.categorySlug}/${activity.slug}/`,
            state: {
              previousConfig: JSON.parse(activity.configuration),
            },
          }}
        />
      );
    }

    return (
      <GridItem key={activity._id} xs={12}>
        <Card className={activity.programmed ? classes.programmedActivityCard : classes.activityCard}>
          <CardMedia className={classes.pic} image={this.state.logo} title={activity.slug} style={pStyle} />

          <div className={classes.details}>
            <CardContent >
              <Typography component="h6" variant="h6">
                {t(activity.name)}
              </Typography>

              <Typography variant="subtitle2" color="textSecondary">
                {moment(activity.stoppedAt).format('HH:mm')}
                {activity.programmed && <HomeIcon className={classes.HomeIcon} />}
              </Typography>

              <div className={classes.actions}>
                <Tooltip disableFocusListener title={t("VHistory_DetailsToolTip")} classes={{ tooltip: classes.tooltip }}>
                  <Button
                    onClick={this.toggleDetailsDrawer(true)}
                    variant="contained"
                    color="primary"
                    aria-label="Details"
                    className={classes.button}
                  >
                    <SearchIcon />
                  </Button>
                </Tooltip>

                {!activity.isInterrupted && (
                  <Tooltip disableFocusListener title={t("VHistory_HistoryToolTip")} classes={{ tooltip: classes.tooltip }}>

                    <Button
                      onClick={this.toggleSeriesDrawer(true)}
                      variant="contained"
                      color="primary"
                      aria-label="Series"
                      className={classes.button}
                    >
                      <TimelineIcon />
                    </Button>
                  </Tooltip>
                )}

                <Tooltip disableFocusListener title={t("VHistory_ReplayToolTip")} classes={{ tooltip: classes.tooltip }}>

                  <Button
                    onClick={this.replay}
                    variant="contained"
                    color="primary"
                    aria-label="Replay"
                    className={classes.button}
                  >
                    <PlayArrow />
                  </Button>
                </Tooltip>
                {/*
                <Button
                  disabled={!activity.notes}
                  onClick={this.toggleNotesDial(true)}
                  variant="contained"
                  color="primary"
                  aria-label="Series"
                  className={classes.button}
                >
                  <CommentIcon />
                </Button>
                */}
              </div>
            </CardContent>
          </div>
          <div className={activity.isInterrupted ? classes.resultsInterrupted : classes.results}>
            <CardContent className={classes.contentTable}>{this.proxyDataRender(activity, classes)}</CardContent>
          </div>
        </Card>

        <Drawer
          anchor="right"
          classes={{ paperAnchorRight: classes.drawer }}
          open={this.state.seriesDrawerOpen}
          onClose={this.toggleSeriesDrawer(false)}
        >
          <PreviousResults slug={activity.slug} configuration={this.state.configuration} />
        </Drawer>

        <Drawer
          anchor="right"
          classes={{ paperAnchorRight: classes.drawer }}
          open={this.state.detailsDrawerOpen}
          onClose={this.toggleDetailsDrawer(false)}
        >
          <Details activity={activity} configuration={this.state.configuration} />
        </Drawer>

        <Dialog open={this.state.notesOpen} onClose={this.toggleNotesDial(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Commentaire d&apos;activité</DialogTitle>
          <DialogContent>
            <DialogContentText>{activity.notes}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleNotesDial(false)} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    );
  }
}

HistoryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(HistoryCard));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import NoResultsImg from 'assets/img/no-results';
import { withTranslation } from 'react-i18next';

class NoProgram extends Component {
  render() {
    const { t } = this.props;
    console.log('no program!');
    return (
      <div>
        <NoResultsImg />
        <Typography paragraph>
          <p style={{ textAlign: 'center' }}>{t('VProgram_NoProgram')}</p>
        </Typography>
      </div>
    );
  }
}

NoProgram.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NoProgram);

import React from 'react';
import PropTypes from 'prop-types';
import { logger } from '../../../logger';

class Controls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      slug: props.slug,
      config: props.config,
      socket: props.socket,
      runtimeState: props.runtimeState,
      renderable: false,
      mainCommands: () => {},
    };

    const ctrlformProm = import(`@covirtua-activities/${this.state.slug}/build/ControlForm.js`).then((ctrlForm) => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        controlForm: ctrlForm.default,
      });
    });

    const maincomProm = import(`@covirtua-activities/${this.state.slug}/build/common/mainCommands.js`).then(
      (mainCom) => {
        this.setState({
          mainCommands: mainCom.default,
        });
      },
    );

    Promise.all([ctrlformProm, maincomProm])
      .then(() => {})
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        this.setState({
          // eslint-disable-next-line new-cap
          commands: new this.state.mainCommands(this.state.socket),
          renderable: true,
        });
      });
  }

  render() {
    if (!this.state.renderable) {
      return <div>Loading...</div>;
    }

    return (
      <form autoComplete="off">
        <this.state.controlForm
          socket={this.state.socket}
          commands={this.state.commands}
          config={this.state.config}
          runtimeState={this.state.runtimeState}
        />
      </form>
    );
  }
}

Controls.propTypes = {
  socket: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  runtimeState: PropTypes.object.isRequired,
};

export default Controls;

/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { Mutation } from 'react-apollo';
import ReactDOM from 'react-dom';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withTranslation } from 'react-i18next';
import { GET_PATIENTS } from '../../graphql/queries';
import { logger } from '../../logger';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  form: {
    width: 300,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    minWidth: '100%',
    maxWidth: 200,
  },
  formLabel: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  },
  birthday: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  studies: {
    width: 180,
  },
  heading: {
    marginBottom: theme.spacing.unit * 2,
  },
  moreButton: {
    marginTop: 15,
  },
  submitButton: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
  },
});

const studiesOptions = [
  { name: 'VPatient_IdentityStudies_e1t', years: 'VPatient_IdentityStudies_e1' },
  { name: 'VPatient_IdentityStudies_e2t', years: 'VPatient_IdentityStudies_e2' },
  { name: 'VPatient_IdentityStudies_e3t', years: 'VPatient_IdentityStudies_e3' },
  { name: 'VPatient_IdentityStudies_e4t', years: 'VPatient_IdentityStudies_e4' },
  { name: 'VPatient_IdentityStudies_e5t', years: 'VPatient_IdentityStudies_e5' },
];
const ITEM_HEIGHT = 48;

const omit = ['anchorEl', 'lateralityLabelWidth', 'sexLabelWidth', 'saved'];

class CivilitiesForm extends React.Component {
  constructor(props) {
    super(props);
    const { civilities } = props;
    const hasData = civilities !== undefined;

    if (hasData) {
      this.state = {
        anchorEl: null,
        lateralityLabelWidth: 0,
        sexLabelWidth: 0,
        firstname: civilities.firstname,
        lastname: civilities.lastname,
        sex: civilities.sex,
        email: civilities.email,
        yearsofstudy: civilities.yearsofstudy || '0',
        birthdate: civilities.birthdate,
        phone: civilities.phone,
        mobile: civilities.mobile,
        address: civilities.address,
        laterality: civilities.laterality,
      };
    } else {
      this.state = {
        anchorEl: null,
        lateralityLabelWidth: 0,
        sexLabelWidth: 0,
        firstname: '',
        lastname: '',
        birthdate: '',
        sex: 'man',
        email: '',
        yearsofstudy: '0',
        phone: '',
        mobile: '',
        address: '',
        laterality: 'righty',
      };
    }

    this.state.saved = true;
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({
      sexLabelWidth: ReactDOM.findDOMNode(this.sexLabelRef).offsetWidth,
      lateralityLabelWidth: ReactDOM.findDOMNode(this.lateralityLabelRef).offsetWidth,
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value, saved: false });
  };

  handleBirthdateChange = (event) => {
    const timeStamp = (new Date(event.target.value)).getTime().toString();
    this.setState({ birthdate: timeStamp, saved: false });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleVertMenuClose = (event) => {
    this.setState({ anchorEl: null, yearsofstudy: event.currentTarget.dataset.yearsofstudy, saved: false });
  };

  handleSave = () => {
    this.setState({ saved: true });
  };

  render() {
    const { classes, redirect, mutation, id, t, closecb } = this.props;
    const {
      anchorEl,
      firstname,
      lastname,
      birthdate,
      email,
      phone,
      mobile,
      yearsofstudy,
      sex,
      laterality,
      lateralityLabelWidth,
      sexLabelWidth,
      address,
    } = this.state;
    const open = Boolean(anchorEl);
    const UTCdate = new Date(birthdate * 1);
    const dateString = UTCdate.toISOString().slice(0, 10);
    return (
      <Mutation mutation={mutation} refetchQueries={() => [{ query: GET_PATIENTS }]}>
        {(upsertPatient) => (
          <div>
            <ValidatorForm
              onSubmit={(e) => {
                e.preventDefault();
                const state = _.omit(this.state, omit);
                const _id = id || null;
                upsertPatient({ variables: { id: _id, civilities: state } }).then((patient) => {
                  this.handleSave();

                  const currentPatient = {
                    id: patient.data.upsertPatient._id,
                    firstname: patient.data.upsertPatient.firstname,
                    lastname: patient.data.upsertPatient.lastname,
                    sex: patient.data.upsertPatient.sex,
                    interseance: patient.data.upsertPatient.account !== null,
                    code: patient.data.upsertPatient.code
                  };
                  sessionStorage.setItem('currentPatient', JSON.stringify(currentPatient));

                  if (redirect) {
                    closecb();
                  }
                });
              }}
              onError={(errors) => logger.error(errors)}
              className={classes.form}
            >
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    name="firstname"
                    id="firstname"
                    label={`${t('VPatient_IdentityFirstName')}*`}
                    validators={['required']}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    value={firstname}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    name="lastname"
                    id="lastname"
                    label={`${t('VPatient_IdentityLastName')}*`}
                    validators={['required']}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    value={lastname}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item>
                  <TextField
                    id="date"
                    name="birthdate"
                    label={t('VPatient_IdentityBirthdate')}
                    type="date"
                    value={dateString}
                    defaultValue="2017-05-24"
                    className={classes.birthday}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleBirthdateChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      ref={(ref) => {
                        this.sexLabelRef = ReactDOM.findDOMNode(ref);
                      }}
                      htmlFor="sex"
                    >
                      {t('VPatient_IdentityGender')}
                    </InputLabel>
                    <Select
                      autoWidth
                      value={sex}
                      onChange={this.handleChange}
                      input={<OutlinedInput labelWidth={sexLabelWidth} name="sex" id="sex" />}
                    >
                      <MenuItem value="man">{t('VPatient_IdentityGender_eMale')}</MenuItem>
                      <MenuItem value="woman">{t('VPatient_IdentityGender_eFemale')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      ref={(ref) => {
                        this.lateralityLabelRef = ReactDOM.findDOMNode(ref);
                      }}
                      htmlFor="laterality"
                    >
                      {t('VPatient_IdentityLaterality')}
                    </InputLabel>
                    <Select
                      autoWidth
                      value={laterality}
                      onChange={this.handleChange}
                      input={<OutlinedInput labelWidth={lateralityLabelWidth - 5} name="laterality" id="laterality" />}
                    >
                      <MenuItem value="righty">{t('VPatient_IdentityLaterality_eRight')}</MenuItem>
                      <MenuItem value="lefty">{t('VPatient_IdentityLaterality_eLeft')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <div>
                    <TextField
                      name="yearsofstudy"
                      id="nbrof-years-of-study"
                      label={t('VPatient_IdentityStudies')}
                      value={yearsofstudy}
                      className={classNames(classes.studies, classes.dense)}
                      margin="dense"
                      onChange={this.handleChange}
                    />
                    <IconButton
                      className={classes.moreButton}
                      aria-label="More"
                      aria-owns={open ? 'long-menu' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={this.handleVertMenuClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: 200,
                        },
                      }}
                    >
                      {studiesOptions.map((option) => (
                        <MenuItem
                          key={option.name}
                          onClick={this.handleVertMenuClose}
                          data-yearsofstudy={t(option.years)}
                        >
                          {t(option.name)}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </Grid>
              </Grid>

              <FormLabel component="legend" className={classes.formLabel}>
                {t('VPatient_IdentityContactInfo')}
              </FormLabel>

              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <PhoneIphone />
                </Grid>
                <Grid item>
                  <TextValidator
                    name="mobile"
                    label={t('VPatient_IdentityContactInfoCell')}
                    type="tel"
                    fullWidth
                    value={mobile}
                    onChange={this.handleChange}
                    errorMessages={['numéro incorrecte']}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Phone />
                </Grid>
                <Grid item>
                  <TextValidator
                    label={t('VPatient_IdentityContactInfoLandline')}
                    name="phone"
                    onChange={this.handleChange}
                    value={phone}
                    type="tel"
                    fullWidth
                    validators={['isNumber']}
                    errorMessages={t('VPatient_IdentityContactInfoCellIncorrect')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Email />
                </Grid>
                <Grid item>
                  <TextValidator
                    label={t('VPatient_IdentityContactInfoMail')}
                    onChange={this.handleChange}
                    name="email"
                    type="email"
                    fullWidth
                    value={email}
                    validators={['isEmail']}
                    errorMessages={t('VPatient_IdentityContactInfoMailInvalid')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextField
                    name="address"
                    id="address"
                    label={t('VPatient_IdentityContactInfoAdress')}
                    fullWidth
                    multiline
                    value={address}
                    onChange={this.handleChange}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="outlined"
                disabled={this.state.saved}
                color="primary"
                className={classes.submitButton}
              >
                {t('Common_Save')}
              </Button>
            </ValidatorForm>
          </div>
        )}
      </Mutation>
    );
  }
}

CivilitiesForm.propTypes = {
  id: PropTypes.any.isRequired,
  closecb: PropTypes.func,
  redirect: PropTypes.any,
  mutation: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  civilities: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

CivilitiesForm.defaultProps = {
  closecb: () => { },
  redirect: null,
};

export default withTranslation()(withStyles(styles)(CivilitiesForm));

import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ConfigForm from 'activities/activityWrapper/components/ConfigForm';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { getApolloClient } from 'utils/apolloClientFactory';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody.jsx';
import dashboardStyle from 'assets/jss/views/dashboardStyle.jsx';
import Snackbar from 'components/Snackbar/Snackbar';
import { checkCurrentUserPermission } from 'utils/permissionChecker';
import NoProgram from 'components/Zero/NoProgram';
import { getDoctorName, getCurrentPatientId, isUserThePatient, ensureAccessToken } from 'utils';
import { GET_PROGRAMMED_ACTIVITIES, DELETE_PROGRAMMED_ACTIVITY, UPSERT_ACTIVITY, UPSERT_ACTIVITY_ORDER } from 'graphql/queries';
import Drawer from '@material-ui/core/Drawer';
import types from 'utils/types';
import { logger } from 'logger';
import theme from 'styles/theme';
import { cvGreenColor } from '../../styles/variables';





const styles = () => ({
  ...dashboardStyle,
  avatar: {
    backgroundColor: cvGreenColor,
    borderRadius: '0%',
    minWidth: "100px",
    minHeight: "100px"
  },
  drawer: {
    maxWidth: '80%',
    minWidth: '50%',
  },
  activityCard: {
    backgroundColor: theme.colors.orangeLight
  },
  activityDescription: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
    minHeight: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
    minHeight: 60,
    justifyContent: 'right',
    alignItems: 'center'
  },
  button: {
    margin: 5
  },
  startButton: {
    width: "100%",
    height: "100%",
    fontSize: "x-large"
  }
});



const deleteActivity = (id) =>
  new Promise((success) => {
    getApolloClient().then((client) => {
      success(
        client.mutate({
          mutation: DELETE_PROGRAMMED_ACTIVITY,
          variables: { activityId: id },
        }),
      );
    });
  });

class Program extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activitiesLoaded: false,
      choosing: true,
      deleteConfirmDialogOpen: false,
      snackbarVisible: false,
      snackbarMessage: "",
      configFormDrawerOpen: false,
      configFormDrawerRenderable: false,
    };
    this.getProgrammedActivities();
    window.logger.store({ context: "Program", message: "Displaying program" })
    if (isUserThePatient()) {
      // Let's prepare for the autonomous activities
    }
  }

  componentDidMount() {
    // Connecting to the socket if patient interseance
    if (!_.includes(this.props.userTypes, types.DOCTOR_TYPE)) {

      const sessionID = Math.floor(Math.random() * 10000);

      if (sessionID) {
        // this.usernameAlreadySelected = true;
        window.comSocket.auth = { sessionID };
        window.comSocket.connect();
        window.activitySocket.auth = { sessionID };
        window.activitySocket.connect();

        window.comSocket.on('connect', () => {
          console.log('communication socket connected');
        });
        window.activitySocket.on('connect', () => {
          console.log('activity socket connected');
        });

      } else {
        console.log('Cannot connect, no session code');
      }

      if (!window.comSocket) {
        throw new Error('missing window.comSocket');
      }

      ensureAccessToken().then(() => {
        logger.debug('ensureAccessToken');
        window.comSocket.emit(
          'startSession',
          {
            id: getCurrentPatientId(),
            token: window.keycloak.token,
          },
          // Callback when message is ack by the server
          () => { },
        );
      });
    }
  }


  setFullScreen = () => {
    console.log('trying to set full screen');
    const docElm = document.documentElement;
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    }
  };


  getProgrammedActivities = () => {
    getApolloClient(false).then((client) => {
      client
        .query({
          query: GET_PROGRAMMED_ACTIVITIES,
          variables: { lastEvaluatedDate: Date.now().toString(), patientId: getCurrentPatientId() },
        })
        .then((response) => {
          // creating display order table
          const displayOrderTable = [];
          for (let actIndex = 0; actIndex < response.data.getAllActivities.length; actIndex += 1) {
            displayOrderTable.push([actIndex, response.data.getAllActivities[actIndex].order]);
          }
          this.setState({
            programmedActivities: response.data.getAllActivities,
            displayOrderTable
          });
          this.setState({ activitiesLoaded: true });
        });
    });
  };


  programInformation = (activity) => {
    const { t } = this.props;
    const UTCCreationdate = new Date(activity.createdAt * 1);

    const programCreation = `
    ${t('VBetweenSessions_Dashboard_OnBy')
        .replace('[DATE]', UTCCreationdate.toLocaleDateString())
        .replace('[DOCTOR]', getDoctorName(JSON.parse(activity.configuration).doctorInput))}`;
    const { programName } = JSON.parse(activity.configuration);
    return { creation: programCreation, name: programName };
  };

  loadConfig = (activity) => {
    const confProm = import(`@covirtua-activities/${activity.slug}/build/config.js`).then((configLoad) => {
      const configModel = Object.assign({}, configLoad.default);
      configModel.defaultConf = JSON.parse(activity.configuration)
      this.setState({
        configModel,
      });
    });
    Promise.all([confProm])
      .then(() => { })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({
          configFormDrawerRenderable: true,
        });
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  showSnackbar = (message) => {
    this.setState({
      snackbarVisible: true,
      snackbarMessage: message,
    });
    setTimeout(() => {
      this.setState({ snackbarVisible: false });
    }, 5000);
  };

  chosenActivityDetail = () => {
    if (this.state.activityChosen)
      return `${this.props.t(this.state.activityChosen.name)} (${this.programInformation(this.state.activityChosen).name})`
    return '';
  };

  closeDeleteConfirmDialog = () => {
    this.setState({ deleteConfirmDialogOpen: false });
  }

  handleDeleteActivity = () => {
    this.setState({ deleteConfirmDialogOpen: false });

    deleteActivity(this.state.activityChosen._id).then((response) => {
      console.log("response:", response);
      this.showSnackbar(this.props.t("VProgram_DeletedConfirmation").replace("[ACTIVITY]", this.chosenActivityDetail()));
      this.getProgrammedActivities();
    });
  };

  hideConfigFormDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ configFormDrawerOpen: false, configFormDrawerRenderable: false });
  };

  modifyExistingConfig = (modifiedConfig, permanent) => {
    this.setState({ configFormDrawerOpen: false, configFormDrawerRenderable: false });
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: UPSERT_ACTIVITY,
          variables: { channel: this.state.activityChosen.channel, config: JSON.stringify(modifiedConfig), permanent },
        })
        .then(() => {
          this.showSnackbar(this.props.t("VProgram_ModifiedConfirmation").replace("[ACTIVITY]", this.chosenActivityDetail()))
          this.getProgrammedActivities();
        }
        )
    });
  }

  modifyActivityOrder = (activity, newOrder) => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: UPSERT_ACTIVITY_ORDER,
          variables: { channel: activity.channel, order: newOrder },
        })
        .then(() => {
          this.getProgrammedActivities();
        }
        )
    });
  }

  upActivity = (activity) => {
    const displayOrder = _.findIndex(this.state.displayOrderTable, item => item[1] === activity.order);
    if (displayOrder > 0) {
      const lower = (displayOrder > 1 ? this.state.displayOrderTable[displayOrder - 2][1] : 0)
      const upper = this.state.displayOrderTable[displayOrder - 1][1];
      const newOrder = lower + (upper - lower) / 2;
      this.modifyActivityOrder(activity, newOrder);
    }
  }

  downActivity = (activity) => {
    const displayOrder = _.findIndex(this.state.displayOrderTable, item => item[1] === activity.order);
    if (displayOrder < this.state.displayOrderTable.length - 1) {
      const lower = this.state.displayOrderTable[displayOrder + 1][1];
      const upper = displayOrder < this.state.displayOrderTable.length - 2 ?
        this.state.displayOrderTable[displayOrder + 2][1] : this.state.displayOrderTable[displayOrder + 1][1] * 2;
      const newOrder = lower + (upper - lower) / 2;
      this.modifyActivityOrder(activity, newOrder);
    }
  }

  renderLoading = () => (
    <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
      <CircularProgress />
    </div>
  );

  render() {
    const { classes, t } = this.props;
    let core;

    if (this.state.activitiesLoaded && this.state.choosing) {
      core =
        (
          <div>
            <Dialog
              open={this.state.deleteConfirmDialogOpen}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {t("VProgram_ConfirmDelete")
                  .replace("[ACTIVITY]", this.chosenActivityDetail())}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={this.closeDeleteConfirmDialog}
                  color="primary"
                >
                  {t('Common_Cancel')}
                </Button>
                <Button
                  onClick={this.handleDeleteActivity}
                  color="primary"
                  variant="contained"
                >
                  {t('Common_Confirm')}
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              place="br"
              color="success"
              open={this.state.snackbarVisible}
              message={this.state.snackbarMessage}
            />
            {this.state.configFormDrawerRenderable &&
              <Drawer
                anchor="right"
                classes={{ paperAnchorRight: classes.drawer }}
                open={this.state.configFormDrawerOpen}
                onClose={this.hideConfigFormDrawer()}
              >{this.state.activityChosen &&
                <ConfigForm
                  configModel={this.state.configModel}
                  isModifyingProgram
                  handleModifyExistingConfig={this.modifyExistingConfig}
                  permanent={this.state.activityChosen.permanent}
                />
                }
              </Drawer>}
            {this.state.programmedActivities.length > 0 ? (
              <div>
                {this.state.programmedActivities.map(
                  (activity) =>
                    (activity.stoppedAt == null || activity.permanent) && (
                      <Card className={classes.activityCard}>
                        <GridContainer>
                          <GridItem xs={4} sm={4} md={4} align="top">
                            <CardHeader
                              avatar={
                                <Avatar
                                  className={classes.avatar}
                                  src={window.AssetResolver.getAsset(
                                    `/activitiesAssets/${_.camelCase(activity.slug)}/image/Icon.png`,
                                  )}
                                />
                              }
                              title={<div>{t(activity.name)}{activity.permanent && !isUserThePatient() && <AllInclusiveIcon />}</div>}
                              titleTypographyProps={{ variant: 'h6' }}
                              subheader={this.programInformation(activity).creation}
                            />
                          </GridItem>
                          <GridItem xs={8} sm={8} md={8}>
                            <CardBody className={classes.cardBody}>
                              <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                  <Card className={classes.activityDescription}>
                                    <Typography variant="h5" gutterBottom>
                                      {this.programInformation(activity).name}
                                    </Typography>
                                  </Card>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                  {isUserThePatient() ? (
                                    <Button
                                      className={classes.startButton}
                                      // eslint-disable-next-line
                                      onClick={() => {
                                        if (!checkCurrentUserPermission("feature_disabledFullScreen"))
                                          this.setFullScreen();
                                        window.logger.store({ context: "Program", message: "Click on activity", data: activity });
                                        this.setState({ activityChosen: activity, choosing: false });
                                      }}
                                      color="primary"
                                      variant="contained"
                                    >
                                      {t('Common_Control_Start')}
                                    </Button>
                                  ) : (
                                    <div className={classes.actionButtons}>
                                      <Button
                                        className={classes.button}
                                        // eslint-disable-next-line
                                        onClick={() => {
                                          this.setState({ activityChosen: activity, deleteConfirmDialogOpen: true })
                                        }}
                                        color="primary"
                                        variant="contained"
                                      >
                                        {t('Common_Delete')}
                                      </Button>
                                      <Button
                                        className={classes.button}
                                        // eslint-disable-next-line
                                        onClick={() => {
                                          this.loadConfig(activity)
                                          // console.log("activity:", activity);
                                          this.setState({ activityChosen: activity, configFormDrawerOpen: true })
                                        }}
                                        color="primary"
                                        variant="contained"
                                      >
                                        {t('Common_Modify')}
                                      </Button>
                                      <Button
                                        className={classes.button}
                                        // eslint-disable-next-line
                                        onClick={() => {
                                          this.upActivity(activity)
                                        }}
                                        color="primary"
                                        variant="contained"
                                      >
                                        <ArrowDropUpIcon />
                                      </Button>
                                      <Button
                                        className={classes.button}
                                        // eslint-disable-next-line
                                        onClick={() => {
                                          this.downActivity(activity)
                                        }}
                                        color="primary"
                                        variant="contained"
                                      >
                                        <ArrowDropDownIcon />
                                      </Button>
                                    </div>
                                  )}
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </GridItem>
                        </GridContainer>
                      </Card>
                    )
                )
                }
              </div>
            ) : (
              <NoProgram />
            )}
          </div>
        )
    } else if (!this.state.choosing) {
      console.log('launching activity, state=', this.state);
      console.log(
        'window.config.services.front.patient.remote.activityRoute :>> ',
        window.config.services.front.patient.remote.activityRoute,
      );
      console.log('window.comSocket :>> ', window.comSocket);
      core = (
        <Redirect
          to={{
            pathname: `${window.config.services.front.patient.remote.activityRoute}`,
            state: this.state,
            socket: window.comSocket,
          }}
        />
      );
    }
    return <div>{core}</div>;
  }
}

Program.propTypes = {
  t: PropTypes.func.isRequired,
  userTypes: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(withRouter(Program)));

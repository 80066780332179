/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Mutation } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withTranslation } from 'react-i18next';
import { formatDate } from '../../utils';
import { GET_PATIENT_CARE } from '../../graphql/queries';
import { cvVioletColor } from '../../styles/variables';
import { logger } from '../../logger';

const omit = ['anchorEl', '__typename'];

const styles = (theme) => ({
  root: {
    color: cvVioletColor,
    '&$checked': {
      color: cvVioletColor,
    },
  },
  checked: {},
  form: {
    width: 300,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    minWidth: '100%',
    maxWidth: 200,
  },
  formLabel: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  },

  submitButton: {
    // width: '100%',
    marginTop: theme.spacing.unit * 4,
  },
});

class PatientCareForm extends Component {
  constructor(props) {
    super(props);

    const patientCare = props.patientCare[parseInt(props.index, 10)];
    if (patientCare) {
      this.state = patientCare;
    } else {
      this.state = {
        description: '',
        ongoing: false,
        title: '',
        start: '',
        end: '',
      };
    }
  }

  handleChange = (event) => {
    const boxes = ['ongoing'];

    if (boxes.includes(event.target.name)) {
      this.setState({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  render() {
    const { classes, mutation, id, onClose, t } = this.props;

    return (
      <Mutation mutation={mutation} refetchQueries={() => [{ query: GET_PATIENT_CARE, variables: { id } }]}>
        {(upsertPatientCare) => (
          <div>
            <ValidatorForm
              onSubmit={(e) => {
                e.preventDefault();
                const state = _.omit(this.state, omit);
                const _id = id || null;

                upsertPatientCare({ variables: { id: _id, patientCare: state } }).then(() => {
                  onClose();
                });
              }}
              onError={(errors) => logger.error(errors)}
              className={classes.form}
            >
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    name="title"
                    value={this.state.title}
                    id="title"
                    label={`${t('VPatient_CareTitle')} *`}
                    validators={['required']}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextField
                    id="start"
                    label={t('VPatient_CareBegin')}
                    type="date"
                    defaultValue={formatDate(Date.now())}
                    className={classes.date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleChange}
                    name="start"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.ongoing}
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                        onChange={this.handleChange}
                        value="ongoing"
                        name="ongoing"
                      />
                    }
                    label={t('VPatient_CareRunning')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextField
                    id="end"
                    label={t('VPatient_CareEnd')}
                    type="date"
                    name="end"
                    onChange={this.handleChange}
                    defaultValue={formatDate(Date.now())}
                    className={classes.date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    name="description"
                    label={t('Common_Description')}
                    fullWidth
                    multiline
                    onChange={this.handleChange}
                    className={classes.textField}
                    margin="normal"
                    value={this.state.description}
                  />
                </Grid>
              </Grid>

              <Button type="submit" variant="outlined" color="primary" className={classes.submitButton}>
                {t('Common_Save')}
              </Button>
            </ValidatorForm>
          </div>
        )}
      </Mutation>
    );
  }
}

PatientCareForm.propTypes = {
  patientCare: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  mutation: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(PatientCareForm));

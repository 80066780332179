/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Patient from 'components/Patients/Patient';
import Card from 'components/Card/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardBody from 'components/Card/CardBody.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import SimpleBasicDialog from 'components/Dialog/SimpleBasicDialog';
import PatientAdd from 'views/Patients/PatientAdd';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import { GET_PATIENTS, UPSERT_PATIENT_CODE } from 'graphql/queries';
import _ from 'lodash';
import { getApolloClient } from 'utils/apolloClientFactory';
import { withTranslation } from 'react-i18next';
import UnauthorizedAccess from 'components/Authentication/UnauthorizedAccess';

const styles = () => ({
  card: {},
});

const Patients = (props) => {
  const { classes, t } = props;
  const [open, setOpen] = useState(false);

  const getSessionCode = () => Math.floor(Math.random() * 10000).toString();

  if (window.comSocket) {
    const roomID = JSON.parse(sessionStorage.getItem('currentPatient')).code;
    window.comSocket.emit('Eject', roomID);
  }

  const generatePatientCode = (id) =>
    new Promise((success) => {
      getApolloClient().then((client) => {
        success(
          client.mutate({
            mutation: UPSERT_PATIENT_CODE,
            variables: { id, code: getSessionCode(id) },
          }),
        );
      });
    });

  const handleClick = (e) => {
    console.log('handleClick');
    const target = e.currentTarget.dataset;
    const patient = {
      id: target._id,
      firstname: target.firstname,
      lastname: target.lastname,
      permacode: target.permacode,
      sex: target.sex,
      interseance: (target.interseancepatient === "true")
    };
    const url = `/activities`;
    generatePatientCode(patient.id).then((response) => {
      patient.code = response.data.upsertPatientCode.code;
      sessionStorage.setItem('currentPatient', JSON.stringify(patient));
      window.location.href = url; // OULALA
    });
  };

  const handleSimpleBasicDialogClose = (currentPatient) => {
    const patient = currentPatient;
    patient.interseance = false; // A newly created patient cannot be "interseance" active right at creation
    const url = `/activities`;
    generatePatientCode(patient.id).then((response) => {
      patient.code = response.data.upsertPatientCode.code;
      sessionStorage.setItem('currentPatient', JSON.stringify(patient));
      window.location.href = url; // OULALA
    });
    setOpen(false);
  };
  const handleAddNewPatient = () => setOpen(true);

  // clean patient from storage as soon as we go to patient selection page
  sessionStorage.removeItem('currentPatient');

  return (
    <div>
      <SimpleBasicDialog
        title={t('VPatientList_NewPatient')}
        content={<PatientAdd history={props.history} closecb={handleSimpleBasicDialogClose} redirect />}
        onClose={handleSimpleBasicDialogClose}
        open={open}
      />
      <div className={classNames(classes.layout, classes.cardGrid)}>
        <Query query={GET_PATIENTS} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return <CircularProgress />;
            if (error)
              return <UnauthorizedAccess errorMsg={error.message} reason={error.graphQLErrors[0].internalErrorCode} />;
            if (_.isEmpty(data)) return `Empty`;
            return (
              <div>
                <Button variant="outlined" color="primary" onClick={handleAddNewPatient}>
                  {t('VPatientList_AddPatient')}
                </Button>

                <GridContainer spacing={40}>
                  {_.sortBy(_.filter(data.getAllPatients, ['isAnonymous', false]), 'firstname').map((p) => (
                    <GridItem key={p._id} sm={6} md={4} lg={4}>
                      <Card className={classes.card}>
                        <CardActionArea
                          // component={Link}
                          // to={`/patients/${p._id}`}
                          onClick={handleClick}
                          data-_id={p._id}
                          data-firstname={p.firstname}
                          data-lastname={p.lastname}
                          data-sex={p.sex}
                          data-permacode={p.permacode || null}
                          data-interseancePatient={p.account !== null}
                        >
                          <CardHeader
                            title={<Typography variant="h6">{p.lastname}</Typography>}
                            subheader={
                              <Typography variant="subtitle1">
                                {p.firstname}
                              </Typography>
                            }
                            data-_id={p._id}
                          />
                          <CardBody><Patient id={p._id} pathologies={p.pathologies} /></CardBody>
                        </CardActionArea>
                      </Card>
                    </GridItem>
                  ))}
                </GridContainer>
                {_.filter(data.getAllPatients, ['isAnonymous', true]).length &&
                  <Typography variant="h6">{t("VPatientList_Anonymous")}</Typography>}
                <GridContainer spacing={40}>
                  {_.sortBy(_.filter(data.getAllPatients, ['isAnonymous', true]), 'firstname').map((p) => (
                    <GridItem key={p._id} sm={6} md={4} lg={4}>
                      <Card className={classes.card}>
                        <CardActionArea
                          // component={Link}
                          // to={`/patients/${p._id}`}
                          onClick={handleClick}
                          data-_id={p._id}
                          data-firstname={p.firstname}
                          data-lastname={p.lastname}
                          data-sex={p.sex}
                          data-permacode={p.permacode || null}
                          data-interseancePatient={p.account !== null}
                        >
                          <CardHeader
                            title={<Typography variant="h6">{p.firstname}</Typography>}
                            data-_id={p._id}
                          />
                        </CardActionArea>
                      </Card>
                    </GridItem>
                  ))}
                </GridContainer>
              </div>
            );
          }}
        </Query>
      </div>
    </div>
  );
};

Patients.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

Patients.defaultProps = {
  history: null,
};

// export default withStyles(styles)(Patients);
export default withTranslation()(withStyles(styles)(withRouter(Patients)));

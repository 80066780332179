import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { ActivityWrapperThumbnail } from 'activities';
import dashboardStyle from 'assets/jss/views/dashboardStyle.jsx';
import { withTranslation } from 'react-i18next';
import { checkCurrentUserPermission } from '../utils/permissionChecker';

class GridThumbnails extends React.Component {
  constructor(props) {
    super(props);

    this.categoriesIcon = {
      Categories_Inhibition: { iconPath: '/appAssets/image/icon/catInhibitions.png' },
      Categories_Memory: { iconPath: '/appAssets/image/icon/catMemory.png' },
      Categories_Attention: { iconPath: '/appAssets/image/icon/catAttention.png' },
      Categories_Language: { iconPath: '/appAssets/image/icon/catLanguage.png' },
      Categories_Predicog: { iconPath: '/appAssets/image/icon/catCNRS.png' },
      Categories_Evolex: { iconPath: '/appAssets/image/icon/catAttention.png' },
      Categories_House: { iconPath: '/appAssets/image/icon/catHouse.png' },
      Categories_Downtown: { iconPath: '/appAssets/image/icon/catDowntown.png' },
    };

    Object.values(this.categoriesIcon).forEach((category) => {
      category.iconFile = window.AssetResolver.getAsset(category.iconPath); // eslint-disable-line no-param-reassign
    });
  }

  render() {
    const { classes, t, activities, color } = this.props;

    return (
      <div>
        {Object.keys(activities).map(
          (subcategory) =>
            checkCurrentUserPermission(subcategory) && (
              <GridContainer key={subcategory}>
                <GridItem xs={12} sm={12} md={12}>
                  <Card chart>
                    <CardHeader color={color}>
                      <Icon>
                        <img src={this.categoriesIcon[subcategory].iconFile} height="25px" width="25px" alt="" />
                      </Icon>
                      <h3 className={classes.cardCategoryTitle}>{t(subcategory)}</h3>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        {activities[subcategory].map(
                          (activity) =>
                            (activity.available ||
                              process.env.REACT_APP_NODE_ENV === 'development' ||
                              process.env.REACT_APP_NODE_ENV === 'staging') &&
                            checkCurrentUserPermission(activity.flag) && (
                              <GridItem xs={12} sm={6} md={2} key={activity.slug}>
                                <ActivityWrapperThumbnail slug={activity.slug} />
                              </GridItem>
                            ),
                        )}
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            ),
        )}
      </div>
    );
  }
}

GridThumbnails.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  activities: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(GridThumbnails));

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { cvVioletColor } from '../../styles/variables';

const styles = (theme) => ({
  root: {
    color: cvVioletColor,
    '&$checked': {
      color: cvVioletColor,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  tooltipIcon: {
    padding: 5,
    paddingLeft: 0,
    color: cvVioletColor,
    marginLeft: -14,
    cursor: 'pointer',
  },
  tooltipIconSelect: {
    padding: 5,
    paddingLeft: 0,
    color: cvVioletColor,
  },
  checked: {},
  formControlWrapper: {
    marginBottom: theme.spacing.unit * 3,
    minWidth: '100%',
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  indentedFormBlock: {
    marginLeft: theme.spacing.unit * 6,
  },
  submitButton: {
    marginRight: 10,
  },
  programButton: {
    marginRight: 10,
  },
  headerFromH7: {
    marginTop: theme.spacing.unit * 3,
  },
  configDivider: {
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  helpButton: {
    color: cvVioletColor,
    marginLeft: '5px',
    width: '10%',
    fontSize: '30px',
    '&:hover': {
      color: cvVioletColor,
    },
  },
  asyncInput: {
    display: 'inline-block',
    width: '75%',
  },
  scenarioDrawer: {
    left: '10%',
  },
  drawerPaper: {
    left: '10%',
  },
  randomScenarLabel: {
    display: 'inline-block',
    padding: '10px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: cvVioletColor,
  },
  submitArea: {
    position: 'fixed',
    bottom: 70,
    right: 50,
    padding: 30,
    border: 'solid 1px #9788AC',
    backgroundColor: '#fcfaff',
    borderRadius: '10px',
  },
  options: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
});
class StopActivityDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closingType: 'eAbandon',
    };
    this.options = [
      {
        // Quit, not saving intermediary results
        value: 'eAbandon',
        label: 'Common_RuntimeView_DQuit_eAbandon',
        tooltip: 'Common_RuntimeView_DQuit_eAbandonToolTip',
      },
      /*{
        // Quit, saving intermediary results
        value: 'eInterrupt',
        label: 'Common_RuntimeView_DQuit_eInterrupt',
        tooltip: 'Common_RuntimeView_DQuit_eInterruptToolTip',
      },*/
      {
        // go back to configuration page
        value: 'eConfig',
        label: 'Common_RuntimeView_DQuit_eConfig',
        tooltip: 'Common_RuntimeView_DQuit_eConfigToolTip',
      },
    ];
  }

  handleClose = () => {
    this.props.onClose();
  };

  handleQuit = () => {
    this.props.onQuit(this.state.closingType);
  };

  onChangeValue = (event) => {
    this.setState({ closingType: event.target.value });
  };

  render() {
    const { classes, onClose, content, t, ...other } = this.props;
    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other} scroll="paper">
        <DialogTitle>{t('Common_RuntimeView_DQuit_Title')}</DialogTitle>
        <DialogContent>
          <div onChange={this.onChangeValue}>
            {this.options.map((option) => (
              <Tooltip title={t(option.tooltip)} classes={{ tooltip: classes.tooltip }}>
                <div>
                  <Typography variant="h7" gutterBottom className={classes.options}>
                    <input type="radio" value={option.value} checked={option.value === this.state.closingType} />
                    {t(option.label)}
                  </Typography>
                </div>
              </Tooltip>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.handleQuit();
            }}
            color="primary"
            variant="contained"
          >
            {t('Common_Exit')}
          </Button>
          <Button
            onClick={() => {
              this.handleClose();
            }}
            color="primary"
            variant="outlined"
          >
            {t('Common_Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

StopActivityDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(StopActivityDialog));

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withTranslation } from 'react-i18next';

class SimpleDialog extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleYes = () => {
    this.props.yes();
    this.props.onClose();
  };

  handleNo = () => {
    this.props.no();
    this.props.onClose();
  };

  render() {
    const { classes, onClose, content, t, ...other } = this.props;

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other} scroll="paper">
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleYes} color="primary">
            {t('Common_Yes')}
          </Button>
          <Button onClick={this.handleNo} color="primary">
            {t('Common_No')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default withTranslation()(SimpleDialog);

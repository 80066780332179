import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody.jsx';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import dashboardStyle from 'assets/jss/views/dashboardStyle.jsx';
import openSocket from 'socket.io-client';
import { GET_PATIENT_BY_CODE } from 'graphql/queries';
import { getApolloClient } from 'utils/apolloClientFactory';
// import { checkCurrentUserPermission } from 'utils/permissionChecker';
import { withTranslation } from 'react-i18next';
import { cvGreenColor } from '../../styles/variables';

let bAckPing = false;
let nCountPing = 0;
const nCountPingMax = 5;
const nPingTimeAck = '2000';
const nPingTimeCheck = '5000';
let nIntervalID = -1;
let bFirstConnection = true;
const styles = () => ({
  ...dashboardStyle,
  avatar: {
    backgroundColor: cvGreenColor,
    borderRadius: '0%',
  },
});

class Patient extends React.Component {
  constructor(props) {
    super(props);
    this.id = Date.now();
    window.comSocket = openSocket(`${window.ACTIVITY_SERVER_URL}/communication`, { autoConnect: false });
    window.activitySocket = openSocket(`${window.ACTIVITY_SERVER_URL}/activity`, { autoConnect: false });
    this.state = {
      communicationChannelJoined: false,
      communicationChannel: '',
      code: '',
    };
  }

  setFullScreen = () => {
    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      const docElm = document.documentElement;
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    };
  }

  checkConnectionStatusRoutine = (socket, roomID) => {
    if (nIntervalID > -1) {
      clearInterval(nIntervalID);
      }
      socket.emit('CustomPing', roomID);
      nIntervalID = setInterval(() => {
        bAckPing = false;

        socket.emit('CustomPing', roomID);
        setTimeout(() => {
          // console.log(bAckPing);
          if (bAckPing) {
            nCountPing = 0;
            // console.log(`${this.id}: Check Timeout Pong from Doctor OK`);
            this.setState({
              communicationChannelJoined: true,
            });
          } else if (nCountPing > nCountPingMax) {
            console.log(`${this.id}: Error Max Retry Pong`);
            this.handleDoctorDisconnected();
          } else {
            nCountPing += 1;
            // console.log(`${this.id}: Retry getting Pong from Doctor`);
          }
        }, nPingTimeAck);
      }, nPingTimeCheck);
    };

  handleJoin = () => {
    getApolloClient(false).then((client) => {
      client
        .query({
          query: GET_PATIENT_BY_CODE,
          variables: { code: this.state.code },
        })
        .then((response) => {
          this.setState({
            communicationChannel: response.data.getPatientByCode._id,
          });

            if (this.state.communicationChannel) {
              const sessionID = this.state.code;
              if (sessionID) {
                window.comSocket.auth = { sessionID: `${this.state.code}` };
                sessionStorage.setItem('sessionID', sessionID);
                window.comSocket.connect();

                window.activitySocket.auth = { sessionID: `${this.state.code}` };
                window.activitySocket.connect();
              } else {
                throw new Error('missing code');
              }

              if (!window.comSocket) {
                throw new Error('missing window.comSocket');
              }
              window.comSocket.on('userDisconnected', () => {
                this.handleDoctorDisconnected();
              });

              window.comSocket.on('Ejected', () => {
                this.handleDoctorDisconnected();
              });

              //  window.comSocket.emit('CustomPing', sessionID);

              this.checkConnectionStatusRoutine(window.comSocket, sessionID);
              window.comSocket.removeAllListeners('CustomPing');
              window.comSocket.on('CustomPing', () => {
                // console.log(`${this.id} : CustomPing`);
                window.comSocket.emit('CustomPong', sessionID);
              });

              window.comSocket.removeAllListeners('CustomPong');
              window.comSocket.on('CustomPong', () => {
                // console.log(`${this.id} : Get Pong from Doctor`);

                if (bFirstConnection) {
                  // console.log('Get First Pong from Doctor');
                  this.setState({
                    communicationChannelJoined: true,
                  });
                  bFirstConnection = false;
                }
                bAckPing = true;
              });

              this.setState({
                communicationChannelJoined: false,
              });
              // window.comSocket.emit('getRoomSize', sessionID, (answer) => {
              //   if (answer.roomSize === 2) {
              //     this.setState({
              //       communicationChannelJoined: true,
              //     });
              //   } else {
              //     this.setState({
              //       communicationChannelJoined: false,
              //     });
              //   }
              // });

              // window.activitySocket.emit('getRoomSize', sessionID, (answer) => {
              //   if (answer.roomSize === 2) {
              //     this.setState({
              //       activityChannelJoined: true,
              //     });
              //   } else {
              //     this.setState({
              //       activityChannelJoined: false,
              //     });
              //   }
              // });
            }
          });
      });
    };

  handleDoctorDisconnected = () => {
    console.log('doctor has left!');

    window.comSocket.disconnect();
    window.activitySocket.disconnect();
    window.location.href = window.config.services.front.patient.site.url;
  };
  handleChange = (event) => {
    this.setState({
      code: event.target.value,
    });
  };

  keyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      this.handleJoin();
    }
  };

  render() {
    const { classes, t } = this.props;
    let core;

      if (!(this.state.communicationChannelJoined /* && this.state.activityChannelJoined */)) {
        core = (
          <Card className={classes.cardOuter}>
            <CardBody>
              <TextField
                autoComplete="off"
                id="activity"
                label={t('Main_SessionCodeInvite')}
                className={classes.textField}
                value={this.state.code}
                onChange={this.handleChange}
                onKeyDown={this.keyPress}
                margin="normal"
                variant="outlined"
                autoFocus
              />
              <Button style={{ display: 'block' }} variant="outlined" color="primary" onClick={this.handleJoin}>
                {t('Main_SessionJoin')}
              </Button>
            </CardBody>
          </Card>
        );
      } else {
        // removing permissionCheck for not connected users
        // if (!checkCurrentUserPermission('feature_disabledFullScreen'))
        // full screen does not work if not immediately following user input
        // this.setFullScreen();
        core = (
          <Redirect
            to={{
              pathname: `${window.config.services.front.patient.site.activityRoute}`,
              state: this.state,
              socket: window.comSocket,
            }}
          />
        );
      }

    return <div>{core}</div>;
  }
}

Patient.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(withRouter(Patient)));

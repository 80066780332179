/* eslint-disable react/jsx-no-bind */
/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoResults from 'components/Zero/NoResults';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import ProgressChart from '../../../views/Stats/ProgressChart';
import Card from '../../../components/Card/Card';
import dashboardStyle from '../../../assets/jss/views/dashboardStyle';
import CardBody from '../../../components/Card/CardBody';
import { cvBluePetrolColor, getColor } from '../../../styles/variables';
import { getApolloClient } from '../../../utils/apolloClientFactory';
import { getCurrentPatientId } from '../../../utils';
import { GET_ACTIVITIES_BY_SLUG } from '../../../graphql/queries';
import { tShortLabel, tReducedLabel, tToolTip } from '../../../i18n/utils';
import { prepareValueForDisplay } from './Formatter';
import { logger } from '../../../logger';
import activitiesList from '../../list-activities.json';

const styles = (theme) => ({
  ...dashboardStyle,
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  avatar: {
    borderRadius: '0%',
  },
  series: {
    padding: 20,
    minWidth: 1000,
  },
  activeRow: {
    backgroundColor: 'rgba(151, 136, 172, 0.7)',
    color: 'white',
  },
  cardBody: {
    // maxHeight : 700,
    overflow: 'auto',
  },
  table: {},
  tableHeadCol: {
    position: 'sticky',
    top: '-20px',
    background: 'white',
    textAlign: 'center',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: cvBluePetrolColor,
    },
  },
  selected: {
    backgroundColor: cvBluePetrolColor,
  },
  group: {
    // display:"table-row-group",
  },
  chart: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: '65%',
    padding: 30,
  },
  container: {
    // height: 700,
    overflow: 'auto',
  },
  cells: {
    textAlign: 'center',
  },
  historyCard: {
    position: 'absolute',
    bottom: '35%',
    top: 0,
    right: 0,
    left: 0,
  },
  historyCardTableOnly: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    margin: 0,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
});

class PreviousResults extends Component {
  constructor(props) {
    super(props);
    this.groupResultsBy = this.groupResultsBy.bind(this);
    momentDurationFormat(moment);
    this.state = {
      slug: props.slug,
      configuration: props.configuration,
      currentSeries: [],
      loading: true,
      groupedActivities: [],
      fieldsToGroupBy: [],
      activities: [],
      selectedActivity: {},
      tableOnly: props.tableOnly || false,
      renderable: false,
    };

    const logoProm = import(`@covirtua-activities/${this.state.slug}/build/logo.png`).then((logoLoad) => {
      this.setState({
        logo: logoLoad.default,
      });
    });

    const dfProm = import(`@covirtua-activities/${this.state.slug}/build/dataFormatter.js`).then(
      (dataFormatterLoad) => {
        const DF = dataFormatterLoad.default;
        this.setState({
          dataFormatter: new DF(this.props.t),
        });
      },
    );

    Promise.all([logoProm, dfProm])
      .then(() => {})
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        this.setState({
          renderable: true,
        });
      });
  }

  componentDidMount() {
    const lastEvaluatedDate = Date.now().toString();
    getApolloClient().then((client) => {
      client
        .query({
          query: GET_ACTIVITIES_BY_SLUG,
          variables: { lastEvaluatedDate, slug: this.state.slug, patientId: getCurrentPatientId() },
        })
        .then((response) => {
          const groupedActivities = this.groupActivitiesByConf(
            _.filter(response.data.getActivitiesBySlug, (act) => act.isCompleted),
          );
          this.setState({
            error: response.error,
            loading: response.loading,
            data: response.data,
            activities: _.filter(response.data.getActivitiesBySlug, (act) => act.isCompleted),
            groupedActivities,
          });
          this.selectSeries(_.filter(response.data.getActivitiesBySlug, (act) => act.isCompleted)[0]);
          this.setState({ fieldsToGroupBy: _.values(_.mapValues(this.getDisplayableConf(), 'field')) });
        });
    });
  }

  getTimes(activities) {
    return _.map(activities, (activity) => {
      const results = JSON.parse(activity.results);
      return activity.results ? results.totalTime : null;
    }).reverse();
  }

  getDisplayableConf() {
    return _.filter(this.state.configuration.attributes, (attribute) => attribute.inSummary);
  }

  getDisplayableResults() {
    return _.filter(this.state.configuration.resultsFields, (result) => result.inSummary);
  }

  getErrors(activities) {
    return _.map(activities, (activity) => {
      const results = JSON.parse(activity.results);
      return activity.results ? results.totalErrors : null;
    }).reverse();
  }

  getDates(activities) {
    return _.map(activities, (activity) => moment(activity.stoppedAt).format('DD/MM/YYYY HH:mm:ss')).reverse();
  }

  getSeries(activities, graphType) {
    const { t } = this.props;
    return _.map(this.getDisplayableResults(), (entry) => ({
      name: tReducedLabel(entry, t),
      type: graphType,
      data: _.map(activities, (activity) => {
        const results = JSON.parse(activity.results);
        return results ? this.state.dataFormatter.formatResultAsSeries(entry.field, results) : '';
      }).reverse(),
    }));
  }

  /* prepareResult(result, type){
    switch(type){
      case "time" :
        return moment.duration(result).format();
      default :
        return  result;
    }
  } */

  isBeingDrawn(activity) {
    return _.includes(this.state.currentSeries, activity);
  }

  filterActivitiesByFields(activities) {
    return _.filter(activities, (activity) =>
      _.isMatch(
        JSON.parse(activity.configuration),
        _.pick(JSON.parse(this.state.selectedActivity.configuration), this.state.fieldsToGroupBy),
      ),
    );
  }

  groupActivitiesByConf(activities) {
    return _.groupBy(activities, 'configuration');
  }

  isSelected(activity) {
    return _.isEqual(activity, this.state.selectedActivity);
  }

  selectSeries(activity) {
    this.setState({ selectedActivity: activity });
    setTimeout(() => {
      this.setState({ currentSeries: this.filterActivitiesByFields(this.state.activities) });
    }, 100);
  }

  groupResultsBy(event, checked) {
    event.persist();
    let fields = this.state.fieldsToGroupBy;
    if (checked) {
      fields.push(event.target.value);
    } else {
      fields = _.pull(fields, event.target.value);
    }
    this.setState({ fieldsToGroupBy: fields });
    this.setState({ currentSeries: this.filterActivitiesByFields(this.state.activities) });
  }

  proxyRender() {
    const { classes, t } = this.props;
    const pStyle = {
      backgroundColor: getColor(activitiesList.categories[this.state.configuration.metadata.type].color),
    };
    if (this.state.loading || !this.state.renderable) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
          <CircularProgress />
        </div>
      );
    }
    if (this.state.error) return `Error! ${this.state.error.message}`;
    if (_.isEmpty(this.state.data.getActivitiesBySlug))
      return (
        <div>
          <NoResults />
        </div>
      );

    return (
      <div className={classes.series}>
        <div>
          <Card className={this.state.tableOnly ? classes.historyCardTableOnly : classes.historyCard}>
            {!this.state.tableOnly && (
              <CardHeader
                avatar={<Avatar className={classes.avatar} style={pStyle} src={this.state.logo} />}
                title={t(this.state.activities[0].name)}
              />
            )}
            <CardBody className={classes.cardBody}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeadCol}>{t('Common_Date')}</TableCell>

                    {this.getDisplayableConf().map((entry) => (
                      <TableCell className={classes.tableHeadCol}>
                        {!this.state.tableOnly && (
                          <Checkbox
                            name={entry.field}
                            onChange={this.groupResultsBy}
                            value={entry.field}
                            defaultChecked
                          />
                        )}
                        <Tooltip disableFocusListener title={tToolTip(entry, t)} classes={{ tooltip: classes.tooltip }}>
                          <div>{tShortLabel(entry, t)}</div>
                        </Tooltip>
                      </TableCell>
                    ))}

                    {this.getDisplayableResults().map((entry) => (
                      <TableCell className={classes.tableHeadCol}>
                        <Tooltip disableFocusListener title={tToolTip(entry, t)} classes={{ tooltip: classes.tooltip }}>
                          <div>{tShortLabel(entry, t)}</div>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody className={classes.container}>
                  {_.values(this.state.groupedActivities).map((activitiesByConf) =>
                    activitiesByConf.map((activity) => (
                      <TableRow
                        key={activity._id}
                        onClick={() => {
                          if (!this.state.tableOnly) this.selectSeries(activity);
                        }}
                        className={
                          this.state.tableOnly
                            ? ''
                            : `${classes.tableRow} ${this.isBeingDrawn(activity) ? classes.activeRow : ''} ${
                                this.isSelected(activity) && !this.state.tableOnly ? classes.selected : ''
                              }`
                        }
                      >
                        <TableCell component="td" scope="row" className={classes.cells}>
                          {moment(activity.stoppedAt).format('DD/MM/YYYY HH:mm')}
                        </TableCell>

                        {this.getDisplayableConf().map((entry) => (
                          <TableCell component="td" scope="row" className={classes.cells}>
                            {prepareValueForDisplay(
                              t,
                              this.state.configuration,
                              entry,
                              JSON.parse(activity.configuration),
                              'short',
                              this.state.dataFormatter,
                            )}
                          </TableCell>
                        ))}

                        {this.getDisplayableResults().map((entry) => (
                          <TableCell component="td" scope="row" className={classes.cells}>
                            {prepareValueForDisplay(
                              t,
                              this.state.configuration,
                              entry,
                              JSON.parse(activity.results),
                              'short',
                              this.state.dataFormatter,
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    )),
                  )}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </div>

        {!this.state.tableOnly && (
          <div className={classes.chart}>
            <ProgressChart
              dates={this.getDates(this.state.currentSeries)}
              series={this.getSeries(this.state.currentSeries, 'line')}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    return <div className="PREVIOUS-RESULTS">{this.proxyRender()}</div>;
  }
}

PreviousResults.defaultProps = {
  tableOnly: false,
};

PreviousResults.propTypes = {
  classes: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  configuration: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  tableOnly: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(PreviousResults));

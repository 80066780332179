import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { checkCurrentUserPermission } from 'utils/permissionChecker';
import dashboardStyle from '../../assets/jss/views/dashboardStyle';
import Civilities from './Civilities';
import Pathologies from './Pathologies';
import PatientCareView from './PatientCareView';
import Comment from './Comment';
import Account from './Account';
import Contact from './Contact';
import MotorSkills from './MotorSkills';
import { GET_PATIENT_CIVILITIES } from '../../graphql/queries';

class PatientProfile extends React.Component {
  constructor(props) {
    super(props);

    const { history } = props;

    // need a regexp on the hash as the auth request to auth server
    // may append the state and code making the tabId not so simple to get
    const tabId = history.location.hash.match(/#tabId=(\d)/i);
    this.tabId = tabId ? tabId[1] : 0;
    this.state = {
      value: tabId ? parseInt(tabId[1], 10) : 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, match, t } = this.props;
    const { id } = match.params;

    return (
      <Query query={GET_PATIENT_CIVILITIES} variables={{ id }} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            );
          if (error) return `Error! ${error.message}`;

          return data.getPatient.isAnonymous ? (
            <div>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                variant="fullWidth"
                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
              >
                <Tab label={t('VPatient_ConditionComment')} />
                {checkCurrentUserPermission('feature_betweenSessionsAccount') && <Tab label={t('VPatient_BetweenSessionsAccount')} classes={{ root: classes.tabRoot }} />}
              </Tabs>

              {this.state.value === 0 && <Comment id={id} />}
              {this.state.value === 1 && <Account id={id} />}
            </div>
          ) : (
            <div>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                variant="fullWidth"
                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                // indicatorColor="primary"
                // textColor="primary"
              >
                <Tab label={t('VPatient_Identity')} classes={{ root: classes.tabRoot }} />
                <Tab label={t('VPatient_Condition')} classes={{ root: classes.tabRoot }} />
                <Tab label={t('VPatient_Motricity')} classes={{ root: classes.tabRoot }} />
                <Tab label={t('VPatient_AdditionalContact')} classes={{ root: classes.tabRoot }} />
                <Tab label={t('VPatient_ConditionCare')} classes={{ root: classes.tabRoot }} />
                <Tab label={t('VPatient_ConditionComment')} classes={{ root: classes.tabRoot }} />
                {checkCurrentUserPermission('feature_betweenSessionsAccount') && <Tab label={t('VPatient_BetweenSessionsAccount')} classes={{ root: classes.tabRoot }} />}
              </Tabs>

              {this.state.value === 0 && <Civilities id={id} />}
              {this.state.value === 1 && <Pathologies id={id} />}
              {this.state.value === 2 && <MotorSkills id={id} />}
              {this.state.value === 3 && <Contact id={id} />}
              {this.state.value === 4 && <PatientCareView id={id} />}
              {this.state.value === 5 && <Comment id={id} />}
              {this.state.value === 6 && <Account id={id} />}
            </div>
          );
        }}
      </Query>
    );
  }
}

PatientProfile.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(PatientProfile));

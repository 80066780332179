/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/jsx-no-bind */

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { mdiVideo, mdiCog } from '@mdi/js';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { logger } from '../../../logger';

const styles = () => ({
  root: {},
  tools: {
    size: 1,
    marginLeft: '12px',
  },
});

class Tools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: props.slug,
      config: props.config,
      configModel: props.configModel,
      socket: props.socket,
      isPatientConnected: props.isPatientConnected,
      renderable: false,
      mainCommands: () => {},
    };
    this.dialogs = [];

    setTimeout(() => {
      this.initToolsValues();
    }, 0);
    // Custom tools were initially intented to be used on lesCourses
    // but a simpler approach has been taken, with an "runtimetool" field
    // directly in config file. Much less volatile though
    // (only allow to change a field value like a parameter, but in the "tool" screen area)
    // So this approach might be usefull if needed later
    /*
        const customToolsProm = import(`@covirtua-activities/${this.state.slug}/build/CustomTools.js`)
          .then((customTools) => {
            this.setState({
              // eslint-disable-next-line react/no-unused-state
              customActivityTools: customTools.default,
            });
          })
          .finally(() => {
            this.setState({
              customActivityToolsRenderable: true,
            });
          });
    */
    const maincomProm = import(`@covirtua-activities/${this.state.slug}/build/common/mainCommands.js`).then(
      (mainCom) => {
        this.setState({
          mainCommands: mainCom.default,
        });
      },
    );

    Promise.all([/* customToolsProm, */ maincomProm])
      .then(() => {})
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        this.setState({
          // eslint-disable-next-line new-cap
          commands: new this.state.mainCommands(this.state.socket),
          renderable: true,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPatientConnected !== this.props.isPatientConnected) {
      this.setState({
        isPatientConnected: this.props.isPatientConnected,
      });
    }
  }

  onChangeValue = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.closeDialog(event.target.name);
    const commandName = this.state[`${event.target.name}_command`];
    this.state.commands.sendCommand({
      name: commandName,
      value: event.target.value,
    });
  };

  showRadioPopup = (event, toolName) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ [`${toolName}_dialogOpen`]: true });
  };

  handleDialogClose(event) {
    event.stopPropagation();
    this.ToolsComponent.closeDialog(this.name);
  }

  closeDialog(dialogName) {
    this.setState({ [`${dialogName}_dialogOpen`]: false });
  }

  initToolsValues() {
    if (this.state.configModel.runtimeTools) {
      this.state.configModel.runtimeTools.forEach((tool) => {
        this.setState({ [tool.name]: this.state.config[`${tool.name}`] });
        this.setState({ [`${tool.name}_command`]: tool.command });
        this.setState({ [`${tool.name}_dialogOpen`]: false });
      });
    }
  }

  render() {
    const { classes, t } = this.props;

    if (!this.state.renderable) {
      return <div>Loading...</div>;
    }

    return (
      <span>
        {/* this.state.customActivityToolsRenderable && this.state.customActivityTools !== undefined && (
          <this.state.customActivityTools // Custom tools for the activities that really need something specific
            socket={this.state.socket}
            commands={this.state.commands}
            config={this.state.config}
          />
        ) */}
        {this.state.configModel.runtimeTools &&
          this.state.configModel.runtimeTools.map(
            (tool) =>
              // runtime Tool in the shape of radiobutton dialog
              tool.inputType === 'radioPopup' && (
                <span key={tool}>
                  <Button
                    onClick={(evt) => {
                      this.showRadioPopup(evt, tool.name);
                    }}
                    variant="contained"
                    aria-haspopup="true"
                    color="primary"
                    className={classNames(classes.tools, classes.button)}
                    disabled={!this.state.isPatientConnected}
                  >
                    {tool.icon === 'mdiVideo' && <Icon path={mdiVideo} />}
                    {tool.icon === 'mdiCog' && <Icon path={mdiCog} />}
                  </Button>
                  <Dialog
                    name={tool.name}
                    open={this.state[`${tool.name}_dialogOpen`]}
                    onClose={this.handleDialogClose}
                    ToolsComponent={this}
                  >
                    <DialogTitle id="form-dialog-title">{t(tool.label)}</DialogTitle>
                    <DialogContent>
                      <div onChange={this.onChangeValue} command={tool.command}>
                        {tool.options.map((option) => (
                          <div>
                            <input
                              type="radio"
                              name={tool.name}
                              value={option.value}
                              checked={option.value === this.state[tool.name]}
                            />
                            {t(option.label)}
                          </div>
                        ))}
                      </div>
                    </DialogContent>
                  </Dialog>
                </span>
              ),
          )}
      </span>
    );
  }
}

Tools.propTypes = {
  socket: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  configModel: PropTypes.object.isRequired,
  isPatientConnected: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(Tools));

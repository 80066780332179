/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import Card from 'components/Card/Card';
import dashboardStyle from 'assets/jss/views/dashboardStyle.jsx';
import CardBody from 'components/Card/CardBody';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import GridItem from 'components/Grid/GridItem.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { getColor } from 'styles/variables';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { checkCurrentUserPermission } from 'utils/permissionChecker';
import { tReducedLabel, tToolTip } from '../../../i18n/utils';
import { prepareValueForDisplay } from './Formatter';
import { logger } from '../../../logger';
import EventReviewer from './EventReviewer';
import activities from '../../list-activities.json';

const styles = (theme) => ({
  ...dashboardStyle,
  avatar: {
    borderRadius: '0%',
  },

  details: {
    flexGrow: 1,
    margin: 10,
    height: '100%',
  },
  notes: {
    minHeight: 100,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
});

class Details extends Component {
  constructor(props) {
    super(props);
    momentDurationFormat(moment);

    this.state = {
      activity: props.activity,
      configuration: props.configuration,
      renderable: false,
    };

    const logoProm = import(`@covirtua-activities/${this.state.activity.slug}/build/logo.png`).then((logoLoad) => {
      this.setState({
        logo: logoLoad.default,
      });
    });

    const dfProm = import(`@covirtua-activities/${props.activity.slug}/build/dataFormatter.js`).then(
      (dataFormatterLoad) => {
        const DF = dataFormatterLoad.default;
        this.setState({
          dataFormatter: new DF(this.props.t),
        });
      },
    );

    Promise.all([logoProm, dfProm])
      .then(() => {})
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        this.setState({
          sections: this.getSections(this.state.configuration.attributes),
          renderable: true,
        });
      });
  }

  getSections(config) {
    const ConfigValues = JSON.parse(this.state.activity.configuration); // used to check the value for each parameter
    // eslint-disable-next-line
    const filteredConfig = config.map((entry) => {
      // Checking all parameter
      if (
        !entry.conditions ||
        _.every(
          _.map(entry.conditions, (
            condition, // checking all conditions for a given parameter
          ) =>
            condition[2] === 'not'
              ? ConfigValues[condition[0]] !== condition[1]
              : ConfigValues[condition[0]] === condition[1],
          ),
        )
      )
        return entry; // We add the parameter in the display list if all conditions are satisfied
    });

    const allSections = _.groupBy(_.compact(filteredConfig), 'section');

    return allSections;
  }

  render() {
    const { classes, t } = this.props;
    const activitiesList = activities;
    const pStyle = {
      backgroundColor: getColor(activitiesList.categories[this.state.configuration.metadata.type].color),
    };

    if (!this.state.renderable) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className={classes.details}>
        <Card>
          <CardHeader
            avatar={<Avatar className={classes.avatar} style={pStyle} src={this.state.logo} />}
            title={t(this.state.activity.name)}
            subheader={moment(this.state.activity.createdAt).format('LLL')}
          />
          <CardBody>
            <Grid key={this.state.activity._id} container>
              <GridItem xs={12}>
                <Card>
                  <CardHeader title={t('Common_Comments')} />
                  <CardBody className={classes.notes}>
                    {this.state.activity.notes &&
                      <Typography variant="body2" gutterBottom>
                        <pre style={{ fontFamily: 'inherit' }}>
                          {this.state.activity.notes.replace("\r", "<br/>")}
                        </pre>
                      </Typography>
                    }
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={5}>
                <Card>
                  <CardHeader title={t('Common_ResultView_Header_Hypotheses')} />
                  <CardBody>
                    {Object.keys(this.state.sections).map((section) => (
                      <div>
                        <Typography variant="h6">{t(section)}</Typography>
                        <List>
                          {this.state.sections[section].map(
                            (entry) =>
                              tReducedLabel(entry, t) !== '' && (
                                <ListItem key={entry.field} dense>
                                  <GridItem xs={5}>
                                    <Tooltip
                                      disableFocusListener
                                      title={tToolTip(entry, t)}
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <ListItemText primary={tReducedLabel(entry, t)} />
                                    </Tooltip>
                                  </GridItem>
                                  <GridItem xs={7}>
                                    <ListItemText
                                      secondary={prepareValueForDisplay(
                                        t,
                                        this.state.configuration,
                                        entry,
                                        JSON.parse(this.state.activity.configuration),
                                        'reduced',
                                      )}
                                    />
                                  </GridItem>
                                </ListItem>
                              ),
                          )}
                        </List>
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={7}>
                <Card>
                  <CardHeader title={t('Common_ResultView_Header_Results')} />
                  <CardBody>
                    <List>
                      {this.state.configuration.resultsFields.map((entry) => (
                        <ListItem key={entry.field} dense>
                          <GridItem xs={5}>
                            <Tooltip
                              disableFocusListener
                              title={tToolTip(entry, t)}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <ListItemText primary={tReducedLabel(entry, t)} />
                            </Tooltip>
                          </GridItem>
                          :
                          <GridItem xs={7}>
                            <ListItemText
                              secondary={prepareValueForDisplay(
                                t,
                                this.state.configuration,
                                entry,
                                JSON.parse(this.state.activity.results),
                                'reduced',
                                this.state.dataFormatter,
                              )}
                            />
                          </GridItem>
                        </ListItem>
                      ))}
                    </List>
                  </CardBody>
                </Card>
              </GridItem>

              {false && checkCurrentUserPermission('feature_reviewer') && (
                <GridItem xs={12}>
                  <Card>
                    <CardHeader title={t('Common_ResultView_Header_Execution')} />
                    <CardBody>
                      <EventReviewer activity={this.state.activity} />
                    </CardBody>
                  </Card>
                </GridItem>
              )}
            </Grid>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Details.propTypes = {
  activity: PropTypes.object.isRequired,
  configuration: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(Details));

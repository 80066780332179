/* eslint-disable react/jsx-no-bind */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Mutation } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withTranslation } from 'react-i18next';
import { cvVioletColor } from '../../styles/variables';
import { GET_PATIENT_MOTORSKILLS, UPSERT_PATIENT_MOTORSKILLS } from '../../graphql/queries';
import { logger } from '../../logger';

const styles = (theme) => ({
  root: {
    color: cvVioletColor,
    '&$checked': {
      color: cvVioletColor,
    },
  },
  checked: {},
  submitButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

const omit = ['saved'];

class MotorSkillsForm extends React.Component {
  constructor(props) {
    super(props);

    if (props.motorSkills) {
      this.state = JSON.parse(props.motorSkills);
    } else {
      this.state = {
        rightInvalidityReason: '',
        leftInvalidityReason: '',
        right: false,
        left: false,
        wheelchair: false,
      };
    }

    this.state.saved = true;
  }

  handleChange = (event) => {
    const boxes = ['right', 'left', 'wheelchair'];
    this.setState({ saved: false });
    if (boxes.includes(event.target.name)) {
      this.setState({ [event.target.name]: event.target.checked });

      if (!event.target.checked) {
        this.setState({ [`${event.target.name}InvalidityReason`]: '' });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleSave = () => {
    this.setState({ saved: true });
  };

  query = (id) => [{ query: GET_PATIENT_MOTORSKILLS, variables: { id } }];

  logErrors = (errors) => logger.error(errors);

  render() {
    const { classes, id, t } = this.props;

    return (
      <div>
        <div>
          <Mutation mutation={UPSERT_PATIENT_MOTORSKILLS} refetchQueries={this.query}>
            {(upsertPatientMotorSkills) => (
              <div>
                <ValidatorForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    const _id = id || null;
                    const state = _.omit(this.state, omit);
                    const motorSkills = JSON.stringify(state);
                    upsertPatientMotorSkills({ variables: { id: _id, motorSkills } }).then(() => {
                      this.handleSave();
                    });
                  }}
                  onError={this.logErrors}
                  className={classes.form}
                >
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.right}
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                            onChange={this.handleChange}
                            value="right"
                            name="right"
                          />
                        }
                        label={t('VPatient_MotricityRight')}
                      />
                      {this.state.right === true && (
                        <Grid container spacing={8}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              multiline
                              id="rightInvalidityReason"
                              label={t('VPatient_MotricityCause')}
                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              onChange={this.handleChange}
                              name="rightInvalidityReason"
                              value={this.state.rightInvalidityReason}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.left}
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                            onChange={this.handleChange}
                            value="left"
                            name="left"
                          />
                        }
                        label={t('VPatient_MotricityLeft')}
                      />
                      {this.state.left === true && (
                        <Grid container spacing={8}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              multiline
                              id="leftInvalidityReason"
                              label={t('VPatient_MotricityCause')}
                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              onChange={this.handleChange}
                              name="leftInvalidityReason"
                              value={this.state.leftInvalidityReason}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.wheelchair}
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                            onChange={this.handleChange}
                            value="wheelchair"
                            name="wheelchair"
                          />
                        }
                        label={t('VPatient_MotricityWheelchair')}
                      />
                    </FormGroup>
                    <FormHelperText>{t('VPatient_MotricityWarning')}</FormHelperText>
                  </FormControl>
                  <Button
                    type="submit"
                    disabled={this.state.saved}
                    variant="outlined"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {t('Common_Save')}
                  </Button>
                </ValidatorForm>
              </div>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

MotorSkillsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  motorSkills: PropTypes.object,
  t: PropTypes.func.isRequired,
};

MotorSkillsForm.defaultProps = {
  motorSkills: null,
};

export default withTranslation()(withStyles(styles)(MotorSkillsForm));

import * as Sentry from '@sentry/react';
import { getApolloClient } from 'utils/apolloClientFactory';
import { CREATE_LOG } from 'graphql/queries';
import { checkCurrentUserPermission } from 'utils/permissionChecker';

export const logger = {
  debug: console.debug,
  warn: console.warn,
  info: console.log,
  store(JsonObject) {
    if (checkCurrentUserPermission("feature_storeLogs")) {
      getApolloClient().then((client) => {
        client
          .mutate({
            mutation: CREATE_LOG,
            variables: { logMessage: JSON.stringify(JsonObject) },
          })
      });
    }
  },
  error(err) {
    console.error(err);
    Sentry.captureException(err);
  },
};

window.logger = logger;

import React from 'react';
import { Redirect, withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Timer from '@material-ui/icons/Timer';
import Tv from '@material-ui/icons/Tv';
import QueuePlayNext from '@material-ui/icons/QueuePlayNext';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import PreviousResults from './components/PreviousResults';
import ConfigView from './components/Config';
import ActivityHeader from '../../components/ActivityHeader/ActivityHeader';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import dashboardStyle from '../../assets/jss/views/dashboardStyle';
import { logger } from '../../logger';
import activities from '../list-activities.json';

const styles = (theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  configCard: {},
  PreviousResults: {
    height: '230px',
  },
  cardOuter: {
    marginTop: '10px',
    marginBottom: '10px',
  },
});

class ActivityWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.path = this.props.match.path;
    this.slug = this.props.match.params.slug;
    this.state = {
      descriptionExpanded: false,
      previousResultsExpanded: false,
      activityStarted: false,
      renderable: false,
    };
  }

  componentDidMount() {
    const logoProm = import(`@covirtua-activities/${this.slug}/build/logo.png`).then((logoLoad) => {
      this.setState({
        logo: logoLoad.default,
      });
    });

    const confProm = import(`@covirtua-activities/${this.slug}/build/config.js`).then((configLoad) => {
      const configTmp = Object.assign({}, configLoad.default);
      if (this.props.location.state && this.props.location.state.previousConfig) {
        configTmp.defaultConf = this.props.location.state.previousConfig;
      }

      this.setState({
        configuration: configTmp,
      });
    });

    const descProm = import(`@covirtua-activities/${this.slug}/build/Description.js`).then((description) => {
      this.setState({
        description: description.default,
      });
    });

    Promise.all([logoProm, confProm, descProm])
      .then(() => {})
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        this.setState({
          renderable: true,
        });
      });
  }

  handleStartActivity = (configSlug, configFull) => {
    this.setState({
      activityStarted: true,
      configSlug,
      config: configFull,
    });
  };

  handleExpandClick = () => {
    this.setState({
      descriptionExpanded: !this.state.descriptionExpanded,
    });
  };

  handlePreviousResulstsExpandClick = () => {
    this.setState({
      previousResultsExpanded: !this.state.previousResultsExpanded,
    });
  };

  render() {
    const { classes, t } = this.props;
    const activitiesList = activities;
    let core;

    if (!this.state.renderable) {
      return <div>Loading...</div>;
    }

    if (!this.state.activityStarted) {
      core = (
        <Card className={classNames(classes.cardOuter, classes.configCard)}>
          <CardBody>
            <ConfigView
              handleStartActivity={this.handleStartActivity}
              slug={this.slug}
              configModel={this.state.configuration}
            />
          </CardBody>
        </Card>
      );
    } else {
      core = (
        <Redirect
          to={{
            pathname: `/activities/${this.state.configuration.metadata.categorySlug}/${this.slug}/live`,
            state: {
              activityStarted: this.state.activityStarted,
              configSlug: this.state.configSlug,
              config: this.state.config,
            },
          }}
        />
      );
    }
    const logoColor = activitiesList.categories[this.state.configuration.metadata.type].color;
    return (
      <div>
        {this.state.activityStarted && (
          <ActivityHeader
            color={logoColor}
            logo={this.state.logo}
            header={t(this.state.configuration.metadata.name)}
            subheader={t(this.state.configuration.metadata.category)}
          />
        )}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card className={classes.cardOuter}>
              <CardActions disableActionSpacing onClick={this.handleExpandClick}>
                <ActivityHeader
                  color={logoColor}
                  logo={this.state.logo}
                  header={t(this.state.configuration.metadata.name)}
                  subheader={t(this.state.configuration.metadata.category)}
                />

                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: this.state.descriptionExpanded,
                  })}
                  aria-expanded={this.state.descriptionExpanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>

              <Collapse in={this.state.descriptionExpanded} timeout="auto" unmountOnExit>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.cvGreenColorAvatar}>
                              <Tv />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={t('PExerciseDetail_IconScreen')} />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.cvGreenColorAvatar}>
                              <QueuePlayNext />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={t('PExerciseDetail_IconSupervision')} />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.cvGreenColorAvatar}>
                              <Timer />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={`10 ${t('Common_UnitMinute')}`} />
                        </ListItem>
                      </List>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Route component={this.state.description} />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Collapse>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card className={classes.cardOuter}>
              <CardActions disableActionSpacing onClick={this.handlePreviousResulstsExpandClick}>
                <Typography variant="h6" gutterBottom>
                  {t('PExerciseDetail_History')}
                </Typography>

                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: this.state.previousResultsExpanded,
                  })}
                  aria-expanded={this.state.previousResultsExpanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>

              <Collapse in={this.state.previousResultsExpanded} timeout="auto" unmountOnExit>
                <CardBody className={classes.PreviousResults}>
                  <PreviousResults slug={this.slug} configuration={this.state.configuration} tableOnly />
                </CardBody>
              </Collapse>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            {core}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ActivityWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(withStyles(styles)(withRouter(ActivityWrapper))));

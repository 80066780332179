import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { getColor } from 'styles/variables';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
  },
  header: {
    margin: 0,
  },
  subheader: {
    fontWeight: 300,
    color: 'grey',
  },
  logoWrapper: {
    marginRight: 2 * theme.spacing.unit,
    paddingTop: '2px',
  },
  logo: {
    width: 50,
  },
});

function ActivityHeader({ ...props }) {
  const { classes, logo, color, header, subheader } = props;
  const pStyle = {
    backgroundColor: getColor(color),
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}>
        <img alt="" src={logo} className={classes.logo} style={pStyle} />
      </div>
      <div>
        <Typography component="h2" variant="h5" className={classes.header}>
          {header}
        </Typography>
        <Typography variant="subtitle1" className={classes.subheader}>
          {subheader}
        </Typography>
      </div>
    </div>
  );
}

ActivityHeader.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.object.isRequired,
  header: PropTypes.element.isRequired,
  logo: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  subheader: PropTypes.element.isRequired,
};

ActivityHeader.defaultProps = {};

export default withStyles(styles)(ActivityHeader);

// eslint-disable-next-line import/no-unresolved
import gql from 'graphql-tag';

export const GET_ACTIVITIES = gql`
  query Activities($lastEvaluatedDate: String!, $patientId: String!) {
    getAllActivities(lastEvaluatedDate: $lastEvaluatedDate, patientId: $patientId) {
      _id
      createdAt
      startedAt
      stoppedAt
      category
      categorySlug
      type
      typeSlug
      name
      slug
      channel
      description
      configuration
      results
      notes
      isCompleted
      isInterrupted
      isStopped
      programmed
      users {
        doctorId
        patientId
        doctor {
          _id
          firstName
          lastName
          email
          license {
            limitedTo
            flags
            _id
            expirationDate
          }
        }
      }
    }
  }
`;

export const GET_ACVITY_EVENTS = gql`
  query Events($channel: String!) {
    getAllEvents(channel: $channel)
  }
`;

export const GET_ACTIVITIES_BY_SLUG = gql`
  query Activities($lastEvaluatedDate: String!, $slug: String!, $patientId: String!) {
    getActivitiesBySlug(lastEvaluatedDate: $lastEvaluatedDate, slug: $slug, patientId: $patientId) {
      _id
      createdAt
      startedAt
      stoppedAt
      category
      categorySlug
      type
      typeSlug
      name
      slug
      description
      configuration
      results
      isCompleted
      isInterrupted
      isStopped
      users {
        doctorId
        patientId
        doctor {
          _id
          firstName
          lastName
          email
          license {
            limitedTo
            flags
            _id
            expirationDate
          }
        }
      }
    }
  }
`;

export const GET_PROGRAMMED_ACTIVITIES = gql`
  query Activities($lastEvaluatedDate: String!, $patientId: String!) {
    getAllActivities(lastEvaluatedDate: $lastEvaluatedDate, patientId: $patientId, programmed: true) {
      _id
      createdAt
      startedAt
      stoppedAt
      programmed
      category
      categorySlug
      type
      typeSlug
      name
      slug
      channel
      description
      configuration
      results
      notes
      permanent
      order
    }
  }
`;

export const GET_PATIENTS = gql`
  query Patients {
    getAllPatients {
      _id
      firstname
      lastname
      isAnonymous
      permacode
      sex
      pathologies {
        content
        form
        slug
      }
      account
    }
  }
`;

export const GET_PATIENT_BY_ID = gql`
  query Patient($id: String!) {
    getPatient(id: $id) {
      _id
      firstname
      lastname
      permacode
      sex
    }
  }
`;

export const GET_PATIENT_CIVILITIES = gql`
  query Patient($id: String!) {
    getPatient(id: $id) {
      _id
      firstname
      lastname
      birthdate
      sex
      yearsofstudy
      laterality
      phone
      mobile
      email
      address
      isAnonymous
    }
  }
`;

export const GET_PATIENT_PATHOLOGIES = gql`
  query Patient($id: String!) {
    getPatient(id: $id) {
      _id
      pathologies {
        content
        form
        slug
      }
    }
  }
`;

export const GET_PATIENT_MOTORSKILLS = gql`
  query Patient($id: String!) {
    getPatient(id: $id) {
      _id
      motorSkills
    }
  }
`;

export const GET_PATIENT_CONTACTS = gql`
  query Patient($id: String!) {
    getPatient(id: $id) {
      _id
      contacts {
        firstname
        lastname
        relation
        civility
        email
        phone
        mobile
        address
      }
    }
  }
`;

export const GET_PATIENT_COMMENT = gql`
  query Patient($id: String!) {
    getPatient(id: $id) {
      _id
      comment
    }
  }
`;

export const GET_PATIENT_ACCOUNT = gql`
  query Patient($id: String!) {
    getPatient(id: $id) {
      _id
      account
    }
  }
`;

export const GET_PATIENT_BY_CODE = gql`
  query Patient($code: String!) {
    getPatientByCode(code: $code) {
      _id
    }
  }
`;

export const GET_PATIENT_BY_PERMACODE = gql`
  query Patient($permacode: String!) {
    getPatientByPermacode(permacode: $permacode) {
      _id
    }
  }
`;

export const UPSERT_PATIENT_MOTORSKILLS = gql`
  mutation UpsertPatientMotorSkills($id: String!, $motorSkills: String!) {
    upsertPatientMotorSkills(id: $id, motorSkills: $motorSkills) {
      _id
      motorSkills
    }
  }
`;

export const UPSERT_PATIENT_COMMENT = gql`
  mutation UpsertPatientComment($id: String!, $comment: String!) {
    upsertPatientComment(id: $id, comment: $comment) {
      _id
      comment
    }
  }
`;

export const UPSERT_PATIENT_ACCOUNT = gql`
  mutation UpsertPatientAccount($id: String!, $account: String!) {
    upsertPatientAccount(id: $id, account: $account) {
      _id
      account
    }
  }
`;

export const UPSERT_PATIENT_CODE = gql`
  mutation UpsertPatientCode($id: String!, $code: String!) {
    upsertPatientCode(id: $id, code: $code) {
      _id
      code
    }
  }
`;

export const UPSERT_PATIENT = gql`
  mutation UpsertPatient($id: String, $civilities: PatientCivilitiesInput!, $condition: PatientPathologyInput) {
    upsertPatient(id: $id, civilities: $civilities, pathology: $condition) {
      _id
      firstname
      lastname
      address
      isAnonymous
      permacode
      code
      pathologies {
        content
        form
        slug
      }
    }
  }
`;

export const UPSERT_PATIENT_NO_CONDITION = gql`
  mutation UpsertPatient($id: String, $civilities: PatientCivilitiesInput!) {
    upsertPatient(id: $id, civilities: $civilities) {
      _id
      firstname
      lastname
      address
      isAnonymous
      permacode
      code
      pathologies {
        content
        form
        slug
      }
    }
  }
`;

export const GET_USER = gql`
  query User {
    getUser {
      _id
      firstName
      lastName
      email
      role
      patientId
      license {
        limitedTo
        flags
        _id
        expirationDate
      }
    }
  }
`;

export const ADD_PATIENT_PATHOLOGY = gql`
  mutation AddPatientPathology($id: String, $condition: String!) {
    addPatientPathology(id: $id, pathology: $condition) {
      _id
      pathologies {
        content
        form
        slug
      }
    }
  }
`;

export const ADD_PATIENT_CONTACT = gql`
  mutation AddPatientContact($id: String, $contact: String!) {
    addPatientContact(id: $id, contact: $contact) {
      _id
      contacts
    }
  }
`;

export const UPSERT_PATIENT_CONTACTS = gql`
  mutation UpsertPatientContacts($id: String, $contact: PatientContactInput!) {
    upsertPatientContacts(id: $id, contact: $contact) {
      _id
      contacts {
        firstname
        lastname
        relation
        civility
        email
        phone
        mobile
        address
      }
    }
  }
`;

export const UPSERT_PATIENT_CARE = gql`
  mutation UpsertPatientCare($id: String, $patientCare: PatientCareInput!) {
    upsertPatientCare(id: $id, patientCare: $patientCare) {
      _id
      patientCare {
        title
        start
        end
        ongoing
        description
      }
    }
  }
`;

export const UPSERT_PATIENT_PATHOLOGY = gql`
  mutation UpsertPatientPathology($id: String, $condition: PatientPathologyInput!) {
    upsertPatientPathology(id: $id, pathology: $condition) {
      _id
      pathologies {
        content
        form
        slug
      }
    }
  }
`;

export const GET_PATIENT_CARE = gql`
  query Patient($id: String!) {
    getPatient(id: $id) {
      _id
      patientCare {
        title
        start
        end
        ongoing
        description
      }
    }
  }
`;

export const DELETE_PATIENT_CARE = gql`
  mutation deletePatientCare($id: String!, $title: String!) {
    deletePatientCare(id: $id, title: $title) {
      _id
      patientCare {
        title
        start
        end
        ongoing
        description
      }
    }
  }
`;

export const DELETE_PATIENT_PATHOLOGY = gql`
  mutation deletePatientPathology($id: String!, $slug: String!) {
    deletePatientPathology(id: $id, slug: $slug) {
      _id
      pathologies {
        content
        form
        slug
      }
    }
  }
`;

export const DELETE_PATIENT_CONTACT = gql`
  mutation deletePatientContact($id: String!, $firstname: String!, $lastname: String!) {
    deletePatientContact(id: $id, firstname: $firstname, lastname: $lastname) {
      _id
      contacts {
        firstname
        lastname
      }
    }
  }
`;

export const ADD_PATIENT_CARE = gql`
  mutation AddPatientCare($id: String, $patientCare: String!) {
    addPatientCare(id: $id, patientCare: $patientCare) {
      _id
      patientCare
    }
  }
`;

export const GET_CONFIGURATIONS = gql`
  query findByActivitySlug($activitySlug: String!) {
    findByActivitySlug(activitySlug: $activitySlug) {
      _id
      name
      slug
      description
      config
      isBuiltIn
      doctor {
        _id
        firstName
        lastName
        email
        keycloakID
      }
    }
  }
`;

export const GET_CONFIGURATIONS_BY_DOCTOR = gql`
  query getConfigurationByDoctor($doctor: Doctor!) {
    getConfigurationByDoctor(doctor: $doctor) {
      _id
      name
      slug
      description
      config
      isBuiltIn
      doctor {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

export const SAVE_CONFIGURATION = gql`
  mutation saveConfiguration(
    $id: String!
    $name: String!
    $activity: String!
    $config: String!
    $description: String
    $doctor: doctorInput!
  ) {
    saveConfiguration(
      id: $id
      name: $name
      activity: $activity
      config: $config
      description: $description
      doctor: $doctor
    ) {
      _id
      name
      slug
      description
      doctor {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_CONFIGURATION_BY_SLUG = gql`
  query getConfigurationBySlug($slug: String!) {
    getConfigurationBySlug(slug: $slug) {
      name
      slug
      version
      createdAt
      type
      typeSlug
      category
      categorySlug
      activitySlug
      activityName
      name
      slug
      config
      description
      doctor {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_ACTIVITY_BY_CHANNEL = gql`
  query findByActivityChannel($channel: String!) {
    findByActivityChannel(channel: $channel) {
      channel
      notes
    }
  }
`;

export const GET_FULL_ACTIVITY_BY_CHANNEL = gql`
  query findByActivityChannel($channel: String!) {
    findByActivityChannel(channel: $channel) {
      _id
      createdAt
      startedAt
      stoppedAt
      category
      categorySlug
      type
      typeSlug
      name
      slug
      description
      configuration
      results
      channel
      notes
    }
  }
`;

export const UPSERT_ACTIVITY_NOTES = gql`
  mutation upsertActivityNotes($channel: String!, $notes: String) {
    upsertActivityNotes(channel: $channel, notes: $notes) {
      _id
      notes
    }
  }
`;

export const UPSERT_ACTIVITY = gql`
  mutation upsertActivity($channel: String!, $config: String, $permanent:Boolean) {
    upsertActivity(channel: $channel, config: $config, permanent:$permanent) {
      _id
      configuration
    }
  }
`;

export const UPSERT_ACTIVITY_ORDER = gql`
  mutation upsertActivityOrder($channel: String!, $order: Float) {
    upsertActivityOrder(channel: $channel, order:$order) {
      _id      
    }
  }
`;

export const DELETE_PROGRAMMED_ACTIVITY = gql`
  mutation deleteProgrammedActivityMutation($activityId: String!) {
    deleteProgrammedActivity(activityId: $activityId) {
      _id
    }
  }
`;

export const CREATE_LOG = gql`
  mutation CreateLog($logMessage: String!) {
    createLog(logMessage: $logMessage) {
      logMessage
    }
  }
`;
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import SimpleBasicDialog from 'components/Dialog/SimpleBasicDialog';
import ContactForm from 'components/Contact/ContactForm';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Contact from 'components/Contact/Contact';
import withStyles from '@material-ui/core/styles/withStyles';
import { Query, Mutation } from 'react-apollo';
import { GET_PATIENT_CONTACTS, UPSERT_PATIENT_CONTACTS, DELETE_PATIENT_CONTACT } from 'graphql/queries';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    minWidth: 100,
    maxWidth: 200,
  },
  birthday: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  studies: {
    width: 180,
  },
  formLabel: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  },
  delete: {
    justifyContent: 'flex-end',
  },
});

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      index: null,
    };
  }

  handleAddContact = () => {
    this.setState({
      open: true,
      index: null,
    });
  };

  handleUpdateContact = (e) => {
    this.setState({
      open: true,
      index: e.currentTarget.dataset.index,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, id, t } = this.props;

    return (
      <div>
        <Button variant="outlined" color="primary" onClick={this.handleAddContact}>
          {t('VPatient_AddAdditionalContact')}
        </Button>

        <Query query={GET_PATIENT_CONTACTS} variables={{ id }} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return <CircularProgress />;
            if (error) return `Error! ${error.message}`;

            return (
              <div>
                <SimpleBasicDialog
                  title={t('VPatient_IdentityContact')}
                  content={
                    <ContactForm
                      id={id}
                      mutation={UPSERT_PATIENT_CONTACTS}
                      onClose={this.handleClose}
                      contacts={data.getPatient.contacts}
                      index={this.state.index}
                    />
                  }
                  onClose={this.handleClose}
                  open={this.state.open}
                />
                <GridContainer spacing={40}>
                  {data.getPatient.contacts.map((c, index) => (
                    <GridItem key={id} sm={6} md={4} lg={4}>
                      <Card className={classes.card}>
                        <CardActionArea onClick={this.handleUpdateContact} data-index={index}>
                          <CardHeader
                            title={<Typography noWrap>{c.firstname}</Typography>}
                            subheader={c.lastname}
                            data-_id={id}
                          />
                          <CardBody>
                            <Contact contact={c} />
                          </CardBody>
                        </CardActionArea>
                        <CardActions className={classes.delete}>
                          <Mutation mutation={DELETE_PATIENT_CONTACT}>
                            {(deletePatientContact) => (
                              <div>
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => {
                                    deletePatientContact({
                                      variables: { id, firstname: c.firstname, lastname: c.lastname },
                                    });
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </div>
                            )}
                          </Mutation>
                        </CardActions>
                      </Card>
                    </GridItem>
                  ))}
                </GridContainer>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Contacts.propTypes = {
  id: PropTypes.any.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(Contacts));

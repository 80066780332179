/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withTranslation } from 'react-i18next';
import { logger } from '../../../logger';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { tReducedLabel } from '../../../i18n/utils';
import { prepareValueForDisplay } from './Formatter';

const styles = () => ({});

class Hypothesis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      slug: props.slug,
      config: props.config,
      renderable: false,
      configuration: {},
    };
  }

  componentDidMount() {
    const confProm = import(`@covirtua-activities/${this.state.slug}/build/config.js`).then((configLoad) => {
      this.setState({
        configuration: configLoad.default,
      });
    });

    Promise.all([confProm])
      .then(() => {
        this.setState({
          sections: this.getSections(this.state.configuration.attributes),
          renderable: true,
        });
      })
      .catch((error) => {
        logger.error(error);
      });
  }

  getSections(config) {
    // eslint-disable-next-line
    const filteredConfig = config.map((entry) => {
      // Checking all parameter
      if (
        !entry.conditions ||
        _.every(
          _.map(entry.conditions, (
            condition, // checking all conditions for a given parameter
          ) =>
            condition[2] === 'not'
              ? this.state.config[condition[0]] !== condition[1]
              : this.state.config[condition[0]] === condition[1],
          ),
        )
      )
        return entry; // We add the parameter in the display list if all conditions are satisfied
    });

    const allSections = _.groupBy(_.compact(filteredConfig), 'section');

    return allSections;
  }

  render() {
    const { t } = this.props;

    if (!this.state.renderable) {
      return <div>Loading...</div>;
    }

    return (
      <GridContainer style={{ width: '100%' }}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography paragraph>
            {Object.keys(this.state.sections).map((section) => (
              <div>
                <Typography style={{ fontWeight: 600 }}>{t(section)}</Typography>
                <List>
                  {this.state.sections[section].map(
                    (entry) =>
                      tReducedLabel(entry, t) !== '' && (
                        <ListItem key={entry.field} dense>
                          <GridItem xs={8}>
                            <ListItemText primary={tReducedLabel(entry, t)} />
                          </GridItem>{' '}
                          :{' '}
                          <GridItem xs={6}>
                            <ListItemText
                              secondary={prepareValueForDisplay(
                                t,
                                this.state.configuration,
                                entry,
                                this.state.config,
                                'reduced',
                              )}
                            />
                          </GridItem>
                        </ListItem>
                      ),
                  )}
                </List>
              </div>
            ))}
          </Typography>
        </GridItem>
      </GridContainer>
    );
  }
}

Hypothesis.propTypes = {
  config: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(Hypothesis));

/* eslint-disable import/no-extraneous-dependencies */

import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, from } from 'apollo-boost';
import { onError } from '@apollo/client/link/error';
import { ensureAccessToken } from 'utils';

// Universal graphQL error handling link
const errorLink = onError();
/* ({ graphQLErrors, networkError, operation, forward }) => {
   if (graphQLErrors)
    operation.setContext({
      error: {
        internalErrorCode:graphQLErrors[0].internalErrorCode,
      },
    });
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);

  forward(operation); 
  } */

export function getApolloClient(isDoctor = true, account = false) {
  const url = (account ? window.ACCOUNT_API_URL : window.API_URL)
  return new Promise((success) => {
    if (isDoctor) {
      ensureAccessToken().then(() => {
        success(
          new ApolloClient({
            link: from([
              errorLink,
              new ApolloLink((operation, forward) => {
                operation.setContext({
                  headers: {
                    authorization: window.keycloak.token ? `Bearer ${window.keycloak.token}` : '',
                  },
                });
                return forward(operation);
              }),
              new HttpLink({ uri: `${url}/graphql`, credentials: 'include' }),
            ]),
            fetchOptions: { credentials: 'include' },
            cache: new InMemoryCache(),
          }),
        );
      });
    } else {
      success(
        new ApolloClient({
          link: from([
            errorLink,
            new ApolloLink((operation, forward) => {
              operation.setContext({
                headers: {
                  authorization: '',
                },
              });
              return forward(operation);
            }),
            new HttpLink({ uri: `${url}/graphql`, credentials: 'include' }),
          ]),
          fetchOptions: { credentials: 'include' },
          cache: new InMemoryCache(),
        }),
      );
    }
  });
}

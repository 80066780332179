/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Mutation } from 'react-apollo';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import Airplay from '@material-ui/icons/Airplay';
import Schedule from '@material-ui/icons/Schedule';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CheckIcon from '@material-ui/icons/Check';
import MenuList from '@material-ui/core/MenuList';
import CardContent from '@material-ui/core/CardContent';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import Drawer from '@material-ui/core/Drawer';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { checkCurrentUserPermission } from 'utils/permissionChecker';
import Snackbar from 'components/Snackbar/Snackbar';
import connectionState from 'components/Activity/connectionState';
// import converter from 'xml-js';
import {
  getCurrentUsers,
  getCurrentUser,
  getCurrentUserKeycloakId,
  getCurrentPatientId,
  getCurrentPatient,
} from '../../../utils';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { cvVioletColor } from '../../../styles/variables';
import SimpleYesNoDialog from '../../../components/Dialog/SimpleYesNoDialog';
import { SAVE_CONFIGURATION } from '../../../graphql/queries';
import { logger } from '../../../logger';
import { AsyncSelectInput, GenericAsyncSelectInput } from './AsyncSelectInput';
import { tToolTip } from '../../../i18n/utils';

const styles = (theme) => ({
  root: {
    color: cvVioletColor,
    '&$checked': {
      color: cvVioletColor,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  tooltipIcon: {
    padding: 5,
    paddingLeft: 0,
    color: cvVioletColor,
    cursor: 'pointer',
    verticalAlign: 'middle',
    size: '30!important',
  },
  checked: {},
  formControlWrapper: {
    marginBottom: theme.spacing.unit * 3,
    minWidth: '100%',
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  indentedFormBlock: {
    marginLeft: theme.spacing.unit * 6,
  },
  submitButton: {
    marginRight: 10,
  },
  programButton: {
    marginRight: 10,
    backgroundColor: theme.colors.orangeLight,
  },
  headerFromH7: {
    marginTop: theme.spacing.unit * 3,
  },
  configurationCheckErrorMessagesLabel: {
    color: 'red',
    textAlign: 'center',
  },
  configurationCheckWarningMessagesLabel: {
    color: cvVioletColor,
    textAlign: 'center',
  },
  configDivider: {
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  helpButton: {
    color: cvVioletColor,
    marginLeft: '5px',
    width: '10%',
    fontSize: '30px',
    '&:hover': {
      color: cvVioletColor,
    },
  },
  asyncInput: {
    display: 'inline-block',
    width: '75%',
  },
  scenarioDrawer: {
    left: '10%',
  },
  drawerPaper: {
    left: '10%',
  },
  randomScenarLabel: {
    display: 'inline-block',
    padding: '10px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: cvVioletColor,
  },
  submitArea: {
    position: 'fixed',
    bottom: 50,
    right: 50,
    padding: 15,
    border: `solid 1px ${cvVioletColor}`,
    backgroundColor: '#fcfaff',
    borderRadius: '10px',
    maxWidth: 480,
  },
});

// Cette façon de gérer la configuration est extrêmement dangeureuse...
const omit = [
  'configuration',
  'labelWidth',
  'saved',
  'yesNoDialogOpen',
  'saveConfigDialogOpen',
  'saveProgramDialogOpen',
  'okToSave',
  'saveConfiguration',
  'configModel',
  'sections',
  'pickConfigDialogOpen',
  'currentTab',
  'idConfig',
  'configDate',
  'isDuplicate',
  'scenarioDrawerOpen',
  'renderable',
  'scenarioRandom',
  'configurationCheckErrorFields',
  'configurationCheckErrorMessages',
  'configurationCheckWarningFields',
  'configurationCheckWarningMessages',
  'configValidator',
  'readyToLaunch',
  'repeatValue',
  'interseanceAvailable',
  'currentPatient',
  'snackbarVisible',
  'snackbarMessage',
  'isPatientConnected'
];

class BasicConfigValidator {
  constructor(t, CustomValidatorClass) {
    this.t = t;
    this.customValidator = CustomValidatorClass ? new CustomValidatorClass(this.t) : null;
  }

  /*
   * A function that just checks that all fields are "not empty"
   * Likely to be used only for functinoal activities that need to select a scenario
   *
   * Furthermore, requests the "customValidator" (define on an activity-basis, in the activity module)
   * to make its check, and combine all the values.
   */
  check(state) {
    let emptyCheckErrorFields = [];
    let emptyCheckErrorMessages = [];

    let emptyCheckWarningFields = [];
    let emptyCheckWarningMessages = [];
    state.configModel.attributes.forEach((attribute) => {
      const value = state[attribute.field];
      if (value === null || value === undefined || value === '') {
        emptyCheckErrorFields.push(attribute.field);
        const str = this.t('Common_ConfigurationView_Caption_EmptyFieldError').replace(
          '[FIELD]',
          `<b>${this.t(attribute.label)}</b>`,
        );
        // eslint-disable-next-line
        emptyCheckErrorMessages.push(<div dangerouslySetInnerHTML={{ __html: str }} />);
      }

      if (attribute.min && value.length < attribute.min) {
        emptyCheckErrorFields.push(attribute.field);
        const str = this.t('Common_ConfigurationView_Caption_MinEltError')
          .replace(
            '[FIELD]',
            `<b>${this.t(attribute.label)}</b>`,
          ).replace(
            '[NUMBER]',
            `<b>${attribute.min}</b>`);
        // eslint-disable-next-line
        emptyCheckErrorMessages.push(<div dangerouslySetInnerHTML={{ __html: str }} />);
      }
    });
    if (this.customValidator) {
      const customValidatorReturn = this.customValidator.check(state);
      // convert return of custom validators into html format
      emptyCheckErrorFields = _.merge(emptyCheckErrorFields, customValidatorReturn[0]);
      // eslint-disable-next-line
      emptyCheckErrorMessages = _.merge(emptyCheckErrorMessages, customValidatorReturn[1].map(str => <div dangerouslySetInnerHTML={{ __html: str }} />));
      emptyCheckWarningFields = customValidatorReturn[2];
      // eslint-disable-next-line
      emptyCheckWarningMessages = customValidatorReturn[3].map(str => <div dangerouslySetInnerHTML={{ __html: str }} />);
    }
    return [emptyCheckErrorFields, emptyCheckErrorMessages, emptyCheckWarningFields, emptyCheckWarningMessages];
  }
}

class ConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configuration: '',
      saved: true,
      labelWidth: 0,
      name: '',
      yesNoDialogOpen: false,
      saveConfiguration: null,
      saveConfigDialogOpen: false,
      saveProgramDialogOpen: false,
      pickConfigDialogOpen: false,
      isDuplicate: false,
      okToSave: false,
      sections: [],
      configModel: props.configModel,
      configDate: moment().format('DD/MM/YYYY'),
      idConfig: '',
      description: '',
      currentTab: 'yours',
      scenarioDrawerOpen: false,
      ...props.configModel.defaultConf,
      renderable: false,
      scenarioRandom: false,
      scenarioString: '',
      doctorInput: {
        _id: getCurrentUser()._id,
        firstName: getCurrentUser().firstName,
        lastName: getCurrentUser().lastName,
        email: getCurrentUser().email,
      },
      currentPatient: `${getCurrentPatient().firstname} ${getCurrentPatient().lastname}`,
      configurationCheckErrorFields: [],
      configurationCheckErrorMessages: [],
      configurationCheckWarningFields: [],
      configurationCheckWarningMessages: [],
      configValidator: null,
      readyToLaunch: false,
      snackbarVisible: false,
      snackbarMessage: '',
      isPatientConnected: false,
      repeatValue: 1,
      permanent: props.permanent
    };
    setTimeout(() => {
      this.setState({ sections: this.getFieldsBySection() });
    }, 0);

    if (!_.isUndefined(this.state.scenario) && this.state.previewScenario) {
      import(`@covirtua-activities/${this.state.configModel.metadata.slug}/build/ScenarioDisplayer.js`).then(
        (displayer) => {
          this.setState({
            scenarioDisplayer: displayer.default,
            renderable: true,
          });
        },
      );
    } else {
      this.state.renderable = true;
    }

    import(`@covirtua-activities/${this.state.configModel.metadata.slug}/build/configValidator.js`)
      .then((result) => {
        this.setState({
          configValidator: new BasicConfigValidator(this.props.t, result.default),
          // configValidator: new basicConfigValidator(this.props.t, null),
        });
      })
      .catch(() => {
        this.setState({
          configValidator: new BasicConfigValidator(this.props.t, null),
        });
      });

    setTimeout(() => {

      this.checkConfig();
    }, 300);

    // register to the connection state change event
    this.patientConnectionStateId = window.registerPatientConnectionClient(this.setConnectionState, this);
  }

  componentWillUnmount() {
    window.unregisterPatientConnectionClient(this.patientConnectionStateId);
  }

  getFieldsBySection() {
    return _.groupBy(this.state.configModel.attributes, 'section');
  }

  /*
  setScenarioAsXML() {
    if (this.state.scenario) {
      const scenar = _.isString(this.state.scenarioString)
        ? this.state.scenarioString
        : JSON.stringify({ Scenario: this.state.scenarioString });
      this.setState({ scenarioString: converter.json2xml(scenar, { compact: true, ignoreComment: true, spaces: 0 }) });
    }
  }
  */

  setReviewedConfig(parameters) {
    this.setState({ scenarioDrawerOpen: false });
    parameters.forEach((param) => {
      this.setState({ [param[0]]: param[1] });
    });
    this.checkConfig();
  }

  setRandomScenario = (random, openDrawer) => () => {
    this.setState({ scenarioRandom: random });
    this.setState({ scenarioDrawerOpen: openDrawer });
  };

  // eslint-disable-next-line
  handleYesNoDialogClose = () => {
    this.setState({ yesNoDialogOpen: false });
  };

  handleDialogNo = () => { };

  saveConfigDialogClose = () => {
    this.setState({ saveConfigDialogOpen: false });
    this.setState({ isDuplicate: false });
  };

  saveProgramDialogClose = () => {
    this.setState({ saveProgramDialogOpen: false });
  };

  handleDialogYes = () => {
    const composit = _.reduce(
      this.state.compositionsChecked,
      (result, value, key) => {
        if (value) {
          result.push(key);
        }
        return result;
      },
      [],
    );

    this.setState({ composition: composit }, () => {
      const state = _.omit(this.state, omit);

      this.state
        .saveConfiguration({
          variables: {
            id: this.state.idConfig,
            name: this.state.name,
            activity: JSON.stringify(this.state.configModel.metadata),
            config: JSON.stringify(state),
            description: this.state.description,
            doctor: this.state.doctorInput,
          },
        })
        .then((configuration) => {
          const { slug } = configuration.data.saveConfiguration;
          this.setState({ configuration: slug }, () => {
            this.handleSave();
            this.setState({ okToSave: false });
          });
        });
    });
  };

  handleSave = () => {
    this.setState({ saved: true });
  };

  handleChange = (event) => {
    if (event.currentTarget.dataset.format === 'sub-option') {
      if (!_.includes(this.state[event.target.name], event.target.value)) {
        this.setState({
          [event.target.name]: _.concat([], this.state[event.target.name], [event.target.value]),
          saved: false,
        });
      } else {
        this.setState({ [event.target.name]: _.pull(this.state[event.target.name], event.target.value), saved: false });
      }
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked, saved: false });
    } else {
      this.setState({ [event.target.name]: event.target.value, saved: false });
    }

    if (event.currentTarget.attributes.target) {
      const remoteFileName = window.AssetResolver.getAsset(event.currentTarget.attributes.target.value, 'json', false);
      const targetContent = event.currentTarget.attributes.targetContent.value;
      const labelFieldName = event.currentTarget.attributes.labelFieldName.value;
      const label = event.currentTarget.attributes.label.value;
      this.setState({ [labelFieldName]: label });
      fetch(remoteFileName)
        .then((res) => res.text())
        .then((result) => {
          const parsedResult = JSON.parse(result);
          this.setState({ [targetContent]: parsedResult });
          this.scenarioString = parsedResult;
          if (this.scenarioString.RandomItems) this.setRandomScenario(true, false);
        });
    }
    this.setState({ saved: false });

    // request check of new parameters
    this.checkConfig();
  };

  showSnackbar = (message) => {
    this.setState({
      snackbarVisible: true,
      snackbarMessage: message,
    });
    setTimeout(() => {
      this.setState({ snackbarVisible: false });
    }, 5000);
  };

  checkConfig = () => {
    // need to make sure all latest parameters are in state!
    setTimeout(() => {
      const check = this.state.configValidator.check(this.state);
      this.setState({ configurationCheckErrorFields: check[0] });
      this.setState({ configurationCheckErrorMessages: check[1] });
      this.setState({ configurationCheckWarningFields: check[2] });
      this.setState({ configurationCheckWarningMessages: check[3] });
      this.setState({ readyToLaunch: check[1].length === 0 });
    }, 300);
  };

  handleConfigurationChange = (configSlug, configID) => {
    this.setState({ configuration: configSlug, idConfig: configID });
    if (!_.isEmpty(configSlug)) {
      const selectedConfig = JSON.parse(_.find(this.props.configs, ['slug', configSlug]).config);

      _.forOwn(selectedConfig, (value, key) => {
        // if (key === 'composition') {
        //   this.setState({ compositionsChecked: _.fromPairs(value.map((k) => [k, true])) });
        // }
        this.setState({ [key]: value });
      });
    }
    this.setState({ pickConfigDialogOpen: false });
  };

  handleSaveAndStart(e, handleStartActivity, saveConfiguration) {
    e.preventDefault();
    e.persist();
    // this.setScenarioAsXML();

    const { configSlug } = e.currentTarget.dataset;

    setTimeout(() => {
      if (this.state.name !== '' && !this.state.saved && this.state.configuration === this.state.name) {
        this.setState({ yesNoDialogOpen: true, saveConfiguration });
      } else {
        const state = _.omit(this.state, omit);
        if (this.state.name !== '') {
          saveConfiguration({
            variables: {
              id: this.state.idConfig,
              name: this.state.name,
              activity: JSON.stringify(this.state.configModel.metadata),
              config: JSON.stringify(state),
              description: this.state.description,
              doctor: this.state.doctorInput,
            },
          }).then((configuration) => {
            const { slug } = configuration.data.saveConfiguration;
            this.setState({ configuration: slug }, () => {
              this.handleSave();
              this.setState({ okToSave: false });
            });
          });
        }
        handleStartActivity(configSlug, JSON.stringify(state));
      }
    }, 500);
  }

  handleRequestProgram() {
    this.setState({ saveProgramDialogOpen: true });
  }

  handleProgram(e) {
    const { t } = this.props;
    e.preventDefault();
    this.setState({ saveProgramDialogOpen: false });

    const state = _.omit(this.state, omit);
    for (let act = 0; act < this.state.repeatValue; act += 1) {
      setTimeout(() => {
        window.comSocket.emit(
          'createActivityChannel',
          {
            slug: this.state.configuration,
            token: window.keycloak.token,
            communicationChannel: getCurrentPatientId(),
            programmed: true,
          },
          () => {
            window.comSocket.emit(
              'programActivity',
              {
                header: {
                  activity: this.state.configModel.metadata,
                  users: getCurrentUsers(),
                },
                body: {
                  config: state,
                  programmed: true,
                  permanent: this.state.permanent
                },
                token: window.keycloak.token,
              },
              () => {
                console.log('Activité et Channel construits');
              },
            );
          },
        );
      }, 100 * act);
    }
    //      config : JSON.stringify(state);
    this.setState({ programName: '' });
    this.showSnackbar(
      t('DCreateProgram_Feedback')
        .replace('[PATIENT]', this.state.currentPatient)
        .replace('[ACTIVITY]', t(this.state.configModel.metadata.name)),
    );
  }

  openNamedSave = () => {
    this.setState({ saveConfigDialogOpen: true });
  };

  handleNamedSaveYes(e, saveConfiguration) {
    const { t } = this.props;
    this.setState({ configDate: moment().format('DD/MM/YYYY') });
    e.preventDefault();
    const state = _.omit(this.state, omit);

    saveConfiguration({
      variables: {
        id: this.state.idConfig,
        name: this.state.name,
        activity: JSON.stringify(this.state.configModel.metadata),
        config: JSON.stringify(state),
        description: this.state.description,
        doctor: this.state.doctorInput,
      },
    })
      .then((configuration) => {
        const { slug } = configuration.data.saveConfiguration;
        this.setState({ configuration: slug }, () => {
          this.handleSave();
          this.setState({ okToSave: false });
          this.setState({ saveConfigDialogOpen: false });
          this.setState({ isDuplicate: false });
        });
        this.showSnackbar(t('PExercisePreset_CreationFeedback').replace('[CONFIGURATION]', this.state.name));
      })
      .catch((err) => {
        logger.debug(err);
        this.setState({ isDuplicate: true });
      });
  }

  handlePickConfig(e) {
    e.preventDefault();
    this.setState({ pickConfigDialogOpen: true });
  }

  toggleScenarioDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ scenarioDrawerOpen: open });
  };

  toolTipIcon = (tooltipLabel, classes) => {
    if (tooltipLabel)
      return (
        <Tooltip title={tooltipLabel} disableFocusListener classes={{ tooltip: classes.tooltip }}>
          <HelpIcon className={classes.tooltipIcon} />
        </Tooltip>
      );
    return '';
  };

  setConnectionState = (patientConnection, comp) => {
    const isPatientConnected = (patientConnection === connectionState.connected);
    setTimeout(() => {
      comp.setState({ isPatientConnected });
    }, 1000);
  }

  render() {
    const { classes, configs, handleStartActivity, t, isModifyingProgram, handleModifyExistingConfig } = this.props;

    if (!this.state.renderable) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <Mutation mutation={SAVE_CONFIGURATION}>
        {(saveConfiguration) => (
          <div>
            <Snackbar
              place="br"
              color="success"
              open={this.state.snackbarVisible}
              message={this.state.snackbarMessage}
            />
            <Dialog
              open={this.state.saveConfigDialogOpen}
              onClose={this.handleSaveDialogClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {t('DCreatePreset_Title')}
                {this.state.configDate}
              </DialogTitle>
              <DialogContent>
                <ValidatorForm onError={logger.error} className={classes.form}>
                  {this.state.isDuplicate && (
                    <Typography style={{ color: 'red', padding: '4px' }} variant="subtitle">
                      <WarningIcon style={{ position: 'relative', top: '4px' }} />{' '}
                      <span>{t('DCreatePreset_ErrorExistingName')}</span>
                    </Typography>
                  )}
                  <TextValidator
                    fullWidth
                    name="name"
                    id="name"
                    label={t('DCreatePreset_Name')}
                    validators={['required']}
                    errorMessages={[t('DCreatePreset_Error')]}
                    className={classNames(classes.textField, classes.dense)}
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  <Divider />
                  <TextValidator
                    fullWidth
                    multiline
                    rows={4}
                    name="description"
                    id="description"
                    label={t('DCreatePreset_Description')}
                    className={classNames(classes.textField, classes.dense)}
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </ValidatorForm>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.saveConfigDialogClose} color="primary">
                  {t('Common_Cancel')}
                </Button>
                <Button
                  onClick={(e) => {
                    this.handleNamedSaveYes(e, saveConfiguration);
                  }}
                  disabled={this.state.name === ''}
                  color="primary"
                >
                  {t('Common_Save')}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.saveProgramDialogOpen}
              onClose={this.handleProgramDialogClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {t('DCreateProgram_Title').replace('[PATIENT]', this.state.currentPatient)}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={8}>
                  <Grid item xs={8}>
                    <ValidatorForm className={classes.form}>
                      <TextValidator
                        fullWidth
                        name="programName"
                        id="programName"
                        label={t('DCreateProgram_Description')}
                        autoComplete="off"
                        className={classNames(classes.textField, classes.dense)}
                        value={this.state.programName}
                        onChange={this.handleChange}
                      />
                    </ValidatorForm>
                    <Tooltip
                      disableFocusListener
                      title={t('DCreateProgram_CaptionToolTip')}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Typography variant="h7">{t('DCreateProgram_Caption')}</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip
                      disableFocusListener
                      title={t('DCreateProgram_Permanent§ToolTip')}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            name="permanent"
                            checked={this.state.permanent}
                            onChange={this.handleChange}
                            value="permanent"
                          />
                        }
                        label={t('DCreateProgram_Permanent')}
                      />
                    </Tooltip>
                    {!this.state.permanent &&
                      <Tooltip
                        disableFocusListener
                        title={t('DCreateProgram_Repeat§ToolTip')}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <ValidatorForm className={classes.form}>
                          <TextValidator
                            fullWidth
                            type="number"
                            validators={['minNumber:1', 'maxNumber:20', 'matchRegexp:^[0-9]$']}
                            name="repeatValue"
                            id="repeatValue"
                            label={t('DCreateProgram_Repeat')}
                            autoComplete="off"
                            className={classNames(classes.textField, classes.dense)}
                            value={this.state.repeatValue}
                            onChange={this.handleChange}
                            errorMessages={t('DCreateProgram_Repeat§ErrorMessage')}
                          />
                        </ValidatorForm>
                      </Tooltip>}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.saveProgramDialogClose} color="primary">
                  {t('Common_Cancel')}
                </Button>
                <Button
                  className={classes.programButton}
                  onClick={(e) => {
                    this.handleProgram(e, saveConfiguration);
                  }}
                  color="primary"
                  variant="outlined"
                >
                  {t('Common_Program')}
                </Button>
              </DialogActions>
            </Dialog>

            <Drawer
              anchor="right"
              open={this.state.scenarioDrawerOpen}
              onClose={this.toggleScenarioDrawer(false)}
              classes={{ paper: classes.drawerPaper }}
            >
              <this.state.scenarioDisplayer
                classes={classes}
                scenario={this.scenarioString}
                configuration={this.state}
                closeCallback={(params) => {
                  this.setReviewedConfig(params);
                }}
              />
            </Drawer>

            <SimpleYesNoDialog
              yes={this.openNamedSave}
              no={this.handleDialogNo}
              content="Etes-vous certain de vouloir modifier l'activité existante ?"
              onClose={this.handleYesNoDialogClose}
              open={this.state.yesNoDialogOpen}
            />

            <ValidatorForm
              onSubmit={(e) => {
                e.preventDefault();
                // const state = _.omit(this.state, omit);

                if (this.state.configuration === this.state.name && this.state.name !== '' && !this.state.okToSave) {
                  this.setState({ yesNoDialogOpen: true, saveConfiguration });
                } else {
                  this.setState({ saveConfigDialogOpen: true, saveConfiguration });
                  /* saveConfiguration({
                    variables: {
                      name: this.state.name,
                      activity: JSON.stringify(this.state.configModel.metadata),
                      config: JSON.stringify(state),
                    },
                  }).then((configuration) => {
                    const slug = configuration.data.saveConfiguration.slug;
                    this.setState({ configuration: slug }, () => {
                      this.handleSave();
                      this.setState({ okToSave: false })
                    });
                  }); */
                }
              }}
              onError={(errors) => logger.error(errors)}
              className={classes.form}
            >
              <GridContainer justify="center">
                <GridItem xs={6} sm={6} md={6}>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.state.configuration === '' && (
                      <Button
                        data-config-slug={`${this.state.configuration}`}
                        onClick={(e) => {
                          this.handlePickConfig(e);
                        }}
                        variant="contained"
                        color="primary"
                      >
                        {t('PExercisePreset_SelectPreset')}
                      </Button>
                    )}
                    {this.state.configuration !== '' && (
                      <Typography variant="h4">
                        <Button
                          data-config-slug={`${this.state.configuration}`}
                          onClick={(e) => {
                            this.handlePickConfig(e);
                          }}
                          variant="contained"
                          color="secondary"
                        >
                          {this.state.configuration}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.setState({ configuration: '' });
                          }}
                        >
                          <CancelIcon />
                        </Button>
                      </Typography>
                    )}

                    <Dialog
                      open={this.state.pickConfigDialogOpen}
                      onClose={() => {
                        this.setState({ pickConfigDialogOpen: false });
                      }}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogTitle id="form-dialog-title">{t('PExercisePreset_SelectPreset')}</DialogTitle>
                      <DialogContent>
                        <AppBar position="static" color="default">
                          <Tabs
                            value={this.state.currentTab}
                            onChange={(evt, index) => {
                              this.setState({ currentTab: index });
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                          >
                            <Tab value="yours" label={t('PExercisePreset_OwnPreset')} />
                            <Tab value="group" label={t('Groupe')} />
                            <Tab value="covirtuas" label={t('PExercisePreset_CovirtuaPreset')} />
                          </Tabs>
                        </AppBar>

                        {this.state.currentTab === 'yours' && (
                          <MenuList style={{ maxHeight: '600px', overflowY: 'auto' }}>
                            {_.filter(
                              configs,
                              (c) =>
                                c.doctor &&
                                (c.doctor.keycloakID === getCurrentUserKeycloakId() ||
                                  c.doctor._id === getCurrentUserKeycloakId()),
                            ).map((configItem) => (
                              <MenuItem
                                key={configItem._id}
                                onClick={() => {
                                  this.handleConfigurationChange(configItem.slug, configItem._id);
                                }}
                                selected={this.state.configuration === configItem.slug}
                                style={{ height: 'unset' }}
                              >
                                <Card style={{ width: '100%', padding: '10px' }}>
                                  <CardHeader
                                    title={
                                      <div
                                        style={{
                                          maxWidth: '80%',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {configItem.name}
                                      </div>
                                    }
                                    action={this.state.configuration === configItem.slug ? <CheckIcon /> : ''}
                                    style={{ padding: '5px 16px' }}
                                  />
                                  <CardContent
                                    style={{
                                      wordBreak: 'break-word',
                                      whiteSpace: 'break-spaces',
                                      overflowY: 'auto',
                                      maxHeight: '60px',
                                      padding: '5px 8px 16px 16px',
                                      margin: '0 5px 16px 0',
                                    }}
                                  >
                                    {configItem.description}
                                  </CardContent>
                                </Card>
                              </MenuItem>
                            ))}
                          </MenuList>
                        )}

                        {this.state.currentTab === 'group' && (
                          <MenuList style={{ maxHeight: '600px', overflowY: 'auto' }}>
                            {_.filter(
                              configs,
                              (c) =>
                                c.doctor &&
                                c.doctor.lastName !== 'Covirtua' &&
                                c.doctor.keycloakID !== getCurrentUserKeycloakId() &&
                                c.doctor._id !== getCurrentUserKeycloakId(),
                            ).map((configItem) => (
                              <MenuItem
                                key={configItem._id}
                                onClick={() => {
                                  this.handleConfigurationChange(configItem.slug);
                                }}
                                selected={this.state.configuration === configItem.slug}
                                style={{ height: 'unset' }}
                              >
                                <Card style={{ width: '100%', padding: '10px' }}>
                                  <CardHeader
                                    title={
                                      <div
                                        style={{
                                          maxWidth: '80%',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {configItem.name}
                                      </div>
                                    }
                                    action={this.state.configuration === configItem.slug ? <CheckIcon /> : ''}
                                    style={{ padding: '5px 16px' }}
                                  />
                                  <CardContent
                                    style={{
                                      wordBreak: 'break-word',
                                      whiteSpace: 'break-spaces',
                                      overflowY: 'auto',
                                      maxHeight: '60px',
                                      padding: '5px 8px 16px 16px',
                                      margin: '0 5px 16px 0',
                                    }}
                                  >
                                    {configItem.description !== null ? configItem.description : ''}
                                  </CardContent>
                                </Card>
                              </MenuItem>
                            ))}
                          </MenuList>
                        )}

                        {this.state.currentTab === 'covirtuas' && (
                          <MenuList style={{ maxHeight: '600px', overflowY: 'auto' }}>
                            {_.filter(configs, (c) => c.doctor && c.doctor.lastname === 'Covirtua').map(
                              (configItem) => (
                                <MenuItem
                                  key={configItem._id}
                                  onClick={() => {
                                    this.handleConfigurationChange(configItem.slug);
                                  }}
                                  selected={this.state.configuration === configItem.slug}
                                  style={{ height: 'unset' }}
                                >
                                  <Card style={{ width: '100%', padding: '10px' }}>
                                    <CardHeader
                                      title={
                                        <div
                                          style={{
                                            maxWidth: '80%',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {configItem.name}
                                        </div>
                                      }
                                      action={this.state.configuration === configItem.slug ? <CheckIcon /> : ''}
                                      style={{ padding: '5px 16px' }}
                                    />
                                    <CardContent
                                      style={{
                                        wordBreak: 'break-word',
                                        whiteSpace: 'break-spaces',
                                        overflowY: 'auto',
                                        maxHeight: '60px',
                                        padding: '5px 8px 16px 16px',
                                        margin: '0 5px 16px 0',
                                      }}
                                    >
                                      {configItem.description !== null ? configItem.description : ''}
                                    </CardContent>
                                  </Card>
                                </MenuItem>
                              ),
                            )}
                          </MenuList>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            this.setState({ pickConfigDialogOpen: false });
                          }}
                          color="primary"
                        >
                          Annuler
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {Object.keys(this.state.sections).map((section) => (
                      <div key={section}>
                        <Typography variant="h6" gutterBottom className={classes.headerFromH7}>
                          {t(section)}
                        </Typography>

                        {this.state.sections[section].map((entry) =>
                          // Check whether the field has conditions
                          !entry.conditions ||
                            (entry.conditions &&
                              _.every(
                                _.map(
                                  entry.conditions,
                                  // eslint-disable-next-line arrow-body-style
                                  // if sub[2]="not", that means it's a "not equal to" condition, otherwise it's a "equal to" condition
                                  (sub) =>
                                    sub[2] === 'not' ? this.state[sub[0]] !== sub[1] : this.state[sub[0]] === sub[1],
                                ),
                              )) ? (
                            /* go through all types of parameter: */

                            /* case A : remote scenario : list based on json asset file pointing to an asset content */
                            entry.remoteLinks ? (
                              <div style={{ lineHeight: '72px' }}>
                                <div className={classes.asyncInput}>
                                  <AsyncSelectInput
                                    key={entry}
                                    context={this}
                                    entry={entry}
                                    classes={classes}
                                    t={t}
                                    error={_.includes(this.state.configurationCheckErrorFields, entry.field)}
                                  />
                                </div>
                                <span>
                                  {this.state[entry.remoteLinks.targetContent] && this.state.previewScenario && (
                                    <Tooltip
                                      disableFocusListener
                                      title={t('VActivityConfig_ScenarioOverviewToolTip')}
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <VisibilityIcon
                                        className={classes.helpButton}
                                        onClick={this.setRandomScenario(false, true)}
                                      />
                                    </Tooltip>
                                  )}
                                </span>
                              </div>
                            ) : /* case B : List based on json asset file */
                              entry.remoteList ? (
                                <GenericAsyncSelectInput
                                  field={entry}
                                  context={this}
                                  entry={entry}
                                  classes={classes}
                                  t={t}
                                  error={_.includes(this.state.configurationCheckErrorFields, entry.field)}
                                />
                              ) : /* case C : other non remote */
                                /* case C1: simple boolean without option (checkbox) */
                                !entry.options && `${entry.type}` === 'boolean' ? (
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state[entry.field]}
                                          classes={{
                                            root: classes.root,
                                            checked: classes.checked,
                                          }}
                                          onChange={this.handleChange}
                                          value={entry.field}
                                          name={entry.field}
                                        />
                                      }
                                      label={t(entry.label)}
                                    />
                                    {this.toolTipIcon(tToolTip(entry, t), classes)}
                                  </div>
                                ) : /* case C2 :  options of non boolean types (list) */
                                  entry.options && `${entry.type}` !== 'boolean' ? (
                                    <div>
                                      <FormControl
                                        fullWidth
                                        className={classes.formControlWrapper}
                                        error={_.includes(this.state.configurationCheckErrorFields, entry.field)}
                                      >
                                        <InputLabel htmlFor={entry.field}>
                                          {t(entry.label)}
                                          {this.toolTipIcon(tToolTip(entry, t), classes)}
                                        </InputLabel>
                                        <Select
                                          value={this.state[entry.field]}
                                          onChange={this.handleChange}
                                          inputProps={{
                                            name: entry.field,
                                            id: entry.field,
                                          }}
                                        >
                                          {entry.options.map((attribute) => (
                                            <MenuItem value={attribute.value} key={attribute.value}>
                                              {t(attribute.label)}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  ) : /* case C3 : boolean with options (pack of checkboxes) */
                                    entry.options && `${entry.type}` === 'boolean' ? (
                                      <div>
                                        {t(entry.label) && t(entry.label) !== '' && (
                                          <Typography
                                            variant="subtitle1"
                                            component="subtitle1"
                                            style={_.includes(this.state.configurationCheckErrorFields, entry.field) ? { color: "red" } : {}}
                                          >
                                            {t(entry.label)}
                                            {this.toolTipIcon(tToolTip(entry, t), classes)}
                                          </Typography>
                                        )}
                                        {entry.options.map((suboption) => (
                                          <span>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={_.includes(this.state[entry.field], suboption.value)}
                                                  classes={{
                                                    root: classes.root,
                                                    checked: classes.checked,
                                                  }}
                                                  onChange={this.handleChange}
                                                  value={suboption.value}
                                                  name={entry.field}
                                                  inputProps={{ 'data-format': 'sub-option' }}
                                                />
                                              }
                                              label={t(suboption.label)}
                                            />
                                            {this.toolTipIcon(tToolTip(suboption, t), classes)}
                                          </span>
                                        ))}
                                      </div>
                                    ) : (
                                      ''
                                    )
                          ) : (
                            ''
                          ),
                        )}
                      </div>
                    ))}
                  </GridItem>
                </GridItem>
              </GridContainer>

              <div className={classNames(classes.submitArea)}>
                {this.state.configurationCheckErrorMessages.length > 0 && (
                  <Typography variant="h7" gutterBottom className={classes.configurationCheckErrorMessagesLabel}>
                    {this.state.configurationCheckErrorMessages.map((txt) => (
                      <p>{txt}</p>
                    ))}
                  </Typography>
                )}
                {this.state.configurationCheckWarningMessages.length > 0 && (
                  <Typography variant="h7" gutterBottom className={classes.configurationCheckWarningMessagesLabel}>
                    {this.state.configurationCheckWarningMessages.map((txt) => (
                      <p>{txt}</p>
                    ))}
                  </Typography>
                )}
                <GridContainer direction="row">
                  <GridItem xs={12} sm={12} md={12}>
                    {!isModifyingProgram &&
                      <div>
                        <Button
                          type="submit"
                          variant="outlined"
                          color="primary"
                          className={classes.submitButton}
                          disabled={!this.state.readyToLaunch}
                        >
                          {t('Common_Save')}
                        </Button>

                        {checkCurrentUserPermission('feature_programActivity') && getCurrentPatient().interseance && this.state.interseanceAvailable && (
                          <Button
                            data-config-slug={`${this.state.configuration}`}
                            onClick={(e) => {
                              this.handleRequestProgram(e);
                            }}
                            variant="outlined"
                            color="primary"
                            className={classes.programButton}
                            disabled={!this.state.readyToLaunch}
                          >
                            {t('Common_Program')}
                            <Schedule className={classes.rightIcon} />
                          </Button>
                        )}

                        <Button
                          data-config-slug={`${this.state.configuration}`}
                          onClick={(e) => {
                            this.handleSaveAndStart(e, handleStartActivity, saveConfiguration);
                          }}
                          variant="contained"
                          color="primary"
                          disabled={!this.state.readyToLaunch || !this.state.isPatientConnected}
                        >
                          {t('Common_Start')}
                          <Airplay className={classes.rightIcon} />
                        </Button>
                      </div>
                    }
                    {isModifyingProgram &&
                      <div>
                        <GridItem>
                          <TextField
                            id="programName"
                            label={t('DCreateProgram_Description')}
                            fullWidth
                            multiline
                            className={classes.textField}
                            margin="normal"
                            name="programName"
                            value={this.state.programName}
                            onChange={this.handleChange}
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <Tooltip
                            disableFocusListener
                            title={t('DCreateProgram_Permanent§ToolTip')}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  name="permanent"
                                  checked={this.state.permanent}
                                  onChange={this.handleChange}
                                  value="permanent"
                                />
                              }
                              label={t('DCreateProgram_Permanent')}
                            />
                          </Tooltip>
                        </GridItem>
                        <Button
                          data-config-slug={`${this.state.configuration}`}
                          onClick={() => {
                            handleModifyExistingConfig(_.omit(this.state, omit), this.state.permanent);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          {t('Common_Modify')}
                        </Button>
                      </div>
                    }
                  </GridItem>
                </GridContainer>
              </div>
            </ValidatorForm>
          </div>
        )}
      </Mutation>
    );
  }
}

ConfigForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleStartActivity: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  configModel: PropTypes.object.isRequired,
  permanent: PropTypes.object,
  handleModifyExistingConfig: PropTypes.func,
  isModifyingProgram: PropTypes.object,
  t: PropTypes.func.isRequired,
};

ConfigForm.defaultProps = {
  permanent: false,
  handleModifyExistingConfig: null,
  isModifyingProgram: false
}

export default withTranslation()(withStyles(styles)(ConfigForm));

import AuthenticationWrapper from 'components/Authentication/AuthenticationWrapper';
import PatientOnSiteLayout from 'layouts/PatientOnSiteLayout';


const patientOnSiteRoutes = [
  {
    path: '/',
    component: PatientOnSiteLayout, // public patient webapp
  },
];

const indexRoutes = [
  {
    path: '/',
    component: AuthenticationWrapper, // AuthenticationWrapper will perform the keycloak authentication before redirecting to the doctor webapp layout (AppLayout.js)
  },
];

export { indexRoutes, patientOnSiteRoutes };

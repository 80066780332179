import React from 'react';
import _ from 'lodash';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import { GET_ACTIVITIES } from 'graphql/queries';
import { getCurrentPatientId, getDoctorName } from 'utils';
import NoHistory from 'components/Zero/NoHistory';
import HistoryCardActivityWrapper from 'activities/activityWrapper/components/HistoryCard.js';
import moment from 'moment';
import Card from 'components/Card/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardBody from 'components/Card/CardBody.jsx';
import { cvGreenColor } from 'styles/variables';

const styles = () => ({
  avatar: {
    backgroundColor: cvGreenColor,
    borderRadius: '0%',
  },
  dateCard: {
    flexDirection: 'row',
  },
  dateCardHeader: {
    width: 150,
    justifyContent: 'center',
  },
});

const PatientHistory = (props) => {
  const groupActivitiesBySession = (activities) => {
    if (activities.length > 0) {
      const TimelyOrderedActivities = _.orderBy(activities, 'stoppedAt', 'asc');
      const initialActivity = TimelyOrderedActivities.pop();
      const ActivitiesPerSession = [
        {
          date: moment(initialActivity.stoppedAt).format('DD/MM/YY'),
          doctor: initialActivity.users.doctor,
          SessionActivities: [initialActivity],
        },
      ];
      while (TimelyOrderedActivities.length > 0) {
        const activity = TimelyOrderedActivities.pop();
        const activityDate = moment(activity.stoppedAt).format('DD/MM/YY');
        const activityDoctor = activity.users.doctor;
        if (
          ActivitiesPerSession[ActivitiesPerSession.length - 1].date === activityDate &&
          ActivitiesPerSession[ActivitiesPerSession.length - 1].doctor === activityDoctor
        )
          ActivitiesPerSession[ActivitiesPerSession.length - 1].SessionActivities.push(activity);
        // If same session (date/doctor), add activity to last session
        else ActivitiesPerSession.push({ date: activityDate, doctor: activityDoctor, SessionActivities: [activity] }); // otherwise create new session
      }
      return ActivitiesPerSession;
    }

    return [];
  };

  const { classes } = props;
  const lastEvaluatedDate = Date.now().toString();

  const renderSwitch = (activity, index) => {
    switch (activity.slug) {
      case 'barrage':
      case 'element-singulier':
      case 'pca':
      case 'le-bon-chiffre':
      case 'categoriser':
      case 'trouver-lintrus':
      case 'designation':
      case 'ajout-config':
      case 'conduite-gps':
      case 'memoire-visuelle-nx':
      case 'n-back-config':
      case 'memory':
      case 'retrait-config':
      case 'le-plan-de-ville':
      case 'template':
      case 'les-courses':
      case 'la-liste':
      case 'predicog-categorize':
      case 'predicog-recognize':
        return <HistoryCardActivityWrapper activity={activity} key={index} />;
      default:
        return 'Aucun historique patient';
    }
  };

  const renderLoading = () => (
    <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
      <CircularProgress />
    </div>
  );

  return (
    <div>
      <GridContainer spacing={24}>
        <GridItem xs={12}>
          <Query
            query={GET_ACTIVITIES}
            variables={{ lastEvaluatedDate, patientId: getCurrentPatientId() }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, error, data }) => {
              if (loading) return renderLoading();

              if (error) return `Error! ${error.message}`;

              if (_.isEmpty(data.getAllActivities))
                return (
                  <div>
                    <NoHistory />
                  </div>
                );
              const activitiesGroupedByDate = groupActivitiesBySession(
                _.filter(data.getAllActivities, (act) => act.isCompleted || act.isInterrupted),
              );
              return (
                <div>
                  {activitiesGroupedByDate.map((session) => (
                    <GridItem key={session.date} xs={12}>
                      <Card className={classes.dateCard}>
                        <CardHeader
                          className={classes.dateCardHeader}
                          title={session.date}
                          subheader={getDoctorName(session.doctor)}
                        />
                        <CardBody className={classes.dateCardBody}>
                          {session.SessionActivities.map((activity, indexSwitch) =>
                            renderSwitch(activity, indexSwitch),
                          )}
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
                </div>
              );
            }}
          </Query>
        </GridItem>
      </GridContainer>
    </div>
  );
};

PatientHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientHistory);

import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import CommentForm from 'components/Comment/CommentForm';
import { GET_PATIENT_COMMENT } from 'graphql/queries';

const styles = {};

class Comment extends React.Component {
  render() {
    const { id } = this.props;

    return (
      <div>
        <Query query={GET_PATIENT_COMMENT} variables={{ id }} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return 'Loading...';
            if (error) return `Error! ${error.message}`;

            const { comment } = data.getPatient;

            return (
              <div>
                <CommentForm id={id} comment={comment} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Comment.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(Comment);

import React from 'react';
import PropTypes from 'prop-types';
import CreationForm from 'components/Civility/CreationForm';
import { UPSERT_PATIENT } from 'graphql/queries';

class PatientAdd extends React.Component {
  render() {
    const { history, closecb, redirect } = this.props;
    return <CreationForm history={history} mutation={UPSERT_PATIENT} closecb={closecb} redirect={redirect} />;
  }
}

PatientAdd.propTypes = {
  history: PropTypes.object.isRequired,
  closecb: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
};

export default PatientAdd;

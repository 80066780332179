import { defaultFont, infoColor, successColor } from 'assets/jss/app.jsx';
import { cvBluePetrolColor, cvGreenColor } from 'styles/variables';

const dashboardStyle = (theme) => ({
  successText: {
    color: successColor,
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  stats: {
    color: '#999999',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  cardCategory: {
    color: '#999999',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardCategoryTitle: {
    ...defaultFont,
    fontWeight: '900',
    display: 'inline',
    paddingLeft: '10px',
    verticalAlign: 'super',
  },
  cardTitle: {
    color: '#3C4858',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  cardOuter: {
    borderRadius: '16px',
  },
  divider: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  },
  hightlight: {
    padding: '8px 12px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    color: '#2e2e2e',
    backgroundColor: '#fafafa',
    border: '1px solid #e5e5e5',
    borderRadius: '2px',
    overflow: 'auto',
  },
  tabsRoot: {
    // borderBottom: '1px solid #e8e8e8',
    marginBottom: '30px',
  },
  tabsIndicator: {
    backgroundColor: infoColor,
    height: '5px',
  },
  tabRoot: {
    '&:hover': {
      color: infoColor,
      opacity: 1,
    },
    '&$tabSelected': {
      color: infoColor,
      opacity: 1,
      backgroundColor: infoColor,
      transition: '0.2s background-color 0.1s',
    },
  },
  rightButton: {
    float: 'right',
  },
  cvBluePetrolAvatar: {
    backgroundColor: cvBluePetrolColor,
  },
  cvGreenColorAvatar: {
    backgroundColor: cvGreenColor,
  },
  button: {
    marginLeft: '12px',
  },
  controlsContainer: {
    marginTop: '36px',
  },
  extendedIcon: {
    marginRight: '6px',
  },
  disabledSection: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
});

export default dashboardStyle;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { tToolTip } from '../../../i18n/utils';

const toolTipIcon = (tooltipLabel, classes) => {
  if (tooltipLabel)
    return (
      <Tooltip title={tooltipLabel} disableFocusListener classes={{ tooltip: classes.tooltip }}>
        <HelpIcon className={classes.tooltipIcon} />
      </Tooltip>
    );
  return '';
};

export const AsyncSelectInput = (attributes) => {
  const { context, entry, classes, t, error } = attributes;
  const [finalOptions, setFinalOptions] = useState([{ label: 'Chargement...', value: 'loading' }]);
  const [renderable, setRenderable] = useState(false);

  useEffect(() => {
    async function fetchScenarii() {
      const options = await fetch(window.AssetResolver.getAsset(entry.remoteLinks.target)).then((res) => res.json());

      if (process.env.REACT_APP_NODE_ENV !== 'development' && process.env.REACT_APP_NODE_ENV !== 'staging') {
        setFinalOptions(_.filter(options, (opt) => opt.available));
      } else {
        setFinalOptions(options);
      }
      entry.options = options;
      setRenderable(true);
    }

    fetchScenarii();
  }, [renderable]);

  return (
    <div>
      <FormControl className={classes.formControlWrapper} error={error}>
        <InputLabel
          htmlFor={entry.field}
          remote="true"
          inputProps={{
            name: entry.field,
            id: entry.field,
            remote: true,
            'data-remote': 'true',
          }}
        >
          {t(entry.label)}
          {toolTipIcon(tToolTip(entry, t), classes)}
        </InputLabel>
        <Select
          value={context.state[entry.field]}
          onChange={context.handleChange}
          remote="true"
          inputProps={{
            name: entry.field,
            id: entry.field,
            remote: true,
            'data-remote': 'true',
          }}
        >
          {finalOptions &&
            finalOptions.map((attribute) => (
              <MenuItem
                value={attribute.value}
                targetContent={entry.remoteLinks.targetContent}
                label={attribute.label}
                labelFieldName={entry.remoteLinks.labelFieldName}
                key={attribute.value}
                target={attribute.target}
              >
                {attribute.label} - ({attribute.description})
                {(process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging') &&
                attribute.available
                  ? ' [P]'
                  : ''}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const GenericAsyncSelectInput = (attributes) => {
  const { context, entry, classes, t, error } = attributes;
  const [finalOptions, setFinalOptions] = useState([{ label: 'Chargement...', value: 'loading' }]);
  const [renderable, setRenderable] = useState(false);

  useEffect(() => {
    async function fetchList() {
      const options = await fetch(window.AssetResolver.getAsset(entry.remoteList.target)).then((res) => res.json());
      let filteredOptions = [...options];

      // filter out options if "filters" field available:
      if (entry.remoteList.filters) {
        entry.remoteList.filters.forEach((filter) => {
          filteredOptions = options.filter(
            (option) =>
              (filter.excluded == null || option[filter.field] !== filter.excluded) &&
              (filter.included == null || option[filter.field] === filter.included),
          );
        });
      }

      const labeledOptions = filteredOptions.map((item) => ({
        value: item[entry.remoteList.value],
        label: item[entry.remoteList.label],
      }));

      let formattedOptions = _.uniqWith(labeledOptions, _.isEqual);

      // remove empty elements if exist
      if (entry.remoteList.options && entry.remoteList.options.includes('removeEmpty')) {
        formattedOptions = _.compact(
          formattedOptions.map((item) => (item.label && item.label.trim().length ? item : null)),
        );
      }
      // alphabetical order ?
      if (entry.remoteList.options && entry.remoteList.options.includes('alphaOrder')) {
        formattedOptions = _.orderBy(formattedOptions, 'label');
      }

      // adding "none" ?
      formattedOptions =
        entry.remoteList.options && entry.remoteList.options.includes('addNone')
          ? _.concat({ value: 'none', label: 'Common_Parameter_eIndifferent' }, formattedOptions)
          : formattedOptions;

      setFinalOptions(formattedOptions);
      entry.options = formattedOptions;
      setRenderable(true);
    }

    fetchList();
  }, [renderable]);
  return (
    <div>
      <FormControl className={classes.formControlWrapper} error={error}>
        <InputLabel
          htmlFor={entry.field}
          remote="true"
          inputProps={{
            name: entry.field,
            id: entry.field,
            remote: true,
            'data-remote': 'true',
          }}
        >
          {t(entry.label)}
          {toolTipIcon(tToolTip(entry, t), classes)}
        </InputLabel>
        <Select
          value={context.state[entry.field]}
          onChange={context.handleChange}
          remote="true"
          inputProps={{
            name: entry.field,
            id: entry.field,
            remote: true,
            'data-remote': 'true',
          }}
        >
          {finalOptions &&
            finalOptions.map((attribute) => (
              <MenuItem value={attribute.value} key={attribute.value}>
                {t(attribute.label)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

import React from 'react';
import { ReactComponent as NoResults } from './no-results.svg';

const NoResultsImg = () => (
  <div>
    <NoResults style={{ width: '100%', height: '10em', padding: '4px', verticalAlign: 'middle' }} />
  </div>
);

export default NoResultsImg;

import { cvVioletColor } from 'styles/variables';
import { defaultFont, drawerWidth, drawerActivityWidth, transition, container } from 'assets/jss/app.jsx';

const appLayoutStyle = (theme) => ({
  wrapper: {
    overflow: 'hidden',
    position: 'absolute',
    inset: '0',
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    position: 'absolute',
    inset: '0 0 0 262px',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  mainPanelLive: {
    overflow: 'auto',
    position: 'relative',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
    height: '100vh',
  },
  mainPanelActivity: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerActivityWidth}px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    position: 'absolute',
    inset: '0',
    overflow: 'auto',
    marginTop: '70px',
    padding: '15px 15px',
    minHeight: 'calc(100vh - 123px)',
  },
  contentLive: {
    marginTop: '0px',
    padding: '15px 15px',
    minHeight: 'calc(100vh - 123px)',
    position: 'absolute',
    inset: '0',
    top: '70px',
  },
  container,
  leftContent: {
    position: 'relative',
    flex: '0 0 33.33333%',
    maxWidth: '33.33333%',
  },
  logo: {
    position: 'relative',
    padding: '15px 15px',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'rgba(180, 180, 180, 0.3)',
    },
  },
  logoLink: {
    ...defaultFont,
    color: cvVioletColor,
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      // color: "#FFFFFF"
    },
  },
  logoImage: {
    width: 130,
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px',
  },
  img: {
    maxHeight: 45,
    top: '18px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  logoTextFirst: {
    color: '#4F3065',
  },
});

export default appLayoutStyle;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  chip: {
    margin: theme.spacing.unit,
  },
  title: {
    marginBottom: theme.spacing.unit,
  },
});

class Patient extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, pathologies, t } = this.props;

    return (
      <div>
        {!_.isEmpty(pathologies) && (
          <div>
            <Typography color="textSecondary" className={classes.title} component="p">
              {t('VPatient_Conditions')}
            </Typography>
            {pathologies.map((pathology) => (
              <Chip className={classes.chip} label={t(JSON.parse(pathology.content).name)} key={pathology.slug} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

Patient.propTypes = {
  classes: PropTypes.object.isRequired,
  pathologies: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(Patient));

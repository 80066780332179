import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import NoResultsImg from 'assets/img/no-results';
import { withTranslation } from 'react-i18next';

class NoHistory extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <NoResultsImg />
        <Typography paragraph>
          <p style={{ textAlign: 'center' }}>{t('VHistory_NoHistory')}</p>
        </Typography>
      </div>
    );
  }
}

NoHistory.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NoHistory);

/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Mutation } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import { withTranslation } from 'react-i18next';
import pathologies from './pathologies';
import { GET_PATIENT_PATHOLOGIES } from '../../graphql/queries';
import { logger } from '../../logger';

const styles = (theme) => ({
  date: {
    marginTop: theme.spacing.unit * 2,
  },
  divider: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    minWidth: 100,
    maxWidth: 200,
  },
  heading: {
    marginBottom: theme.spacing.unit * 2,
  },
  submitButton: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
  },
  pahtologyPanel: {
    width: '300px',
  },
});

class PathologyForm extends Component {
  constructor(props) {
    super(props);
    let pathology;
    if (props.index) {
      pathology = props.pathologies[parseInt(props.index, 10)];
    } else {
      pathology = null;
    }

    if (pathology) {
      const content = JSON.parse(pathology.content);
      this.state = {
        pathologie: {
          form: pathology.form,
          name: content.name,
          slug: pathology.slug,
        },
        pathology: content,
      };
    } else {
      this.state = {
        pathologie: null,
        pathology: {},
      };
    }
  }

  handleClick = (e) => {
    this.setState({
      pathologie: {
        name: e.currentTarget.dataset.pathologie,
        slug: e.currentTarget.dataset.slug,
        iscustom: e.currentTarget.dataset.iscustom,
        form: e.currentTarget.dataset.form,
      },
    });
    setTimeout(() => {
      const newPathology = this.state.pathologie;

      if (this.state.pathologie.iscustom) {
        newPathology.type = this.state.pathology.type;
      }

      newPathology.form = this.state.pathologie.form;

      this.setState({
        pathology: newPathology,
      });
    }, 0);
  };

  handleChange = (pathology) => {
    const newPathology = pathology;

    if (this.state.pathologie.iscustom) {
      newPathology.type = this.state.pathology.type;
    }

    newPathology.form = this.state.pathologie.form;

    this.setState({
      pathology: newPathology,
    });
  };

  handleCustomTypeChange = (e) => {
    const newPathology = this.state.pathology;

    if (this.state.pathologie.iscustom) {
      newPathology.type = e.target.value;
    }

    this.setState({
      pathology: newPathology,
    });
  };

  render() {
    const { classes, mutation, id, onClose, t } = this.props;
    return (
      <Mutation mutation={mutation} refetchQueries={() => [{ query: GET_PATIENT_PATHOLOGIES, variables: { id } }]}>
        {(upsertPatientPathology) => (
          <div className={classNames(classes.pahtologyPanel)}>
            {this.props.index ? (
              <React.Fragment />
            ) : (
              <React.Fragment>
                {Object.keys(pathologies).map((key) => (
                  <ExpansionPanel key={key}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{t(key)}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={8}>
                        <Grid item xs={12}>
                          {pathologies[key].map((pathologie) => (
                            <div key={pathologie.slug}>
                              <Button
                                key={pathologie.slug}
                                color="primary"
                                data-pathologie={pathologie.name}
                                data-slug={pathologie.slug}
                                data-iscustom={pathologie.isCustom}
                                data-form={pathologie.form}
                                onClick={this.handleClick}
                              >
                                {t(pathologie.name)}
                              </Button>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </React.Fragment>
            )}

            {this.props.index ? <React.Fragment /> : <Divider className={classes.divider} />}

            {this.state.pathologie ? (
              <ValidatorForm
                onSubmit={(e) => {
                  e.preventDefault();
                  const _id = id || null;
                  const pathology = {
                    content: JSON.stringify(_.omit(this.state.pathology, ['form', 'slug'])),
                    slug: this.state.pathology.slug,
                    form: this.state.pathology.form,
                  };

                  upsertPatientPathology({ variables: { id: _id, condition: pathology } }).then(() => {
                    onClose();
                  });
                }}
                onError={(errors) => logger.error(errors)}
              >
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <Typography variant="title" gutterBottom>
                      {t(this.state.pathologie.name)}
                    </Typography>
                  </Grid>
                </Grid>

                {/* this.state.pathologie.iscustom === 'true' && (
                  <Grid container spacing={8}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="standard-dense"
                        label={t('VPatient_ConditionType')}
                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        name="type"
                        onChange={this.handleCustomTypeChange}
                      />
                    </Grid>
                  </Grid>
                ) */}

                <Button type="submit" variant="outlined" color="primary" className={classes.submitButton}>
                  {t('Common_Save')}
                </Button>
              </ValidatorForm>
            ) : (
              <div>{t('VPatient_NoConditionSummary')}</div>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

PathologyForm.propTypes = {
  id: PropTypes.any.isRequired,
  index: PropTypes.number,
  pathologies: PropTypes.any.isRequired,
  mutation: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};

PathologyForm.defaultProps = {
  index: 0,
};

export default withTranslation()(withStyles(styles)(PathologyForm));

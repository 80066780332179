import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import withStyles from '@material-ui/core/styles/withStyles';
import routes from 'routes/activityPatient';
import classNames from 'classnames';
import patientLayoutStyle from 'assets/jss/layouts/patientLayoutStyle.jsx';
import { ApolloProvider } from 'react-apollo';
import { getApolloClient } from 'utils/apolloClientFactory';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route exact path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

class Patient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderable: false,
    };
    getApolloClient(false).then((client) => {
      this.client = client;
      this.setState({ renderable: true });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      this.state.renderable && (
        <ApolloProvider client={this.client}>
          <div className={classNames(classes.wrapper, classes.mainPanel)} >
              {switchRoutes}</div>
        </ApolloProvider>
      )
    );
  }
}

Patient.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(patientLayoutStyle)(Patient);

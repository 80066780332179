export const tReducedLabel = (field, t) => {
  let reduced = '';
  if (field.label_reduced && t(field.label_reduced) !== field.label_reduced) {
    reduced = t(field.label_reduced);
  } else if (t(`${field.label}§Reduced`) !== `${field.label}§Reduced`) {
    reduced = t(`${field.label}§Reduced`);
  } else {
    reduced = t(field.label);
  }
  return reduced;
};

export const tShortLabel = (field, t) => {
  let short = '';
  if (field.label_short) {
    short = t(field.label_short);
  } else if (t(`${field.label}§Short`) !== `${field.label}§Short`) {
    short = t(`${field.label}§Short`);
  } else {
    short = tReducedLabel(field, t);
  }
  return short;
};

export const tToolTip = (field, t) => {
  let toolTip = '';
  if (field.info) {
    toolTip = t(field.info);
  } else if (t(`${field.label}§ToolTip`) !== `${field.label}§ToolTip`) {
    toolTip = t(`${field.label}§ToolTip`);
  }
  return toolTip;
};

/* eslint-disable class-methods-use-this */
import { getCurrentUser } from 'utils';

export function checkPermission(license, flag) {
  if (!license.flags) {
    return false;
  }

  let isAllowed = false;
  for (let i = 0; i < license.flags.length; i += 1) {
    if (window.featureFlagsMatchingTable.matching_table[license.flags[i]]) {
      for (let j = 0; j < window.featureFlagsMatchingTable.matching_table[license.flags[i]].length; j += 1) {
        if (window.featureFlagsMatchingTable.matching_table[license.flags[i]][j] === flag) {
          isAllowed = true;
        }
      }
    }
  }
  return isAllowed;
}

export function checkCurrentUserPermission(flag) {
  const currentUser = getCurrentUser();
  // cannot check permission on anonymous user
  if (!currentUser || !currentUser.license) return false;
  return checkPermission(currentUser.license, flag);
}

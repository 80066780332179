import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class SimpleBasicDialog extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { content, title, ...other } = this.props;
    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-basic-dialog-title" {...other} scroll="paper">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }
}

SimpleBasicDialog.propTypes = {
  content: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

SimpleBasicDialog.defaultProps = {
  onClose: () => {},
};

export default SimpleBasicDialog;

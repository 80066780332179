import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

const styles = () => ({});

class Pathology extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, pathology, t } = this.props;
    return (
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {t(pathology.name)}
        </Typography>
        <Typography component="p">{pathology.comment}</Typography>
      </div>
    );
  }
}

Pathology.propTypes = {
  classes: PropTypes.object.isRequired,
  pathology: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(Pathology));

/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withTranslation } from 'react-i18next';
import { GET_PATIENT_ACCOUNT, UPSERT_PATIENT_ACCOUNT } from '../../graphql/queries';
import { logger } from '../../logger';

const styles = (theme) => ({
  root: {},
  submitButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

class AccountForm extends React.Component {
  constructor(props) {
    super(props);

    if (props.account) {
      this.state = {
        account: props.account,
      };
    } else {
      this.state = {
        account: '',
      };
    }

    this.state.saved = true;
  }

  handleChange = (event) => {
    this.setState({ saved: false });
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSave = () => {
    this.setState({ saved: true });
  };

  render() {
    const { classes, id, t } = this.props;

    return (
      <div>
        <div>
          <Mutation
            mutation={UPSERT_PATIENT_ACCOUNT}
            refetchQueries={() => [{ query: GET_PATIENT_ACCOUNT, variables: { id } }]}
          >
            {(upsertPatientAccount) => (
              <div>
                <ValidatorForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    const _id = id || null;
                    upsertPatientAccount({ variables: { id: _id, account: this.state.account } }).then(() => {
                      this.handleSave();
                    });
                  }}
                  onError={(errors) => logger.error(errors)}
                  className={classes.form}
                >
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <TextField
                        id="account"
                        label={t('VPatient_BetweenSessionsAccount')}
                        fullWidth
                        multiline
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                        name="account"
                        value={this.state.account}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    disabled={this.state.saved}
                    variant="outlined"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {t('Common_Save')}
                  </Button>
                </ValidatorForm>
              </div>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

AccountForm.propTypes = {
  classes: PropTypes.object.isRequired,
  account: PropTypes.string,
  id: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

AccountForm.defaultProps = {
  account: '',
};

export default withTranslation()(withStyles(styles)(AccountForm));

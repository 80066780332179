import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GridThumbnails from 'activities/GridThumbnails.js';
import { withTranslation } from 'react-i18next';
import { getCurrentPatient, ensureAccessToken } from 'utils';
import { logger } from 'logger';
import activities from '../../activities/list-activities.json';
import dashboardStyle from '../../assets/jss/views/dashboardStyle';

class Activities extends React.Component {
  state = {
    value: 0,
  };

  componentDidMount() {
    const currentPatient = getCurrentPatient();

    const sessionID = currentPatient.code; // For convenience the sessionId used is the generated patient code

    if (sessionID) {
      // this.usernameAlreadySelected = true;
      window.comSocket.auth = { sessionID };
      window.comSocket.connect();
      window.activitySocket.auth = { sessionID };
      window.activitySocket.connect();

      window.comSocket.on('connect', () => {
        console.log('communication socket connected');
      });
      window.activitySocket.on('connect', () => {
        console.log('activity socket connected');
      });

      // window.comSocket.on('CustomPong', () => {
      //   window.comSocket.emit('CustomPong', sessionID);
      // });
      // setTimeout(() => {
      //   window.comSocket.disconnect();
      //   setTimeout(() => {
      //     window.comSocket.connect();
      //   }, '5000');
      // }, '5000');
    } else {
      console.log('Cannot connect, no session code');
    }

    if (!window.comSocket) {
      throw new Error('missing window.comSocket');
    }

    ensureAccessToken().then(() => {
      logger.debug('ensureAccessToken');
      window.comSocket.emit(
        'startSession',
        {
          id: currentPatient.id,
          token: window.keycloak.token,
        },
        // Callback when message is ack by the server
        () => {},
      );
    });
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const activitiesList = activities;
    const { classes, t } = this.props;
    return (
      <div>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          variant="fullWidth"
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          {Object.keys(activitiesList.categories).map((category) => (
            <Tab
              label={t(activitiesList.categories[category].label)}
              key={category}
              classes={{ root: classes.tabRoot }}
            />
          ))}
        </Tabs>

        {Object.keys(activitiesList.categories).map(
          (category, index) =>
            this.state.value === index && (
              <GridThumbnails
                category={category}
                key={category}
                activities={activitiesList.categories[category].activities}
                color={activitiesList.categories[category].color}
              />
            ),
        )}
      </div>
    );
  }
}

Activities.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(Activities));

import { ActivityWrapperView, ActivityWrapperLiveView } from 'activities';

const activitiesRoutes = [
  {
    path: '/activities/:category/:slug',
    sidebarName: 'Activity',
    navbarName: 'Activity',
    component: ActivityWrapperView,
  },
  {
    path: '/activities/:category/:slug/live',
    sidebarName: 'Activity',
    navbarName: 'Activity',
    component: ActivityWrapperLiveView,
  },
];

export default activitiesRoutes;

import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import NoResultsImg from 'assets/img/no-results';

class NoResults extends Component {
  render() {
    return (
      <div>
        <NoResultsImg />
        <Typography paragraph>
          <p style={{ textAlign: 'center' }}>Aucun résultat d&apos;activité...</p>
        </Typography>
      </div>
    );
  }
}

export default NoResults;

const cardThumbnailStyle = {
  // cardThumbnailHeader: {
  //   // padding: "15px",
  //   // justifyContent: "space-between",
  //   // display: "flex"
  //   // margin: "auto",
  //   // borderRadius: "50%"
  //   display: "flex",
  //   justifyContent: "center",
  // },
  cardThumbnailTitle: {
    color: '#3C4858',
    marginTop: '10px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  cardThumbnailAvatar: {
    width: '80px',
    height: '80px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  img: {
    width: '55px',
    height: '55px',
  },
};

export default cardThumbnailStyle;

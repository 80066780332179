import PatientView from 'views/Patient/Patient';
import { ActivityWrapperPatientView } from 'activities';

const mainRoutes = [
  {
    path: process.env.REACT_APP_NODE_ENV === 'development' ? '/seance' : '/',
    // exact: true,
    sidebarName: 'Joindre une activité',
    navbarName: 'Joindre une activité',
    icon: 'content_paste',
    component: PatientView,
  },
  {
    path: '/activity/',
    navbarName: 'Activité',
    icon: 'content_paste',
    component: ActivityWrapperPatientView,
  },
  // { redirect: true, to: '/patient' },
];

export default mainRoutes;

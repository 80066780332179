/* eslint-disable new-cap */
/* eslint-disable class-methods-use-this */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingScreen from 'components/Activity/LoadingScreen';
import _ from 'lodash';
import dashboardStyle from '../../assets/jss/views/dashboardStyle';
import RuntimeToolsOverlay from '../../components/Activity/RuntimeToolsOverlay';
import { logger } from '../../logger';
import AssetResolver from '../../utils/AssetResolver';
import { checkCurrentUserPermission } from '../../utils/permissionChecker';

class Patient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activityCompleted: false,
      doctorDisconnected: false,
      renderable: false,
      configuration: _.get(this, 'props.location.state.activityChosen.configuration')
        ? JSON.parse(this.props.location.state.activityChosen.configuration)
        : '',
      // communicationChannel: this.props.location.state.communicationChannel,
      socket: window.activitySocket,
      activityChannel: _.get(this, 'props.location.state.activityChosen.channel') || '',
      slug: _.get(this, 'props.location.state.activityChosen.slug') || '',
      activityData: _.get(this, 'props.location.state.activityChosen') || {},
      permacode: this.props.location.state.permacode || '',
      t: props.t,
      runtimeState: { totalTime: 0 },
    };

    this.setUpNewSocket(!this.state.activityData.programmed)

    this.screenMsg = {
      loading: 'votre activité est en cours de chargement',
      waiting: 'votre activité va démarrer',
    };

    this.activityRunning = false;
    this.activityStartTime = null;
    this.pauseTime = 0;
    this.cumulatedPauseTime = 0;
  }

  componentDidMount() {
    this.setProgrammedActivity();
  }

  setProgrammedActivity() {
    this.setState({
      activityCompleted: false,
      doctorDisconnected: false,
      renderable: false,
    });
    const activityProm = import(`@covirtua-activities/${this.state.slug}/build/index.js`).then((LazyActivity) => {
      this.setState({
        activity: LazyActivity.default,
      });
    });

    const maincomProm = import(`@covirtua-activities/${this.state.slug}/build/common/mainCommands.js`).then(
      (mainCom) => {
        this.setState({
          mainCommands: new mainCom.default(this.state.socket),
        });
      },
    );

    const comProm = import(`@covirtua-activities/${this.state.slug}/build/commands.js`).then((com) => {
      this.setState({
        commands: new com.default(this.state.socket),
      });
    });

    Promise.all([activityProm, maincomProm, comProm])
      .then(() => {})
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        console.log('emit joined');
        this.state.socket.emit('joinActivityChannel', { channelToJoin: this.state.activityChannel });
        window.history.pushState({}, null, this.state.slug);
        this.setState({ renderable: true });
      });
  }

  setUpNewSocket(bSeance) {
    if (this.state.socket) {
      this.state.socket.removeAllListeners();
    }
    // this.state.socket = openSocket(`${window.ACTIVITY_SERVER_URL}/activity`, { autoConnect: false });
    // const sessionID = this.props.location.state.code;
    // this.state.socket.auth = { sessionID };
    //  console.log(`session ID ${sessionID}`);
    // this.state.socket.connect();
    // logger.debug('SETTING UP NEW SOCKET');
    //  console.log(`Code ${this.props.location.state.code}`);
    //  logger.debug(this.state.socket);
    //  logger.debug(this.state.communicationChannel);

    if (bSeance) {
      this.state.socket.once('joinedActivityChannel', (data) => {
        logger.debug(`JOINED ACTIVITY CHANNEL ${this.state.activityChannel}`);
        // need to get the users info from the context so that it can be sent to the activity patient view
        // users { doctorId, patientId }
        this.setState({
          configuration: JSON.parse(data.configuration),
        });
        const actiProm = import(`@covirtua-activities/${this.state.slug}/build/index.js`).then((lazyActivity) => {
          this.setState({
            activity: lazyActivity.default,
          });
        });

        const maincomProm = import(`@covirtua-activities/${this.state.slug}/build/common/mainCommands.js`).then(
          (mainCom) => {
            this.setState({
              mainCommands: new mainCom.default(this.state.socket),
            });
          },
        );

        const comProm = import(`@covirtua-activities/${this.state.slug}/build/commands.js`).then((com) => {
          this.setState({
            commands: new com.default(this.state.socket),
          });
        });

        Promise.all([actiProm, maincomProm, comProm])
          .then(() => { })
          .catch((error) => {
            logger.error(error);
          })
          .finally(() => {
            this.setState({
              renderable: true,
            });
            this.sendRatio();
          });
      });      
      /*
    this.state.socket.on('syncTime', (msg) => {
      const temp = this.state.runtimeState;
      temp.totalTime = msg.serverTime;
      this.setState({ runtimeState: temp });
    });

      this.state.socket.on('initActivity', (context) => {
        logger.debug('INIT ACTIVITY');
        this.setState({
          configuration: context.configuration,
          renderable: true,
        });
      });
  */
      window.comSocket.on('switchActivity', (context) => {
        console.log(`SWITCHING ACTIVITY TO ${context.slug}`);
        console.log(`ACTIVITY CHANNEL ${context.activityChannel}`);

        this.setState({
          activityCompleted: false,
          doctorDisconnected: false,
          renderable: false,
          slug: context.slug,
          screen: 'loading',
          activityChannel: context.activityChannel,
          runtimeState: { totalTime: 0 },
        });
        const actiProm = import(`@covirtua-activities/${this.state.slug}/build/index.js`).then((lazyActivity) => {
          this.setState({
            activity: lazyActivity.default,
          });
          console.log("loaded activity:", this.state.activity);
        });

        const maincomProm = import(`@covirtua-activities/${this.state.slug}/build/common/mainCommands.js`).then(
          (mainCom) => {
            this.setState({
              mainCommands: new mainCom.default(this.state.socket),
            });
          },
        );

        const comProm = import(`@covirtua-activities/${this.state.slug}/build/commands.js`).then((com) => {
          this.setState({
            commands: new com.default(this.state.socket),
          });
        });

        Promise.all([actiProm, maincomProm, comProm])
          .then(() => { })
          .catch((error) => {
            logger.error(error);
          })
          .finally(() => {
            logger.debug(`JOINING ACTIVITY CHANNEL ${this.state.activityChannel}`);
            // The emitting socket will be added to the activity room
            console.log(`JOINING ACTIVITY CHANNEL ${this.state.activityChannel}`);
            console.log('emit joined');
            this.state.socket.emit('joinActivityChannel', { channelToJoin: this.state.activityChannel });
            window.history.pushState({}, null, this.state.slug);
          });
      });

      if (this.isSocketDefinedInRouter()) {
        // this.state.socket.on('command', (data) => {
        //   switch(data.body.name){
        //     case 'enableValidate':
        //       this.setState({
        //         isValidateButtonEnabled: data.body.enableValidate,
        //       });
        //       break;
        //     default:;
        //   }
        // });

       /* this.state.socket.on('syncTime', (msg) => {
          const temp = this.state.runtimeState;
          temp.totalTime = msg.serverTime;
          this.setState({ runtimeState: temp });
        }); */

        this.state.socket.on('completeActivity', () => {
          this.setState({ activityCompleted: true, doctorDisconnected: true, activity: null });

          this.setUpNewSocket(true);
        });

        this.state.socket.on('pauseResumeActivity', (data) => {
          const newPauseState = data.body.value;
          if (newPauseState) {
            this.pauseTime = Date.now();
          } else if (this.activityStartTime === null) {// This is the first "pauseResume", hence the activity start
              this.activityStartTime = Date.now();
              this.activityRunning = true;
              this.updateTime();
            }
            else { // This is a "resume" event, let's increment the cumulated pause time
              const pauseDuration = (Date.now() - this.pauseTime)
              this.pauseTime = 0;
              this.cumulatedPauseTime += pauseDuration
            }
        });

        this.state.socket.on('disconnected', (data) => {
          this.activityRunning = false;
          this.setState({
            [data]: true,
            activity: null,
          });
          logger.debug('DOCTOR DISCONNECTED');
          //   this.state.socket.disconnect();
          this.setUpNewSocket(true);
        });
      }
    } else {

      this.state.socket.on('completeActivity', () => {
        this.setState({ activityCompleted: true, doctorDisconnected: true, activity: null });
        this.activityRunning = false;
        // trying to go back to main menu:
        setTimeout((window.location.href = '/program'), 500);
      });

      this.state.socket.on('quitAutonomousActivity', () => {
        this.activityRunning = false;
        this.setState({ activityCompleted: false, doctorDisconnected: true, activity: null });
        // trying to go back to main menu:
        setTimeout((window.location.href = '/program'), 500);
      });

      this.state.socket.on('startAutonomousActivity', () => {
        this.activityStartTime = Date.now();
        this.activityRunning = true;
        this.updateTime();
      });
    }

    /* // window.comSocket.emit('joinCommunicationChannel', { channelToJoin: this.state.communicationChannel });
    window.comSocket.on('userDisconnected', () => {
      // When doctor is disconnected, we go back to session code
      window.comSocket.disconnect();
      window.activitySocket.disconnect();
      window.location.href = window.config.services.front.patient.site.url;
    }); */
  }

  updateTime() {
    if (!this.pauseTime) { // we are not currently pausing the activity 
      const newTotalTime = Date.now() - this.activityStartTime - this.cumulatedPauseTime;
      if ((newTotalTime - this.state.runtimeState.totalTime) > 1000) {
        const tempRTS = this.state.runtimeState;
        tempRTS.totalTime = newTotalTime;
        this.setState({ runtimeState: tempRTS });
      }
    }
    if (this.activityRunning) // force update while the activity is still running
      setTimeout(() => { this.updateTime() }, 200);
  }

  sendRatio() {
    window.comSocket.emit('patientRatio', { ratio: window.innerWidth / window.innerHeight });
  }

  isSocketDefinedInRouter = () => this.props.location.socket;

  handleClick = (event) => {
    if (event.currentTarget.dataset.action === 'validate') {
      this.state.commands.validate({});
    }

    if (event.currentTarget.dataset.action === 'start') {
      this.state.mainCommands.pauseResumeActivity({ value: false });
      // this.setState({
      //   isActivityStarted: true,
      // });
    }
  };

  proxyRenderActivity(users) {
    return this.state.activity ? (
      <this.state.activity
        config={this.state.configuration}
        assetPath="/activitiesAssets/_common/"
        socket={this.state.socket}
        isDoctor={false}
        users={users}
        assetResolver={AssetResolver}
        t={this.state.t}
        programmed={this.state.activityData.programmed}
        permacode={this.state.permacode}
        runtimeState={this.state.runtimeState}
        developerConsoleLogs={checkCurrentUserPermission('feature_activityConsoleLogs')}
      />
    ) : (
      <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
        <LoadingScreen />
      </div>
    );
  }

  render() {
    let core;
    const users = null; // needs to get that info from context of channel after a join

    if (!this.props.location.state || this.state.activityCompleted || this.state.doctorDisconnected) {
      if (this.state.activityCompleted && this.state.activityData.programmed) {
        core = (
          <Redirect
            to={{
              pathname:
                process.env.REACT_APP_NODE_ENV === 'development'
                  ? `/interseance/${this.state.permacode}`
                  : `${window.config.services.front.patient.remote.url}/${this.state.permacode}`,
            }}
          />
        );
      } else {
        core = (
          <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
            <LoadingScreen />
          </div>
        );
      }
    } else {
      if (!this.state.renderable) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
            {this.screenMsg[this.state.screen]}
            <LoadingScreen />
          </div>
        );
      }
      core = (
        <div
          style={{
            backgroundColor: this.state.activity ? 'black' : '',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <RuntimeToolsOverlay socket={this.state.socket} isDoctor={false}>
            {this.proxyRenderActivity(users)}
          </RuntimeToolsOverlay>
        </div>
      );
    }

    return <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'stretch' }}>{core}</div>;
  }
}

Patient.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(withRouter(Patient)));

/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { Mutation } from 'react-apollo';
import ReactDOM from 'react-dom';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { GET_PATIENT_CONTACTS } from '../../graphql/queries';
import { logger } from '../../logger';

const omit = ['anchorEl', 'civilityLabelWidth', '__typename'];

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  form: {
    width: 300,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    minWidth: '100%',
    maxWidth: 200,
  },
  formLabel: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  },
  birthday: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  studies: {
    width: 180,
  },
  heading: {
    marginBottom: theme.spacing.unit * 2,
  },
  moreButton: {
    marginTop: 15,
  },
  submitButton: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
  },
});

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    const contact = props.contacts[parseInt(props.index, 10)];

    if (contact) {
      this.state = contact;
    } else {
      this.state = {
        anchorEl: null,
        civilityLabelWidth: 0,
        firstname: '',
        lastname: '',
        relation: '',
        civility: 'mr',
        email: '',
        phone: '',
        mobile: '',
        address: '',
      };
    }
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({
      civilityLabelWidth: ReactDOM.findDOMNode(this.civilityLabelRef).offsetWidth,
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, mutation, id, onClose, t } = this.props;

    return (
      <Mutation mutation={mutation} refetchQueries={() => [{ query: GET_PATIENT_CONTACTS, variables: { id } }]}>
        {(upsertPatientContacts) => (
          <div>
            <ValidatorForm
              onSubmit={(e) => {
                e.preventDefault();
                const state = _.omit(this.state, omit);
                const _id = id || null;
                upsertPatientContacts({ variables: { id: _id, contact: state } }).then(() => {
                  onClose();
                });
              }}
              onError={(errors) => logger.error(errors)}
              className={classes.form}
            >
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    name="firstname"
                    value={this.state.firstname}
                    id="firstname"
                    label={`${t('VPatient_IdentityFirstName')}*`}
                    validators={['required']}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    name="lastname"
                    value={this.state.lastname}
                    id="lastname"
                    label={`${t('VPatient_IdentityLastName')}*`}
                    validators={['required']}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      ref={(ref) => {
                        this.civilityLabelRef = ReactDOM.findDOMNode(ref);
                      }}
                      htmlFor="civility"
                    >
                      {t('VPatient_Identity')}
                    </InputLabel>
                    <Select
                      autoWidth
                      name="civility"
                      value={this.state.civility}
                      onChange={this.handleChange}
                      input={<OutlinedInput labelWidth={this.state.civilityLabelWidth} name="civility" id="civility" />}
                    >
                      <MenuItem value="mr">{t('VPatient_Identity_eMr')}</MenuItem>
                      <MenuItem value="mmr">{t('VPatient_Identity_eMme')}</MenuItem>
                      <MenuItem value="mmr">{t('VPatient_Identity_eMlle')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="relation"
                    name="relation"
                    value={this.state.relation}
                    label={t('VPatient_IdentityContactInfoRelationship')}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>

              <FormLabel component="legend" className={classes.formLabel}>
                {t('VPatient_IdentityContactInfo')}
              </FormLabel>

              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <PhoneIphone />
                </Grid>
                <Grid item>
                  <TextValidator
                    id="mobile"
                    name="mobile"
                    label={t('VPatient_IdentityContactInfoCell')}
                    type="tel"
                    value={this.state.mobile}
                    onChange={this.handleChange}
                    // validators={['required', 'isNumber']}
                    // errorMessages={['this field is required', 'numéro incorrecte']}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Phone />
                </Grid>
                <Grid item>
                  <TextValidator
                    id="phone"
                    label={t('VPatient_IdentityContactInfoLandline')}
                    name="phone"
                    onChange={this.handleChange}
                    value={this.state.phone}
                    type="tel"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Email />
                </Grid>
                <Grid item>
                  <TextValidator
                    id="email"
                    label={t('VPatient_IdentityContactInfoMail')}
                    onChange={this.handleChange}
                    name="email"
                    type="email"
                    value={this.state.email}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <TextField
                    id="address"
                    name="address"
                    label={t('VPatient_IdentityContactInfoAdress')}
                    fullWidth
                    multiline
                    value={this.state.address}
                    onChange={this.handleChange}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Button type="submit" variant="outlined" color="primary" className={classes.submitButton}>
                {t('Common_Save')}
              </Button>
            </ValidatorForm>
          </div>
        )}
      </Mutation>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  id: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(ContactForm));

const cvBluePetrolColor = '#96B0BD';
const cvBrownColor = '#C8B79F';
const cvGreenColor = '#93D4C1';
const cvLightGreenColor = '#DAF222';
const cvVioletColor = '#9D75BC';
const cvOrangeColor = '#F26D22';
const cvCyanColor = '#16F2EE';
const cvGreyColor = '#707073';

export const getColor = (colorName) => {
  switch (colorName) {
    case 'cvBluePetrolColor':
      return cvBluePetrolColor;
    case 'cvBrownColor':
      return cvBrownColor;
    case 'cvGreenColor':
      return cvGreenColor;
    case 'cvLightGreenColor':
      return cvLightGreenColor;
    case 'cvVioletColor':
      return cvVioletColor;
    case 'cvOrangeColor':
      return cvOrangeColor;
    case 'cvCyanColor':
      return cvCyanColor;
    case 'cvGreyColor':
      return cvGreyColor;
    default:
  }
  return 0;
};

export {
  cvBluePetrolColor,
  cvBrownColor,
  cvGreenColor,
  cvLightGreenColor,
  cvVioletColor,
  cvOrangeColor,
  cvCyanColor,
  cvGreyColor,
};

export default {
  VPatient_ConditionNeuro: [
    {
      name: 'VPatient_ConditionNeuroStroke',
      slug: 'avc',
      form: 'Type1',
    },
    {
      name: 'VPatient_ConditionNeuroTrauma',
      slug: 'trauma-cranien',
      form: 'Type2',
    },
    {
      name: 'VPatient_ConditionNeuroTumor',
      slug: 'tumeur-cerebrale',
      form: 'Type3',
    },
    {
      name: 'VPatient_ConditionNeuroOther',
      slug: 'autre-pathologie-neurologique',
      form: 'Type3',
      isCustom: true,
    },
  ],
  VPatient_ConditionNeurodegenerative: [
    {
      name: 'VPatient_ConditionNeurodegenerativeAlzheimer',
      slug: 'alzheimer',
      form: 'Type4',
    },
    {
      name: 'VPatient_ConditionNeurodegenerativeParkinson',
      slug: 'parkinson',
      form: 'Type5',
    },
    {
      name: 'VPatient_ConditionNeurodegenerativeSEP',
      slug: 'sep',
      form: 'Type6',
    },
    {
      name: 'VPatient_ConditionNeurodegenerativeOther',
      slug: 'autre-pathologie-neurodegenerative',
      form: 'Type8',
      isCustom: true,
    },
  ],
  VPatient_ConditionDevelopmental: [
    {
      name: 'VPatient_ConditionDevelopmentalAutism',
      slug: 'autisme',
      form: 'Type7',
    },
    {
      name: 'VPatient_ConditionDevelopmentalDyscalculia',
      slug: 'dyscalculie',
      form: 'Type7',
    },
    {
      name: 'VPatient_ConditionDevelopmentalDysgraphia',
      slug: 'dysgraphie',
      form: 'Type7',
    },
    {
      name: 'VPatient_ConditionDevelopmentalDyslexia',
      slug: 'dyslexie',
      form: 'Type7',
    },
    {
      name: 'VPatient_ConditionDevelopmentalDysphasia',
      slug: 'dysphasie',
      form: 'Type7',
    },
    {
      name: 'VPatient_ConditionDevelopmentalDyspraxia',
      slug: 'dyspraxie',
      form: 'Type7',
    },
    {
      name: 'VPatient_ConditionDevelopmentalAHDH',
      slug: 'tdah',
      form: 'Type7',
    },
  ],
  VPatient_ConditionOther: [
    {
      name: 'VPatient_ConditionOtherGenetic',
      slug: 'maladie-genetique-rare',
      form: 'Type8',
      isCustom: true,
    },
    {
      name: 'VPatient_ConditionOtherOther',
      slug: 'autre-pathologie',
      form: 'Type7',
      isCustom: true,
    },
  ],
};

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query } from 'react-apollo';
import CivilitiesForm from 'components/Civility/CivilitiesForm';
import { GET_PATIENT_CIVILITIES, UPSERT_PATIENT_NO_CONDITION } from 'graphql/queries';

const styles = () => ({});

class Civilities extends React.Component {
  render() {
    const { id } = this.props;

    return (
      <div>
        <Query query={GET_PATIENT_CIVILITIES} variables={{ id }} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </div>
              );
            if (error) return `Error! ${error.message}`;

            return (
              <div>
                <CivilitiesForm civilities={data.getPatient} id={id} mutation={UPSERT_PATIENT_NO_CONDITION} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Civilities.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(Civilities);

/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import { withTranslation } from 'react-i18next';
import dashboardStyle from 'assets/jss/views/dashboardStyle.jsx';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import { getApolloClient } from '../../../utils/apolloClientFactory';
import { GET_ACVITY_EVENTS } from '../../../graphql/queries';
import { logger } from '../../../logger';

const styles = (theme) => ({
  ...dashboardStyle,
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class EventReviewer extends Component {
  constructor(props) {
    super(props);
    momentDurationFormat(moment);
    this.state = {
      activity: props.activity,
      events: [],
      renderable: false,
      descriptionExpanded: [],
      validityDate: 1648808158076, // 01/04/2022
    };
  }

  componentDidMount() {
    getApolloClient().then((client) => {
      client
        .query({
          query: GET_ACVITY_EVENTS,
          variables: { channel: this.state.activity.channel },
        })
        .then((response) => {
          this.setState({
            events: _.filter(
              _.map(response.data.getAllEvents, (fullEvent) => JSON.parse(fullEvent)),
              (event) => moment(event.moment).isAfter(this.state.validityDate),
            ),
          });
        })
        .catch((error) => {
          logger.debug(error);
        })
        .finally(() => {
          this.setState({
            renderable: true,
          });
        });
    });
  }

  handleExpandClick = (index) => {
    const tmpDescrpExp = this.state.descriptionExpanded;
    tmpDescrpExp[index] = !tmpDescrpExp[index];
    this.setState({
      descriptionExpanded: tmpDescrpExp,
    });
  };

  render() {
    const { events, renderable } = this.state;
    const { t, classes } = this.props;

    if (!renderable)
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
          <CircularProgress />
        </div>
      );

    if (events && events.length > 0) {
      return (
        <div>
          <Typography variant="body1" gutterBottom>
            Seuls les enregistrements au-delà de cette date sont disponibles :{' '}
            {moment(this.state.validityDate).format('DD/MM/YY hh:mm:ss')}
          </Typography>
          {events.map((fullEvent, index) => (
            <Card>
              <CardActions
                disableActionSpacing
                onClick={() => {
                  this.handleExpandClick(index);
                }}
              >
                <Typography variant="body2" key={fullEvent.event.name} gutterBottom>
                  {moment(fullEvent.moment).format('DD/MM/YY hh:mm:ss')} - {fullEvent.event.name} -{' '}
                  {moment.duration(fullEvent.chrono, 'milliseconds').format('mm:ss', { trim: false })}
                </Typography>

                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: this.state.descriptionExpanded[index],
                  })}
                  aria-expanded={this.state.descriptionExpanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={this.state.descriptionExpanded[index]} timeout="auto" unmountOnExit>
                <CardBody>
                  <Typography variant="subtitle1" gutterBottom>
                    {JSON.stringify(fullEvent.event)}
                  </Typography>
                </CardBody>
              </Collapse>
            </Card>
          ))}
        </div>
      );
    }

    return (
      <div>
        <Typography variant="body2" gutterBottom>
          {t('PExerciseDetail_NoExerciseResult')}
        </Typography>
      </div>
    );
  }
}

EventReviewer.propTypes = {
  activity: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(EventReviewer));

import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import MotorSkillsForm from 'components/MotorSkills/MotorSkillsForm';
import { GET_PATIENT_MOTORSKILLS } from 'graphql/queries';

const styles = {};

class MotorSkills extends React.Component {
  render() {
    const { id } = this.props;

    return (
      <div>
        <Query query={GET_PATIENT_MOTORSKILLS} variables={{ id }} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return 'Loading...';
            if (error) return `Error! ${error.message}`;

            const { motorSkills } = data.getPatient;

            return (
              <div>
                <MotorSkillsForm id={id} motorSkills={motorSkills} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

MotorSkills.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(MotorSkills);

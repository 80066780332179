/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withTranslation } from 'react-i18next';
import { GET_PATIENT_COMMENT, UPSERT_PATIENT_COMMENT } from '../../graphql/queries';
import { logger } from '../../logger';

const styles = (theme) => ({
  root: {},
  submitButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

class CommentForm extends React.Component {
  constructor(props) {
    super(props);

    if (props.comment) {
      this.state = {
        comment: props.comment,
      };
    } else {
      this.state = {
        comment: '',
      };
    }

    this.state.saved = true;
  }

  handleChange = (event) => {
    this.setState({ saved: false });
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSave = () => {
    this.setState({ saved: true });
  };

  render() {
    const { classes, id, t } = this.props;

    return (
      <div>
        <div>
          <Mutation
            mutation={UPSERT_PATIENT_COMMENT}
            refetchQueries={() => [{ query: GET_PATIENT_COMMENT, variables: { id } }]}
          >
            {(upsertPatientComment) => (
              <div>
                <ValidatorForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    const _id = id || null;
                    upsertPatientComment({ variables: { id: _id, comment: this.state.comment } }).then(() => {
                      this.handleSave();
                    });
                  }}
                  onError={(errors) => logger.error(errors)}
                  className={classes.form}
                >
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <TextField
                        id="comment"
                        label={t('VPatient_Comments')}
                        fullWidth
                        multiline
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                        name="comment"
                        value={this.state.comment}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    disabled={this.state.saved}
                    variant="outlined"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {t('Common_Save')}
                  </Button>
                </ValidatorForm>
              </div>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

CommentForm.propTypes = {
  classes: PropTypes.object.isRequired,
  comment: PropTypes.string,
  id: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

CommentForm.defaultProps = {
  comment: '',
};

export default withTranslation()(withStyles(styles)(CommentForm));

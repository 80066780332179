import React from 'react';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';

function getPlayPauseButton(state) {
  let playPauseButton;
  if (state.activityPaused) {
    playPauseButton = (
      <React.Fragment>
        <PlayArrow />
      </React.Fragment>
    );
  } else {
    playPauseButton = (
      <React.Fragment>
        <Pause />
      </React.Fragment>
    );
  }
  return playPauseButton;
}

export default getPlayPauseButton;

import _ from 'lodash';
import List from '@material-ui/icons/List';
import RecentActors from '@material-ui/icons/RecentActors';
import ImportantDevices from '@material-ui/icons/ImportantDevices';
import DateRange from '@material-ui/icons/DateRange';
import Activities from '../views/Activities/Activities';
import PatientProfile from '../views/Patients/PatientProfile';
import PatientAdd from '../views/Patients/PatientAdd';
import PatientHistory from '../views/Patients/PatientHistory';
import Program from '../views/Activities/Program';
import activityRoutes from './activity';
import Roles from '../utils/roles';

const mainRoutes = [
  {
    path: '/patients/:id/profile',
    sidebarName: 'MainMenu_PatientFile',
    navbarName: 'MainMenu_PatientFile',
    icon: RecentActors,
    component: PatientProfile,
    requiredRoles: [Roles.ADMIN_ROLE, Roles.USER_ROLE, Roles.SUPERVISOR_ROLE],
  },
  {
    path: '/activities',
    sidebarName: 'MainMenu_Activities',
    navbarName: 'MainMenu_Activities',
    icon: ImportantDevices,
    component: Activities,
    requiredRoles: [Roles.ADMIN_ROLE, Roles.USER_ROLE],
    featureFlag: 'app_menu_activities_access',
  },
  {
    path: '/history',
    sidebarName: 'MainMenu_History',
    navbarName: 'MainMenu_History',
    icon: List,
    component: PatientHistory,
    requiredRoles: [Roles.ADMIN_ROLE, Roles.USER_ROLE, Roles.SUPERVISOR_ROLE],
    featureFlag: 'app_menu_history_access',
  },
  {
    path: '/patients/new',
    sidebarName: 'nodisplay',
    navbarName: 'Patient New',
    icon: RecentActors,
    component: PatientAdd,
    requiredRoles: [Roles.ADMIN_ROLE, Roles.USER_ROLE, Roles.SUPERVISOR_ROLE],
  },

  {
    redirect: true,
    path: '/patients',
    to: '/',
    navbarName: 'Redirect',
    featureFlag: 'app_menu_patient_directory_access',
  },

  {
    path: '/program',
    sidebarName: 'MainMenu_Program',
    navbarName: 'MainMenu_Program',
    icon: DateRange,
    component: Program,
    requiredRoles: [Roles.ADMIN_ROLE, Roles.USER_ROLE, Roles.SUPERVISOR_ROLE],
    featureFlag: 'app_menu_program_access',
  },
];

export default _.concat(mainRoutes, activityRoutes);

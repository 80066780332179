/* eslint-disable func-names */
/* eslint-disable class-methods-use-this */
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons

/*
  Component description :
    A set of runtime visual tools designed to allow the therapist to help the patient during live sessions.

    Includes :
    - Pen drawing
    - Highlighting
    - Half-neglect vision bar
    - Eraser
*/

class RuntimeToolsOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPenActive: false,
      isMarkerActive: false,
      isSeparationActive: false,
      isDrawing: false,
      isDoctor: props.isDoctor,
      width: props.width,
      height: props.height,
      separationColor: props.separationColor,
      penColor: props.penColor,
      markerColor: props.markerColor,
      splitPosition: 0,
      cursor: '',
    };

    // get pictures for the overlay tools, and configure them for cursor use:
    this.iconMarker = `url(${window.AssetResolver.getAsset(`/appAssets/image/icon/mdiMarker.png`)}) 2 22, auto`;
    this.iconPen = `url(${window.AssetResolver.getAsset(`/appAssets/image/icon/mdiPen.png`)}) 2 20, auto`;
    this.iconSplit = `url(${window.AssetResolver.getAsset(
      `/appAssets/image/icon/mdiFormatHorizontalAlignCenter.png`,
    )}) 12 0, auto`;

    setTimeout(() => {
      this.setState({ splitPosition: this.state.width / 2 });
    }, 0);
    this.commands = this.props.commands;
    this.socket = this.props.socket;
    this.canvasesContent = [];
    this.socket.on('command', (data) => {
      switch (data.body.name) {
        case 'action':
          switch (data.body.value) {
            case 'draw':
              if (this.state.isDoctor) {
                if (!this.state.isPenActive) {
                  this.deactivacteTools();
                  this.setState({ isPenActive: true });
                  this.setState({ penColor: data.body.color });
                  this.setState({ cursor: this.iconPen });
                } else {
                  this.setState({ isPenActive: false });
                  this.setState({ cursor: '' });
                }
              }
              break;
            case 'colorize':
              if (this.state.isDoctor) {
                if (!this.state.isMarkerActive) {
                  this.deactivacteTools();
                  this.setState({ isMarkerActive: true });
                  this.setState({ markerColor: data.body.color });
                  this.setState({ cursor: this.iconMarker });
                } else {
                  this.setState({ isMarkerActive: false });
                  this.setState({ cursor: '' });
                }
              }
              break;
            case 'split':
              if (this.state.isDoctor) {
                if (!this.state.isSeparationActive) {
                  this.deactivacteTools();
                  this.setState({ isSeparationActive: true });
                  this.setState({ separationColor: data.body.color });
                  this.splitLine(this.state.splitPosition, this.state.separationColor);
                  this.setState({ cursor: this.iconSplit });
                  this.sendChanges();
                } else {
                  this.setState({ isSeparationActive: false });
                  this.setState({ cursor: '' });
                }
              }
              break;
            case 'clear':
              if (this.state.isDoctor) {
                this.cleanOverlayDisplay('all');
                this.clearCurrentCanvasContent();
                this.deactivacteTools();
              }
              break;
            case 'setColor':
              if (this.state.isDoctor) {
                this.setState({ [data.body.toolColor]: data.body.color });
                if (data.body.toolColor === 'separationColor') {
                  this.splitLine(this.state.splitPosition, this.state.separationColor);
                  this.sendChanges();
                }
              }
              break;
            case 'selectOverlayCanvas':
              if (data.body.sentByDoctor === this.state.isDoctor) {
                this.currentCanvas = data.body.canvas;

                this.cleanOverlayDisplay('all');
                this.displayCurrentCanvas();
              }
              break;

            default:
          }
          break;
        case 'cleanOverlay':
          this.cleanOverlayDisplay(data.body.value.layer);
          break;
        case 'canvasContexts':
          this.deserialize(data.body.value, data.body.canvas);
          break;
        default:
      }
      if (this.state.isDoctor) {
        this.fireToolsChange();
      }
    });
  }

  serialize(canvas) {
    return canvas.toDataURL();
  }

  deserialize(value, canvas) {
    // update the corresponding canvas in the stored data for the drawing
    const existingIndex = this.canvasesContent.findIndex((item) => item.canvas === canvas);
    if (existingIndex > -1) this.canvasesContent[existingIndex].drawing = value.drawingCanvas;
    else this.canvasesContent.push({ canvas, drawing: value.drawingCanvas });

    this.splitViewCanvas = value.splitViewCanvas;
    this.displayCurrentCanvas();
  }

  displayCurrentCanvas() {
    const storedCanvas = this.canvasesContent.find((item) => item.canvas === this.currentCanvas);
    if (storedCanvas) {
      const drawingCanvas = document.getElementById('drawingOverlay');
      const img = new Image();
      img.onload = function() {
        drawingCanvas.width = img.width;
        drawingCanvas.height = img.height;
        drawingCanvas.getContext('2d').drawImage(img, 0, 0);
      };
      img.src = storedCanvas.drawing;
    }

    const splitViewCanvas = document.getElementById('splitViewOverlay');
    const img2 = new Image();
    img2.onload = function() {
      splitViewCanvas.width = img2.width;
      splitViewCanvas.height = img2.height;
      splitViewCanvas.getContext('2d').drawImage(img2, 0, 0);
    };
    img2.src = this.splitViewCanvas;
  }

  // remove data stored for the given canvas
  clearCurrentCanvasContent() {
    const existingIndex = this.canvasesContent.findIndex((item) => item.canvas === this.currentCanvas);
    if (existingIndex > -1) this.canvasesContent.splice(existingIndex, 1);
    this.sendChanges();
  }

  drawLine(prevX, prevY, x, y, color) {
    const canvas = document.getElementById('drawingOverlay');
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.globalCompositeOperation = 'source-over';
    ctx.moveTo(prevX, prevY);
    ctx.lineTo(x, y);
    ctx.strokeStyle = color;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.lineWidth = 15;
    ctx.globalAlpha = 1;
    ctx.stroke();
  }

  highlightLine(prevX, prevY, x, y, color) {
    const canvas = document.getElementById('drawingOverlay');
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.globalCompositeOperation = 'xor';
    ctx.moveTo(prevX, prevY);
    ctx.lineTo(x, y);
    ctx.strokeStyle = color;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.lineWidth = 50;
    ctx.globalAlpha = 0.2;
    ctx.stroke();
    ctx.stroke();
    ctx.stroke();
  }

  fireToolsChange() {
    return this.props.onToolsChange({
      isPenActive: this.state.isPenActive,
      isMarkerActive: this.state.isMarkerActive,
      isSeparationActive: this.state.isSeparationActive,
      isDrawing: this.state.isDrawing,
    });
  }

  deactivacteTools() {
    this.setState({ isSeparationActive: false });
    this.setState({ isMarkerActive: false });
    this.setState({ isPenActive: false });
  }

  sendChanges() {
    const drawingCanvas = document.getElementById('drawingOverlay');
    const splitViewCanvas = document.getElementById('splitViewOverlay');
    this.setState({ isDrawing: false });
    this.commands.sendCommand({
      name: 'canvasContexts',
      canvas: this.currentCanvas,
      value: {
        drawingCanvas: this.serialize(drawingCanvas),
        splitViewCanvas: this.serialize(splitViewCanvas),
      },
    });
  }

  startDrawing = () => {
    if (this.state.isDoctor && (this.state.isPenActive || this.state.isMarkerActive || this.state.isSeparationActive)) {
      this.setState({ isDrawing: true });
      window.addEventListener(
        'mouseup',
        () => {
          this.sendChanges();
        },
        { once: true },
      );
    }
  };

  handleRuntimeTool = (evt) => {
    if (this.state.isDrawing) {
      evt.persist();
      evt.preventDefault();
      evt.stopPropagation();
      const canvas = document.getElementById('drawingOverlay');
      const horizontalRatio = this.state.width / evt.target.getBoundingClientRect().width;
      const verticalRatio = this.state.height / evt.target.getBoundingClientRect().height;
      const relativeX = evt.pageX - canvas.getBoundingClientRect().left - window.scrollX;
      const relativeY = evt.pageY - canvas.getBoundingClientRect().top - window.scrollY;
      const prevX = (relativeX - evt.movementX) * horizontalRatio;
      const prevY = (relativeY - evt.movementY) * verticalRatio;
      const x = relativeX * horizontalRatio;
      const y = relativeY * verticalRatio;

      if (this.state.isPenActive) {
        this.drawLine(prevX, prevY, x, y, this.state.penColor);
      }

      if (this.state.isMarkerActive) {
        this.highlightLine(prevX, prevY, x, y, this.state.markerColor);
      }

      if (this.state.isSeparationActive) {
        this.splitLine(x, this.state.separationColor);
      }
    }
  };

  splitLine(x, color) {
    const canvas = document.getElementById('splitViewOverlay');
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, this.state.width, this.state.height);

    ctx.beginPath();
    ctx.globalAlpha = 1;
    ctx.lineWidth = 16;
    ctx.strokeStyle = color;
    ctx.moveTo(x, 4);
    ctx.lineTo(x, this.state.height - 5);
    ctx.stroke();

    ctx.globalAlpha = 0.1;
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, x, this.state.height);

    this.setState({ splitPosition: x });
  }

  cleanOverlayDisplay(layer) {
    const ctx1 = document.getElementById('splitViewOverlay').getContext('2d');
    const ctx2 = document.getElementById('drawingOverlay').getContext('2d');
    switch (layer) {
      case 'all':
        ctx1.clearRect(0, 0, this.state.width, this.state.height);
        ctx2.clearRect(0, 0, this.state.width, this.state.height);
        break;
      case 'splitView':
        ctx1.clearRect(0, 0, this.state.width, this.state.height);
        this.setState({ splitPosition: this.state.width / 2 });
        break;
      default:
        ctx1.clearRect(0, 0, this.state.width, this.state.height);
        ctx2.clearRect(0, 0, this.state.width, this.state.height);
    }
  }

  render() {
    const { children, classes, markerColor, penColor, separationColor, isDoctor, ...rest } = this.props;
    return (
      <div
        {...rest}
        onMouseDown={this.startDrawing}
        onMouseMove={this.handleRuntimeTool}
        className={this.state.isDoctor ? classes.runtimeToolsCanvasDoctor : classes.runtimeToolsCanvasPatient}
        role="button"
        style={{
          aspectRatio: '16 / 9',
          maxHeight: window.innerHeight,
          maxWidth: (16 / 9) * window.innerHeight,
          cursor: this.state.cursor,
          margin: 'auto',
        }}
      >
        <canvas
          id="splitViewOverlay"
          width={this.state.width}
          height={this.state.height}
          className={classes.runtimeToolsOverlay}
        />
        <canvas
          id="drawingOverlay"
          width={this.state.width}
          height={this.state.height}
          className={classes.runtimeToolsOverlay}
        />
        <div
          style={{
            height: '100%',
            pointerEvents:
              this.state.isDoctor &&
              (this.state.isPenActive || this.state.isMarkerActive || this.state.isSeparationActive)
                ? 'none'
                : 'all',
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

const styles = () => ({
  runtimeToolsCanvasDoctor: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  runtimeToolsCanvasPatient: {
    overflow: 'hidden',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  runtimeToolsOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    zIndex: 1,
  },
});

RuntimeToolsOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  separationColor: PropTypes.string,
  penColor: PropTypes.string,
  markerColor: PropTypes.string,
  isDoctor: PropTypes.bool,
  commands: PropTypes.object,
  socket: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  onToolsChange: PropTypes.object,
};

RuntimeToolsOverlay.defaultProps = {
  width: 1920,
  height: 1080,
  separationColor: 'rgb(0,0,255)',
  penColor: 'rgb(255,0,0)',
  markerColor: 'rgb(240,255,0)',
  isDoctor: false,
  commands: null,
  onToolsChange: null,
};

export default withStyles(styles)(RuntimeToolsOverlay);

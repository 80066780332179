/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import appLayoutStyle from 'assets/jss/layouts/appLayoutStyle.jsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import UnauthorizedAccess, { reasons } from './UnauthorizedAccess';

const intersectArrays = (arr1, arr2) => (arr1 || []).filter((role) => (arr2 || []).includes(role));

/**
 * This component wraps a react-router Route, and protects it by checking if the user the right keycloak role to access the component/page
 */

class ProtectedRoute extends Component {
  render() {
    let isAuthorized = false;
    let hasValidLicense = false;
    let returnCode = reasons.isNotAllowed;
    const { userRoles, userLicense, requiredRoles } = this.props;

    if (!requiredRoles || !requiredRoles.length) {
      isAuthorized = true;
    } else {
      isAuthorized = intersectArrays(userRoles, requiredRoles).length > 0;
      if (!isAuthorized) returnCode = reasons.isNotAllowed;
    }

    if (userLicense) {
      if (moment().isAfter(moment(userLicense.expirationDate))) {
        returnCode = reasons.licenceExpired;
      } else {
        hasValidLicense = true;
      }
    } else {
      returnCode = reasons.noLicence;
    }

    if (isAuthorized && hasValidLicense) {
      return (
        <Route
          path={this.props.path}
          render={(props) => (
            <this.props.component
              {...props}
              userRoles={this.props.userRoles}
              userLicense={this.props.userLicense}
              userTypes={this.props.userTypes}
            />
          )}
          key={this.props.sidebarName}
        />
      );
    }

    return <UnauthorizedAccess reason={returnCode} />;
  }
}

ProtectedRoute.propTypes = {
  userRoles: PropTypes.array.isRequired,
  userTypes: PropTypes.array.isRequired,
  userLicense: PropTypes.object.isRequired,
  requiredRoles: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  sidebarName: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  sidebarName: '',
};

export default withStyles(appLayoutStyle)(ProtectedRoute);

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import GroupIcon from '@material-ui/icons/Group';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
// core components
import { getCurrentPatient } from 'utils';
import Types from 'utils/types';
import { checkCurrentUserPermission } from 'utils/permissionChecker';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import logo from 'assets/img/covirtua-logo.png';
import sidebarStyle from 'assets/jss/components/sidebarStyle.jsx';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

const styles = () => ({
  sublist: {
    width: 'inherit',
    marginTop: '0',
  },
  noUpperCase: {
    textTransform: 'unset',
  },
});

const Sidebar = ({ ...props }) => {
  const currentPatient = getCurrentPatient();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    let unsuffixedRoute = '/' + routeName.split('/')[1];
    return props.location.pathname.indexOf(unsuffixedRoute) > -1 ? true : false;
  }
  const { classes, image, routes, t, userTypes, userRoles } = props;
  var links = (
    <List className={classes.list}>
      {//link to patients selection is only available to therapists
      _.includes(userTypes, Types.DOCTOR_TYPE) && (
        <NavLink to={'/patients'} className={classes.item} activeClassName="active">
          <ListItem button className={classNames(classes.itemLink, classes.logo)}>
            <ListItemIcon className={classes.itemIcon}>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemIcon className={classes.itemIcon}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="h6" className={classNames(classes.headerFromH7)}>
                  {t('MainMenu_MyPatients')}
                </Typography>
              }
              className={classes.itemText}
            />
          </ListItem>
        </NavLink>
      )}
      {// Display of current patient is only available to therapists
      currentPatient != null && _.includes(userTypes, Types.DOCTOR_TYPE) && (
        <ListItem>
          <Typography
            variant="h5"
            className={classNames(classes.headerFromH7, classes.logo)}
          >{`${currentPatient.firstname} ${currentPatient.lastname}`}</Typography>
        </ListItem>
      )}
      <ListItem>
        <List className={classNames(classes.sublist, classes.list)}>
          {routes.map((prop, key) => {
            if (prop.redirect) return null;
            if (prop.sidebarName === 'Activity') return null;
            if (prop.sidebarName === 'nodisplay') return null;
            // Menu items are only available for certain types and certain roles.
            // If not available, an item is not displayed.
            if (!_.intersection(userRoles, prop.requiredRoles).length) return null;
            if (prop.featureFlag && !checkCurrentUserPermission(prop.featureFlag)) return null;

            const highlightedFontClasses = classNames({
              [' ' + classes.purpleFont]: activeRoute(prop.path),
            });
            return (
              <div key={key}>
                <NavLink
                  to={prop.path.replace(':id', currentPatient.id)}
                  className={classes.item}
                  activeClassName="active"
                >
                  <ListItem button className={classes.itemLink}>
                    <ListItemIcon className={classes.itemIcon + highlightedFontClasses}>
                      {typeof prop.icon === 'string' ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={t(prop.sidebarName)}
                      className={classes.itemText + highlightedFontClasses}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
              </div>
            );
          })}
        </List>
      </ListItem>
    </List>
  );
  var brand = (
    <div className={classNames(classes.logo, classes.noUpperCase)}>
      <a href="https://www.covirtua.com/" className={classNames(classes.logoLink, classes.noUpperCase)}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(withStyles(sidebarStyle)(Sidebar)));

import i18n from 'i18next';

export function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function getCurrentPatient() {
  return JSON.parse(sessionStorage.getItem('currentPatient'));
}

export function getCurrentPatientId() {
  return JSON.parse(sessionStorage.getItem('currentPatient')).id;
}

export function getCurrentUserKeycloakId() {
  return localStorage.getItem('userKeycloakId');
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem('user'));
}

export function getCurrentUserPatientId() {
  return JSON.parse(localStorage.getItem('user')).patientId;
}

export function getDoctorName(doctor) {
  if (doctor) {
    if ((doctor.firstName && doctor.firstName !== '') || (doctor.lastName && doctor.lastName !== '')) {
      return `${doctor.firstName} ${doctor.lastName.toUpperCase()}`;
    } else if (doctor.email) {
      return `${doctor.email}`;
    }
  }
  return i18n.t(`Common_UnknownDoctor`);
}

export function ensureAccessToken() {
  if (!window.keycloak) {
    return new Promise((success) => {
      success();
    });
  }
  return window.keycloak.updateToken(window.keycloak.tokenParsed.exp / 2);
}

export function getCurrentUsers() {
  return {
    doctorId: getCurrentUserKeycloakId(),
    patientId: getCurrentPatientId(),
  };
}

export function isUserThePatient() {
  return getCurrentUserPatientId() === getCurrentPatientId();
}

export function getBaseUrl() {
  const baseUrlTmp = window.location.href.split('/');
  const baseUrl = `${baseUrlTmp[0]}//${baseUrlTmp[2]}`;
  return baseUrl;
}

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import headerStyle from 'assets/jss/components/headerStyle.jsx';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import HeaderLinks from './HeaderLinks';
import LinkIcon from '@material-ui/icons/Link';
import { Fab } from '@material-ui/core';
import Card from 'components/Card/Card';
import Tooltip from '@material-ui/core/Tooltip';
import PatientConnectionStatus from 'components/Activity/PatientConnectionStatus';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardHeader from 'components/Card/CardHeader';
import Types from 'utils/types';
import _ from 'lodash';
import connectionState from 'components/Activity/connectionState';
import Snackbar from 'components/Snackbar/Snackbar';

import { getCurrentPatient } from 'utils';

const nCountPingMax = 5;
const nPingTimeAck = '2000';
const nPingTimeCheck = '5000';
let nIntervalID = -1;
const nSnackbarTimeout = 3000;
const styles = (theme) => ({
  ...headerStyle(theme),
  patientCode: {
    maxWidth: '270px',
    margin: 'auto',
    padding: '3px',
    width: 'unset',
    paddingBottom: '0',
  },
  patientCodeContent: {
    padding: '3px',
    paddingBottom: '0',
  },
  buttonLink: {
    width: '35px',
    height: '30px',
    marginLeft: 'auto',
  },
  noUpperCase: {
    textTransform: 'unset',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.id = Date.now();
    const currentPatient = getCurrentPatient();
    this.state = {
      // activityRoomFor2Connected: false,
      // comRoomFor2Connected: false,
      patientConnectionState: connectionState.notConnected,
      anchorEl: null,
      currentPatient,
      seance_open: false,
      open: false,
      displayConnection: props.displayConnection,
      bFirstConnection: true,
      bAckPing: false,
      nCountPing: 0,
      disconnectSnackbar: { open: false },
    };
    //
    if (window.patientConnectedOnce === undefined) window.patientConnectedOnce = false;
    if (window.patientConnectionClients === undefined) {
      window.patientConnectionClients = [];
      window.lastConnectionState = connectionState.notConnected;
      window.registerPatientConnectionClient = (callback, comp) => {
        window.patientConnectionClients.push({ callback, comp });
        callback(window.lastConnectionState, comp);
        return window.patientConnectionClients.length;
      }
      window.unregisterPatientConnectionClient = (callbackId) => {
        window.patientConnectionClients.splice(callbackId - 1, 1)
      }
      window.informPatientConnectionClients = (connectionState) => {
        if (window.lastConnectionState !== connectionState) {
          window.lastConnectionState = connectionState
          if (window.patientConnectionClients.length)
            window.patientConnectionClients.forEach(client =>
              client.callback(connectionState, client.comp));
        }
      }
    }
  }




  componentDidMount = () => {

    /* function updateConnectionStatus(socket, roomID, propKey, comp) {
      const stateProp = {};

      socket.emit('getRoomSize', roomID, (response) => {
        if (response.roomSize > 1) {
          stateProp[propKey] = true;
        } else {
          stateProp[propKey] = false;
        }
        comp.setState(stateProp);
      });
    } */

    if (this.state.displayConnection) {
      // pdateConnectionStatus(window.comSocket, this.state.currentPatient.code, 'comRoomFor2Connected', this);
      // updateConnectionStatus(window.activitySocket, this.state.currentPatient.code, 'activityRoomFor2Connected', this);
      // setTimeout(() => {
      /*
      // subscribe to the connection event
      window.comSocket.on('newConnection', () => {
        updateConnectionStatus(window.comSocket, this.state.currentPatient.code, 'comRoomFor2Connected', this);
      });
      window.activitySocket.on('newConnection', () => {

        updateConnectionStatus(
          window.activitySocket,
          this.state.currentPatient.code,
          'activityRoomFor2Connected',
          this,
        );
      }); */

      // function checkConnectionStatus(socket, roomID, propKey, comp) {
      //   const stateProp = {};

      //   socket.emit('getRoomSize', roomID, (response) => {
      //     if (response.roomSize > 1) {
      //       stateProp[propKey] = true;
      //     } else {
      //       stateProp[propKey] = false;
      //     }
      //     comp.setState(stateProp);
      //   });
      // }

      function checkConnectionStatusRoutine(socket, roomID, comp) {
        if (nIntervalID > -1) {
          clearInterval(nIntervalID);
        }
        socket.emit('CustomPing', roomID);
        nIntervalID = setInterval(() => {
          comp.setState({ bAckPing: false });

          socket.emit('CustomPing', roomID);
          setTimeout(() => {
            if (comp.state.bAckPing) {
              comp.setState({ nCountPing: 0 });
              comp.handlePatientConnected(comp);
            } else if (!comp.state.bFirstConnection) {
              if (comp.state.nCountPing > nCountPingMax) {
                console.log('Error Max Retry Pong');
                comp.handlePatientDisconnected(comp);
              } else {
                comp.handlePatientConnectionLost(comp);
                comp.setState({ nCountPing: comp.state.nCountPing + 1 });
              }
            }
          }, nPingTimeAck);
        }, nPingTimeCheck);
      }

      window.comSocket.removeAllListeners('CustomPing');
      window.comSocket.on('CustomPing', () => {
        window.comSocket.emit('CustomPong', getCurrentPatient().code);
        if (this.state.bFirstConnection) {
          // on first ping of patient upon connection, send ping to update / confirm connexion status
          window.comSocket.emit('CustomPing', getCurrentPatient().code);
        }
      });

      window.comSocket.removeAllListeners('CustomPong');

      window.comSocket.on('CustomPong', () => {
        if (this.state.bFirstConnection) {
          this.handlePatientConnected(this);
          this.setState({ bFirstConnection: false });
        }
        this.setState({ bAckPing: true });
      });
      // start the routine only once the patient has connected once
      checkConnectionStatusRoutine(window.comSocket, getCurrentPatient().code, this);


      window.comSocket.removeAllListeners('userConnected');

      window.comSocket.on('userConnected', (id) => {
        // updateConnectionStatus(id, true, this);
        this.setState({ nCountPing: 0 });
        if (id !== window.comSocket.id) {
          this.handlePatientConnected(this);
        }
      });

      window.comSocket.removeAllListeners('userDisconnected');
      window.comSocket.on('userDisconnected', (id) => {
        this.setState({ bAckPing: false });
        if (id !== window.comSocket.id) this.handlePatientDisconnected();
      });
    }
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  isHome() {
    return this.props.location && this.props.location.pathname === '/';
  }

  isPatientSelected() {
    return sessionStorage.getItem('currentPatient') !== null;
  }

  isLive() {
    return this.props.location.pathname.includes('/live');
  }

  handlePatientConnected = (comp) => {
    comp.setState({ patientConnectionState: connectionState.connected });
    window.informPatientConnectionClients(connectionState.connected)
  }

  handlePatientConnectionLost = (comp) => {
    comp.setState({ patientConnectionState: connectionState.connectionLost });
    window.informPatientConnectionClients(connectionState.connectionLost)
  }


  handlePatientDisconnected() {
    this.setState({ patientConnectionState: connectionState.notConnected });
    window.informPatientConnectionClients(connectionState.notConnected)
    this.handleSnackbar(true);
    setTimeout(() => {
      window.location.href = '/';
    }, nSnackbarTimeout);
  }



  hasNoHeader() {
    return this.isHome() || !this.isPatientSelected() || !_.includes(this.props.userTypes, Types.DOCTOR_TYPE);
  }

  showProfile() {
    return !(
      _.includes(this.props.userTypes, Types.PATIENT_TYPE) && this.props.location.pathname.includes('/activity')
    );
  }

  handleSnackbar = (open) => {
    this.setState((prevState) => ({
      disconnectSnackbar: {
        ...prevState.disconnectSnackbar,
        open,
      },
    }));
  };

  render() {
    const { classes, color, t } = this.props;
    const appBarClasses = classNames({
      [` ${classes[color]}`]: color,
    });

    return (
      <div>
        <AppBar className={classes.appBar + appBarClasses}>
          <Toolbar className={classes.container} marginLeft="400px">
            <div className={this.hasNoHeader() ? classes.flex : undefined}>
              {this.hasNoHeader() && <React.Fragment />}
            </div>
            {!this.hasNoHeader() && (
              <Card className={classes.patientCode}>
                <CardHeader className={classes.patientCodeContent} color="">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant="subtitle1" align="center">
                        {this.state.displayConnection && (
                          <PatientConnectionStatus patientConnectionState={this.state.patientConnectionState} />
                        )}
                      </Typography>
                    </GridItem>
                    {this.state.patientConnectionState === connectionState.notConnected && (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem>
                            <Tooltip title={t('Main_AtHomeLinkInvite_toolTip')} classes={{ tooltip: classes.tooltip }}>
                              <Fab
                                onClick={() => {
                                  navigator.clipboard.writeText(`${window.config.services.front.patient.site.url}`);
                                }}
                                color="primary"
                                aria-label="CopyPatientURL"
                                className={classNames(classes.button, classes.buttonLink)}
                                data-action="CopyPatientURL"
                              >
                                <LinkIcon />
                              </Fab>
                            </Tooltip>
                          </GridItem>
                          <GridItem>
                            <Typography variant="subtitle1" paddingLeft="20px">
                              {t('Main_SessionCode')} :{' '}
                              <strong>{JSON.parse(sessionStorage.getItem('currentPatient')).code}</strong>
                            </Typography>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    )}
                  </GridContainer>
                </CardHeader>
              </Card>
            )}

            <Hidden smDown implementation="css">
              {this.showProfile() && <HeaderLinks keycloak={this.props.keycloak} />}
            </Hidden>
            <Hidden mdUp implementation="css">
              <IconButton color="inherit" aria-label="open drawer" onClick={this.props.handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Snackbar
          place="br"
          color="danger"
          autoHideDuration={nSnackbarTimeout}
          open={this.state.disconnectSnackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t('Main_PatientDisconnectedCaption')}
        />
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', '']),
  location: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

Header.defaultProps = { color: '' };

export default withTranslation()(withStyles(styles)(Header));

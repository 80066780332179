import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import appLayoutStyle from 'assets/jss/layouts/appLayoutStyle.jsx';
import { Switch } from 'react-router-dom';
import authenticatedRoutes from 'routes/authenticated';
import Keycloak from 'keycloak-js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getApolloClient } from 'utils/apolloClientFactory';
import { GET_USER } from 'graphql/queries';
import { UPDATE_USER_LAST_LOGIN } from 'graphql/accountsQueries';
import ProtectedRoute from 'components/Authentication/ProtectedRoute';
import _ from 'lodash';
import Types from 'utils/types';

/**
 * This class performs keycloak authentication before letting the user access to private routes
 */

class AuthenticationWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      userRoles: [],
      userTypes: [],
      userLicense: null,
    };
  }

  componentDidMount() {
    window.keycloak = new Keycloak(`/keycloak.${process.env.REACT_APP_NODE_ENV || 'development'}.json`);

    // checkLoginIframe is true by default but can cause issues in development when altering keycloak's configuration many times (can lead to inconsistent cookies) : https://www.keycloak.org/docs/latest/securing_apps/#session-status-iframe + https://github.com/dasniko/keycloak-reactjs-demo/issues/3#issuecomment-438745122 + https://github.com/ebondu/angular2-keycloak/issues/10 + https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc#session-status-iframe
    const enableCheckLoginIframe = process.env.NODE_ENV !== 'development';

    window.keycloak
      .init({ onLoad: 'login-required', checkLoginIframe: enableCheckLoginIframe })
      .then((authenticated) => {
        this.updateToken();
        this.updateUserInDb().then((res) => {
          if (res) {
            const user = res.data.getUser;
            this.updateUserLastLogin(user._id)
            // if the user contains a link to a patient, it's a patient! Load the current patient immediately
            const currentUserType = user.patientId ? [Types.PATIENT_TYPE] : [Types.DOCTOR_TYPE];

            this.setState({
              userRoles: (window.keycloak.realmAccess && user.role) || [],
              userTypes: (window.keycloak.realmAccess && currentUserType) || [],
              userLicense: user.license,
              authenticated,
            });
          }
        });
      })
      .catch(() => {
        console.error('failed to initialize');
      });

    window.keycloak.onAuthRefreshSuccess = this.updateToken;
    window.keycloak.onTokenExpired = window.keycloak.updateToken;
  }

  updateToken = () => {
    localStorage.setItem('accountUrl', window.keycloak.createAccountUrl());
    localStorage.setItem('userKeycloakId', window.keycloak.subject);
  };

  // Updates (or creates) the user in database with the latest identity values (name, email, etc) from keycloak
  updateUserInDb = async () => {
    const client = await getApolloClient();
    const res = await client.query({ query: GET_USER });
    if (res) localStorage.setItem('user', JSON.stringify(_.omit(res.data.getUser, ['__typename'])));
    return res;
  };

  updateUserLastLogin = (userId) => {
    const login = new Date(window.keycloak.tokenParsed.auth_time * 1000);
    getApolloClient(true, true).then((client) => { // get appolo client for Account back
      client.mutate({
        mutation: UPDATE_USER_LAST_LOGIN,
        variables: {
          userId,
          lastLogin: login,
        },
      });
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.authenticated ? (
          <Switch>
            {authenticatedRoutes.map((prop) => (
              <ProtectedRoute
                userRoles={this.state.userRoles}
                userTypes={this.state.userTypes}
                userLicense={this.state.userLicense}
                requiredRoles={prop.requiredRoles}
                path={prop.path}
                component={prop.component}
                key={prop.path}
              />
            ))}
          </Switch>
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.content}>
              <div className={classes.container} style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

AuthenticationWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(appLayoutStyle)(AuthenticationWrapper);

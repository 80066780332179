import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import cardThumbnailStyle from 'assets/jss/components/cardThumbnailStyle.jsx';

class CardThumbnail extends Component {
  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  render() {
    const { avatar, title, linkTo, classes, backgroundColor } = this.props;
    return (
      <div>
        <Card>
          <CardActionArea component={Link} to={linkTo}>
            <CardContent>
              <Avatar
                aria-label=""
                src={avatar}
                alt={title}
                classes={{
                  img: classes.img,
                }}
                style={{ backgroundColor }}
                className={classes.cardThumbnailAvatar}
              />
              <Typography
                gutterBottom
                variant="subtitle1"
                align="center"
                component="h3"
                className={classes.cardThumbnailTitle}
              >
                {title}
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* <CardFooter stats>
            <DateRange />Sur ecran
            <AccessTime /> 10 mins
            <DateRange />Sur ecran
          </CardFooter> */}
        </Card>
      </div>
    );
  }
}

CardThumbnail.propTypes = {
  avatar: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default withStyles(cardThumbnailStyle)(CardThumbnail);

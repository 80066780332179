import AppLayout from 'layouts/AppLayout';
import Roles from 'utils/roles';

/**
 * Those are the routes that are displayed after the user is authentified by keycloak
 */

const authenticatedRoutes = [
  {
    path: '/',
    component: AppLayout,
    requiredRoles: [Roles.ADMIN_ROLE, Roles.USER_ROLE],
  },
];

export default authenticatedRoutes;

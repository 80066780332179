import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import pathologies from './pathologies';

const styles = () => ({
  pahtologyPanel: {
    width: '300px',
  },
});

class ConditionPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pathologie: null,
      pathology: null,
    };
  }

  handleChangePatho = (pathology) => {
    const newPathology = pathology;

    if (this.state.pathologie.iscustom) {
      newPathology.type = this.state.pathology.type;
    }

    newPathology.form = this.state.pathologie.form;

    this.setState({
      pathology: newPathology,
    });
  };

  handleClick = (e) => {
    this.setState({
      pathologie: {
        name: e.currentTarget.dataset.pathologie,
        slug: e.currentTarget.dataset.slug,
        iscustom: e.currentTarget.dataset.iscustom,
        form: e.currentTarget.dataset.form,
      },
      pathology: {
        name: e.currentTarget.dataset.pathologie,
        slug: e.currentTarget.dataset.slug,
      },
    });
  };

  validate = () => {
    this.props.closecb(this.state.pathology);
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classNames(classes.pahtologyPanel)}>
        <Grid container xs={12}>
          {Object.keys(pathologies).map((key) => (
            <ExpansionPanel key={t(key)}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{t(key)}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    {pathologies[key].map((pathologie) => (
                      <div key={pathologie.slug}>
                        <Button
                          key={pathologie.slug}
                          color="primary"
                          data-pathologie={pathologie.name}
                          data-slug={pathologie.slug}
                          data-iscustom={pathologie.isCustom}
                          data-form={pathologie.form}
                          onClick={this.handleClick}
                        >
                          {t(pathologie.name)}
                        </Button>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Grid>
        {this.state.pathologie != null && (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography variant="title" gutterBottom>
                {t(this.state.pathologie.name)}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={this.validate}
          className={classes.submitButton}
        >
          {t('Common_Validate')}
        </Button>
      </div>
    );
  }
}

ConditionPicker.propTypes = {
  closecb: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(ConditionPicker));

import React, { useEffect, useState } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import { standardValueFormat } from './Formatter';

momentDurationFormat(moment);

export const ActivityState = withTranslation()((attributes) => {
  const { runtimeState, config, t } = attributes;
  const [dataFormatter, setdataFormatter] = useState(null);
  const [renderable, setRenderable] = useState(false);

  const formatData = (entry) => {
    let result = null;
    if (dataFormatter) {
      result = dataFormatter.formatData(entry.field, runtimeState);
    }

    if (result == null) {
      if (Array.isArray(runtimeState[entry.field])) {
        result = runtimeState[entry.field].map((d) => <div> - {d}</div>);
      }
      return standardValueFormat(t, config, entry, runtimeState);
    }

    return result;
  };

  useEffect(() => {
    const dataFormatterProm = import(`@covirtua-activities/${config.metadata.slug}/build/dataFormatter.js`).then(
      (dataFormatterLoad) => {
        const DF = dataFormatterLoad.default;
        setdataFormatter(new DF(runtimeState, t));
        setRenderable(true);
      },
    );
    Promise.resolve(dataFormatterProm);
  }, [renderable]);

  return (
    <GridItem xs={12} sm={12} md={12}>
      {config.runtimeStateFields.map((entry) => (
        <Typography>
          {t(entry.label)} : {formatData(entry)}
        </Typography>
      ))}
    </GridItem>
  );
});

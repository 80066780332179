/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { Mutation } from 'react-apollo';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { checkCurrentUserPermission } from 'utils/permissionChecker';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withTranslation } from 'react-i18next';
import SimpleBasicDialog from 'components/Dialog/SimpleBasicDialog';
import { GET_PATIENTS } from '../../graphql/queries';
import { logger } from '../../logger';
import ConditionPicker from './ConditionPicker';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  form: {
    width: 300,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    minWidth: '100%',
    maxWidth: 200,
  },
  formLabel: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  },
  birthday: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  studies: {
    width: 180,
  },
  heading: {
    marginBottom: theme.spacing.unit * 2,
  },
  moreButton: {
    marginTop: 15,
  },
  submitButton: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
  },
  pathologyName: {
    marginTop: '15px',
    padding: '5px',
  },
  errorMessage: {
    color: '#db2269',
  },
});

const omit = ['sexLabelWidth', 'saved', 'pathologie', 'pathology', 'conditionPickerOpen', 'erorMessage'];

class CreationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sexLabelWidth: 0,
      firstname: '',
      lastname: '',
      birthdate: '',
      sex: '',
      pathologie: null,
      pathology: null,
      isAnonymous: false,
      conditionPickerOpen: false,
      erorMessage: '',
    };

    this.state.saved = true;
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({
      sexLabelWidth: ReactDOM.findDOMNode(this.sexLabelRef).offsetWidth,
    });
  }

  setCondition = (condition) => {
    this.setState({ pathology: condition });
    this.setState({ conditionPickerOpen: false });
  };

  handleChange = (event) => {
    if (event.target.name === 'isAnonymous') {
      this.setState({ [event.target.name]: !this.state[event.target.name], saved: false });
    } else {
      this.setState({ [event.target.name]: event.target.value, saved: false });
    }
  };

  handleSave = () => {
    this.setState({ saved: true });
  };

  render() {
    const { classes, redirect, mutation, id, t, closecb } = this.props;
    const { firstname, lastname, sex, sexLabelWidth } = this.state;

    return (
      <Mutation mutation={mutation} refetchQueries={() => [{ query: GET_PATIENTS }]}>
        {(upsertPatient) => (
          <div>
            <ValidatorForm
              onSubmit={(e) => {
                e.preventDefault();
                const state = _.omit(this.state, omit);
                const _id = id || null;
                const pathology = this.state.pathology
                  ? {
                      content: JSON.stringify(_.omit(this.state.pathology, ['form', 'slug'])),
                      slug: this.state.pathology.slug,
                      form: this.state.pathology.form,
                    }
                  : null;
                upsertPatient({
                  variables: { id: _id, civilities: state, condition: this.state.pathology ? pathology : null },
                })
                  .then((patient) => {
                    this.handleSave();

                    const currentPatient = {
                      id: patient.data.upsertPatient._id,
                      firstname: patient.data.upsertPatient.firstname,
                      lastname: patient.data.upsertPatient.lastname,
                      permacode: patient.data.upsertPatient.permacode,
                    };

                    if (redirect) {
                      closecb(currentPatient);
                    }
                  })
                  .catch((error) => {
                    logger.debug(error);
                    this.setState({ erorMessage: "Une erreur s'est produite. Veuillez renseigner tous les champs." });
                  });
              }}
              onError={(errors) => logger.error(errors)}
              className={classes.form}
            >
              {checkCurrentUserPermission('feature_anonymization') && (
                <FormControlLabel
                  control={
                    <Switch
                      name="isAnonymous"
                      checked={this.state.isAnonymous}
                      onChange={this.handleChange}
                      value="isAnonymous"
                    />
                  }
                  label={t('VPatient_Anonymize')}
                />
              )}
              <Grid container>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    name="firstname"
                    id="firstname"
                    label={this.state.isAnonymous ? `${t('VPatient_ID')}*` : `${t('VPatient_IdentityFirstName')}*`}
                    validators={['required']}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    value={firstname}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>

              {!this.state.isAnonymous && (
                <Grid container>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      name="lastname"
                      id="lastname"
                      label={`${t('VPatient_IdentityLastName')}*`}
                      validators={['required']}
                      className={classNames(classes.textField, classes.dense)}
                      margin="dense"
                      value={lastname}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
              )}

              {!this.state.isAnonymous && (
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      id="date"
                      name="birthdate"
                      label={t('VPatient_IdentityBirthdate')}
                      type="date"
                      className={classes.birthday}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel
                        ref={(ref) => {
                          this.sexLabelRef = ReactDOM.findDOMNode(ref);
                        }}
                        htmlFor="sex"
                      >
                        {t('VPatient_IdentityGender')}
                      </InputLabel>
                      <Select
                        autoWidth
                        value={sex}
                        onChange={this.handleChange}
                        input={<OutlinedInput labelWidth={sexLabelWidth} name="sex" id="sex" />}
                      >
                        <MenuItem value="man">{t('VPatient_IdentityGender_eMale')}</MenuItem>
                        <MenuItem value="woman">{t('VPatient_IdentityGender_eFemale')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {!this.state.isAnonymous && (
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.setState({ conditionPickerOpen: true });
                    }}
                  >
                    {t('VPatient_AddCondition')}
                  </Button>
                  <SimpleBasicDialog
                    title={t('VPatient_AddCondition')}
                    content={<ConditionPicker closecb={this.setCondition} />}
                    onClose={() => {
                      this.setState({ conditionPickerOpen: false });
                    }}
                    open={this.state.conditionPickerOpen}
                    maxWidth={false}
                  />
                  <br />
                  {this.state.pathology && (
                    <Chip
                      className={classNames(classes.chip, classes.pathologyName)}
                      label={t(this.state.pathology.name)}
                      key={this.state.pathology.slug}
                    />
                  )}
                </div>
              )}
              <Button
                type="submit"
                variant="outlined"
                disabled={
                  this.state.firstname === '' ||
                  (!this.state.isAnonymous && this.state.lastname === '') ||
                  this.state.saved ||
                  (!this.state.isAnonymous && this.state.pathology === null)
                }
                color="primary"
                className={classes.submitButton}
              >
                {t('Common_Save')}
              </Button>

              {this.state.erorMessage !== '' && (
                <Typography className={classes.errorMessage}>{this.state.erorMessage}</Typography>
              )}
            </ValidatorForm>
          </div>
        )}
      </Mutation>
    );
  }
}

CreationForm.propTypes = {
  id: PropTypes.any.isRequired,
  closecb: PropTypes.func,
  redirect: PropTypes.any,
  mutation: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

CreationForm.defaultProps = {
  closecb: () => {},
  redirect: null,
};

export default withTranslation()(withStyles(styles)(CreationForm));

/* eslint-disable dot-notation */
const trad = {
  activities: {
    barrage: {
      // use the activity slug
      htmlDescription: `<p>L'activité "Le barrage" entraîne l'attention soutenue sélective et l'analyse visuelle. L'attention soutenue sélective est la capacité à sélectionner un stimulus parmi d'autres comme centre de son attention et à maintenir cette sélection pendant une période de temps.</p><p>Une ou deux cibles sont présentées au patient qui doit ensuite les retrouver parmi un ensemble d'éléments distincts.</p><p>Au démarrage de l'activité, le thérapeute configure la disposition, le nombre et le type d'éléments présentés au patient.</p><p>Ces éléments peuvent être :<ul><li>Des <b>lettres</b></li><li>Des <b>formes géométriques</b></li><li>Des <b>pictogrammes</b></li><li>Des <b>photos</b></li></ul></p><p>Le thérapeute précise également la nature du barrage :<ul><li><b>Simple:</b> identifier une cible unique</li><li><b>Double:</b> identifier deux cibles</li></ul></p><p>L'affichage de la cible peut être permanent ou temporaire. Dansle second cas, la cible doit être maintenue en mémoire de travaildurant la tâche de barrage. Le thérapeute peut choisir d'animerles éléments et choisir la vitesse de l'animation.</p>`,
      config: {
        gridType: 'Type de grille',
        disposition: {
          fieldName: 'Disposition',
          grid: 'Grille',
          scattered: 'Eparpillé',
        },
        numberOfTurns: {
          fieldName: 'Nombre de tours',
        },
        allotedTime: {
          fieldName: 'Temps imparti',
          noLimit: 'Pas de limite',
          _3minutes: '3 minutes',
          _5minutes: '5 minutes',
          _10minutes: '10 minutes',
        },
        typeOfLetter: {
          fieldName: 'Type de lettres',
          uppercase: 'MAJUSCULES',
          lowercase: 'Minuscules',
          uppercaseAndLowercase: 'MAJUSCULES et minuscules',
        },
        shapeType: {
          fieldName: 'Type de formes',
          letters: 'Lettres',
          geometricShapes: 'Formes géométriques',
          pictograms: 'Pictogrammes',
          photos: 'Photographies',
        },
        nbrOfRandomElements: {
          fieldName: "Nombre d'éléments aléatoires",
        },
        barrageType: {
          fieldName: 'Type de barrage',
          simple: 'Barrage simple',
          double: 'Barrage double',
          triple: 'Barrage triple',
          quadruple: 'Barrage quadruple',
          quintuple: 'Barrage quintuple',
        },
        targetDisplay: {
          fieldName: 'Affichage de la cible',
          temporary: 'Affichage temporaire',
          permanent: 'Affichage permanent',
        },
        targetFrequency: {
          fieldName: 'Fréquence de la cible',
          _5percent: '5% des éléments',
          _10percent: '10% des éléments',
          _15percent: '15% des éléments',
          _20percent: '20% des éléments',
        },
      },
    },
  },
  front: {
    activityWrapper: {
      onScreen: 'Sur écran',
      supervision: 'Supervision',
      minutes: 'mins',
      configName: 'Nom de la configuration',
      configNameRequired: 'Nom de la configuration requis',
      savedConfiguration: 'Configuration',
      save: 'Sauver',
      start: 'Démarrer',
      confirmQuit: "Êtes-vous certain de vouloir stopper l'activité en cours ?",
      patientCodeLabel: 'Code de connexion',
      patientLinkLabel: 'Communiquez le lien suivant à votre patient',
      patientConnected: 'Patient connecté',
      patientNotConnected: 'Patient non connecté',
      patientView: 'Vue Patient',
      notes: 'Notes',
      patientCodeJoinLabel: 'Code de la séance',
      patientJoin: 'Joindre',
    },
  },
};

trad['AddPatient'] = `£Ajouter un patient`;
trad['SolutionName'] = `£COVIRTUA Cognition`;
trad['TherapistApplicationName'] = `£COVIRTUA Cognition Thérapeute`;
trad['Common_Colon'] = `£ : `;
trad['Common_Previous'] = `£Précédent`;
trad['Common_Next'] = `£Suivant`;
trad['Common_Exit'] = `£Quitter`;
trad['Common_Save'] = `£Sauvegarder`;
trad['Common_Record'] = `£Enregistrer`;
trad['Common_OK'] = `£OK`;
trad['Common_PointTaken'] = `£C'est noté`;
trad['Common_Validate'] = `£Valider`;
trad['Common_Cancel'] = `£Annuler`;
trad['Common_NoThanks'] = `£Non merci`;
trad['Common_Back'] = `£Retour`;
trad['Common_Delete'] = `£Supprimer`;
trad['Common_Modify'] = `£Modifier`;
trad['Common_Confirm'] = `£Confirmer`;
trad['Common_Yes'] = `£Oui`;
trad['Common_No'] = `£Non`;
trad['Common_File'] = `£Fichier`;
trad['Common_Open'] = `£Ouvrir`;
trad['Common_Close'] = `£Fermer`;
trad['Common_Print'] = `£Imprimer`;
trad['Common_Start'] = `£Démarrer`;
trad['Common_Interrupt'] = `£Interrompre`;
trad['Common_Continue'] = `£Continuer`;
trad['Common_Export'] = `£Exporter`;
trad['Common_Displayed'] = `£Affiché`;
trad['Common_Hidden'] = `£Masqué`;
trad['Common_Min'] = `£Mini`;
trad['Common_Max'] = `£Maxi`;
trad['Common_Escape'] = `£Échap`;
trad['Common_Space'] = `£Espace`;
trad['Common_Return'] = `£Entrée`;
trad['Common_Left'] = `£Gauche`;
trad['Common_Up'] = `£Haut`;
trad['Common_Down'] = `£Bas`;
trad['Common_Right'] = `£Droite`;
trad['Common_UnitSecond'] = `£ s`;
trad['Common_UnitMinute'] = `£ min`;
trad['Common_UnitGram'] = `£ g`;
trad['Common_UnitKiloGram'] = `£ kg`;
trad['Common_UnitCentiLitre'] = `£ cl`;
trad['Common_UnitLitre'] = `£ L`;
trad['Common_UnitMeter'] = `£ m`;
trad['Common_UnitSlices'] = `£ tranches`;
trad['Common_UnitSheets'] = `£ feuilles`;
trad['Common_UnitUnits'] = `£ unités`;
trad['Common_CopyRight'] = `£(c)2016-2017 - COVIRTUA`;
trad['Common_CopyRightReserved'] = `£COVIRTUA(c)2016 - Tous droits reservés`;
trad['Common_UserRight'] = `£Droits d'utilisation soumis à la license utilisateur COVIRTUA Cognition`;
trad['Common_Patient'] = `£Patient`;
trad['Common_Date'] = `£Date`;
trad['Common_Day'] = `£Jour`;
trad['Common_Days_Monday'] = `£Lundi`;
trad['Common_Days_Tuesday'] = `£Mardi`;
trad['Common_Days_Wednesday'] = `£Mercredi`;
trad['Common_Days_Thursday'] = `£Jeudi`;
trad['Common_Days_Friday'] = `£Vendredi`;
trad['Common_Days_Saturday'] = `£Samedi`;
trad['Common_Days_Sunday'] = `£Dimanche`;
trad['Common_Etc'] = `£ et [OTHERS] autre(s)`;
trad['Common_Description'] = `£Description`;
trad['Common_Comments'] = `£Commentaires`;
trad['Common_None'] = `£Aucun`;
trad['Common_DateTimeFormatFull'] = `£Le dd/MM/yyyy à HH:mm`;
trad['Common_Among'] = `£ parmi`;
trad['Common_Percent'] = `£ %`;
trad['Common_QuestionMark'] = `£?`;
trad['Common_DateSeparator'] = `£/`;
trad['Common_HelpToolTip'] = `£Accéder à l'aide en ligne.`;
trad['Common_HomeToolTip'] = `£Revenir au menu principal.`;
trad['Reporting_ActivityReportTitle'] = `£Rapport d'activité`;
trad['Reporting_ActivityPrintName'] = `£Résultat d'activité`;
trad['Reporting_ActivityFileName'] = `£Activity_`;
trad['Reporting_SessionReportTitle'] = `£Rapport de session`;
trad['Reporting_SessionPrintName'] = `£Résultat de session`;
trad['Reporting_SessionFileName'] = `£Session_`;
trad['Main_Version'] = `£Version`;
trad['Main_DemoMode'] = `£MODE DEMONSTRATION`;
trad['Main_About'] = `£A propos...`;
trad['Main_Contact'] = `£Contacter un assistant`;
trad['Main_Profile'] = `£Mon profil`;
trad['Main_Disconnect'] = `£Déconnexion`;
trad['Main_SessionCode'] = `£Code de séance`;
trad['Main_SessionCodeInvite'] = `£Code de la séance`;
trad['Main_SessionJoin'] = `£Joindre`;
trad['MainMenu_History'] = `£Historique`;
trad['MainMenu_Statistics'] = `£Statistiques`;
trad['MainMenu_MyPatients'] = `£Mes patients`;
trad['MainMenu_PatientFile'] = `£Dossier patient`;
trad['MainMenu_Dashboard'] = `£Suivi`;
trad['MainMenu_Activities'] = `£Activités`;
trad['VHistory_NoHistory'] = `£Aucun historique pour ce patient...`;
trad['VConfig_Mic'] = `£Microphone`;
trad[
  'VConfig_MicToolTip'
] = `£Ce champs paramètre d'identifier l'interface qui sera utilisée cmme micro dans les activités qui le permettent.`;
trad['VConfig_Webcam'] = `£Webcam`;
trad[
  'VConfig_WebcamToolTip'
] = `£Ce champs paramètre d'identifier l'interface qui sera utilisée comme webcam dans les activités qui le permettent.`;
trad['VConfig_Welcome'] = `£Bienvenue dans COVIRTUA Cognition!`;
trad['VConfig_Title'] = `£Configuration`;
trad['VConfig_ReminderHow'] = `£Comment faire?`;
trad['VConfig_Language'] = `£Langue`;
trad['VConfig_ErrorWebcam'] = `£• La webcam à utiliser`;
trad['VConfig_ErrorMic'] = `£• Le micro à utiliser`;
trad['VConfig_WebcamFPS'] = `£Img/s Webcam`;
trad['VExperience_Notepad'] = `£Bloc-notes`;
trad['VFrontPage_SelectPatient'] = `£Démarrer pour`;
trad['VFrontPage_CreatePatient'] = `£Créer un nouveau patient`;
trad['VFrontPage_SeeAll'] = `£Voir tous les patients`;
trad['VMainMenu_TabProject'] = `£PROJETS`;
trad['VMainMenu_TabBottomUp'] = `£ANALYTIQUE`;
trad['VMainMenu_TabTopDown'] = `£FONCTIONNEL`;
trad['VMainMenu_TabTools'] = `£OUTILS`;
trad['VMainMenu_PictureBookTitle'] = `£Imagier`;
trad[
  'VMainMenu_PictureBookDesc'
] = `£Utilisez les images de l'application pour construire un imagier ou un cahier de communication pour votre patient`;
trad[
  'VMainMenu_HomeToolTip'
] = `£Saisir les commentaires de fin de séances, et revenir à l'écran de choix d'un patient.`;
trad['VMainMenuCB_DReminderSelf'] = `£Vous avez indiqué`;
trad['VMainMenuCB_DReminderOther'] = `£a indiqué`;
trad['VMainMenuCB_DReminderDate'] = `£Au terme de la session du`;
trad['VMainMenuCB_DReminderTitle'] = `£Rappel`;
trad['VPatient_PatientCreation'] = `£Création d'un nouveau patient`;
trad['VPatient_NewPatient'] = `£Nouveau patient`;
trad['VPatient_Anonymize'] = `£Anonymiser`;
trad['VPatient_ID'] = `£Identifiant`;
trad['VPatient_PatientEdition'] = `£Edition d'un patient`;
trad['VPatient_PatientDetail'] = `£Affichage d'un patient`;
trad['VPatient_AgeYears'] = `£ ans`;
trad['VPatient_AgeYearsMonths'] = `£ ans et [MONTHS] mois`;
trad['VPatient_Identity'] = `£Civilité`;
trad['VPatient_Identity_eMr'] = `£M.`;
trad['VPatient_Identity_eMme'] = `£Mme`;
trad['VPatient_Identity_eMlle'] = `£Mlle`;
trad['VPatient_IdentityFirstName'] = `£Prénom`;
trad['VPatient_IdentityLastName'] = `£Nom`;
trad['VPatient_IdentityBirthdate'] = `£Date de naissance`;
trad['VPatient_IdentityStudies'] = `£Nombre d'années d'étude`;
trad['VPatient_IdentityStudies_e1t'] = `£Brevet`;
trad['VPatient_IdentityStudies_e1'] = `£0`;
trad['VPatient_IdentityStudies_e2t'] = `£Baccalauréat`;
trad['VPatient_IdentityStudies_e2'] = `£2`;
trad['VPatient_IdentityStudies_e3t'] = `£License`;
trad['VPatient_IdentityStudies_e3'] = `£5`;
trad['VPatient_IdentityStudies_e4t'] = `£Master`;
trad['VPatient_IdentityStudies_e4'] = `£7`;
trad['VPatient_IdentityStudies_e5t'] = `£Doctorat`;
trad['VPatient_IdentityStudies_e5'] = `£10`;
trad['VPatient_IdentityStudies_1duration'] = `£9`;
trad['VPatient_IdentityStudies_2duration'] = `£12`;
trad['VPatient_IdentityStudies_3duration'] = `£15`;
trad['VPatient_IdentityStudies_4duration'] = `£17`;
trad['VPatient_IdentityStudies_5duration'] = `£17`;
trad['VPatient_IdentityStudies_6duration'] = `£19`;
trad['VPatient_IdentityGender'] = `£Sexe`;
trad['VPatient_IdentityGender_eFemale'] = `£Femme`;
trad['VPatient_IdentityGender_eMale'] = `£Homme`;
trad['VPatient_IdentityLaterality'] = `£Latéralité`;
trad['VPatient_IdentityLaterality_eLeft'] = `£Gaucher`;
trad['VPatient_IdentityLaterality_eRight'] = `£Droitier`;
trad['VPatient_IdentityContact'] = `£Contact`;
trad['VPatient_IdentityContactInfo'] = `£Coordonnées`;
trad['VPatient_IdentityContactInfoLandline'] = `£Téléphone fixe`;
trad['VPatient_IdentityContactInfoCell'] = `£Téléphone mobile`;
trad['VPatient_IdentityContactInfoCellIncorrect'] = `£Numéro de téléphone incorrect`;
trad['VPatient_IdentityContactInfoFax'] = `£Fax`;
trad['VPatient_IdentityContactInfoMail'] = `£Adresse e-mail`;
trad['VPatient_IdentityContactInfoMailInvalid'] = `£Adresse e-mail invalide`;
trad['VPatient_IdentityContactInfoAdress'] = `£Adresse`;
trad['VPatient_IdentityContactInfoRelationship'] = `£Relation`;
trad['VPatient_IdentityContactInfoRelationshipNb'] = `£Contact n°`;
trad['VPatient_Condition'] = `£Pathologie`;
trad['VPatient_Conditions'] = `£Pathologies`;
trad['VPatient_NoCondition'] = `£Aucune pathologie n'a été précisée.`;
trad['VPatient_NoConditionAdd'] = `£Cliquez ce bouton pour en saisir une première.`;
trad['VPatient_NoConditionSummary'] = `£Aucune pathologie renseignée`;
trad['VPatient_AddCondition'] = `£Ajouter une pathologie`;
trad['VPatient_AddConditionSummary'] = `£Cliquez ici pour ajouter une pathologie`;
trad['VPatient_ConditionSeverity'] = `£Gravité`;
trad['VPatient_ConditioSeverity_eLeger'] = `£Léger`;
trad['VPatient_ConditionSeverity_eModere'] = `£Modéré`;
trad['VPatient_ConditionSeverity_eSevere'] = `£Sévère`;
trad['VPatient_ConditionNeuro'] = `£Pathologies neurologiques`;
trad['VPatient_ConditionNeuroStroke'] = `£AVC`;
trad['VPatient_ConditionNeuroTrauma'] = `£Traumatisme crânien`;
trad['VPatient_ConditionNeuroPlace_eLeft'] = `£Gauche`;
trad['VPatient_ConditionNeuroPlace_eRight'] = `£Droite`;
trad['VPatient_ConditionNeuroTumor'] = `£Tumeur cérébrale`;
trad['VPatient_ConditionNeuroOther'] = `£Autre pathologie neurologique`;
trad['VPatient_ConditionNeurodegenerative'] = `£Pathologies neurodégénératives`;
trad['VPatient_ConditionNeurodegenerativeAlzheimer'] = `£Maladie d'Alzheimer`;
trad['VPatient_ConditionNeurodegenerativeAlzheimer_ePreDementiel'] = `£Pré-démentiel`;
trad['VPatient_ConditionNeurodegenerativeAlzheimer_eLeger'] = `£Léger`;
trad['VPatient_ConditionNeurodegenerativeAlzheimer_eModere'] = `£Modéré`;
trad['VPatient_ConditionNeurodegenerativeAlzheimer_eAvance'] = `£Avancé`;
trad['VPatient_ConditionNeurodegenerativeParkinson'] = `£Maladie de Parkinson`;
trad['VPatient_ConditionNeurodegenerativeParkinson_e1'] = `£1`;
trad['VPatient_ConditionNeurodegenerativeParkinson_e1_5'] = `£1.5`;
trad['VPatient_ConditionNeurodegenerativeParkinson_e2'] = `£2`;
trad['VPatient_ConditionNeurodegenerativeParkinson_e2_5'] = `£2.5`;
trad['VPatient_ConditionNeurodegenerativeParkinson_e3'] = `£3`;
trad['VPatient_ConditionNeurodegenerativeParkinson_e4'] = `£4`;
trad['VPatient_ConditionNeurodegenerativeParkinson_e5'] = `£5`;
trad['VPatient_ConditionNeurodegenerativeSEP'] = `£Sclérose en plaques`;
trad['VPatient_ConditionNeurodegenerativeSEPType_eProgressivePrimaire'] = `£Primaire progressive`;
trad['VPatient_ConditionNeurodegenerativeSEPType_eProgressiveSecondaire'] = `£Secondaire progressive`;
trad['VPatient_ConditionNeurodegenerativeSEPType_eProgressiveRecurrente'] = `£Rémittente`;
trad['VPatient_ConditionNeurodegenerativeSEPType_eCIS'] = `£Bénigne`;
trad['VPatient_ConditionNeurodegenerativeOther'] = `£Autre pathologie neurodégénérative`;
trad['VPatient_ConditionDevelopmental'] = `£Troubles développementaux et de l'apprentissage`;
trad['VPatient_ConditionDevelopmentalAutism'] = `£Autisme`;
trad['VPatient_ConditionDevelopmentalDyscalculia'] = `£Dyscalculie`;
trad['VPatient_ConditionDevelopmentalDysgraphia'] = `£Dysgraphie`;
trad['VPatient_ConditionDevelopmentalDyslexia'] = `£Dyslexie`;
trad['VPatient_ConditionDevelopmentalDysphasia'] = `£Dysphasie`;
trad['VPatient_ConditionDevelopmentalDyspraxia'] = `£Dyspraxie`;
trad['VPatient_ConditionDevelopmentalAHDH'] = `£TDAH`;
trad['VPatient_ConditionOther'] = `£Autres troubles`;
trad['VPatient_ConditionOtherGenetic'] = `£Maladie génétique rare`;
trad['VPatient_ConditionOtherOther'] = `£Autre trouble`;
trad['VPatient_ConditionCare'] = `£Prise en charge`;
trad['VPatient_ConditionComment'] = `£Commentaires`;
trad['VPatient_ConditionType'] = `£Type`;
trad['VPatient_ConditionPlace'] = `£Localisation`;
trad['VPatient_Condition1stSymptom'] = `£1er Symptomes`;
trad['VPatient_ConditionStage'] = `£Stade`;
trad['VPatient_AdditionalContact'] = `£Contact`;
trad['VPatient_NoAdditionalContact'] = `£Aucun contact n'a été renseigné.`;
trad['VPatient_NoAdditionalContactAdd'] = `£Cliquez ce bouton pour en créer un nouveau.`;
trad['VPatient_NoAdditionalContactSummary'] = `£Aucun contact renseigné`;
trad['VPatient_AddAdditionalContact'] = `£Ajouter un contact`;
trad['VPatient_AddAdditionalContactSummary'] = `£Cliquez ici pour ajouter un contact`;
trad['VPatient_NoCare'] = `£Aucune prise en charge n'a été renseignée.`;
trad['VPatient_NoCareSummary'] = `£Aucune prise en charge renseignée.`;
trad['VPatient_NoCareAdd'] = `£Cliquez ce bouton pour en spécifier une.`;
trad['VPatient_AddCare'] = `£Ajouter une prise en charge`;
trad['VPatient_AddCareSummary'] = `£Cliquez ici pour ajouter une PEC`;
trad['VPatient_CareNb'] = `£Prise en charge n°`;
trad['VPatient_CareTitle'] = `£Titre`;
trad['VPatient_CareBegin'] = `£Début`;
trad['VPatient_CareEnd'] = `£Fin`;
trad['VPatient_CareRunning'] = `£Toujours en cours`;
trad['VPatient_AddMotricitySummary'] = `£Cliquez ici pour ajouter une invalidité`;
trad['VPatient_NoMotricitySummary'] = `£Aucune incapacité renseignée`;
trad['VPatient_Motricity'] = `£Capacités motrices`;
trad['VPatient_MotricityLeft'] = `£Invalide de la main gauche`;
trad['VPatient_MotricityLeftShort'] = `£Inv. main gauche`;
trad['VPatient_MotricityRight'] = `£Invalide de la main droite`;
trad['VPatient_MotricityRightShort'] = `£Inv. main droite`;
trad['VPatient_MotricityCause'] = `£Motif de l'invalidité`;
trad['VPatient_MotricityWheelchair'] = `£Se déplace en fauteuil roulant`;
trad['VPatient_MotricityWheelchairShort'] = `£fauteuil roulant`;
trad[
  'VPatient_MotricityWarning'
] = `£Note: Ces paramètres impactent directement l'ergonomie de certaines activités de COVIRTUA Cognition. Il est donc important de correctement les renseigner.`;
trad['VPatient_Help'] = `£Avant de démarrer, créons votre premier patient`;
trad['VPatient_Comments'] = `£Commentaires`;
trad['VPatientList_Title'] = `£Liste des patients`;
trad['VPatientList_FollowedBy'] = `£Suivi par `;
trad['VPatientList_Therapists'] = `£ thérapeutes`;
trad['VPatientList_AllTherapists'] = `£Tous les thérapeutes`;
trad['VPatientList_Filter'] = `£Filtrage`;
trad['VPatientList_FilterAll'] = `£Tous`;
trad['VPatientList_FilterOwned'] = `£Les miens`;
trad['VPatientList_Group'] = `£Regroupement`;
trad['VPatientList_GroupDate'] = `£Par date`;
trad['VPatientList_GroupDateThisWeek'] = `£Cette semaine`;
trad['VPatientList_GroupDateLastWeek'] = `£La semaine dernière`;
trad['VPatientList_GroupDatePreviousLastWeek'] = `£Il y a deux semaines`;
trad['VPatientList_GroupDateEarlier'] = `£Il y a plus de deux semaines`;
trad['VPatientList_GroupName'] = `£Par nom`;
trad['VPatientList_Edit'] = `£Modifier`;
trad['shortcut_VPatientList_Edit'] = `£M`;
trad['VPatientList_View'] = `£Voir`;
trad['shortcut_VPatientList_View'] = `£V`;
trad['VPatientList_Delete'] = `£Supprimer`;
trad['VPatientList_Assign'] = `£Assigner`;
trad['VPatientList_Create'] = `£Créer`;
trad['shortcut_VPatientList_Create'] = `£C`;
trad['VPatientList_Select'] = `£Choisir`;
trad['shortcut_VPatientList_Select'] = `£Return`;
trad['VPatientList_DeleteConfirmSingle'] = `£Supprimer cette fiche patient?`;
trad['VPatientList_DeleteConfirmMultiple'] = `£Supprimer ces fiches patient?`;
trad[
  'VPatientList_DeleteConfirm'
] = `£Cette action est définitive, et vos données patients seront perdues. Confirmez-vous votre choix?`;
trad['VPatientList_TherapistAssignmentTitle'] = `£Choisissez le(s) thérapeute(s)`;
trad['VPatientList_TherapistAssignment'] = `£Assigner`;
trad['VPatientList_NoTherapistAssignment'] = `£Aucun thérapeute associé`;
trad['VPatientList_DisplayAllPatients'] = `£Afficher tous les patients`;
trad['VPatientList_FilterbyTherapist'] = `£Filtrer par thérapeute`;
trad['VPatientList_OrderDate'] = `£Trier par date`;
trad['VPatientList_OrderAlpha'] = `£Trier par ordre alphabétique`;
trad['VPictureBookEdit_Words'] = `£Mots`;
trad['VPictureBookEdit_Objects'] = `£Objets`;
trad['VPictureBookEdit_Pictures'] = `£Images`;
trad['VPictureBookEdit_Category'] = `£Catégorie:`;
trad['VPictureBookEdit_SubCategory'] = `£Sous-catégorie:`;
trad['VPictureBookEdit_Syllables'] = `£Nb Syllabes`;
trad['VPictureBookEdit_Length'] = `£Longueur:`;
trad['VPictureBookEdit_Length_Short'] = `£Court`;
trad['VPictureBookEdit_Length_Long'] = `£Long`;
trad['VPictureBookEdit_Frequency'] = `£Fréquence:`;
trad['VPictureBookEdit_Complexity'] = `£Complexité:`;
trad['VPictureBookEdit_Structure'] = `£Structure:`;
trad['VPictureBookEdit_Tone'] = `£Phonème:`;
trad['VPictureBookEdit_ToneBegining'] = `£Initial`;
trad['VPictureBookEdit_ToneMiddle'] = `£Médian`;
trad['VPictureBookEdit_ToneEnd'] = `£Final`;
trad['VPictureBookEdit_Age'] = `£Age acquis:`;
trad[
  'VPictureBookEdit_Add'
] = `£Double-cliquez ou glissez-déposez un élément de cette liste pour l'ajouter sur la page active.`;
trad['VPictureBookEdit_DisplayFilters'] = `£Afficher les filtres`;
trad['VPictureBookEdit_DeleteFilters'] = `£Supprimer les filtres`;
trad[
  'VPictureBookEdit_EmptyBookCaption'
] = `£Votre imagier est actuellement vide.&#xD;Ajoutez une première page pour commencer à le construire!`;
trad['VPictureBookEdit_DeletePage'] = `£Supprimer la page?`;
trad['VPReport_Therapist'] = `£Thérapeute: `;
trad['VPReport_Date'] = `£Date: `;
trad['VUserSelection_Title'] = `£Vous êtes...`;
trad['VUserSelection_Edition'] = `£Edition`;
trad['VUserSelection_Edition_eLiberal'] = `£Professionnels libéraux`;
trad['VUserSelection_Edition_eInstitution'] = `£Établissements de Santé`;
trad['VUserSelection_Waiting'] = `£Un petit instant s'il vous plait...`;
trad['VUserSelection_WaitingThanks'] = `£Merci de votre patience!`;
trad['PAdditionalData_DataToInstall'] = `£Certaines données doivent être préalablement installées`;
trad['PAdditionalData_InstallBut'] = `£Installer`;
trad['PAdditionalData_Installed'] = `£Installés`;
trad['PAdditionalData_NoDataInstalledTitle'] = `£Aucune donnée additionnelle n'est installée.`;
trad[
  'PAdditionalData_NoDataInstalledCaption'
] = `£Vous pouvez dès maintenant en télécharger depuis la liste ci-dessous.`;
trad['PAdditionalData_AdditionalDataError'] = `£En erreur`;
trad['PAdditionalData_AdditionalDataAvailable'] = `£Disponibles`;
trad['PAdditionalData_NoAdditionalDataAvailable'] = `£Aucune autre donnée additionnelle n'est disponible`;
trad['PAdditionalData_AdditionalDataServerErrorTitle'] = `£Attention`;
trad['PAdditionalData_AdditionalDataServerErrorCaption1'] = `£Le serveur de données additionnel est injoignable.`;
trad[
  'PAdditionalData_AdditionalDataServerErrorCaption2'
] = `£Si votre poste n'a pas d'accès à Internet, utilisez un autre poste pour télécharger les données depuis notre site web, puis cliquer sur 'Importer'.`;
trad['PCalendar_Month'] = `£Mois`;
trad[
  'PConnectionSummary_ToolTipConnected'
] = `£Une application Patient est connectée. Cliquez pour afficher plus d'informations.`;
trad['PConnectionSummary_ToolTipInterrupted'] = `£La connection de l'application patient a été interrompue.`;
trad['PConnectionSummary_ToolTipDisconnected'] = `£Aucune application Patient n'est connectée.`;
trad['PConnectionSummary_ShowPatientTitle'] = `£Information sur l'application Patient`;
trad[
  'PConnectionSummary_ShowPatientCaption'
] = `£ • Nom de la machine: [MACHINE]&#xD; • Identifiant: [ID]&#xD; • Connecté depuis: [MIN] minutes.`;
trad['PConnectionSummary_ShowPatientOK'] = `£Forcer déconnexion`;
trad['PConnectionSummary_ShowPatientCancel'] = `£Fermer`;
trad['PGraph_InsignificantTitle'] = `£Cette serie ne contient pas suffisamment de résultats`;
trad['PGraph_InsignificantCaptionBeforeIcon'] = `£Vous pouvez utiliser l'icône de paramétrage `;
trad['PGraph_InsignificantCaptionAfterIcon'] = `£pour élargir les résultats à prendre en compte dans le graphique.`;
trad['PGraph_Hypotheses'] = `£Hypothèses comparées`;
trad['PLogin_EMail'] = `£E-mail`;
trad['PLogin_Password'] = `£Mot de passe`;
trad['PLogin_LostPassword'] = `£Mot de passe oublié?`;
trad['PLogin_Register'] = `£S'inscrire`;
trad['PLogin_Connect'] = `£Connexion`;
trad['shortcut_PLogin_Connect'] = `£Return`;
trad['PExerciseDetail_SnapshotsHeader'] = `£Captures d'écran`;
trad['PExerciseDetail_ExerciseDescTimeUnit'] = `£ min`;
trad['PExerciseDetail_NoExerciseResult'] = `£Aucun résultat enregistré pour cette activité`;
trad['PExerciseDetail_WarningConnectTitle'] = `£Connectez votre poste 'Patient' !`;
trad[
  'PExerciseDetail_WarningConnectCaption'
] = `£Pour pouvoir lancer une activité COVIRTUA Cognition, vous devez d'abord exécuter l'application 'Patient' sur un poste dédié&#xD;et la connecter à l'application 'Thérapeute'. Si vous rencontrez des difficultés, `;
trad['PExerciseDetail_WarningConnectLink'] = `£cliquez ici`;
trad['PExerciseDetail_NoAdditionalDataTitle'] = `£Aucune donnée additionnele n'est installée!`;
trad[
  'PExerciseDetail_NoAdditionalDataCaption'
] = `£Cette activité exige l'installation préalable de données additionnelles.&#xD;Cliquez sur le bouton 'Données Additionnelles' et choisissez des données à installer.`;
trad['PExerciseDetail_IconScreen'] = `£Sur écran`;
trad['PExerciseDetail_IconImmersion'] = `£Immersion`;
trad['PExerciseDetail_IconPaper'] = `£Papier`;
trad['PExerciseDetail_IconSideBySide'] = `£Face à face`;
trad['PExerciseDetail_IconSupervision'] = `£Supervision`;
trad['PExerciseFilter_Choose'] = `£Veuillez choisir une famille de fonctions`;
trad['PExerciseFilter_Precision'] = `£(+précis?)`;
trad['PExerciseFilter_Executives'] = `£Fonctions executives`;
trad['PExerciseFilter_Executives_Plan'] = `£Planification`;
trad['PExerciseFilter_Executives_Anticipate'] = `£Anticipation`;
trad['PExerciseFilter_Executives_Inhibition'] = `£Inhibition`;
trad['PExerciseFilter_Executives_Decision'] = `£Prise de décision`;
trad['PExerciseFilter_Executives_Reasoning'] = `£Raisonnement`;
trad['PExerciseFilter_Attention'] = `£Attention`;
trad['PExerciseFilter_Memory'] = `£Mémoire`;
trad['PExerciseFilter_Memory_Work'] = `£Mémoire`;
trad['PExerciseFilter_Memory_Semantic'] = `£Mémoire`;
trad['PExerciseFilter_Memory_Episodic'] = `£Mémoire`;
trad['PExerciseFilter_Gnosis'] = `£Gnosies`;
trad['PExerciseFilter_Language'] = `£Langage`;
trad['PExercisePreset_Creator'] = `£Créé par `;
trad['PExercisePreset_CreationDate'] = `£Créé le `;
trad['PExercisePreset_CreationBy'] = `£ par `;
trad['PExercisePreset_SelectPreset'] = `£Choisir une configuration sauvegardée`;
trad['PExercisePreset_HeaderConfigurations'] = `£Configurations sauvegardées`;
trad['PExercisePreset_CovirtuaPresetToolTip'] = `£Configurations proposées par COVIRTUA.`;
trad['PExercisePreset_OwnPreset'] = `£Vos configurations.`;
trad['PExercisePreset_CovirtuaPreset'] = `£Les configurations proposées par COVIRTUA.`;
trad['PExercisePreset_TeamPreset'] = `£Les configurations de vos collègues.`;
trad['PExerciseResult_Comment'] = `£commentaires`;
trad['PExerciseResult_DateToolTip'] = `£Date de réalisation de l'exercice`;
trad['PPatientSummary_PatientToolTip'] = `£Consulter ou modifier le profil patient.`;
trad['PProtocole_Name'] = `£Nom`;
trad['PProtocole_NextActivity'] = `£Prochaine activité`;
trad['PProtocole_CurrentStep'] = `£Etape courante`;
trad['PProtocole_Steps'] = `£Etapes`;
trad['PProtocole_Displayed'] = `£Protocole affiché`;
trad['PProtocole_Start'] = `£Démarré le`;
trad['PProtocole_End'] = `£Terminé le`;
trad['PProtocole_Thérapist'] = `£Initié par`;
trad['PProtocole_Conditions'] = `£Conditions`;
trad['PProtocole_Running'] = `£En cours - démarré le [BEGIN]`;
trad['PProtocole_Aborted'] = `£Annulé - démarré le [BEGIN], annulé le [END]`;
trad['PProtocole_Ended'] = `£Terminé - démarré le [BEGIN], terminé le [END]`;
trad['PSession_Comment'] = `£Commentaires sur la session`;
trad['PSession_Notes'] = `£Notes pour la prochaine session`;
trad['PSession_Series'] = `£Série`;
trad['PSession_SeeAll'] = `£Tout voir`;
trad['PSession_Replay'] = `£Rejouer`;
trad['PSession_DateFormat'] = `£dd/MM/yyyy`;
trad['PSession_Today'] = `£Aujourd'hui`;
trad['PSession_Yesterday'] = `£Hier`;
trad['PSession_With'] = `£ avec `;
trad['PSession_DaysShort'] = `£ j.`;
trad['PUserSummary_Welcome'] = `£Bienvenue`;
trad['PUserSummary_ProfileToolTip'] = `£Voir ou modifier mon profil.`;
trad['PUserSummary_HelpToolTip'] = `£Accédez à l'aide en ligne.`;
trad['PUserSummary_SettingsToolTip'] = `£Paramétrez l'application.`;
trad['PUserSummary_LogoutToolTip'] = `£Saisir les commentaires de fin de séances, et changer de thérapeute.`;
trad['PUserSummary_HelpTitle'] = `£N'oubliez pas!`;
trad[
  'PUserSummary_HelpContent'
] = `£A tout moment, le bouton '?' vous permet d'accéder à la page du manuel en ligne pour votre écran ou votre activité!`;
trad['PUserSummary_HelpStop'] = `£Ne plus afficher`;
trad['PUserEdition_TitleCreate'] = `£Création d'un profil thérapeute`;
trad['PUserEdition_TitleEdit'] = `£Edition de votre profil`;
trad['PUserEdition_FirstUserGuidance'] = `£Avant de démarrer, créons le premier utilisateur du logiciel: VOUS!`;
trad['PUserCreation_FirstName'] = `£Prénom`;
trad['PUserCreation_LastName'] = `£Nom`;
trad['PUserCreation_UserTitle'] = `£Votre fonction`;
trad['PUserCreation_UserTitle_eOrthophoniste'] = `£Orthophoniste`;
trad['PUserCreation_UserTitle_eErgotherapeute'] = `£Ergothérapeute`;
trad['PUserCreation_UserTitle_eNeuropsychologue'] = `£Neuropsychologue`;
trad['PUserCreation_UserTitle_ePsychomotricien'] = `£Psychomotricien(-ne)`;
trad['PUserCreation_UserTitle_eMedecin'] = `£Médecin`;
trad['PUserCreation_UserTitle_eAnimateur'] = `£Animateur(-rice)`;
trad['PUserCreation_UserTitle_eEtudiant'] = `£Etudiant(e)`;
trad['PUserCreation_UserTitle_eChercheur'] = `£Chercheur(-euse)`;
trad['PUserCreation_UserTitle_eAutre'] = `£Autres fonctions`;
trad[
  'PUserCreation_AdditionalInfosGuidance'
] = `£Certains exercices vous permettront de parler la bouche des personnages les peuplant.`;
trad['PUserCreation_Gender'] = `£Vous êtes...`;
trad['PUserCreation_Gender_eFemale'] = `£une femme`;
trad['PUserCreation_Gender_eMale'] = `£un homme`;
trad['PUserCreation_Avatar'] = `£Votre avatar`;
trad['PUserCreation_TelemetryCheckbox'] = `£J'accepte l'envoi de données de télémétrie`;
trad[
  'PUserCreation_TelemetryDescription'
] = `£Ces données nous permettront d'améliorer les fonctionalités proposées et l'ergonomie générale du logiciel`;
trad['PUserCreation_ResultsSharingCheckbox'] = `£J'accepte l'envoi de données de résultats`;
trad[
  'PUserCreation_ResultsSharingDescription'
] = `£Si vous l'acceptez, nous récupérerons les résultats des activités, en les anonymisant totalement.&#xD;Ces données nous permettront de contrôler l'efficacité de nos activités, et, à terme, de vous proposer des parcours adaptés aux pathologies de vos patients.`;
trad['DAbout_Title'] = `£A propos de `;
trad['DAbout_Team'] = `£L'équipe`;
trad['DAbout_Dependances'] = `£Dépendances`;
trad['DAbout_Conditions'] = `£CGU`;
trad['DAbout_ConditionsDetail'] = `£Pour accéder aux conditions générales d'utilisation de COVIRTUA Cognition, `;
trad['DAbout_ConditionsLink'] = `£cliquez ici`;
trad['DCreatePreset_Title'] = `£Sauvegarde de la configuration du `;
trad[
  'DCreatePreset_Caption'
] = `£Vous pourrez retrouver cette configuration dans le panneau 'Configurations', sous l'onglet 'Mes configurations'.`;
trad['DCreatePreset_Name'] = `£Nom de la configuration`;
trad['DCreatePreset_Description'] = `£Description de la configuration`;
trad['DSessionEnd_Title'] = `£Fin de séance`;
trad['DSessionEnd_Intro'] = `£Vous allez cloturer la séance du jour de`;
trad[
  'DSessionEnd_Desc'
] = `£Vous pouvez préciser un commentaire sur la séance, et saisir un memo qui vous sera rappelé lors de sa prochaine séance.`;
trad['DSessionEnd_Notes'] = `£Notes sur la séance`;
trad['DSessionEnd_Reminder'] = `£Rappel pour la prochaine séance`;
trad['DSessionEnd_EndPrint'] = `£Terminer et imprimer le rapport`;
trad['DSessionEnd_EndExport'] = `£Terminer et exporter le rapport`;
trad['DPrint_Title'] = `£Impression en cours`;
trad['DPrint_Caption'] = `£Impression du document.`;
trad['DExtract_Title'] = `£Selectionner le fichier de destination`;
trad['DExtract_FileName'] = `£Sauvegarder ici`;
trad['DVariableChange_Title'] = `£Changer cette variable ?`;
trad[
  'DVariableChange_Caption'
] = `£En changeant cette variable, les annotations et tout le contenu des documents impactés seront supprimées.&#xD;Souhaitez-vous continuer ?`;
trad['FBAdditionalDataCB_ZipInstall'] = `£Fichier Zip`;
trad['FBAdditionalData_XmlInstall'] = `£Fichier Xml`;
trad['MBAdditionalData_DownloadingTitle'] = `£Téléchargement des données`;
trad[
  'MBAdditionalData_DownloadingCaption'
] = `£Les données [PACKAGE] sont en cours de téléchargement, merci de patienter.`;
trad['MBAdditionalData_UnzipTitle'] = `£Extraction des données`;
trad['MBAdditionalData_UnzipCaption'] = `£Les données sont en cours d'extraction, merci de patienter.`;
trad['MBAdditionalData_InstalledTitle'] = `£Données additionnelles installées`;
trad[
  'MBAdditionalData_InstalledCaption'
] = `£Vos données additionnelles ont été installées.&#xD;L'application PATIENT a été déconnectée. Connectez-la à nouveau pour finaliser la mise à jour des données.`;
trad['MBExercise_Snapshot'] = `£Captures d'écran`;
trad['MBExercisePreset_SaveCovirtuaPresetTitle'] = `£Sauvegarde réussie`;
trad[
  'MBExercisePreset_SaveCovirtuaPresetCaption'
] = `£Le preset a été sauvegardé dans votre répertoire '[PATH]'.&#xD;Le chemin a été copié dans le presse papier. Merci de zipper ce repertoire et de l'envoyer à votre développeur préféré.`;
trad['MBExercisePreset_DeletePresetTitle'] = `£Suppression d'une configuration`;
trad[
  'MBExercisePreset_DeletePresetCaption'
] = `£Etes-vous certain de souhaiter supprimer la configuration [CONFIG]?&#xD;Notez que cette opération est définitive!`;
trad['MBExercisePreset_CreateCovirtuaPresetTitle'] = `£Création d'un preset COVIRTUA`;
trad['MBExercisePreset_CreateCovirtuaPresetCaption'] = `£Promouvoir le preset '[PRESET]' ?`;
trad['MBExercisePanel_MissingDataTitle'] = `£Données manquantes`;
trad[
  'MBExercisePanel_MissingDataCaption'
] = `£Il manque certaines données additionnelles pour lancer cet exercice.&#xD;Merci de télécharger les données [PACKAGE].`;
trad['MBExperience_CancelTitle'] = `£Annuler cet exercice?`;
trad['MBExperience_CancelCaption'] = `£Voulez-vous vraiment annuler cet exercice?`;
trad['MBConfiguration_StorageMissingTitle'] = `£Attention`;
trad[
  'MBConfiguration_StorageMissingCaption'
] = `£Le repertoire de stockage configuré n'existe pas ou n'est pas accessible.&#xD;Merci de vérifier le chemin précisé.`;
trad['MBConfiguration_NetworkTitle'] = `£Choix du réseau`;
trad['MBConfiguration_NetworkCaption'] = `£Merci de choisir une interface réseau connectée.`;
trad['MBConfiguration_StorageChangeTitle'] = `£Changement du stockage des données`;
trad[
  'MBConfiguration_StorageChangeCaption'
] = `£Attention: Vous venez de modifier le repertoire de stockage des données.&#xD;Vos données actuelles (patients et thérapeutes) ne seront plus accessibles.&#xD;Si vous souhaitez basculer d'un stockage local à un stockage partagé, ou réciproquement, merci de consulter la marche à suivre sur www.covirtua.com/Support.&#xD;Confirmez-vous ce choix?`;
trad['MBLicense_WrongMachineTitle'] = `£Licence invalide`;
trad['MBLicense_WrongMachineCaption'] = `£Attention: La licence ne correspond pas à votre machine!`;
trad['MBLicense_ExpiredTitle'] = `£Licence invalide`;
trad['MBLicense_ExpiredCaption'] = `£Attention: Votre licence a expiré!`;
trad['MBMain_SupportTitle'] = `£Contacter un assistant`;
trad[
  'MBMain_SupportCaption'
] = `£Nous allons maintenant démarrer le logiciel d'assistance.&#xD;Selon votre connexion Internet, le lancement peut prendre plusieurs dizaines de secondes.&#xD;Utilisez le tchat pour nous expliquer votre problème!&#xD;N'oubliez pas de nous indiquer l'email de votre compte pour que nous puissions vous recontacter si nous ne sommes pas disponibles!`;
trad['MBMain_SupportOK'] = `£Allons-y!`;
trad['MBMain_DisconnectTitle'] = `£Déconnexion`;
trad[
  'MBMain_DisconnectCaption'
] = `£Souhaitez-vous réellement vous déconnecter?&#xD;Vos identifiants de connexion seront supprimés de ce poste, et l'application sera fermée.`;
trad['MBMain_DisconnectOK'] = `£Déconnexion!`;
trad['MBMain_ClientDisconnectTitle'] = `£Déconnexion`;
trad['MBMain_ClientDisconnectCaption'] = `£Attention! Le patient s'est déconnecté ou sa connexion n'est pas stable!`;
trad[
  'MBMain_DemoCaption'
] = `£Nous espérons que vous avez apprécié cet aperçu de l'activité '[ACTIVITY]'.&#xD; Pour profiter pleinement des activités de COVIRTUA Cognition, choisissez la formule d'abonnement qui vous convient.`;
trad['MBMain_DemoOK'] = `£S'inscrire`;
trad['FBConfig_DataStorageRepCaption'] = `£Sélectionnez le répertoire de stockage des données COVIRTUA Cognition.`;
trad['MBConfiguration_ChangeTitle'] = `£Configuration modifiée`;
trad['MBConfiguration_ChangeCaption'] = `£Vos modifications seront prises en compte au prochain démarrage du logiciel.`;
trad['MBPictureBookEdit_DemoModeTitle'] = `£Mode de démonstration`;
trad[
  'MBPictureBookEdit_DemoModeCaption'
] = `£Cet outil vous permet de construire des imagiers ou des cahiers de communication pour vos patients.&#xD;&#xD;Dans la version de démonstration, vous pourrez construire un imagier, mais ne pourrez pas l'imprimer ou le sauvegarder.`;
trad['MBProtocole_LaunchTitle'] = `£Confirmer le démarrage du protocole ?`;
trad[
  'MBProtocole_LaunchCaption'
] = `£Vous êtes sur le point de démarrer un protocole pour votre patient.&#xD;Ce protocole nécessite d'être suivi pendant plusieurs séances, sur une longue durée.&#xD;Confirmez-vous votre choix ?`;
trad['MBProtocole_InterruptTitle'] = `£Confirmer l'interruption du protocole ?`;
trad[
  'MBProtocole_InterruptCaption'
] = `£Vous êtes sur le point d'interrompre le protocole de votre patient.&#xD;Cette action est irrémédiable, et ne peut pas être annulée.&#xD;Confirmez-vous votre choix ?`;
trad['MBUserSelection_DeleteTitle'] = `£Supprimer l'utilisateur [FIRSTNAME] [LASTNAME]?`;
trad[
  'MBUserSelection_DeleteCaption'
] = `£Si vous supprimez cet utilisater, ses patients seront conservés, et pourront être réaffectés à un autre utilisateur.`;
trad['MBVersionHelper_UpdateTitle'] = `£Mise à jour des données`;
trad[
  'MBVersionHelper_UpdateCaptionIntro'
] = `£Suite à la mise à jour de l'application, les données de COVIRTUA Cognition doivent être mises à jour pour continuer.`;
trad[
  'MBVersionHelper_UpdateCaptionShared'
] = `£Merci de vous assurer qu'aucune autre machine du réseau n'est actuellement en train d'accéder aux données partagées.`;
trad[
  'MBVersionHelper_UpdateCaptionClosing'
] = `£Le processus prendra quelques secondes. Merci de ne pas éteindre la machine pendant ce temps!`;
trad['MBVersionHelper_UpdateOKCaption'] = `£Mettre à jour.`;
trad['MBVersionHelper_UpdateDoneCaption'] = `£La mise à jour des données a été correctement effectuée!`;
trad['MBVersionHelper_UpdateDoneOKCaption'] = `£Utiliser COVIRTUA Cognition.`;
trad['Error_Title'] = `£Oops!`;
trad['Error_Code'] = `£Code erreur`;
trad['Error_Link'] = `£Si le problème persiste, rendez-vous sur www.covirtua.com `;
trad[
  'Error_001001001'
] = `£Il semblerait que Covirtua Cognition soit déjà lancé sur ce PC, ou qu'une autre application l'empêche de démarrer.&#xD;Nous vous conseillons de redémarrer votre ordinateur.`;
trad[
  'Error_001001002'
] = `£Il semblerait qu'une autre application empêche Covirtua Cognition de démarrer.&#xD;Nous vous conseillons de redémarrer votre ordinateur, et éventuellement de vérifier votre pare-feu.`;
trad[
  'Error_001001003'
] = `£Il semblerait qu'une autre application empêche Covirtua Cognition de démarrer la WebCam.&#xD;Nous vous conseillons de redémarrer votre ordinateur, et éventuellement de vérifier qu'aucun autre logiciel ne tente d'accéder à la WebCam.`;
trad[
  'Error_001001004'
] = `£Il semblerait que votre configuration réseau soit dans un état instable.&#xD;Nous vous conseillons de redémarrer votre ordinateur et votre box (ou votre routeur).`;
trad[
  'Error_001001005'
] = `£Nous n'avons pas réussi à nous connecter au réseau local.&#xD;Vérifiez que le PC est connecté à votre réseau en WIFI ou par un cable réseau, et relancez l'application.`;
trad[
  'Error_001001006'
] = `£Nous n'avons pas réussi à nous connecter au réseau local.&#xD;Vérifiez que le PC est connecté à votre réseau en WIFI ou par un cable réseau, et relancez l'application.`;
trad[
  'Error_001002001'
] = `£Les données COVIRTUA Cognition présentes sur le disque sont issues d'une version plus récente que la version installée.&#xD;Merci de mettre à jour votre logiciel`;
trad[
  'Error_001002002'
] = `£Impossible de mettre à jour les données.&#xD;Il est probable qu'un processus de mise à jour soit déjà lancé sur une machine du réseau, ou que le répertoire ne soit pas accessible. Merci de réessayer ultérieurement.`;
trad[
  'Error_001002003'
] = `£Impossible de mettre à jour les données.&#xD;Il est probable qu'un processus de mise à jour soit déjà lancé sur une machine du réseau, ou que le répertoire ne soit pas accessible. Merci de réessayer ultérieurement.`;
trad[
  'Error_001002004'
] = `£Impossible de finaliser la mise à jour des données.&#xD;Veuillez redémarrer votre machine et relancer COVIRTUA Cognition`;
trad[
  'Error_001002005'
] = `£Impossible de finaliser la mise à jour des données.&#xD;Veuillez redémarrer votre machine et relancer COVIRTUA Cognition`;
trad[
  'Error_001002006'
] = `£Impossible de finaliser la mise à jour des données.&#xD;Veuillez redémarrer votre machine et relancer COVIRTUA Cognition.&#xD;Si vos données n'apparaissent plus, contactez le support.`;
trad[
  'Error_001003001'
] = `£Votre licence ne vous permet pas de lancer l'application Plateau Technique.&#xD;Contactez-nous pour mettre à jour votre licence vers une licence 'Etablissements de Santé'.`;
trad[
  'Error_001003002'
] = `£Un autre utilisateur de COVIRTUA Cognition s'est connecté avec le même profil thérapeute ou pour le même patient!&#xD;Vous allez être redirigé sur la page d'accueil de l'application.`;
trad['Error_001004001'] = `£Le téléchargement des données a échoué.&#xD;Merci de réessayer plus tard.`;
trad[
  'Error_001004002'
] = `£Une erreur est survenue lors de la décompression des données.&#xD;Verifiez votre espace disque et vos droits, puis réessayez.`;
trad[
  'Error_001004003'
] = `£Une erreur est survenue lors de l'import des données.&#xD;Vérifiez votre espace disque et vos droits, puis réessayez.`;
trad[
  'Error_001004004'
] = `£Une erreur est survenue lors du processus d'export.&#xD;Vérifiez votre espace disque et vos droits, puis réessayez.`;
trad[
  'Error_001004005'
] = `£Une erreur est survenue lors du processus d'impression.&#xD;Vérifiez l'installation de votre imprimante et de ses drivers, puis réessayez.`;
trad[
  'Error_001004006'
] = `£Une ou plusieurs extensions d'activités n'ont pas pu être chargées.&#xD;Merci de consulter le gestionnaire d'extensions dans l'application.`;
trad[
  'Error_001004007'
] = `£Impossible de sauvegarder la configuration de l'exercice. Veuillez réessayer ultérieurement.`;
trad[
  'Error_001004008'
] = `£Une erreur s'est produite à la création de la configuration. Veuillez réessayer ultérieurement.`;
trad['Error_001004009'] = `£Une configuration de même nom existe déjà. Merci de la renommer.`;
trad['Error_001004010'] = `£Le nom de la configuration contient des caractères invalides. Merci de le modifier.`;
trad[
  'Error_001004011'
] = `£Une erreur s'est produite lors de la suppression de la configuration. Veuillez réessayer ultérieurement.`;
trad[
  'PLoginCB_NetworkError'
] = `£Erreur réseau: Merci de vérifier votre connexion ou de réessayer dans quelques instants`;
trad['PLoginCB_AuthentError'] = `£Erreur d'authentification: Le login ou le mot de passe n'est pas reconnu`;
trad[
  'PLoginCB_InvalidProducts'
] = `£Erreur critique: Ce compte est associé à différents types de produits. Merci de nous contacter à support@covirtua.com`;
trad[
  'PLoginCB_NoTokenAvailable'
] = `£Nombre maximal d'installations atteint: Rendez-vous sur votre interface de gestion pour libérer des licenses`;
trad[
  'PLoginCB_LicenceExpired'
] = `£Aucune licence trouvée: Vous n'avez pas de licence COVIRTUA Cognition, ou vos licences sont expirées`;
trad['PLoginCB_Success'] = `£Authentification réussie!`;
trad['Protocols_TransitionSuccess'] = `£Au moins [SUCCESS]% de réussite sur les [ACTIVITIES] dernières activités`;
trad['Protocols_TransitionFailure'] = `£Au plus [FAILURE]% d'échec sur les [ACTIVITIES] dernières activités`;
trad['Protocols_TransitionFirstCondition'] = `£Si `;
trad['Protocols_TransitionNextTransition'] = `£ et si `;
trad['Protocols_TransitionOperationDifferent'] = `£'[RESULT]' est différent de [VALUE]`;
trad['Protocols_TransitionOperationEqual'] = `£'[RESULT]' est égal à [VALUE]`;
trad['Protocols_TransitionOperationGreater'] = `£'[RESULT]' est strictement plus grand que [VALUE]`;
trad['Protocols_TransitionOperationGreaterEq'] = `£'[RESULT]' est plus grand ou égal à [VALUE]`;
trad['Protocols_TransitionOperationLesser'] = `£'[RESULT]' est strictement plus petit que [VALUE]`;
trad['Protocols_TransitionOperationLesserEq'] = `£'[RESULT]' est plus petit ou égal à [VALUE]`;
trad['Protocols_Transition_minPCSuccessDesc'] = `£Réussite : Plus de [SUCCESS]% de bonnes réponses.`;
trad['Protocols_Memory_Name'] = `£Mém. Trv. Vis.`;
trad['Protocols_Memory_FullName'] = `£Mémoire de travail visuelle`;
trad['Protocols_Memory_Summary'] = `£Projet d'entrainement à la mémoire de travail visuelle`;
trad['Protocols_Memory_SuccessDesc'] = `£Réussite : Plus de [SUCCESS]% de bonnes réponses.`;
trad['Protocols_Memory_Success80Desc'] = `£Réussite : Plus de 80% de bonnes réponses.`;
trad['Protocols_Memory_Step0'] = `£Evaluation initiale`;
trad['Protocols_Memory_Step0Desc'] = `£Evaluation initiale, pour savoir où commencer`;
trad[
  'Protocols_Memory_Step0Act1Desc'
] = `£Activité d'étalonnage. Le résultat conditionnera l'étape initiale du protocole.`;
trad['Protocols_Memory_Step1'] = `£Phase 1`;
trad['Protocols_Memory_Step1Desc'] = `£Phase 1, facile`;
trad['Protocols_Memory_EvalStep1'] = `£Phase 1 - Validation`;
trad['Protocols_Memory_EvalStep1Desc'] = `£Phase 1 - validation, facile`;
trad['Protocols_Memory_Step2'] = `£Phase 2`;
trad['Protocols_Memory_Step2Desc'] = `£Phase 2, moyen`;
trad['Protocols_Memory_EvalStep2'] = `£Phase 2 - Validation`;
trad['Protocols_Memory_EvalStep2Desc'] = `£Phase 2 - validation, moyen`;
trad['Protocols_Memory_Step3'] = `£Phase 3`;
trad['Protocols_Memory_Step3Desc'] = `£Phase 3, difficile`;
trad['Protocols_Memory_EvalStep3'] = `£Phase 3 - Validation`;
trad['Protocols_Memory_EvalStep3Desc'] = `£Phase 3 - validation, difficile`;
trad['Categories_House'] = `£A la maison`;
trad['Categories_City'] = `£En ville`;
trad['Categories_Driving'] = `£Itineraire en ville`;
trad['Categories_Market'] = `£Au supermarché`;
trad['Categories_Attention'] = `£Attention`;
trad['Categories_Inhibition'] = `£Inhibition`;
trad['Categories_Language'] = `£Langage et communication`;
trad['Categories_Memory'] = `£Mémoire`;
trad['Common_UserManual'] = `£Voir la notice`;
trad['Common_Configuration'] = `£Configuration`;
trad['Common_Control'] = `£Contrôle`;
trad['Common_Control_Accept'] = `£Accepter avec réserves`;
trad['Common_Control_AcceptToolTip'] = `£Accepter avec réserves et passer au tirage suivant`;
trad['Common_Control_Appear'] = `£Apparaitre`;
trad['Common_Control_AppearToolTip'] = `£Apparaitre dans une fenêtre dans le monde virtuel.`;
trad['Common_Control_AutoCorrect'] = `£Correction`;
trad[
  'Common_Control_AutoCorrectToolTip'
] = `£Compter une auto-correction sur l'élément précédent, préalablement compté en erreur.`;
trad['Common_Control_Cancel'] = `£Annuler`;
trad['Common_Control_CancelToolTip'] = `£Abandonner l'activité et revenir au choix d'activité.`;
trad['Common_Control_Center'] = `£Recentrer la position`;
trad['Common_Control_CenterToolTip'] = `£Recentrer la position de l'utilisateur dans le monde virtuel.`;
trad['Common_Control_Change'] = `£Changer de mot`;
trad['Common_Control_ChangeToolTip'] = `£Changer de mot et en tirer un nouveau.`;
trad['Common_Control_Disappear'] = `£Disparaitre`;
trad['Common_Control_DisappearToolTip'] = `£Disparaitre du monde virtuel.`;
trad['Common_Control_FlipBack'] = `£Retourner`;
trad['Common_Control_FlipBackToolTip'] = `£Retourner les cartes découvertes face cachée.`;
trad['Common_Control_Hide'] = `£Masquer`;
trad['Common_Control_HideToolTip'] = `£Masquer l'affichage sur l'écran du patient.`;
trad['Common_Control_Pause'] = `£Suspendre`;
trad['Common_Control_PauseToolTip'] = `£Mettre l'activité en pause.`;
trad['Common_Control_Reject'] = `£Rejeter`;
trad['Common_Control_RejectToolTip'] = `£Refuser le tirage actuel, et passer au tirage suivant.`;
trad['Common_Control_RejectOneToolTip'] = `£Compter une erreur et passer à l'élément suivant.`;
trad['Common_Control_Resume'] = `£Reprendre`;
trad['Common_Control_ResumeToolTip'] = `£Reprendre l'activité.`;
trad['Common_Control_RoadBookHide'] = `£Masquer la feuille`;
trad['Common_Control_RoadBookHideToolTip'] = `£Masquer la feuille de route sur l'écran du patient.`;
trad['Common_Control_RoadBookShow'] = `£Afficher la feuille`;
trad['Common_Control_RoadBookShowToolTip'] = `£Afficher la feuille de route sur l'écran du patient.`;
trad['Common_Control_SetCamera'] = `£Régler la vue`;
trad['Common_Control_SetCameraToolTip'] = `£Définir l'angle de vue de la caméra.`;
trad['Common_Control_Show'] = `£Afficher`;
trad['Common_Control_ShowToolTip'] = `£Afficher l'activité sur l'écran du patient.`;
trad['Common_Control_Skip'] = `£Passer`;
trad['Common_Control_SkipToolTip'] = `£Passer au tirage suivant, sans compter ni succès ni echec.`;
trad['Common_Control_Start'] = `£Démarrer`;
trad['Common_Control_StartToolTip'] = `£Démarrer l'activité.`;
trad['Common_Control_Startover'] = `£Relancer`;
trad['Common_Control_Terminate'] = `£Terminer`;
trad['Common_Control_TerminateToolTip'] = `£Terminer l'activité et sauvegarder le résultat.`;
trad['Common_Control_Validate'] = `£Valider`;
trad['Common_Control_ValidateToolTip'] = `£Valider le tirage en cours et basculer sur le tirage suivant.`;
trad['Common_Control_ValidateOneToolTip'] = `£Compter un succès et passer à l'élément suivant.`;
trad['Common_Control_ValidateSupported'] = `£Valider avec étayage`;
trad['Common_Control_ValidateSupportedToolTip'] = `£Valider en comptant un étayage, et passer au tirage suivant.`;
trad['Common_Control_ValidateCategory'] = `£Valider la catégorie`;
trad['Common_Control_ValidateCategoryToolTip'] = `£Valider la catégorie et débloque l'étape suivante.`;
trad['shortcut_Common_Control_Accept'] = `£F`;
trad['shortcut_Common_Control_Appear'] = `£T`;
trad['shortcut_Common_Control_Change'] = `£C`;
trad['shortcut_Common_Control_Center'] = `£P`;
trad['shortcut_Common_Control_FlipBack'] = `£Space`;
trad['shortcut_Common_Control_HideRoadbook'] = `£F`;
trad['shortcut_Common_Control_Pause'] = `£Space`;
trad['shortcut_Common_Control_Reject'] = `£N`;
trad['shortcut_Common_Control_SetCamera'] = `£C`;
trad['shortcut_Common_Control_Skip'] = `£X`;
trad['shortcut_Common_Control_StartOver'] = `£R`;
trad['shortcut_Common_Control_Validate'] = `£V`;
trad['shortcut_Common_Control_ValidateSupported'] = `£F`;
trad['Common_Parameter_Tab_AutoDistractors'] = `£Distracteurs automatiques`;
trad['Common_Parameter_Tab_Average'] = `£Moyennes par élément`;
trad['Common_Parameter_Tab_Categories'] = `£Spécifications des catégories`;
trad[
  'Common_Parameter_Tab_CategoriesToolTip'
] = `£Cet ensemble de paramètres permet de définir les catégories des substantifs à utiliser dans l'exercice.`;
trad['Common_Parameter_Tab_Configs'] = `£Compositions`;
trad[
  'Common_Parameter_Tab_ConfigsToolTip'
] = `£Description des compositions qui vont contenir les couleurs que le patient devra reternir.`;
trad['Common_Parameter_Tab_Display'] = `£Affichage`;
trad['Common_Parameter_Tab_Distractors'] = `£Distracteurs continus`;
trad['Common_Parameter_Tab_General'] = `£Paramètres généraux`;
trad['Common_Parameter_Tab_GPSConfig'] = `£Configuration du GPS`;
trad['Common_Parameter_Tab_Help'] = `£Aides`;
trad['Common_Parameter_Tab_Interf'] = `£Interférences`;
trad['Common_Parameter_Tab_Items'] = `£Éléments`;
trad['Common_Parameter_Tab_Layout'] = `£Disposition`;
trad['Common_Parameter_Tab_Mode'] = `£Configuration du mode`;
trad['Common_Parameter_Tab_Roadmap'] = `£Feuille de route`;
trad['Common_Parameter_Tab_Specs'] = `£Spécification des substantifs`;
trad[
  'Common_Parameter_Tab_SpecsToolTip'
] = `£Cet ensemble de paramètres permet de définir les attributs des substantifs à utiliser dans l'exercice.`;
trad['Common_Parameter_Tab_Targets'] = `£Configuration des cibles`;
trad['Common_Parameter_Tab_Tools'] = `£Outils`;
trad['Common_Parameter_Tab_Topology'] = `£Topologie`;
trad['Common_Parameter_Tab_Surroundings'] = `£Environnement sonore`;
trad[
  'Common_Parameter_Tab_SurroundingsToolTip'
] = `£Cet ensemble de paramètres permet de définir l'environnement sonore du supermarché, dont la musique d'ambiance et les annonces vocales.`;
trad['Common_Parameter_eIndifferent'] = `£Indifférent`;
trad['Common_Parameter_eWith'] = `£Avec`;
trad['Common_Parameter_eWithout'] = `£Sans`;
trad['Common_Parameter_AllowPatient'] = `£Autoriser le patient à manipuler`;
trad[
  'Common_Parameter_AllowPatientToolTip'
] = `£Ce paramètre permet d'autoriser ou de bloquer la manipulation du graph par le patient.`;
trad['Common_Parameter_Alternative'] = `£Variante`;
trad['Common_Parameter_Alternative_MapComplexity'] = `£Variante de la carte`;
trad['Common_Parameter_Alternative_Scenario'] = `£Variante du scénario`;
trad['Common_Parameter_Alternative_TransportComplexity'] = `£Variante de la fiche`;
trad['Common_Parameter_AllowNext'] = `£Permettre au patient de passer`;
trad['Common_Parameter_AllowNext§Reduced'] = `£Autorisé à passer`;
trad['Common_Parameter_AllowNext§Short'] = `£Peut passer`;
trad[
  'Common_Parameter_AllowNext§ToolTip'
] = `£Ce paramètre permet d'autoriser le patient à passer à la configuration suivante dès qu'il estime avoir mémorisé.`;
trad['Common_Parameter_Anim'] = `£Déplacement continu des éléments`;
trad['Common_Parameter_Anim§Reduced'] = `£Déplacement continu`;
trad['Common_Parameter_Anim§Short'] = `£Déplacement`;
trad[
  'Common_Parameter_Anim§ToolTip'
] = `£Ce paramètre indique si l'ensemble des éléments affichés doivent être en mouvement continu ou fixes.`;
trad['Common_Parameter_AnimType'] = `£Déplacement continu des éléments`;
trad['Common_Parameter_AnimType'] = `£Type d'anim.`;
trad['Common_Parameter_AnimTypeDesc'] = `£Type de mouvement des éléments`;
trad[
  'Common_Parameter_AnimTypeToolTip'
] = `£Ce paramètre indique si l'ensemble des éléments affichés doivent être animés ou fixes.`;
trad['Common_Parameter_AnimType_eNone'] = `£Aucune`;
trad['Common_Parameter_AnimType_eMoving'] = `£Déplacement continu`;
trad['Common_Parameter_Announcement'] = `£Annonces vocales`;
trad['Common_Parameter_AnswerType'] = `£Type`;
trad[
  'Common_Parameter_AnswerTypeToolTip'
] = `£Ce paramètre permet de définir le type de feuille de réponse (Guidée ou Libre) mise à disposition du patient.`;
trad['Common_Parameter_AnswerType_eGuidee'] = `£Guidée`;
trad['Common_Parameter_AnswerType_eLibre'] = `£Libre`;
trad['Common_Parameter_AutoDistractor'] = `£Ajout de distracteurs automatiques`;
trad['Common_Parameter_AutoDistractor§Reduced'] = `£Distracteurs automatiques`;
trad['Common_Parameter_AutoDistractor§Short'] = `£Distract. auto.`;
trad[
  'Common_Parameter_AutoDistractor§ToolTip'
] = `£Ce paramètre permet de définir l'utilisation de distracteurs automatiques. Le patient devra les inhiber et maintenir son attention sur la tâche en cours.`;
trad['Common_Parameter_AutoDistractorActive'] = `£Distracteurs automatiques actifs`;
trad['Common_Parameter_AutoDistractor_eNone'] = `£Aucun`;
trad[
  'Common_Parameter_AutoDistractor_eNoneToolTip'
] = `£Avec cette option, aucun distracteur automatique n'a été utilisé au lancement de l'exercice.`;
trad['Common_Parameter_AutoDistractor_eMultiple'] = `£Multiple`;
trad[
  'Common_Parameter_AutoDistractor_eMultipleToolTip'
] = `£Avec cette option, plusieurs distracteurs automatiques ont été utilisés dès le lancement de l'exercice.`;
trad['Common_Parameter_AutoDistractor_eAudio'] = `£Distracteurs audio`;
trad['Common_Parameter_AutoDistractor_eAudio§Short'] = `£Audio`;
trad[
  'Common_Parameter_AutoDistractor_eAudio§ToolTip'
] = `£Ce paramètre permet de jouer aléatoirement des sons marqués (travaux, bris de glace,...).`;
trad['Common_Parameter_AutoDistractor_eVideo'] = `£Distracteurs animés`;
trad['Common_Parameter_AutoDistractor_eVideo§Short'] = `£Vidéo`;
trad[
  'Common_Parameter_AutoDistractor_eVideo§ToolTip'
] = `£Ce paramètre permet de jouer aléatoirement de petites animations (type dessins animés).`;
trad['Common_Parameter_AutoDistractor_eSimulTask'] = `£Doubles tâches`;
trad['Common_Parameter_AutoDistractor_eSimulTask§Short'] = `£Double`;
trad[
  'Common_Parameter_AutoDistractor_eSimulTask§ToolTip'
] = `£Ce paramètre permet de lancer aléatoirement une activité simple.&#xD;Une mongolfière en mouvement apparait à l'écran. L'utilisateur doit cliquer ou appuyer dessus pour la faire disparaitre.`;
trad['Common_Parameter_AutoDistractorFrequency'] = `£Fréquence d'apparition des distracteurs`;
trad['Common_Parameter_AutoDistractorFrequency§Reduced'] = `£Fréquence des distracteurs`;
trad['Common_Parameter_AutoDistractorFrequency§Short'] = `£Fréq. dist.`;
trad[
  'Common_Parameter_AutoDistractorFrequency§ToolTip'
] = `£Ce paramètre permet de contrôler la fenêtre de fréquence d'apparition des distracteurs automatiques (Désactivée, Faible, modérée ou élevée).`;
trad[
  'Common_Parameter_AutodistractorFrequency_eLowToolTip'
] = `£Avec cette option, un distracteur est lancé avec une périodicité aléatoire comprise entre 15 et 25 secondes.`;
trad[
  'Common_Parameter_AutodistractorFrequency_eMediumToolTip'
] = `£Avec cette option, un distracteur est lancé avec une périodicité aléatoire comprise entre 5 et 10 secondes.`;
trad[
  'Common_Parameter_AutodistractorFrequency_eHighToolTip'
] = `£Avec cette option, un distracteur est lancé avec une périodicité aléatoire comprise entre 2 et 7 secondes.`;
trad['Common_Parameter_Autozoom'] = `£Zoom auto`;
trad['Common_Parameter_AutozoomDesc'] = `£Zoom automatique`;
trad[
  'Common_Parameter_AutozoomToolTip'
] = `£Ce paramètre permet de zoomer automatiquement sur une carte dès qu'elle est révelée.`;
trad['Common_Parameter_Background'] = `£Afficher le décor`;
trad['Common_Parameter_Background§Reduced'] = `£Décor`;
trad['Common_Parameter_Background§ShortShort'] = `£Décor`;
trad['Common_Parameter_Background§ToolTip'] = `£Ce paramètre permet d'afficher un décor en arrière plan.`;
trad['Common_Parameter_Begin'] = `£Son initial`;
trad['Common_Parameter_BeginDesc'] = `£Sonorité initiale du substantif`;
trad['Common_Parameter_BeginToolTip'] = `£Ce paramètre indique la sonorité initiale du substantif utilisé.`;
trad['Common_Parameter_Cancel'] = `£Type de barrage`;
trad['Common_Parameter_Cancel§Reduced'] = `£Type barrage`;
trad['Common_Parameter_Cancel§Short'] = `£Barrage`;
trad[
  'Common_Parameter_Cancel§ToolTip'
] = `£Ce paramètre indique le type de barrage utilisé dans l'exercice (simple ou double).`;
trad['Common_Parameter_Cancel_eSimple'] = `£Barrage simple`;
trad['Common_Parameter_Cancel_eSimple§Short'] = `£Simple`;
trad[
  'Common_Parameter_Cancel_eSimple§ToolTip'
] = `£Avec cette option, l'exercice consiste en une recherche de tous les exemplaires d'un seul élément.`;
trad['Common_Parameter_Cancel_eDouble'] = `£Barrage double`;
trad['Common_Parameter_Cancel_eDouble§Short'] = `£Double`;
trad[
  'Common_Parameter_Cancel_eDouble§ToolTip'
] = `£Avec cette option, l'exercice consiste en une recherche de tous les exemplaires de deux éléments distincts.`;
trad['Common_Parameter_Cancel_eTriple'] = `£Barrage triple`;
trad['Common_Parameter_Cancel_eTriple§Short'] = `£Triple`;
trad[
  'Common_Parameter_Cancel_eTriple§ToolTip'
] = `£Avec cette option, l'exercice consiste en une recherche de tous les exemplaires de trois élément.`;
trad['Common_Parameter_Categories_Mother'] = `£Catégorie mère`;
trad['Common_Parameter_Cancel_eQuadruple'] = `£Barrage quadruple`;
trad['Common_Parameter_Cancel_eQuadruple§Short'] = `£quadruple`;
trad[
  'Common_Parameter_Cancel_eQuadruple§ToolTip'
] = `£Avec cette option, l'exercice consiste en une recherche de tous les exemplaires de quatre élément.`;
trad['Common_Parameter_Categories_N1'] = `£Première catégorie`;
trad['Common_Parameter_Categories_N2'] = `£Deuxième catégorie`;
trad['Common_Parameter_Categories_N3'] = `£Troisième catégorie`;
trad['Common_Parameter_CategoriesSelection'] = `£Choisir les catégories`;
trad['Common_Parameter_SelectedCategories§Reduced'] = `£Categories`;
trad[
  'Common_Parameter_CategoriesSelection§ToolTip'
] = `£Ce paramètre permet de définir si les catégories sont choisies ou selectionnées au hasard.`;
trad['Common_Parameter_Category'] = `£Catégorie`;
trad['Common_Parameter_CategoryDesc'] = `£Catégorie sémantique`;
trad['Common_Parameter_CategoryToolTip'] = `£Ce paramètre indique la catégorie sémantique du substantif utilisé.`;
trad['Common_Parameter_Category_eAnimals'] = `£Animaux`;
trad['Common_Parameter_Category_eAnimalsToolTip'] = `£Avec cette option, les éléments sont les plus variés.`;
trad['Common_Parameter_Category_eVegetables'] = `£Fruits et légumes`;
trad['Common_Parameter_Category_eDress'] = `£Habillement`;
trad['Common_Parameter_Category_eJobs'] = `£Métiers`;
trad['Common_Parameter_Category_ePlants'] = `£Végétaux`;
trad[
  'Common_Parameter_Category_ePlantsToolTip'
] = `£Avec cette option, le patient fait face à de nombreuses images auxquelles il est difficile d'associer un nom: la mémorisation est donc principalement visuelle.`;
trad['Common_Parameter_Category_eRandom'] = `£Aléatoire`;
trad[
  'Common_Parameter_Category_eRandomToolTip'
] = `£Avec cette option, les éléments sont tirés dans l'ensemble de la base lexicale disponible`;
trad['Common_Parameter_CharListDesc'] = `£Caractères proposées`;
trad['Common_Parameter_CharList'] = `£Caractères`;
trad[
  'Common_Parameter_CharListToolTip'
] = `£Cette liste permet de définir les caractères qui seront proposées au patient en cours d'exercice (chiffres, lettres ou les deux).`;
trad['Common_Parameter_Char_eLetters'] = `£Lettres`;
trad['Common_Parameter_Char_eNumbers'] = `£Chiffres`;
trad['Common_Parameter_Char_eLettersNumbers'] = `£Chiffres et lettres`;
trad['Common_Parameter_Char_eLettersNumbersShort'] = `£Chif. + let.`;
trad['Common_Parameter_Colors'] = `£Couleurs utilisées`;
trad['Common_Parameter_Colors§Reduced'] = `£Couleurs`;
trad['Common_Parameter_Colors§Short'] = `£Couleurs`;
trad[
  'Common_Parameter_Colors§ToolTip'
] = `£Cette liste permet de définir les couleurs qui seront utilisées en cours d'exercice (parmi blanc, rouge, vert, bleu, jaune et noir).`;
trad['Common_Parameter_Colors_eWhite'] = `£Blanc`;
trad['Common_Parameter_Colors_eRed'] = `£Rouge`;
trad['Common_Parameter_Colors_eGreen'] = `£Vert`;
trad['Common_Parameter_Colors_eBlue'] = `£Bleu`;
trad['Common_Parameter_Colors_eYellow'] = `£Jaune`;
trad['Common_Parameter_Colors_eBlack'] = `£Noir`;
trad['Common_Parameter_Colors_ePink'] = `£Rose`;
trad['Common_Parameter_Colors_eGrey'] = `£Gris`;
trad['Common_Parameter_Colors_ePurple'] = `£Violet`;
trad['Common_Parameter_Colors_eOrange'] = `£Orange`;
trad['Common_Parameter_ColorUseDesc'] = `£Utilisation des couleurs`;
trad['Common_Parameter_ColorUse'] = `£Couleurs`;
trad[
  'Common_Parameter_ColorUseToolTip'
] = `£Ce paramètre permet de définir l'utilisation de la couleur pour les éléments(pas de couleur, une seule couleur, toutes les couleurs).`;
trad['Common_Parameter_ColorUse_eNone'] = `£Pas de couleur`;
trad[
  'Common_Parameter_ColorUse_eNoneToolTip'
] = `£Avec cette option, tous les éléments sont affichés en monochrome (noir).`;
trad['Common_Parameter_ColorUse_eOne'] = `£Une seule couleur`;
trad[
  'Common_Parameter_ColorUse_eOneToolTip'
] = `£Avec cette option, pour chaque élément aléatoirement selectionné, une couleur est choisie au hasard pour constituer une paire.`;
trad['Common_Parameter_ColorUse_eTwo'] = `£Deux couleurs`;
trad[
  'Common_Parameter_ColorUse_eTwoToolTip'
] = `£Avec cette option, pour chaque élément aléatoirement selectionné, deux couleurs sont choisies au hasard parmi celles selectionnées pour constituer deux paire.`;
trad['Common_Parameter_ColorUse_eThree'] = `£Trois couleurs`;
trad[
  'Common_Parameter_ColorUse_eThreeToolTip'
] = `£Avec cette option, pour chaque élément aléatoirement selectionné, trois couleurs sont choisie au hasard parmi celles selectionnées pour constituer trois paire.`;
trad['Common_Parameter_ColorUse_eAll'] = `£Toutes les couleurs.`;
trad[
  'Common_Parameter_ColorUse_eAllToolTip'
] = `£Avec cette option, pour chaque élément aléatoirement selectionné, une nouvelle paire est constituée avec chaque couleur selectionnée.`;
trad['Common_Parameter_Complexity§Reduced'] = `£Complexité`;
trad['Common_Parameter_Complexity§Short'] = `£Complexité`;
trad['Common_Parameter_Complexity_eVerySimple'] = `£Très simple`;
trad['Common_Parameter_Complexity_eSimple'] = `£Simple`;
trad['Common_Parameter_Complexity_eAverage'] = `£Moyenne`;
trad['Common_Parameter_Complexity_eComplex'] = `£Complexe`;
trad['Common_Parameter_Complexity_eHard'] = `£Difficile`;
trad['Common_Parameter_Complexity_eVeryHard'] = `£Très difficile`;
trad['Common_Parameter_Configs'] = `£Compositions`;
trad['Common_Parameter_ConfigsDesc'] = `£Compositions à utiliser`;
trad['Common_Parameter_Config_e2x2'] = `£Grille 2x2`;
trad['Common_Parameter_Config_e4Circles'] = `£4 Cercles`;
trad['Common_Parameter_Config_e2x3'] = `£Grille 2x3`;
trad['Common_Parameter_Config_e6Circles'] = `£6 Cercles`;
trad['Common_Parameter_Config_e3x3'] = `£Grille 3x3`;
trad['Common_Parameter_Config_e10Circles'] = `£10 Cercles`;
trad['Common_Parameter_Config_e10Triangles'] = `£10 Triangles`;
trad['Common_Parameter_Config_e4x4'] = `£Grille 4x4`;
trad['Common_Parameter_Config_e16Triangles'] = `£16 Triangles`;
trad['Common_Parameter_Config_e5x5'] = `£Grille 5x5`;
trad['Common_Parameter_Config_eCube3x3'] = `£Cube 3x3`;
trad['Common_Parameter_Config_e20Triangles'] = `£20 Triangles`;
trad['Common_Parameter_CountTarget'] = `£Cibles à compter`;
trad['Common_Parameter_CountTarget§Reduced'] = `£Cibles`;
trad['Common_Parameter_CountTarget§Short'] = `£Cibles`;
trad[
  'Common_Parameter_CountTarget§ToolTip'
] = `£Ce paramètre précise la nature des élément que le patient devra compter (chiffre ou carré).`;
trad['Common_Parameter_CountTarget_eNumbers'] = `£Compter les chiffres`;
trad['Common_Parameter_CountTarget_eNumbers§Short'] = `£Chiffres`;
trad[
  'Common_Parameter_CountTarget_eNumbers§ToolTip'
] = `£Avec cette option, le patient devra compter le nombre de chiffres affichés (indépendamment de leur valeur individuelle).`;
trad['Common_Parameter_CountTarget_eSquares'] = `£Compter les carrés`;
trad['Common_Parameter_CountTarget_eSquares§Short'] = `£Carrés`;
trad[
  'Common_Parameter_CountTarget_eSquares§ToolTip'
] = `£Avec cette option, le patient devra compter le nombre de carrés affichés (indépendament des chiffres qu'ils contiennent éventuellement).`;
trad['Common_Parameter_CouplingRule'] = `£Appairage`;
trad['Common_Parameter_CouplingRuleDesc'] = `£Méthode utilisée pour appairer les éléments`;
trad[
  'Common_Parameter_CouplingRuleToolTip'
] = `£Ce paramètre permet de définir comment les paires vont être réalisées (par élément identiques ou par association).`;
trad['Common_Parameter_CouplingRule_eIdentityWordShort'] = `£Même mot`;
trad[
  'Common_Parameter_CouplingRule_eIdentityWordToolTip'
] = `£Avec cette option, deux cartes sont associées si elles illustrent un substantif identique.`;
trad['Common_Parameter_CouplingRule_eIdentityCharShort'] = `£Même car.`;
trad[
  'Common_Parameter_CouplingRule_eIdentityCharToolTip'
] = `£Avec cette option, deux cartes sont associées si elles représentent un caractère identique.`;
trad['Common_Parameter_CouplingRule_eAssociation'] = `£Associées`;
trad['Common_Parameter_CouplingRule_eAssociationDesc'] = `£Par association`;
trad[
  'Common_Parameter_CouplingRule_eAssociationToolTip'
] = `£Avec cette option, les éléments sont à rapprocher par paire grâce à diverses associations.`;
trad['Common_Parameter_CouplingRule_eIdentity'] = `£Identiques`;
trad['Common_Parameter_CouplingRule_eIdentityDesc'] = `£Par paires`;
trad[
  'Common_Parameter_CouplingRule_eIdentityToolTip'
] = `£Avec cette option, les éléments sont à rapprocher par paires parfaitement identiques.`;
trad['Common_Parameter_CouplingRuleAssociations'] = `£Association`;
trad['Common_Parameter_CouplingRuleAssociationsDesc'] = `£Règle utilisées pour associer les éléments`;
trad[
  'Common_Parameter_CouplingRuleAssociationsToolTip'
] = `£Ce paramètre permet de définir comment associer deux cartes (majuscule/minuscule, image/substantif, image/Initiale).`;
trad['Common_Parameter_CouplingRuleAssociations_eUpperLower'] = `£Majuscule / Minuscule`;
trad[
  'Common_Parameter_CouplingRuleAssociations_eUpperLowerToolTip'
] = `£Avec cette option, deux cartes sont associées si elles représente une version minuscule et une version majuscule de la même lettre.`;
trad['Common_Parameter_CouplingRuleAssociations_eUpperLowerShort'] = `£MAJ/min`;
trad['Common_Parameter_CouplingRuleAssociations_ePicName'] = `£Image / Substantif`;
trad[
  'Common_Parameter_CouplingRuleAssociations_ePicNameToolTip'
] = `£Avec cette option, deux cartes sont associées si l'une représente une image, et l'autre indique le nom associé.`;
trad['Common_Parameter_CouplingRuleAssociations_ePicNameShort'] = `£Image/Mot`;
trad['Common_Parameter_CouplingRuleAssociations_eNumSymb'] = `£Nombre / Symbole`;
trad[
  'Common_Parameter_CouplingRuleAssociations_eNumSymbToolTip'
] = `£Avec cette option, deux cartes sont associées si l'une représente un symbole répété plusieurs fois, et l'autre indique, par un chiffre, ce nombre de répétitions.`;
trad['Common_Parameter_CouplingRuleAssociations_eColName'] = `£Couleur / Nom de la couleur`;
trad['Common_Parameter_CouplingRuleAssociations_eColNameShort'] = `£Couleur/Nom`;
trad[
  'Common_Parameter_CouplingRuleAssociations_eColNameToolTip'
] = `£Avec cette option, deux cartes sont associées si l'une est d'une couleur et l'autre comporte le nom de la couleur.`;
trad['Common_Parameter_CouplingRuleAssociations_eNumOp'] = `£Opération / Nombre`;
trad[
  'Common_Parameter_CouplingRuleAssociations_eNumOpToolTip'
] = `£Avec cette option, deux cartes sont associées si l'une indique une opération arithmétique, et l'autre indique le résultat.`;
trad['Common_Parameter_DefineInstructionsDesc'] = `£Définir les instructions`;
trad[
  'Common_Parameter_DefineInstructionsToolTip'
] = `£Ce paramètre permet de définir une à une toutes les instructions qui seront proposées au patient, en lieu et place de la génération aléatoire de la feuille de route.`;
trad['Common_Parameter_DefineInstruction1'] = `£Première instruction`;
trad['Common_Parameter_DefineInstruction2'] = `£Deuxième instruction`;
trad['Common_Parameter_DefineInstruction3'] = `£Troisième instruction`;
trad['Common_Parameter_DefineInstruction4'] = `£Quatrième instruction`;
trad['Common_Parameter_DefineInstruction5'] = `£Cinquième instruction`;
trad['Common_Parameter_DefineInstruction6'] = `£Sixième instruction`;
trad['Common_Parameter_DefineInstruction_1L'] = `£1ère à gauche`;
trad['Common_Parameter_DefineInstruction_2L'] = `£2ème à gauche`;
trad['Common_Parameter_DefineInstruction_3L'] = `£3ème à gauche`;
trad['Common_Parameter_DefineInstruction_1R'] = `£1ère à droite`;
trad['Common_Parameter_DefineInstruction_2R'] = `£2ème à droite`;
trad['Common_Parameter_DefineInstruction_3R'] = `£3ème à droite`;
trad['Common_Parameter_DefineInstruction_R1'] = `£1ère sortie au prochain rond point`;
trad['Common_Parameter_DefineInstruction_R2'] = `£2ème sortie au prochain rond point`;
trad['Common_Parameter_DefineInstruction_R3'] = `£3ème sortie au prochain rond point`;
trad['Common_Parameter_Depth'] = `£Rang de l'élément à retenir`;
trad['Common_Parameter_Depth§Reduced'] = `£Profondeur`;
trad['Common_Parameter_Depth§Short'] = `£Profondeur`;
trad[
  'Common_Parameter_Depth§ToolTip'
] = `£Ce paramètre indique le nombre d'éléments successifs à mémoriser avant l'annonce.`;
trad['Common_Parameter_Depth_eN1'] = `£Element précédent (N - 1)`;
trad['Common_Parameter_Depth_eN1§Short'] = `£N-1`;
trad['Common_Parameter_Depth_eN2'] = `£Element N - 2`;
trad['Common_Parameter_Depth_eN2§Short'] = `£N-2`;
trad['Common_Parameter_Depth_eN3'] = `£Element N - 3`;
trad['Common_Parameter_Depth_eN3§Short'] = `£N-3`;
trad['Common_Parameter_Depth_eN4'] = `£Element N - 4`;
trad['Common_Parameter_Depth_eN4§Short'] = `£N-4`;
trad['Common_Parameter_Display'] = `£Affichage des éléments`;
trad['Common_Parameter_Display§Reduced'] = `£Affichage des éléments`;
trad['Common_Parameter_Display§Short'] = `£Affichage`;
trad[
  'Common_Parameter_Display§ToolTip'
] = `£Ce paramètre indique le type d'affichage des éléments pendant l'activité (image, nom ou les deux).`;
trad['Common_Parameter_Display_PhonoHelpDesc'] = `£Affichage des aides phonologiques`;
trad['Common_Parameter_Display_PhonoHelp'] = `£Affichage aides phono.`;
trad['Common_Parameter_Display_SemanticHelpDesc'] = `£Affichage des aides sémantiques`;
trad['Common_Parameter_Display_SemanticHelp'] = `£Affichage aides sémant.`;
trad['Common_Parameter_Display_eTextPict'] = `£Affichage des noms et des images`;
trad['Common_Parameter_Display_eTextPictSingle'] = `£Affichage du nom et de l'image`;
trad['Common_Parameter_Display_eTextPict§Short'] = `£Nom + Image`;
trad[
  'Common_Parameter_Display_eTextPict§ToolTip'
] = `£Avec cette option, le nom et l'image des objets sont affichés: les traitements phonologique et sémantiques sont activés, ce qui peut rendre l'exercice plus difficile.`;
trad['Common_Parameter_Display_eTextOnly'] = `£Affichage des noms`;
trad['Common_Parameter_Display_eTextOnlySingle'] = `£Affichage du nom`;
trad['Common_Parameter_Display_eTextOnly§Reduced'] = `£Noms`;
trad['Common_Parameter_Display_eTextOnly§Short'] = `£Noms`;
trad[
  'Common_Parameter_Display_eTextOnly§ToolTip'
] = `£Avec cette option, uniquement le nom des objets est affiché: seul le traitement phonologique est ainsi activé .`;
trad['Common_Parameter_Display_ePictOnly'] = `£Affichage des images`;
trad['Common_Parameter_Display_ePictOnlySingle'] = `£Affichage de l'image`;
trad['Common_Parameter_Display_ePictOnly§Reduced'] = `£Images`;
trad['Common_Parameter_Display_ePictOnly§Short'] = `£Images`;
trad[
  'Common_Parameter_Display_ePictOnly§ToolTip'
] = `£Avec cette option, uniquement l'image des objets est affichée: seul le traitement sémantique est ainsi activé.`;
trad['Common_Parameter_DisplayCategories'] = `£Affichage d'aide sur les catégories`;
trad['Common_Parameter_DisplayCategories§Reduced'] = `£Affichage catégories`;
trad['Common_Parameter_DisplayCategories§Short'] = `£Aff. cat.`;
trad[
  'Common_Parameter_DisplayCategories§ToolTip'
] = `£Ce paramètre indique le type d'aide affichée pour les catégories (aucune aide, uniquement le nombre, ou également leurs noms respectifs).`;
trad['Common_Parameter_DisplayCategories_eNone'] = `£Aucune aide`;
trad['Common_Parameter_DisplayCategories_eNone§Short'] = `£Rien`;
trad[
  'Common_Parameter_DisplayCategories_eNone§ToolTip'
] = `£Avec cette option, aucune aide de catégorisation n'est affichée.`;
trad['Common_Parameter_DisplayCategories_eNumber'] = `£Indication du nombre`;
trad['Common_Parameter_DisplayCategories_eNumber§Short'] = `£Nombre`;
trad['Common_Parameter_DisplayCategories_eNumber§ToolTip'] = `£Avec cette option, le nombre de catégories est précisé.`;
trad['Common_Parameter_DisplayCategories_eName'] = `£Indication des noms de catégorie`;
trad['Common_Parameter_DisplayCategories_eName§Short'] = `£Catégories`;
trad[
  'Common_Parameter_DisplayCategories_eName§ToolTip'
] = `£Avec cette option, le nombre de catégories et leurs noms respectifs sont affichés.`;
trad['Common_Parameter_DisplayPersistence'] = `£Persistance`;
trad['Common_Parameter_DisplayPersistenceDesc'] = `£Persistance des instructions`;
trad[
  'Common_Parameter_DisplayPersistenceToolTip'
] = `£Ce paramètre permet de forcer l'affichage persistent de l'information affichée sur le GPS, elle est mise à jour en fonction des actions de l'utilisateur.`;
trad['Common_Parameter_DisplaySolution'] = `£Afficher la réponse`;
trad[
  'Common_Parameter_DisplaySolutionToolTip'
] = `£Ce paramètre permet d'afficher la réponse sur les postes thérapeute et patient.`;
trad['Common_Parameter_DisplayRight'] = `£Paires trouvées`;
trad['Common_Parameter_DisplayRightDesc'] = `£Affichage des réponses passées`;
trad[
  'Common_Parameter_DisplayRightToolTip'
] = `£Ce paramètre permet de définir la manière dont les réponses passées doivent être présentées (visibles, cachées, estompées ou effacées).`;
trad['Common_Parameter_DisplayRight_eVisible'] = `£Faces Visibles`;
trad['Common_Parameter_DisplayRight_eVisibleShort'] = `£Visibles`;
trad[
  'Common_Parameter_DisplayRight_eVisibleToolTip'
] = `£Avec cette option, les éléments restent découverts jusqu'à la fin de l'exercice.`;
trad['Common_Parameter_DisplayRight_eVisibleShaded'] = `£Faces visibles estompées `;
trad['Common_Parameter_DisplayRight_eVisibleShadedShort'] = `£Visibles estompées `;
trad[
  'Common_Parameter_DisplayRight_eVisibleShadedToolTip'
] = `£Avec cette option, les éléments restent découverts, mais légèrement estompés, jusqu'à la fin de l'exercice.`;
trad['Common_Parameter_DisplayRight_eHidden'] = `£Face cachées`;
trad['Common_Parameter_DisplayRight_eHiddenShort'] = `£Cachées`;
trad['Common_Parameter_DisplayRight_eHiddenToolTip'] = `£Les éléments sont à nouveau retournés face cachée.`;
trad['Common_Parameter_DisplayRight_eHiddenShaded'] = `£Face cachées estompées`;
trad['Common_Parameter_DisplayRight_eHiddenShadedShort'] = `£Cachées estompées`;
trad[
  'Common_Parameter_DisplayRight_eHiddenShadedToolTip'
] = `£Avec cette option, les éléments sont à nouveau retournés face cachés, mais légèrement estompés.`;
trad['Common_Parameter_DisplayRight_eRemoved'] = `£Enlevées`;
trad['Common_Parameter_DisplayRight_eRemovedToolTip'] = `£Les éléments sont supprimés de l'affichage.`;
trad['Common_Parameter_DisplayTime'] = `£Durée d'affichage des éléments à mémoriser`;
trad['Common_Parameter_DisplayTime§Reduced'] = `£Durée d'affichage`;
trad['Common_Parameter_DisplayTime§Short'] = `£Affichage`;
trad[
  'Common_Parameter_DisplayTime§ToolTip'
] = `£Ce paramètre permet de définir la durée (dépendante de la difficulté de la configuration) pendant laquelle les éléments seront affichés (très longue, longue, normale ou définie manuellement).`;
trad['Common_Parameter_DisplayTime_eVerySlow'] = `£Très longue`;
trad[
  'Common_Parameter_DisplayTime_eVerySlow§ToolTip'
] = `£Avec cette option, les éléments seront affichés pendant une durée situé entre 5 et 12 secondes (selon la complexité).`;
trad['Common_Parameter_DisplayTime_eSlow'] = `£Longue`;
trad[
  'Common_Parameter_DisplayTime_eSlowToolTip'
] = `£Avec cette option, les éléments seront affichés pendant une durée situé entre 3 et 8 secondes (selon la complexité).`;
trad['Common_Parameter_DisplayTime_eNormal'] = `£Normale`;
trad[
  'Common_Parameter_DisplayTime_eNormalToolTip'
] = `£Avec cette option, les éléments seront affichés pendant une durée situé entre 1.5 et 4 secondes (selon la complexité).`;
trad['Common_Parameter_DisplayTime_eManual'] = `£Gérée manuellement`;
trad['Common_Parameter_DisplayTime_eManual§Short'] = `£Manuel`;
trad[
  'Common_Parameter_DisplayTime_eManual§ToolTip'
] = `£Avec cette option, les éléments resteront affichés tant que le thérapeute ne passe pas à la configuration suivante.`;
trad['Common_Parameter_DistractingInformation'] = `£Info non pertinente`;
trad[
  'Common_Parameter_DistractingInformationToolTip'
] = `£Ce paramètre permet de définir dans quelle mesure des informations non pertinentes seront présentées au patient`;
trad['Common_Parameter_Duration'] = `£Durée maximale de l'exercice`;
trad['Common_Parameter_Duration§Reduced'] = `£Durée Max`;
trad['Common_Parameter_Duration§Short'] = `£Durée`;
trad[
  'Common_Parameter_Duration§ToolTip'
] = `£Ce paramètre permet de définir la limite de durée totale de l'exercice, après laquelle l'exercice se terminera automatiquement une fois le tirage en cours terminé.`;
trad['Common_Parameter_Duration_e1'] = `£1 minute`;
trad['Common_Parameter_Duration_e3'] = `£3 minutes`;
trad['Common_Parameter_Duration_e5'] = `£5 minutes`;
trad['Common_Parameter_Duration_e10'] = `£10 minutes`;
trad['Common_Parameter_Duration_e15'] = `£15 minutes`;
trad['Common_Parameter_Duration_eNone'] = `£Pas de limite`;
trad['Common_Parameter_Duration_eNone§Short'] = `£Pas de limite`;
trad['Common_Parameter_End'] = `£Son final`;
trad['Common_Parameter_EndDesc'] = `£Sonorité finale du substantif`;
trad['Common_Parameter_EndToolTip'] = `£Ce paramètre indique la sonorité finale du substantif utilisé.`;
trad['Common_Parameter_FlipBackTimer'] = `£Retournement`;
trad['Common_Parameter_FlipBackTimerDesc'] = `£Délai de retournement`;
trad[
  'Common_Parameter_FlipBackTimerToolTip'
] = `£Ce paramètre permet de définir le délai avant que les cartes visibles soit à nouveau retournées (court, moyen, long ou manuel).`;
trad['Common_Parameter_FlipBackTimer_eQuick'] = `£Délai court`;
trad['Common_Parameter_FlipBackTimer_eQuickShort'] = `£Rapide`;
trad[
  'Common_Parameter_FlipBackTimer_eQuickToolTip'
] = `£Avec cette option, les éléments selectionés sont rapidement retournés face cachée (2 secondes).`;
trad['Common_Parameter_FlipBackTimer_eAverage'] = `£Délai moyen`;
trad['Common_Parameter_FlipBackTimer_eAverageShort'] = `£Moyen`;
trad[
  'Common_Parameter_FlipBackTimer_eAverageToolTip'
] = `£Avec cette option, les éléments selectionés sont retournés face cachée avec un délai modéré (5 secondes).`;
trad['Common_Parameter_FlipBackTimer_eSlow'] = `£Délai long`;
trad['Common_Parameter_FlipBackTimer_eSlowShort'] = `£Lent`;
trad[
  'Common_Parameter_FlipBackTimer_eSlowToolTip'
] = `£Avec cette option, les éléments selectionés sont retournés face cachée après un temps suffisament long (10 secondes).`;
trad['Common_Parameter_FlipBackTimer_eManual'] = `£Manuellement`;
trad['Common_Parameter_FlipBackTimer_eManualShort'] = `£Manuel`;
trad[
  'Common_Parameter_FlipBackTimer_eManualToolTip'
] = `£Avec cette option, les éléments selectionés restent face visible tant que le thérapeute ne clique par sur le bouton suivant, ou que le patient ne selectionne pas une autre carte.`;
trad['Common_Parameter_Frequency'] = `£Fréquence`;
trad['Common_Parameter_Frequency_eDisabled'] = `£Désactivée`;
trad['Common_Parameter_Frequency_eLow'] = `£Faible`;
trad['Common_Parameter_Frequency_eMedium'] = `£Modérée`;
trad['Common_Parameter_Frequency_eHigh'] = `£Elevée`;
trad['Common_Parameter_GameSpeed'] = `£Vitesse du jeu`;
trad['Common_Parameter_GameSpeed§Reduced'] = `£Vitesse`;
trad['Common_Parameter_GameSpeed§Short'] = `£Vitesse`;
trad[
  'Common_Parameter_GameSpeed§ToolTip'
] = `£Ce paramètre permet de moduler la vitesse globale de la simulation, déterminant la vélocité de tous les véhicules de l'environnement.`;
trad['Common_Parameter_GameSpeed_eSlow'] = `£Lente`;
trad[
  'Common_Parameter_GameSpeed_eSlowToolTip'
] = `£Avec cette option, le jeu est ralenti de 60%, limitant la vitesse des véhicules à environ 20 km/h.`;
trad['Common_Parameter_GameSpeed_eModerate'] = `£Modérée`;
trad[
  'Common_Parameter_GameSpeed_eModerateToolTip'
] = `£Avec cette option, le jeu est ralenti de 40%, limitant la vitesse des véhicules à environ 30 km/h.`;
trad['Common_Parameter_GameSpeed_eFast'] = `£Rapide`;
trad[
  'Common_Parameter_GameSpeed_eFastToolTip'
] = `£Avec cette option, le jeu est ralenti de 20%, limitant la vitesse des véhicules à environ 40 km/h.`;
trad['Common_Parameter_GameSpeed_eVeryFast'] = `£Très rapide`;
trad[
  'Common_Parameter_GameSpeed_eVeryFastToolTip'
] = `£Avec cette option, le jeu se déroule à vitesse normale, les véhicules se déplacent donc à une vitesse de 50 km/h.`;
trad['Common_Parameter_Goal'] = `£Consigne`;
trad['Common_Parameter_GoalDesc'] = `£Types d'instructions (écrites/orales)`;
trad['Common_Parameter_Goal_eAudio'] = `£Ecrit + Audio`;
trad['Common_Parameter_Goal_eNoAudio'] = `£Ecrit`;
trad['Common_Parameter_Guiding'] = `£Guidage`;
trad['Common_Parameter_GuidingDesc'] = `£Mode de guidage`;
trad[
  'Common_Parameter_GuidingToolTip'
] = `£Ce paramètre indique le mode de conduite de l'activité (libre ou semi-guidé).`;
trad['Common_Parameter_Guiding_eFree'] = `£Mode Libre`;
trad['Common_Parameter_Guiding_eFreeShort'] = `£Libre`;
trad[
  'Common_Parameter_Guiding_eFreeToolTip'
] = `£Avec cette option, le patient et le thérapeute sont libres dans la construction du graphique. Le thérapeute peut tout de même débloquer des indiçages.`;
trad['Common_Parameter_Guiding_eGuided'] = `£Mode semi-guidé`;
trad['Common_Parameter_Guiding_eGuidedShort'] = `£Semi-guidé`;
trad[
  'Common_Parameter_Guiding_eGuidedToolTip'
] = `£Avec cette option, le patient et le thérapeute passent par deux étapes successives, d'abord en validant la catégorie, puis en explorant sémantiquement le substantif.`;
trad['Common_Parameter_IngredientsQuantity'] = `£Quantité d'ingrédients`;
trad[
  'Common_Parameter_IngredientsQuantityToolTip'
] = `£Ce paramètre permet d'afficher les quantités d'ingrédients dans le texte des recettes.`;
trad['Common_Parameter_InstructionType'] = `£Type`;
trad[
  'Common_Parameter_InstructionTypeToolTip'
] = `£Ce paramètre permet de définir le type de message faisant office d'instruction pour l'exercice`;
trad['Common_Parameter_InstructionType_eText'] = `£Texte`;
trad['Common_Parameter_InstructionType_eAudio'] = `£Audio`;
trad['Common_Parameter_InstructionIndexDesc'] = `£Numéro de l'instruction`;
trad[
  'Common_Parameter_InstructionIndexToolTip'
] = `£Ce paramètre permet d'afficher le numéro des instructions sur la feuille de route.`;
trad['Common_Parameter_InstructionStrike'] = `£Barrer les instructions réalisées`;
trad['Common_Parameter_InstructionStrikeDesc'] = `£Barrer les instructions réalisées`;
trad[
  'Common_Parameter_InstructionStrikeToolTip'
] = `£Ce paramètre permet de barrer automatiquement les instructions passées (qu'elles aient été réalisées avec succès ou non, dans le cas de l'utilisation du mode 'une seule tentative par instruction').`;
trad['Common_Parameter_Interf'] = `£Interférences`;
trad[
  'Common_Parameter_InterfToolTip'
] = `£Interférences utilisées pendant l'exercice (comptage vocal, forme et couleur).`;
trad['Common_Parameter_Interf_Multiple'] = `£Multiples`;
trad[
  'Common_Parameter_Interf_MultipleToolTip'
] = `£Utilisation d'interférences multiples (parmi comptage vocal, forme et couleur).`;
trad['Common_Parameter_Interf_NoneToolTip'] = `£Aucune interférence n'a été utilisée.`;
trad['Common_Parameter_InterfNumber'] = `£Interférence par affichage de nombre`;
trad['Common_Parameter_InterfNumber§Reduced'] = `£Interférence de nombre`;
trad['Common_Parameter_InterfNumber§Short'] = `£Interf. nombre`;
trad[
  'Common_Parameter_InterfNumber§ToolTip'
] = `£Ce paramètre permet d'afficher des chiffres en plus des formes à compter. L'affichage de chiffres dans les cases provoque par défaut un léger effet Stroop.D'autres leviers sont disponibles pour amplifier l'interférence.`;
trad['Common_Parameter_InterfNumberActive'] = `£Interférence nombre activée`;
trad['Common_Parameter_InterfNumber_Type'] = `£Type d'affichage des nombres`;
trad['Common_Parameter_InterfNumber_Type§Reduced'] = `£Type d'affichage`;
trad['Common_Parameter_InterfNumber_Type§Short'] = `£Type Nb.`;
trad[
  'Common_Parameter_InterfNumber_Type§ToolTip'
] = `£Ce paramètre indique le mode d'affichage des chiffres (en chiffres arabes, en toutes lettres ou les deux).`;
trad['Common_Parameter_InterfNumber_Type_eDigit'] = `£Chiffres`;
trad['Common_Parameter_InterfNumber_Type_eWords'] = `£Mots`;
trad['Common_Parameter_InterfNumber_Type_eWordsDigits'] = `£Chiffres et mots`;
trad['Common_Parameter_InterfNumber_Multiple'] = `£Multiplicité de l'affichage des nombres`;
trad['Common_Parameter_InterfNumber_Multiple§Reduced'] = `£Multiplicité d'interférence`;
trad['Common_Parameter_InterfNumber_Multiple§Short'] = `£Multiplicité`;
trad[
  'Common_Parameter_InterfNumber_Multiple§ToolTip'
] = `£Ce paramètre indique combien de nombres sont affichés (dans une seule forme, dans plusieurs formes ou dans toutes).`;
trad['Common_Parameter_InterfNumber_Multiple_eOne'] = `£Une seule forme`;
trad['Common_Parameter_InterfNumber_Multiple_eOne§Short'] = `£Une`;
trad[
  'Common_Parameter_InterfNumber_Multiple_eOne§ToolTip'
] = `£Avec cette option, le nombre n'est affiché que dans une seule forme.`;
trad['Common_Parameter_InterfNumber_Multiple_eSeveral'] = `£Plusieurs formes`;
trad['Common_Parameter_InterfNumber_Multiple_eSeveral§Short'] = `£Plusieurs`;
trad[
  'Common_Parameter_InterfNumber_Multiple_eSeveral§ToolTip'
] = `£Avec cette option, le nombre est affiché dans plusieurs formes.`;
trad['Common_Parameter_InterfNumber_Multiple_eAll'] = `£Toutes les formes`;
trad['Common_Parameter_InterfNumber_Multiple_eAll§Short'] = `£Toutes`;
trad[
  'Common_Parameter_InterfNumber_Multiple_eAll§ToolTip'
] = `£Avec cette option, un nombre est affiché dans chaque forme.`;
trad['Common_Parameter_InterfNumber_Animate'] = `£Animer l'affichage des chiffres`;
trad['Common_Parameter_InterfNumber_Animate§Reduced'] = `£Animer les chiffres`;
trad['Common_Parameter_InterfNumber_Animate§Short'] = `£Anim. chiffres`;
trad[
  'Common_Parameter_InterfNumber_Animate§ToolTip'
] = `£Ce paramètre permet de faire apparaitre les chiffres de manière animée. Leur apparition attire ainsi davantage l'attention.`;
trad['Common_Parameter_InterfNumber_Delay'] = `£Avancer l'affichage des chiffres`;
trad['Common_Parameter_InterfNumber_Delay§Reduced'] = `£Décallage affichage de chiffres`;
trad['Common_Parameter_InterfNumber_Delay§Short'] = `£Av. aff. chiffres`;
trad[
  'Common_Parameter_InterfNumber_Delay§ToolTip'
] = `£Ce paramètre déclenche l'affichage des cases quelques instants après les chiffres. Les chiffres attirent ainsi davantage l'attention.`;
trad['Common_Parameter_InterfVoice'] = `£Interférence par comptage vocal`;
trad['Common_Parameter_InterfVoice§Reduced'] = `£Interférence vocale`;
trad['Common_Parameter_InterfVoice§Short'] = `£Vocale`;
trad[
  'Common_Parameter_InterfVoice§ToolTip'
] = `£Ce paramètre déclenche l'annonce vocale d'un chiffre aléatoire, toutes les deux secondes, provoquant une interférence sonore sur la tache de comptage.`;
trad['Common_Parameter_InterfVoiceActive'] = `£Interférence vocale active`;
trad['Common_Parameter_InterfShape'] = `£Interférence de forme`;
trad['Common_Parameter_InterfShape§Reduced'] = `£Interférence de forme`;
trad['Common_Parameter_InterfShape§Short'] = `£Forme`;
trad['Common_Parameter_InterfShape§ToolTip'] = `£Ce paramètre permet d'afficher des formes parasites parmi les carrés.`;
trad['Common_Parameter_InterfShapeActive'] = `£Interférence forme active`;
trad['Common_Parameter_InterfShape_Freq'] = `£Fréquence d'affichage des formes parasites`;
trad['Common_Parameter_InterfShape_Freq§Reduced'] = `£Fréquence d'interférence de forme`;
trad['Common_Parameter_InterfShape_Freq§Short'] = `£Fréq. interf. forme`;
trad[
  'Common_Parameter_InterfShape_Freq§ToolTip'
] = `£Ce paramètre permet de définir la fréquence d'apparition de formes parasites parmi les formes à compter`;
trad['Common_Parameter_InterfColor'] = `£Interférence de couleur`;
trad['Common_Parameter_InterfColor§Reduced'] = `£Interférence de couleur`;
trad['Common_Parameter_InterfColor§Short'] = `£Couleur`;
trad['Common_Parameter_InterfColor§ToolTip'] = `£Ce paramètre permet l'apparition de couleurs parasites.`;
trad['Common_Parameter_InterfColorActive'] = `£Interférence de couleur active`;
trad['Common_Parameter_InterfColor_Freq'] = `£Fréquence d'affichage de l'interférence de couleur`;
trad['Common_Parameter_InterfColor_Freq§Reduced'] = `£Fréquence d'interférence de couleur`;
trad['Common_Parameter_InterfColor_Freq§Short'] = `£Freq. interf. coul.`;
trad[
  'Common_Parameter_InterfColor_Freq§ToolTip'
] = `£Ce paramètre permet de régler la fréquence d'apparition des couleurs parasites.`;
trad['Common_Parameter_ItemProximity'] = `£Proximité sémantique`;
trad['Common_Parameter_ItemProximity§Reduced'] = `£Proximité`;
trad['Common_Parameter_ItemProximity§Short'] = `£Proximité`;
trad[
  'Common_Parameter_ItemProximity§ToolTip'
] = `£Ce paramètre définit la proximité sémantique des différents groupes de substantifs utilisés dans l'exercice (proche, lointain ou indifférent).`;
trad['Common_Parameter_ItemProximity_eClose'] = `£Proche`;
trad[
  'Common_Parameter_ItemProximity_eClose§ToolTip'
] = `£Les catégories sont sémantiquement proches (par exemple, Arbres et Fleurs).`;
trad['Common_Parameter_ItemProximity_eRemote'] = `£Lointain`;
trad[
  'Common_Parameter_ItemProximity_eRemote§ToolTip'
] = `£Avec cette option, les catégories sont sémantiquement éloignées (par exemple, Végétaux et Outils).`;
trad['Common_Parameter_ItemSize'] = `£Taille des éléments`;
trad['Common_Parameter_ItemSize§Reduced'] = `£Taille`;
trad['Common_Parameter_ItemSize§Short'] = `£Taille`;
trad[
  'Common_Parameter_ItemSize§ToolTip'
] = `£Ce paramètre permet de définir la taille de l'élément affiché sur l'écran du patient (petit, moyen ou grand).`;
trad['Common_Parameter_ItemSize_eSmall'] = `£Petit`;
trad[
  'Common_Parameter_ItemSize_eSmall§ToolTip'
] = `£Avec cette option, l'élément occupe environ 1/4 de la hauteur de l'écran.`;
trad['Common_Parameter_ItemSize_eMedium'] = `£Moyen`;
trad[
  'Common_Parameter_ItemSize_eMedium§ToolTip'
] = `£Avec cette option, l'élément occupe environ la moitié de la hauteur de l'écran.`;
trad['Common_Parameter_ItemSize_eLarge'] = `£Grand`;
trad[
  'Common_Parameter_ItemSize_eLarge§ToolTip'
] = `£Avec cette option, l'élément occupe environ 3/4 de la hauteur de l'écran.`;
trad['Common_Parameter_ItemType'] = `£Type d'élément présenté`;
trad['Common_Parameter_ItemType§Reduced'] = `£Type d'élément`;
trad['Common_Parameter_ItemType§Short'] = `£Eléments`;
trad[
  'Common_Parameter_ItemType§ToolTip'
] = `£Ce paramètre permet de définir le type d'élément qui sera affiché pour le patient (Forme, caractère, image, pictogramme).`;
trad['Common_Parameter_ItemType_eChars'] = `£Des caractères`;
trad['Common_Parameter_ItemType_eChars§Reduced'] = `£Caractères`;
trad['Common_Parameter_ItemType_eChars§Short'] = `£Caractères`;
trad['Common_Parameter_ItemType_eChar_Type'] = `£Type de caractère`;
trad[
  'Common_Parameter_ItemType_eChar_Type§ToolTip'
] = `£Ce paramètre indique le type de caractères utilisé (majuscules, minuscules, les deux, ou signes de ponctuation).`;
trad['Common_Parameter_ItemType_eChar_Type_ePunctuation'] = `£Ponctuation`;
trad['Common_Parameter_ItemType_eChar_Type_ePunctuationDesc'] = `£Charactères de ponctuation`;
trad['Common_Parameter_ItemType_eChar_Type_eSpecial'] = `£Spéciaux`;
trad['Common_Parameter_ItemType_eChar_Type_eSpecialDesc'] = `£Charactères spéciaux`;
trad['Common_Parameter_ItemType_eColors'] = `£Des couleurs`;
trad['Common_Parameter_ItemType_eColors§Reduced'] = `£Couleurs`;
trad['Common_Parameter_ItemType_eColors§Short'] = `£Couleurs`;
trad['Common_Parameter_ItemType_eLetters'] = `£Des lettres`;
trad['Common_Parameter_ItemType_eLetters§Reduced'] = `£Des lettres`;
trad['Common_Parameter_ItemType_eLetters§Short'] = `£Des lettres`;
trad['Common_Parameter_ItemType_eLetters_Type'] = `£Type de lettres`;
trad['Common_Parameter_ItemType_eLetters_Type§Reduced'] = `£Type de lettres`;
trad['Common_Parameter_ItemType_eLetters_Type§Short'] = `£Type`;
trad[
  'Common_Parameter_ItemType_eLetters_Type§ToolTip'
] = `£Ce paramètre indique le type de lettres utilisé (majuscules, minuscules ou les deux).`;
trad['Common_Parameter_ItemType_eLetters_Type_eLower'] = `£Minuscules`;
trad['Common_Parameter_ItemType_eLetters_Type_eUpper'] = `£MAJUSCULES`;
trad['Common_Parameter_ItemType_eLetters_Type_eBoth'] = `£MAJUSCULES et minuscules`;
trad['Common_Parameter_ItemType_eLetters_Type_eBoth§Short'] = `£MAJ. et min.`;
trad['Common_Parameter_ItemType_eLetters_Type_eEither'] = `£MAJUSCULES ou minuscules`;
trad['Common_Parameter_ItemType_eLetters_Type_eEither§Short'] = `£MAJ. ou min.`;
trad['Common_Parameter_ItemType_eNumbers'] = `£Des chiffres`;
trad['Common_Parameter_ItemType_eNumbers§Reduced'] = `£Chiffres`;
trad['Common_Parameter_ItemType_eNumbers§Short'] = `£Chiffres`;
trad['Common_Parameter_ItemType_ePictures'] = `£Des pictogrammes`;
trad['Common_Parameter_ItemType_ePictures§Short'] = `£Pictos`;
trad['Common_Parameter_ItemType_ePhotos'] = `£Des photographies`;
trad['Common_Parameter_ItemType_ePhotos§Short'] = `£Photos`;
trad['Common_Parameter_ItemType_eShapes'] = `£Des formes`;
trad['Common_Parameter_ItemType_eShapes§Reduced'] = `£Formes`;
trad['Common_Parameter_ItemType_eShapes§Short'] = `£Formes`;
trad['Common_Parameter_ItemType_eWords'] = `£Des substantifs`;
trad['Common_Parameter_ItemType_eWordsShort'] = `£Mots`;
trad['Common_Parameter_KeepFirstCard'] = `£Garder la première`;
trad['Common_Parameter_KeepFirstCardDesc'] = `£Conserver la première carte`;
trad[
  'Common_Parameter_KeepFirstCardToolTip'
] = `£Ce paramètre permet de conserver la première carte retournée visible jusqu'à ce que la deuxième carte associée soit découverte également.`;
trad['Common_Parameter_Layout'] = `£Type de disposition`;
trad['Common_Parameter_Layout§Reduced'] = `£Disposition`;
trad['Common_Parameter_Layout§Short'] = `£Disposition`;
trad[
  'Common_Parameter_Layout§ToolTip'
] = `£Ce paramètre précise la façon dont sont disposés les éléments (éparpillés aléatoirement ou alignés sur une grille).`;
trad['Common_Parameter_Layout_eRandom'] = `£Eparpillés`;
trad['Common_Parameter_Layout_eRandom§ToolTip'] = `£Les éléments sont répartis aléatoirement sur tout l'écran.`;
trad['Common_Parameter_Layout_eGrid'] = `£En quadrillage`;
trad['Common_Parameter_Layout_eGrid§Short'] = `£Grille`;
trad['Common_Parameter_Layout_eGrid§ToolTip'] = `£Les éléments sont alignés sur une grille.`;
trad['Common_Parameter_Layout_eGridSize'] = `£Taille de la grille`;
trad['Common_Parameter_Layout_eGridSize§Reduced'] = `£Taille de grille`;
trad['Common_Parameter_Layout_eGridSize§Short'] = `£Taille`;
trad['Common_Parameter_Layout_eGridSize§ToolTip'] = `£Ce paramètre indique le nombre d'éléments affichés.`;
trad['Common_Parameter_Layout_eGridSize_e3x3'] = `£3 x 3`;
trad['Common_Parameter_Layout_eGridSize_e5x5'] = `£5 x 5`;
trad['Common_Parameter_Layout_eGridSize_e7x7'] = `£7 x 7`;
trad['Common_Parameter_Layout_eGridSize_e7x10'] = `£7 x 10`;
trad['Common_Parameter_Layout_eGridSize_e7x15'] = `£7 x 15`;
trad['Common_Parameter_Layout_eDice'] = `£Sur un dé`;
trad['Common_Parameter_Layout_eDice§Short'] = `£Dé`;
trad['Common_Parameter_Layout_eDice§ToolTip'] = `£Les éléments sont disposés sur la constellation des points d'un dé.`;
trad['Common_Parameter_Layout_eRow'] = `£Sur une ligne`;
trad['Common_Parameter_Layout_eRow§Short'] = `£Ligne`;
trad['Common_Parameter_Layout_eRow§ToolTip'] = `£Les éléments sont alignés horizontalement.`;
trad['Common_Parameter_Layout_eColumn'] = `£Sur une colonne`;
trad['Common_Parameter_Layout_eColumn§Short'] = `£Colonne`;
trad['Common_Parameter_Layout_eColumn§ToolTip'] = `£Les éléments sont alignés verticalement.`;
trad['Common_Parameter_Length'] = `£Longueur des substantifs`;
trad['Common_Parameter_Length§Reduced'] = `£Longueur`;
trad['Common_Parameter_Length§Short'] = `£Longueur`;
trad[
  'Common_Parameter_Length§ToolTip'
] = `£Ce paramètre indique la longueur des substantifs utilisés (court, long ou indifférent).`;
trad['Common_Parameter_Length_eShort'] = `£Court`;
trad['Common_Parameter_Length_eLong'] = `£Long`;
trad['Common_Parameter_LimitByAge'] = `£Limiter les éléments disponibles en fonction de l'age du patient`;
trad['Common_Parameter_LimitByAge§Reduced'] = `£Contraint par l'âge`;
trad['Common_Parameter_LimitByAge§Short'] = `£Limit. age`;
trad[
  'Common_Parameter_LimitByAge§ToolTip'
] = `£Ce paramètre permet de ne selectionner que des substantifs adaptés à l'age renseigné dans le dossier du patient (par exemple pas d'alcool pour un patient agé de moins de 18 ans).`;
trad['Common_Parameter_MapAvailable'] = `£Plan disponible`;
trad['Common_Parameter_MapAvailableDesc'] = `£Mise à disposition du plan`;
trad[
  'Common_Parameter_MapAvailableToolTip'
] = `£Ce paramètre permet de définir si les plans sont mis à dispositions des clients à l'entrée du magasin.`;
trad['Common_Parameter_Music'] = `£Jouer la musique de fond`;
trad['Common_Parameter_Music§Reduced'] = `£Musique`;
trad['Common_Parameter_Music§Short'] = `£Musique`;
trad['Common_Parameter_Music§ToolTip'] = `£Ce paramètre permet de jouer une musique en fond sonore.`;
trad['Common_Parameter_NbCards'] = `£Nombre de cartes`;
trad['Common_Parameter_NbCardsShort'] = `£Nb cartes`;
trad['Common_Parameter_NbCardsToolTip'] = `£Ce paramètre indique le nombre total de cartes à proposer au patient.`;
trad['Common_Parameter_NbCards_e4'] = `£4 cartes`;
trad['Common_Parameter_NbCards_e6'] = `£6 cartes`;
trad['Common_Parameter_NbCards_e8'] = `£8 cartes`;
trad['Common_Parameter_NbCards_e12'] = `£12 cartes`;
trad['Common_Parameter_NbCards_e16'] = `£16 cartes`;
trad['Common_Parameter_NbCards_e20'] = `£20 cartes`;
trad['Common_Parameter_NbCards_e24'] = `£24 cartes`;
trad['Common_Parameter_NbCards_e30'] = `£30 cartes`;
trad['Common_Parameter_NbCards_e36'] = `£36 cartes`;
trad['Common_Parameter_NbCards_e42'] = `£42 cartes`;
trad['Common_Parameter_NbCategories'] = `£Nombre de catégories`;
trad['Common_Parameter_NbCategories§Reduced'] = `£Nombre de catégories`;
trad['Common_Parameter_NbCategories§Short'] = `£Nb cat.`;
trad['Common_Parameter_NbCategories§ToolTip'] = `£Ce paramètre indique le nombre de catégories distinctes à proposer.`;
trad['Common_Parameter_NbCategories_e2'] = `£2 catégories`;
trad['Common_Parameter_NbCategories_e3'] = `£3 catégories`;
trad['Common_Parameter_NbColors'] = `£Nombre de couleurs à mémoriser par tirage`;
trad['Common_Parameter_NbColors§Reduced'] = `£Couleurs à mémoriser`;
trad['Common_Parameter_NbColors§Short'] = `£Couleurs`;
trad[
  'Common_Parameter_NbColors§ToolTip'
] = `£Ce paramètre indique le nombre de couleurs différentes à utiliser pendant l'exercice.`;
trad['Common_Parameter_NbColors_e0'] = `£Aucune`;
trad['Common_Parameter_NbColors_e1'] = `£1 couleur`;
trad['Common_Parameter_NbColors_e2'] = `£2 couleurs`;
trad['Common_Parameter_NbConfigs§Reduced'] = `£Compositions`;
trad['Common_Parameter_NbConfigs§Short'] = `£Compositions`;
trad['Common_Parameter_NbConfigs_e1'] = `£Une seule composition`;
trad['Common_Parameter_NbConfigs_e2'] = `£2 compositions`;
trad['Common_Parameter_NbConfigs_e3'] = `£3 compositions`;
trad['Common_Parameter_NbInterfColor§Reduced'] = `£Couleurs parasites`;
trad['Common_Parameter_NbInterfColor'] = `£Nombre de couleurs parasites`;
trad['Common_Parameter_NbInterfColor§Short'] = `£Parasites`;
trad[
  'Common_Parameter_NbInterfColor§ToolTip'
] = `£Ce paramètre indique le nombre de couleurs parasites (qui ne doivent pas être comptées) utilisées dans une série.`;
trad['Common_Parameter_NbInstructionsDesc'] = `£Nombre d'instructions`;
trad['Common_Parameter_NbInstructions'] = `£Instructions`;
trad[
  'Common_Parameter_NbInstructionsToolTip'
] = `£Ce paramètre permet de définir le nombre d'instructions successives que le patient devra suivre.`;
trad['Common_Parameter_NbInstructionsShort'] = `£Nb instr.`;
trad['Common_Parameter_NbInstructions_e2'] = `£2 instructions`;
trad['Common_Parameter_NbInstructions_e3'] = `£3 instructions`;
trad['Common_Parameter_NbInstructions_e4'] = `£4 instructions`;
trad['Common_Parameter_NbInstructions_e5'] = `£5 instructions`;
trad['Common_Parameter_NbInstructions_e6'] = `£6 instructions`;
trad['Common_Parameter_NbItems'] = `£Nombre d'éléments par tirage`;
trad['Common_Parameter_NbItems§Reduced'] = `£Nombre d'éléments`;
trad['Common_Parameter_NbItems§Short'] = `£Nb elts.`;
trad['Common_Parameter_NbItems§ToolTip'] = `£Ce paramètre indique le nombre total d'éléments proposés à chaque tirage.`;
trad['Common_Parameter_NbItemsUnit'] = `£ elts`;
trad['Common_Parameter_NbItems_e2'] = `£2 éléments`;
trad['Common_Parameter_NbItems_e3'] = `£3 éléments`;
trad['Common_Parameter_NbItems_e4'] = `£4 éléments`;
trad['Common_Parameter_NbItems_e5'] = `£5 éléments`;
trad['Common_Parameter_NbItems_e6'] = `£6 éléments`;
trad['Common_Parameter_NbItems_e8'] = `£8 éléments`;
trad['Common_Parameter_NbItems_e10'] = `£10 éléments`;
trad['Common_Parameter_NbItems_e12'] = `£12 éléments`;
trad['Common_Parameter_NbItems_e15'] = `£15 éléments`;
trad['Common_Parameter_NbItems_e16'] = `£16 éléments`;
trad['Common_Parameter_NbItems_e20'] = `£20 éléments`;
trad['Common_Parameter_NbItems_e24'] = `£24 éléments`;
trad['Common_Parameter_NbItems_e25'] = `£25 éléments`;
trad['Common_Parameter_NbItems_e30'] = `£30 éléments`;
trad['Common_Parameter_NbItems_e36'] = `£36 éléments`;
trad['Common_Parameter_NbItems_e42'] = `£42 éléments`;
trad['Common_Parameter_NbItemTypes'] = `£Nombre d'éléments distincts`;
trad['Common_Parameter_NbItemTypes§Reduced'] = `£Nombre modèles`;
trad['Common_Parameter_NbItemTypes§Short'] = `£Nb Modèles`;
trad[
  'Common_Parameter_NbItemTypes§ToolTip'
] = `£Ce paramètre indique le nombre total de modèles utilisés pour les éléments (en comptant celui de l'élément unique).`;
trad['Common_Parameter_NbItemTypes_e2'] = `£2 types`;
trad['Common_Parameter_NbItemTypes_e3'] = `£3 types`;
trad['Common_Parameter_NbItemTypes_e4'] = `£4 types`;
trad['Common_Parameter_NbItemTypes_e5'] = `£5 types`;
trad['Common_Parameter_NbRuns'] = `£Nombre de tirages`;
trad['Common_Parameter_NbRuns§Reduced'] = `£Nombre de Tirages`;
trad['Common_Parameter_NbRuns§Short'] = `£Tirages`;
trad['Common_Parameter_NbRuns§ToolTip'] = `£Ce paramètre indique le nombre de tirages effectués.`;
trad['Common_Parameter_NbRuns_e1'] = `£1 Tirage`;
trad['Common_Parameter_NbRuns_e3'] = `£3 Tirages`;
trad['Common_Parameter_NbRuns_e5'] = `£5 Tirages`;
trad['Common_Parameter_NbRuns_e7'] = `£7 Tirages`;
trad['Common_Parameter_NbRuns_e10'] = `£10 Tirages`;
trad['Common_Parameter_NbRuns_e15'] = `£15 Tirages`;
trad['Common_Parameter_NbRuns_e20'] = `£20 Tirages`;
trad['Common_Parameter_NbRuns_e24'] = `£24 Tirages`;
trad['Common_Parameter_NbRuns_e25'] = `£25 Tirages`;
trad['Common_Parameter_NbRuns_e30'] = `£30 Tirages`;
trad['Common_Parameter_NbRuns_e35'] = `£35 Tirages`;
trad['Common_Parameter_NbRuns_e40'] = `£40 Tirages`;
trad['Common_Parameter_NbRuns_e50'] = `£50 Tirages`;
trad['Common_Parameter_NbRuns_e60'] = `£60 Tirages`;
trad['Common_Parameter_NbRuns_e80'] = `£80 Tirages`;
trad['Common_Parameter_NoConfiguration'] = `£Configuration libre`;
trad['Common_Parameter_Recipe'] = `£Affichage recette`;
trad[
  'Common_Parameter_RecipeToolTip'
] = `£Ce paramètre permet d'afficher la description des étapes dans le livre de recettes.`;
trad['Common_Parameter_RemoveAlcool'] = `£Supprimer les boissons alcoolisées`;
trad[
  'Common_Parameter_RemoveAlcoolToolTip'
] = `£Ce paramètre permet d'éviter les produits alcoolisés, tels que le vin, dans la liste de courses.`;
trad['Common_Parameter_Roundabouts'] = `£Ronds points`;
trad['Common_Parameter_Roundabouts§Reduced'] = `£Ronds points`;
trad['Common_Parameter_Roundabouts§Short'] = `£Ronds points`;
trad[
  'Common_Parameter_Roundabouts§ToolTip'
] = `£Ce paramètre permet d'ajouter des ronds-points dans la ville, auxquels le patient devra s'arrêter.`;
trad['Common_Parameter_RunMode'] = `£Mode de conduite`;
trad['Common_Parameter_Scenario'] = `£Scénario`;
trad['Common_Parameter_ScenarioDesc'] = `£Nom du scenario utilisé`;
trad[
  'Common_Parameter_ScenarioToolTip'
] = `£Ce paramètre permet de selectionner le scénario souhaité pour l'exercice. Un scénario peut être libre ou contraint:&#xD;• Libre : Le patient doit raisonner sur une thématique générique proposée&#xD;• Contraint : Les informations sont implictement ou explicitement exprimées dans la consigne.`;
trad['Common_Parameter_SelectedWord'] = `£Substantif choisi`;
trad['Common_Parameter_SelectedWordShort'] = `£Substantif`;
trad['Common_Parameter_SelectedWordDesc'] = `£substantif selectionné pour l'exercice`;
trad[
  'Common_Parameter_SelectedWordToolTip'
] = `£Ce paramètre permet de définir le mot à utiliser pour l'exercice dans le cas d'un tirage unique.`;
trad['Common_Parameter_SelectedCategoryDesc'] = `£Catégorie des substantifs`;
trad['Common_Parameter_SelectedCategory'] = `£Catégorie`;
trad[
  'Common_Parameter_SelectedCategoryToolTip'
] = `£Ce paramètre permet de choisir la catégorie dans laquelle selectionner les substantifs.`;
trad['Common_Parameter_Shape'] = `£Type de formes`;
trad['Common_Parameter_Shape§Reduced'] = `£Formes`;
trad['Common_Parameter_Shape§Short'] = `£Formes`;
trad[
  'Common_Parameter_Shape§ToolTip'
] = `£Ce paramètre permet d'établir le types de formes que le patient devra mémoriser (abstraite - des figure géométrique - ou concrète - des icônes de moyens de transport).`;
trad['Common_Parameter_Shape_eGeometry'] = `£Formes géométriques`;
trad['Common_Parameter_Shape_eGeometry§Short'] = `£Abstraits`;
trad['Common_Parameter_Shape_eGeometry_eSquare'] = `£Carré`;
trad['Common_Parameter_Shape_eGeometry_eCircle'] = `£Cercle`;
trad['Common_Parameter_Shape_eGeometry_eTriangle'] = `£Triangle`;
trad['Common_Parameter_Shape_eGeometry_eStar'] = `£Etoile`;
trad['Common_Parameter_Shape_eGeometry_eMoon'] = `£Lune`;
trad['Common_Parameter_Shape_eGeometry_eCross'] = `£Croix`;
trad['Common_Parameter_Shape_eGeometry_eRhombus'] = `£Losange`;
trad['Common_Parameter_Shape_eTransportation'] = `£Icônes transport`;
trad['Common_Parameter_Shape_eTransportation§Short'] = `£Concrets`;
trad['Common_Parameter_Shape_eTransportation_eCar'] = `£Voiture`;
trad['Common_Parameter_Shape_eTransportation_ePlane'] = `£Avion`;
trad['Common_Parameter_Shape_eTransportation_eBike'] = `£Moto`;
trad['Common_Parameter_Shape_eTransportation_eBoat'] = `£Bateau`;
trad['Common_Parameter_Shape_eTransportation_eBus'] = `£Bus`;
trad['Common_Parameter_Shape_eTransportation_eTruck'] = `£Camion`;
trad['Common_Parameter_Shape_eTransportation_eBalloon'] = `£Mongolfière`;
trad['Common_Parameter_Shape_eTransportation_eRocket'] = `£Fusée`;
trad['Common_Parameter_Shape_eTransportation_eVan'] = `£Camionette`;
trad['Common_Parameter_Shape_eTransportation_eHelicopter'] = `£Hélicoptère`;
trad['Common_Parameter_ShapeListDesc'] = `£Formes proposées`;
trad['Common_Parameter_ShapeList'] = `£Formes`;
trad[
  'Common_Parameter_ShapeListToolTip'
] = `£Cette liste permet de définir les formes qui seront proposées au patient en cours d'exercice.`;
trad['Common_Parameter_Single'] = `£Distinction d'élément unique`;
trad['Common_Parameter_Single§Reduced'] = `£Elément unique`;
trad['Common_Parameter_Single§Short'] = `£Elt unique`;
trad[
  'Common_Parameter_Single§ToolTip'
] = `£Ce paramètre indique ce qui distingue l'élément que l'utilisateur devra annoncer (symétrique de tous les autres ou complètement différent).`;
trad['Common_Parameter_Single_eDifferent'] = `£Forme différente`;
trad['Common_Parameter_Single_eDifferent§Short'] = `£Différent`;
trad[
  'Common_Parameter_Single_eDifferent§ToolTip'
] = `£Avec cette option, l'élément unique est différent de tous les autres, tout en étant de même type.`;
trad['Common_Parameter_Single_eSymmetry'] = `£Forme Symétrique`;
trad['Common_Parameter_Single_eSymmetry§Short'] = `£Symétrique`;
trad[
  'Common_Parameter_Single_eSymmetry§ToolTip'
] = `£Avec cette option, l'élément unique est le symétrique de tous les autres.`;
trad['Common_Parameter_SkipOnError'] = `£Un seul essai`;
trad['Common_Parameter_SkipOnErrorDesc'] = `£Une seule tentative par instruction`;
trad[
  'Common_Parameter_SkipOnErrorToolTip'
] = `£Par défaut, la simulation ne passera à l'instruction suivante que lorsqu'une séquence est correctement réalisée, laissant autant de chances que nécessaire à l'utilisateur en réinitialisant le décompte des intersections à chaque erreur. &#xD; Si ce paramètre est activé, la simulation passera à l'insuction suivante, quel que soit le résultat.`;
trad['Common_Parameter_SoundComplexity'] = `£Complexité phonologique`;
trad['Common_Parameter_SoundComplexity§Reduced'] = `£Complexité`;
trad['Common_Parameter_SoundComplexity§Short'] = `£Complexité`;
trad[
  'Common_Parameter_SoundComplexity§ToolTip'
] = `£Ce paramètre indique la complexité phonologique des substantifs utilisés (simple, complexe ou indifférent).`;
trad['Common_Parameter_SpeakerClipsFrequency'] = `£Fréquence des annonces`;
trad['Common_Parameter_SpeakerClipsFrequencyShort'] = `£Annonces`;
trad[
  'Common_Parameter_SpeakerClipsFrequencyToolTip'
] = `£Ce paramètre permet de définir la fréquence des annonces publicitaires diffusées pendant l'activité.`;
trad['Common_Parameter_TargetDisplay'] = `£Affichage des cibles`;
trad['Common_Parameter_TargetDisplay§Reduced'] = `£Affichage`;
trad['Common_Parameter_TargetDisplay§Short'] = `£Affichage`;
trad[
  'Common_Parameter_TargetDisplay§ToolTip'
] = `£Ce paramètre indique pendant combien de temps les cibles du barrage doivent être affichées.`;
trad['Common_Parameter_TargetDisplay_eTemp'] = `£Affichage temporaire`;
trad['Common_Parameter_TargetDisplay_eTemp§Short'] = `£Temporaire`;
trad['Common_Parameter_TargetDisplay_ePerm'] = `£Affichage permanent`;
trad['Common_Parameter_TargetDisplay_ePerm§Short'] = `£Permanent`;
trad['Common_Parameter_TargetFrequency'] = `£Fréquence des cibles`;
trad['Common_Parameter_TargetFrequency§Reduced'] = `£Fréquence`;
trad['Common_Parameter_TargetFrequency§Short'] = `£Fréquence`;
trad[
  'Common_Parameter_TargetFrequency§ToolTip'
] = `£Ce paramètre indique la proportion de cibles affichées parmi l'ensemble des éléments.`;
trad['Common_Parameter_TargetFrequency_e5'] = `£5% des éléments`;
trad['Common_Parameter_TargetFrequency_e5§Short'] = `£5%`;
trad['Common_Parameter_TargetFrequency_e10'] = `£10% des éléments`;
trad['Common_Parameter_TargetFrequency_e10§Short'] = `£10%`;
trad['Common_Parameter_TargetFrequency_e15'] = `£15% des éléments`;
trad['Common_Parameter_TargetFrequency_e15§Short'] = `£15%`;
trad['Common_Parameter_TargetFrequency_e20'] = `£20% des éléments`;
trad['Common_Parameter_TargetFrequency_e20§Short'] = `£20%`;
trad['Common_Parameter_Termination'] = `£Finalisation`;
trad['Common_Parameter_TerminationDesc'] = `£Fin de l'exercice`;
trad[
  'Common_Parameter_TerminationToolTip'
] = `£Ce paramètre permet de définir quand l'exercice se termine (après une certaine durée ou après un certain nombre de tirages).`;
trad['Common_Parameter_Termination_eDuration'] = `£Durée`;
trad[
  'Common_Parameter_Termination_eDurationToolTip'
] = `£Avec cette option, l'exercice se terminera à la fin du tirage, une fois la durée écoulée.`;
trad['Common_Parameter_Termination_eRuns'] = `£Tirages`;
trad[
  'Common_Parameter_Termination_eRunsToolTip'
] = `£Avec cette option, l'exercice se terminera après un certain nombre de tirages.`;
trad['Common_Parameter_TotalPrice'] = `£Prix total`;
trad['Common_Parameter_TotalPriceDesc'] = `£Prix total du panier`;
trad[
  'Common_Parameter_TotalPriceToolTip'
] = `£Ce paramètre permet d'afficher le prix total du panier au bas de la liste de courses.`;
trad['Common_Parameter_TrafficLight'] = `£Feux tricolores`;
trad['Common_Parameter_TrafficLight§Reduced'] = `£Feux`;
trad['Common_Parameter_TrafficLight§Short'] = `£Feux`;
trad[
  'Common_Parameter_TrafficLight§ToolTip'
] = `£Ce paramètre permet d'ajouter des feux tricolores, auxquels le patient est susceptible de devoir s'arrêter.`;
trad['Common_Parameter_Traffic'] = `£Densité de trafic`;
trad['Common_Parameter_Traffic§Reduced'] = `£Trafic`;
trad['Common_Parameter_Traffic§Short'] = `£Trafic`;
trad[
  'Common_Parameter_Traffic§ToolTip'
] = `£Ce paramètre permet de moduler le nombre d'autres véhicules parcourant la ville.`;
trad['Common_Parameter_Traffic_eNone'] = `£Aucun`;
trad['Common_Parameter_Traffic_eNoneDesc'] = `£Aucun trafic`;
trad['Common_Parameter_Traffic_eSparce'] = `£Léger`;
trad['Common_Parameter_Traffic_eSparceDesc'] = `£Trafic Léger`;
trad['Common_Parameter_Traffic_eMedium'] = `£Modéré`;
trad['Common_Parameter_Traffic_eMediumDesc'] = `£Trafic Modéré`;
trad['Common_Parameter_Traffic_eDense'] = `£Dense`;
trad['Common_Parameter_Traffic_eDenseDesc'] = `£Trafic Dense`;
trad['Common_Parameter_TransportComplexity_eSimple'] = `£Simplifié`;
trad[
  'Common_Parameter_TransportComplexity_eSimpleToolTip'
] = `£Avec cette option, seuls le jour et la tranche horaire concernés par le scénario sont affichés.`;
trad['Common_Parameter_TransportComplexity_eMedium'] = `£Moyenne`;
trad[
  'Common_Parameter_TransportComplexity_eMediumToolTip'
] = `£Avec cette option, les horaires sont indiquées sur l'ensemble de la journée, mais sans prendre en compte les différents jours de la semaine.`;
trad['Common_Parameter_TransportComplexity_eComplete'] = `£Complète`;
trad[
  'Common_Parameter_TransportComplexity_eCompleteToolTip'
] = `£Avec cette option, toutes les données disponibles sont affichées.`;
trad['Common_Parameter_Vegetation'] = `£Végétation`;
trad['Common_Parameter_VegetationDesc'] = `£Densité de végétation`;
trad['Common_Parameter_Vegetation_eNone'] = `£Aucune`;
trad['Common_Parameter_Vegetation_eNoneDesc'] = `£Aucune végétation`;
trad['Common_Parameter_Vegetation_eRandom'] = `£Aléatoire`;
trad['Common_Parameter_Vegetation_eRandomDesc'] = `£Implantation aléatoire de végétation`;
trad['Common_Parameter_Vegetation_eFull'] = `£Complète`;
trad['Common_Parameter_Vegetation_eFullDesc'] = `£Implantation complète de végétation`;
trad['Common_Parameter_VoiceGPS'] = `£Guidage vocal`;
trad['Common_Parameter_VoiceGPSToolTip'] = `£Ce paramètre permet de diffuser les annonces vocales du GPS.`;
trad['Common_Parameter_WordFrequency'] = `£Fréquence des substantifs`;
trad['Common_Parameter_WordFrequency§Reduced'] = `£Fréquence`;
trad['Common_Parameter_WordFrequency§Short'] = `£Fréquence`;
trad[
  'Common_Parameter_WordFrequency§ToolTip'
] = `£Ce paramètre indique la fréquence des substantifs utilisés (fréquent, rare ou indifférent).`;
trad['Common_Parameter_WordFrequency_eRare'] = `£Rare`;
trad['Common_Parameter_WordFrequency_eAverage'] = `£Moyenne`;
trad['Common_Parameter_WordFrequency_eFrequent'] = `£Fréquent`;
trad['Common_Result_Answer'] = `£Réponse proposée`;
trad['Common_Result_AttentionError'] = `£Erreurs d'attention`;
trad['Common_Result_AttentionErrorShort'] = `£Err. att.`;
trad['Common_Result_AutoCorrect'] = `£Auto-correction`;
trad['Common_Result_AvgMemorizationTime'] = `£Temps moyen de mémorisation`;
trad['Common_Result_AvgMemorizationTimeToolTip'] = `£Temps moyen passé sur la mémorisation d'une configuration.`;
trad['Common_Result_AvgTime'] = `£Temps moyen par réponse`;
trad['Common_Result_AvgTimeShort'] = `£Tps moyen`;
trad[
  'Common_Result_AvgTimeToolTip'
] = `£Temps moyen par réponse sur l'ensemble de celles données au cours de l'exercice.`;
trad['Common_Result_AvgViews'] = `£Nb de vues par carte`;
trad['Common_Result_AvgViewsToolTip'] = `£Nombre moyen de fois qu'une carte a été retournée.`;
trad['Common_Result_AvgViewsShort'] = `£Vues/carte`;
trad['Common_Result_Challenges'] = `£Obstacles`;
trad['Common_Result_Due'] = `£Echéance`;
trad['Common_Record_Error'] = `£Nombre d'erreurs`;
trad['Common_Record_Error§Reduced'] = `£Nombre d'erreurs`;
trad['Common_Record_Error§Short'] = `£Erreurs`;
trad['Common_Record_Error§ToolTip'] = `£Nombre de mauvaises réponses données en cours d'exercice.`;
trad['Common_Result_ErrorDistractor'] = `£Erreurs sur distracteur`;
trad['Common_Result_Failed'] = `£Séries échouées`;
trad['Common_Result_FaultySelection'] = `£Selections inappropriées`;
trad[
  'Common_Result_FaultySelectionToolTip'
] = `£Nombre de fois qu'une carte déjà vue est retournée sans donner lieu à la constitution d'une paire.`;
trad['Common_Result_FullResult'] = `£Résultat complet`;
trad['Common_Result_Graph'] = `£Structures des graphs construits`;
trad[
  'Common_Result_GraphToolTip'
] = `£Dans cette structure, les éléments fournis par le logiciel sont indiqués entre parenthèse, ceux proposés puis selectionnés par le patient sont en police normale, et ceux générés par le patient seul sont en gras.`;
trad['Common_Result_Items'] = `£Articles`;
trad['Common_Result_LeftErrors'] = `£Erreurs à gauche`;
trad['Common_Result_Level'] = `£Niveau atteint`;
trad['Common_Result_LevelShort'] = `£Niveau`;
trad['Common_Result_LevelToolTip'] = `£Nombre maximum d'éléments retenus au cours d'un seul tirage.`;
trad['Common_Result_MissedPair'] = `£Paires Manquées`;
trad[
  'Common_Result_MissedPairToolTip'
] = `£Nombre de fois qu'une carte, constituant une paire dont les deux éléments ont déjà été vus, est retournée sans donner lieu à la constitution de la paire.`;
trad['Common_Result_MissedTraficLight'] = `£Feux rouges grillés`;
trad['Common_Result_MissedStops'] = `£Stops grillés`;
trad['Common_Result_NbSuccess'] = `£Nombre de succès`;
trad['Common_Result_PCSuccess'] = `£Degré de réussite`;
trad['Common_Result_PCSuccessShort'] = `£% Succès`;
trad['Common_Result_PCSuccessToolTip'] = `£Porportion de bonnes réponses au cours de l'exercice.`;
trad['Common_Result_PlayedCards'] = `£Cartes joués`;
trad['Common_Result_PlayedTurns'] = `£Tours joués`;
trad['Common_Result_Right'] = `£Nombre de réponses justes`;
trad['Common_Result_NbRuns'] = `£Nombre de tirages réalisés`;
trad['Common_Result_Score'] = `£Score`;
trad['Common_Result_ScoreToolTip'] = `£Cumul du nombre d'éléments mémorisés avec succès.`;
trad['Common_Result_SequenceDescription'] = `£Description du tirage`;
trad['Common_Result_SequencesDescription'] = `£Description des tirages`;
trad['Common_Result_Success'] = `£Réussite de l'exercice`;
trad['Common_Result_SuccessShort'] = `£Résultat`;
trad['Common_Result_SuccessToolTip'] = `£Réussite de l'exercice.`;
trad['Common_Result_Success_eFailureShort'] = `£Echec`;
trad['Common_Result_Success_eFailure'] = `£Echec`;
trad['Common_Result_Success_eAverageShort'] = `£Satisfaisant`;
trad['Common_Result_Success_eAverage'] = `£Réussite partielle`;
trad['Common_Result_Success_eSuccessShort'] = `£Succès`;
trad['Common_Result_Success_eSuccess'] = `£Réussite totale`;
trad['Common_Result_Support_Semantic'] = `£Aides sémantiques`;
trad['Common_Result_Support_SemanticShort'] = `£Aides`;
trad[
  'Common_Result_Support_SemanticToolTip'
] = `£Nombre d'aides sémantiques proposées par le thérapeutes en cours d'exercice.`;
trad['Common_Result_Support_Phono'] = `£Aides phonologiques`;
trad[
  'Common_Result_Support_PhonoToolTip'
] = `£Nombre d'aides phonologiques proposées par le thérapeutes en cours d'exercice.`;
trad['Common_Result_Support'] = `£Aides`;
trad['Common_Result_PCSupportedSuccess'] = `£Degré de réussite avec étayage`;
trad['Common_Result_PCSupportedSuccessShort'] = `£% av étayage`;
trad['Common_Result_PCSupportedSuccessToolTip'] = `£Proportion des bonnes réponses obtenues avec étayage.`;
trad['Common_Result_SupportedTime'] = `£Temps moyen par réponse avec étayage`;
trad['Common_Result_SupportedTimeShort'] = `£Tps rép av étayage`;
trad['Common_Result_SupportedTimeToolTip'] = `£Temps moyen par réponse parmi celles fournies avec étayage.`;
trad['Common_Result_TotalDistance'] = `£Distance totale`;
trad['Common_Result_TotalPrice'] = `£Prix total`;
trad['Common_Result_TotalPriceDesc'] = `£Prix total du panier`;
trad[
  'Common_Result_TotalPriceToolTip'
] = `£Ce paramètre permet d'afficher le prix total du panier au bas de la liste de courses.`;
trad['Common_Record_TotalTime'] = `£Temps total`;
trad['Common_Record_TotalTime§Reduced'] = `£Temps total`;
trad['Common_Record_TotalTime§Short'] = `£Temps`;
trad['Common_Record_TotalTime§ToolTip'] = `£Temps total pour compléter l'exercice.`;
trad['Common_Result_Turns'] = `£Nombre de tours`;
trad['Common_Result_TurnsShort'] = `£Nb tours`;
trad['Common_Result_TurnsToolTip'] = `£Nombre de tours nécessaire pour associer toutes les paires.`;
trad['Common_Result_UnitDistance'] = `£Distance moyenne`;
trad['Common_Result_UnitDistanceShort'] = `£Dist/Obj`;
trad['Common_Result_UnitDistanceToolTip'] = `£Distance moyenne par objet.`;
trad['Common_Result_UnitTime'] = `£Temps moyen`;
trad['Common_Result_UnitTimeShort'] = `£Temps/Obj`;
trad['Common_Result_UnitTimeToolTip'] = `£Temps moyen par objet.`;
trad['Common_Result_UnjustifiedStops'] = `£Arrêts inopinés`;
trad['Common_Result_UnjustifiedStopsToolTip'] = `£Nombre d'arrêts non imputable à un obstacle ou un feu tricolore.`;
trad['Common_Result_UnsupportedTime'] = `£Temps moyen par réponse sans étayage`;
trad['Common_Result_UnsupportedTimeShort'] = `£Tps rép ss étayage`;
trad['Common_Result_UnsupportedTimeToolTip'] = `£Temps moyen par réponse parmi celles fournies sans étayage.`;
trad['Common_ConfigurationView_Configuration'] = `£Configuration de l'activité.`;
trad['Common_ConfigurationView_Modify'] = `£Modifier les paramètres.`;
trad[
  'Common_ConfigurationView_Caption_CriteriaError'
] = `£Selectionnez au moins un critère à mémoriser (forme, couleur ou symbole).`;
trad[
  'Common_ConfigurationView_Caption_SeriesSpecError'
] = `£Les catégories ou sous-catégories choisies doivent nécessairement être différentes.`;
trad[
  'Common_ConfigurationView_Caption_CategoriesError'
] = `£La configuration des mots est trop restrictive, et aucune série ne peut être générée. Merci d'être moins spécifique dans votre demande.`;
trad['Common_ConfigurationView_Caption_ShapesError'] = `£Selectionnez au moins deux formes pour cette activité.`;
trad[
  'Common_ConfigurationView_Caption_ColorsError'
] = `£Merci de selectionner plus de couleurs pour cette configuration d'activité.`;
trad[
  'Common_ConfigurationView_Caption_SingleSpecError'
] = `£Aucun mot de la base ne correspond à ces critères ! Merci de fixer des critères moins restrictifs.`;
trad[
  'Common_ConfigurationView_Caption_InsufficientSpecError'
] = `£La configuration des mots est trop restrictive, et ne permet pas la génération d'un nombre suffisant d'éléments. Merci d'être moins spécifique dans votre demande.`;
trad['Common_ConfigurationView_Caption_NoParameter'] = `£Aucune configuration n'est disponible pour cette activité.`;
trad['Common_RuntimeView_Header_AvailableDocuments'] = `£Documents disponibles`;
trad['Common_RuntimeView_Header_Control'] = `£Contrôle`;
trad['Common_RuntimeView_Header_CurrentDocument'] = `£Document courant`;
trad['Common_RuntimeView_Header_CurrentDocumentsControllerChange'] = `£Changer`;
trad['Common_RuntimeView_Header_Supervision'] = `£Supervision`;
trad['Common_RuntimeView_Header_ExpectedAnswer'] = `£Annonce attendue`;
trad['Common_RuntimeView_Header_Explanation'] = `£Explications`;
trad['Common_RuntimeView_Header_GPS'] = `£GPS`;
trad['Common_RuntimeView_Header_Hypotheses'] = `£Hypothèses de départ`;
trad['Common_RuntimeView_Header_Journal'] = `£Journal`;
trad['Common_RuntimeView_Header_Notes'] = `£Annotations`;
trad['Common_RuntimeView_Header_Map'] = `£Carte`;
trad['Common_RuntimeView_Header_Objects'] = `£Articles`;
trad['Common_RuntimeView_Header_Roadbook'] = `£Feuille de route`;
trad['Common_RuntimeView_Header_Runs'] = `£Tirages`;
trad['Common_RuntimeView_Header_State'] = `£Etat`;
trad['Common_RuntimeView_Header_Stream'] = `£Capture`;
trad['Common_RuntimeView_Header_SMSSending'] = `£Envoi de SMS`;
trad['Common_RuntimeView_Header_Selection'] = `£Selection`;
trad['Common_RuntimeView_Header_MethodologyBehavior'] = `£Comportement`;
trad['Common_RuntimeView_Header_ShopingList'] = `£Liste de courses`;
trad['Common_RuntimeView_Command_Tab_Help'] = `£Aides`;
trad['Common_RuntimeView_Command_Tab_Action'] = `£Action`;
trad['Common_RuntimeView_Command_Tab_Associations'] = `£Associations`;
trad['Common_RuntimeView_Command_Tab_AvailableTools'] = `£Outils disponibles`;
trad['Common_RuntimeView_Command_Tab_Begin'] = `£Phonème initial`;
trad['Common_RuntimeView_Command_Tab_BeginLike'] = `£Association phonème initial`;
trad['Common_RuntimeView_Command_Tab_Category'] = `£Catégorie / Sous catégorie`;
trad['Common_RuntimeView_Command_Tab_Complexity'] = `£Complexité`;
trad['Common_RuntimeView_Command_Tab_End'] = `£Phonème final`;
trad['Common_RuntimeView_Command_Tab_EndLike'] = `£Rime`;
trad['Common_RuntimeView_Command_Tab_Features'] = `£Groupes de traits`;
trad['Common_RuntimeView_Command_Tab_Group'] = `£Groupe`;
trad['Common_RuntimeView_Command_Tab_Guiding'] = `£Outils d'indiçage`;
trad['Common_RuntimeView_Command_Tab_Inventory'] = `£Inventaire`;
trad['Common_RuntimeView_Command_Tab_NbSyllable'] = `£Nombre de Syllabes`;
trad['Common_RuntimeView_Command_Tab_Obstacles'] = `£Obstacles`;
trad[
  'Common_RuntimeView_Command_Tab_ObstaclesToolTip'
] = `£Ces commandes permettent de générer des obstacles qui apparaîtront sous quelques secondes.`;
trad['Common_RuntimeView_Command_Tab_Place'] = `£Lieu`;
trad['Common_RuntimeView_Command_Tab_Properties'] = `£Propriétés`;
trad['Common_RuntimeView_Command_Tab_SMS'] = `£Envoi de SMS`;
trad['Common_RuntimeView_Command_Tab_TimeControl'] = `£Gestion du temps`;
trad['Common_RuntimeView_Command_Tab_Use'] = `£Utilisation`;
trad['Common_RuntimeView_Command_Tab_Visibility'] = `£Visibilité`;
trad['Common_RuntimeView_Command_Action_DisplayQuest'] = `£Afficher 'Je fais...'`;
trad['Common_RuntimeView_Command_Action_DisplayProposals'] = `£Afficher des suggestions d'action`;
trad['Common_RuntimeView_Command_Action_DisplayExample'] = `£Afficher un exemple d'action`;
trad['Common_RuntimeView_Command_Action_Node'] = `£Je fais...`;
trad['Common_RuntimeView_Command_Association_DisplayQuest'] = `£Afficher 'Je ressemble à...'`;
trad['Common_RuntimeView_Command_Association_DisplayNode'] = `£Je ressemble à...`;
trad['Common_RuntimeView_Command_AvailableTools_Search'] = `£Afficher la zone de recherche`;
trad['Common_RuntimeView_Command_AvailableTools_Transport'] = `£Afficher la liste des transports`;
trad['Common_RuntimeView_Command_Begin_DisplayQuest'] = `£Afficher 'Commence par'`;
trad['Common_RuntimeView_Command_Begin_DisplayProposals'] = `£Afficher des suggestions de son initial`;
trad['Common_RuntimeView_Command_Begin_Display'] = `£Afficher le son initial`;
trad['Common_RuntimeView_Command_Begin_DisplayLikeQuest'] = `£Afficher 'Commence comme...'`;
trad['Common_RuntimeView_Command_Begin_DisplayLikeProposals'] = `£Afficher des suggestions de mots similaires`;
trad['Common_RuntimeView_Command_Begin_DisplayLike'] = `£Afficher un exemple d'un mot similaire`;
trad['Common_RuntimeView_Command_CategoryShow'] = `£Afficher la catégorie`;
trad['Common_RuntimeView_Command_DisplayPhonoHelpStart'] = `£Afficher une aide phonologique "commence comme"`;
trad['Common_RuntimeView_Command_DisplayPhonoHelpEnd'] = `£Afficher une aide phonologique "Termine comme"`;
trad['Common_RuntimeView_Command_DisplaySemanticHelp'] = `£Afficher un mot de la même catégorie sémantique`;
trad['Common_RuntimeView_Command_DisplayExample'] = `£Afficher un exemple d'un mot similaire`;
trad['Common_RuntimeView_Command_DocumentsDisplay'] = `£Afficher`;
trad['Common_RuntimeView_Command_DocumentsHide'] = `£Masquer`;
trad['Common_RuntimeView_Command_DocumentsPrint'] = `£Imprimer`;
trad['Common_RuntimeView_Command_DocumentsChange'] = `£Changer`;
trad['Common_RuntimeView_Command_EmptyJournal'] = `£Vider`;
trad['Common_RuntimeView_Command_End_DisplayQuest'] = `£Afficher 'Termine par'`;
trad['Common_RuntimeView_Command_End_DisplayProposals'] = `£Afficher des suggestions de son final`;
trad['Common_RuntimeView_Command_End_Display'] = `£Afficher le son final`;
trad['Common_RuntimeView_Command_End_DisplayLikeQuest'] = `£Afficher 'Rime avec...'`;
trad['Common_RuntimeView_Command_End_DisplayLikeProposals'] = `£Afficher des suggestions de mots similaires`;
trad['Common_RuntimeView_Command_End_DisplayLike'] = `£Afficher un exemple d'un mot similaire`;
trad['Common_RuntimeView_Command_ExportMap'] = `£Exporter&#xD; le plan`;
trad['Common_RuntimeView_Command_FeaturesGroup'] = `£Afficher le groupe de traits`;
trad['Common_RuntimeView_Command_FeaturesGroupHide'] = `£Cacher le groupe`;
trad['Common_RuntimeView_Command_Features'] = `£Afficher les traits du groupe`;
trad['Common_RuntimeView_Command_Filter'] = `£Filtrer les produits`;
trad['Common_RuntimeView_Command_Group_DisplayQuest'] = `£Afficher 'Je suis de la famille des...'`;
trad['Common_RuntimeView_Command_Group_DisplayProposals'] = `£Afficher des propositions de catégorie`;
trad['Common_RuntimeView_Command_Group_Display'] = `£Afficher la catégorie`;
trad['Common_RuntimeView_Command_Group_Node'] = `£Je suis de la famille des...`;
trad['Common_RuntimeView_Command_GuidingTools'] = `£Afficher outils d'indiçage`;
trad['Common_RuntimeView_Command_Guiding_Suggestions'] = `£Afficher les suggestions`;
trad['Common_RuntimeView_Command_Hide'] = `£Cacher`;
trad['Common_RuntimeView_Command_Mode'] = `£Mode`;
trad['Common_RuntimeView_Command_Mode_eIndependant'] = `£Indépendant`;
trad['Common_RuntimeView_Command_Mode_eSynchronized'] = `£Synchronisé`;
trad['Common_RuntimeView_Command_Notes'] = `£Notes`;
trad['Common_RuntimeView_Command_NbSyllable_DisplayQuest'] = `£Afficher 'Nb de syllabes...'`;
trad['Common_RuntimeView_Command_NbSyllable_DisplayProposals'] = `£Afficher des propositions de Nb`;
trad['Common_RuntimeView_Command_NbSyllable_Display'] = `£Afficher le nb`;
trad['Common_RuntimeView_Command_Obstacle_Ambulance'] = `£Une ambulance`;
trad[
  'Common_RuntimeView_Command_Obstacle_AmbulanceToolTip'
] = `£Cette commande crée une ambulance, signalée par une sirène, se présentera à une intersection, imposant au conducteur de s'arrêter jusqu'à ce que l'ambulance soit passée.`;
trad['Common_RuntimeView_Command_Obstacle_RoadBlock'] = `£Route barrée`;
trad[
  'Common_RuntimeView_Command_Obstacle_RoadBlockToolTip'
] = `£Cette commande permet de créer une zone de travaux, imposant au conducteur de s'arrêter jusqu'à ce que le thérapeute enlève l'obstacle.`;
trad['Common_RuntimeView_Command_Obstacle_Ball'] = `£Un ballon`;
trad[
  'Common_RuntimeView_Command_Obstacle_BallToolTip'
] = `£Cette commande permet de lancer un ballon en travers de la route, imposant au conducteur de freiner au dernier moment, et d'attendre que le thérapeute le fasse disparaître.`;
trad['Common_RuntimeView_Command_Obstacle_Remove'] = `£Supprimer les obstacles`;
trad[
  'Common_RuntimeView_Command_Obstacle_RemoveToolTip'
] = `£Cette commande permet d'enlever un embouteillage ou de supprimer un obstacle créé par le thérapeute.`;
trad['Common_RuntimeView_Command_Reset'] = `£Réinitialiser`;
trad[
  'Common_RuntimeView_Command_Reset§ToolTip'
] = `£Cette commande permet d'enlever un embouteillage ou de supprimer un obstacle et de réinitialiser la position du véhicule.`;
trad['Common_RuntimeView_Command_Patientview'] = `£Vue patient'`;
trad['Common_RuntimeView_Command_Place_DisplayQuest'] = `£Afficher 'On me trouve...'`;
trad['Common_RuntimeView_Command_Place_DisplayProposals'] = `£Afficher des suggestions de lieu`;
trad['Common_RuntimeView_Command_Place_DisplayExample'] = `£Afficher un exemple de lieu`;
trad['Common_RuntimeView_Command_Place_Node'] = `£On me trouve...`;
trad['Common_RuntimeView_Command_Properties_DisplayQuest'] = `£Afficher 'Je suis / j'ai...'`;
trad['Common_RuntimeView_Command_Properties_DisplayProposals'] = `£Afficher des suggestions de propriétés`;
trad['Common_RuntimeView_Command_Properties_DisplayExample'] = `£Afficher un exemple de propriété`;
trad['Common_RuntimeView_Command_Properties_Node'] = `£Je suis / j'ai...`;
trad['Common_RuntimeView_Command_Show'] = `£Afficher`;
trad['Common_RuntimeView_Command_ShowAll'] = `£Montrer toutes les cartes`;
trad['Common_RuntimeView_Command_ShowNone'] = `£Retourner toutes les cartes`;
trad['Common_RuntimeView_Command_SimulateTime'] = `£Activer la simulation temporelle`;
trad['Common_RuntimeView_Command_SuggestRow'] = `£Indiquer la ligne`;
trad['Common_RuntimeView_Command_SuggestColumn'] = `£Indiquer la colonne`;
trad['Common_RuntimeView_Command_HideSuggestRow'] = `£Masquer l'indice de ligne`;
trad['Common_RuntimeView_Command_HideSuggestColumn'] = `£Masquer l'indice de colonne`;
trad['Common_RuntimeView_Command_SuggestOptions4'] = `£Conserver 4 options`;
trad['Common_RuntimeView_Command_SuggestOptions2'] = `£Conserver 2 options`;
trad['Common_RuntimeView_Command_SuppressOptions1'] = `£Elliminer une option`;
trad['Common_RuntimeView_Command_SuppressOptions2'] = `£Elliminer 2 options`;
trad['Common_RuntimeView_Command_SupressSuggestions'] = `£Masquer l'aide`;
trad['Common_RuntimeView_Command_SubCategory'] = `£Afficher la sous-catégorie`;
trad['Common_RuntimeView_Command_Switch2Cards'] = `£Intervertir 2 cartes`;
trad['Common_RuntimeView_Command_TargetDisplay'] = `£Afficher la cible`;
trad['Common_RuntimeView_Command_TargetHide'] = `£Cacher la cible`;
trad['Common_RuntimeView_Command_Talk'] = `£Parler`;
trad['Common_RuntimeView_Command_Tooltip_ShowStream'] = `£Afficher/masquer le flux vidéo.`;
trad['Common_RuntimeView_Command_Tooltip_ShowList'] = `£Afficher/masquer la liste de courses.`;
trad['Common_RuntimeView_Command_TrafficJam_Remove'] = `£Dégager un embouteillage`;
trad[
  'Common_RuntimeView_Command_TrafficJam_RemoveToolTip'
] = `£Cette commande permet d'enlever un embouteillage ou de supprimer un obstacle créé par le thérapeute.`;
trad['Common_RuntimeView_Command_Use_DisplayQuest'] = `£Afficher 'Je sers à...'`;
trad['Common_RuntimeView_Command_Use_DisplayProposals'] = `£Afficher des propositions d'utilisation`;
trad['Common_RuntimeView_Command_Use_DisplayExample'] = `£Afficher un exemple d'utilisation`;
trad['Common_RuntimeView_Command_Use_Node'] = `£Je sers à...`;
trad['Common_RuntimeView_Command_Visibility_Center'] = `£Centrer`;
trad['Common_RuntimeView_Command_Visibility_CenterCaption'] = `£Centrer sur un élément du scénario.`;
trad['Common_RuntimeView_Command_Visibility_HideAll'] = `£Tout cacher`;
trad['Common_RuntimeView_Command_Visibility_See'] = `£Voir`;
trad['Common_RuntimeView_Command_Visibility_SeeAll'] = `£Tout voir`;
trad['Common_RuntimeView_Command_Visibility_SeeHideCaption'] = `£Voir ou masquer des lignes.`;
trad['Common_RuntimeView_Command_Zoom'] = `£Zoom`;
trad['shortcut_Common_RuntimeView_Command_Appear'] = `£T`;
trad['shortcut_Common_RuntimeView_Command_Center'] = `£C`;
trad['shortcut_Common_RuntimeView_Command_DocumentsPrint'] = `£P`;
trad['shortcut_Common_RuntimeView_Command_Mode_eIndependant'] = `£I`;
trad['shortcut_Common_RuntimeView_Command_Mode_eSynchronized'] = `£S`;
trad['shortcut_Common_RuntimeView_Command_Obstacle_Ambulance'] = `£E`;
trad['shortcut_Common_RuntimeView_Command_Obstacle_RoadBlock'] = `£A`;
trad['shortcut_Common_RuntimeView_Command_Obstacle_Ball'] = `£Z`;
trad['shortcut_Common_RuntimeView_Command_Obstacle_Remove'] = `£R`;
trad['Common_RuntimeView_State_Crash'] = `£Collisions`;
trad['Common_RuntimeView_State_Elapsed'] = `£Temps écoulé`;
trad['Common_RuntimeView_State_FoundPairs'] = `£Paires trouvées`;
trad['Common_RuntimeView_State_GameTime'] = `£Heure de simulation`;
trad['Common_RuntimeView_State_LevelErrors'] = `£Erreurs pour ce niveau`;
trad['Common_RuntimeView_State_LevelRemainingItems'] = `£Tirages restant pour ce niveau`;
trad['Common_RuntimeView_State_PlayedTurns'] = `£Tours joués`;
trad['Common_RuntimeView_State_PlayedCards'] = `£Cartes joués`;
trad['Common_RuntimeView_State_RemainingPairs'] = `£Paires restantes`;
trad['Common_RuntimeView_State_Run'] = `£Tirage`;
trad['Common_RuntimeView_State_RunOutOf'] = `£ sur `;
trad['Common_RuntimeView_State_Time'] = `£Heure`;
trad['Common_RuntimeView_State_CurrentLevel'] = `£Niveau actuel`;
trad['Common_RuntimeView_Label_CellMessage'] = `£Messages`;
trad['Common_RuntimeView_Label_CellModify'] = `£Modifier`;
trad['Common_RuntimeView_Label_CellSend'] = `£Envoyer`;
trad['Common_RuntimeView_Label_ConnectPatientMessage'] = `£Communiquez le lien suivant à votre patient`;
trad['Common_RuntimeView_Label_ConnectStatus_eConnected'] = `£Patient connecté`;
trad['Common_RuntimeView_Label_ConnectStatus_eNotconnected'] = `£Patient non connecté`;
trad['Common_RuntimeView_Label_Journal_Crash'] = `£Collision !`;
trad['Common_RuntimeView_Label_Journal_Error'] = `£Erreur de navigation !`;
trad['Common_RuntimeView_Label_Journal_MissedTraficLight'] = `£Feu rouge grillé !`;
trad['Common_RuntimeView_Label_Journal_MissedStop'] = `£Stop grillé !`;
trad['Common_RuntimeView_Label_Journal_Success'] = `£Succès !`;
trad['Common_RuntimeView_Label_Hide'] = `£Cacher`;
trad['Common_RuntimeView_Label_MailFrom'] = `£De`;
trad['Common_RuntimeView_Label_MailSubject'] = `£Objet`;
trad['Common_RuntimeView_Label_MailContent'] = `£Message`;
trad['Common_RuntimeView_Label_MailSend'] = `£Envoyer`;
trad['Common_RuntimeView_Label_Objects'] = `£Articles`;
trad['Common_RuntimeView_Label_Ongoing'] = `£En cours...`;
trad['Common_RuntimeView_Label_Pause'] = `£En pause!`;
trad['Common_RuntimeView_Label_Price'] = `£Prix`;
trad['Common_RuntimeView_Label_Quantity'] = `£Quantités`;
trad['Common_RuntimeView_Label_ReceipesCookingTime'] = `£Temps de cuisson`;
trad['Common_RuntimeView_Label_ReceipesIngredients'] = `£Ingrédients`;
trad['Common_RuntimeView_Label_ReceipesNb'] = `£Pour`;
trad['Common_RuntimeView_Label_ReceipesPreparationTime'] = `£Temps de préparation`;
trad['Common_RuntimeView_Label_ReceipesPreparation'] = `£Préparation`;
trad['Common_RuntimeView_Label_Show'] = `£Montrer`;
trad['Common_RuntimeView_Label_TimeTableSearch'] = `£Rechercher`;
trad['Common_RuntimeView_Label_TimeTableSearchNoResults'] = `£Aucun résultat n'a été trouvé`;
trad['Common_RuntimeView_Label_TimeTableSearchResults'] = `£Résultats pour '`;
trad['Common_RuntimeView_Label_Transportation'] = `£Transports`;
trad['Common_RuntimeView_Label_TransportComplexity_Alternative'] = `£Complexité`;
trad['Common_RuntimeView_Label_TransportComplexity_eSimple'] = `£Simplifiée`;
trad[
  'Common_RuntimeView_Label_TransportComplexity_eSimpleToolTip'
] = `£Seuls le jour et la tranche horaire concernés par le scénario sont affichés.`;
trad['Common_RuntimeView_Label_TransportComplexity_eMedium'] = `£Moyenne`;
trad[
  'Common_RuntimeView_Label_TransportComplexity_eMediumToolTip'
] = `£Seul le jour concerné par le scénario est affiché.`;
trad['Common_RuntimeView_Label_TransportComplexity_eComplete'] = `£Complète`;
trad['Common_RuntimeView_Label_TransportComplexity_eCompleteToolTip'] = `£Toutes les données sont affichées.`;
trad['Common_RuntimeView_Label_AnswerSheet'] = `£Fiche réponse.`;
trad['Common_RuntimeView_Caption_CategoryDesc'] = `£Je suis de la grande famille des...`;
trad['Common_RuntimeView_Caption_Characteristics'] = `£Mes caracteristiques sont...`;
trad['Common_RuntimeView_Caption_ConclusionNarrative'] = `£La proposition semble `;
trad['Common_RuntimeView_Caption_ConclusionNarrative_eRight'] = `£JUSTE`;
trad['Common_RuntimeView_Caption_ConclusionNarrative_eWrong'] = `£FAUSSE`;
trad['Common_RuntimeView_Caption_Family'] = `£Je suis de la grande famille des...`;
trad['Common_RuntimeView_Caption_TimeTableTitle'] = `£Bienvenue sur MesHoraires.com`;
trad[
  'Common_RuntimeView_Caption_TimeTableIntro'
] = `£Tous les horaires des commerces et des administrations de votre ville !`;
trad['Common_RuntimeView_Caption_TransportTitle'] = `£Horaires des lignes de transport`;
trad[
  'Common_RuntimeView_Caption_TransportIntro'
] = `£Bienvenue sur la page proposant les horaires de vos transports.&#xD;Selectionnez une ligne pour consulter ses horaires.`;
trad['Common_RuntimeView_Caption_TransportTitleLine'] = `£Horaires de la ligne `;
trad[
  'Common_RuntimeView_Caption_TherapistDesc'
] = `£Le personnage 'Thérapeute' vous permet d'agir avec votre patient dans le monde virtuel.&#xD;Si vous utilisez l'option 'Apparaitre' votre image apparaitra dans une télévision flottant aux cotés du patient.&#xD;Vous pouvez naviguer dans l'espace via un clic droit sur le fond du plan ou un élément du plan.&#xD;Utilisez aussi le clic droit sur un raccourcis de la zone 'Objets' pour naviguer rapidement sur un élément à trouver.`;
trad[
  'Common_RuntimeView_Caption_VendorDesc'
] = `£Le personnage 'Vendeur' (ou 'Vendeuse') représente le personnel du magasin. En cliquant sur le bouton 'Parler', vous pouvez répondre par leur bouche aux questions du patient, par exemple pour lui indiquer où trouver un objet.&#xD;Vous pouvez naviguer dans l'espace via un clic droit sur le fond du plan ou un élément du plan.&#xD;Utilisez aussi le clic droit sur un raccourcis de la zone 'Objets' pour naviguer rapidement sur un élément à trouver.`;
trad['Common_ResultView_Header_Hypotheses'] = `£Hypothèses de départ`;
trad['Common_ResultView_Header_Results'] = `£Résultats`;
trad['Common_ResultView_Header_Execution'] = `£Déroulement`;
trad['Common_ResultView_Header_CurveProgress'] = `£Evolution`;
trad['Methodology_Title'] = `£Fiche de méthode`;
trad['Methodology_Goal'] = `£Quel est mon but principal ?`;
trad['Methodology_Steps'] = `£Quelles sont les étapes ?`;
trad['Methodology_StepsCaption'] = `£Revenir à la ligne après chaque étape.`;
trad['Methodology_Learn'] = `£J'apprends les étapes`;
trad['Methodology_LearnCheck'] = `£J'ai appris, je suis prêt à vérifier l'apprentissage`;
trad['Methodology_Remember'] = `£Est-ce que je me rappelle des étapes ?`;
trad['Methodology_ExecuteStep'] = `£Je fais l'étape n°`;
trad['Methodology_ExecuteStepCheck'] = `£J'ai effectué l'étape`;
trad['Methodology_VérifyStep'] = `£Je vérifie avoir bien réalisé l'étape`;
trad['Methodology_VérifyStep_eOK'] = `£J'ai bien réalisé cette étape`;
trad['Methodology_VérifyStep_eKO'] = `£Je n'ai pas bien réalisé cette étape`;
trad['Methodology_VérifyGoal'] = `£Je vérifie avoir bien atteint le but`;
trad['Methodology_VérifyGoal_eOK'] = `£J'ai bien atteint mon but`;
trad['Methodology_VérifyGoal_eKO'] = `£Je n'ai pas atteint mon but`;
trad['Methodology_GoalReached'] = `£Félicitations, vous avez atteint votre but principal !`;
trad['Methodology_StartOver'] = `£Recommencer`;
trad['Methodology_StepsDisplay'] = `£Affichage execution étape`;
trad['Methodology_StepsDisplay_eNone'] = `£Non dynamique`;
trad['Methodology_StepsDisplay_eNoneToolTip'] = `£ La fiche d'aide n'est pas dynamique.`;
trad['Methodology_StepsDisplay_eAll'] = `£Toutes les étapes`;
trad[
  'Methodology_StepsDisplay_eAllToolTip'
] = `£Lors de la réalisation d'une étape, toutes les autres étapes sont rappelées.`;
trad['Methodology_StepsDisplay_eCurrent'] = `£Étape courante`;
trad[
  'Methodology_StepsDisplay_eCurrentToolTip'
] = `£Lors de la réalisation de chaque étape, seule l'étape courante est rappelée.`;
trad['Methodology_StepsDisplay_eHidden'] = `£Étape masquée`;
trad[
  'Methodology_StepsDisplay_eHiddenToolTip'
] = `£L'objectif de l'étape courante n'est pas rappelé pendant la réalisation.`;
trad['EmptySheet_Title'] = `£Bloc-notes`;
trad['GuidedAnswerSheet_Title'] = `£Feuille de réponses`;
trad['GuidedAnswerSheet_GeneralInfo'] = `£Informations générales`;
trad['GuidedAnswerSheet_IngredientsTab'] = `£Liste des articles`;
trad['GuidedAnswerSheet_IngredientsAdd'] = `£Ajouter un article`;
trad['GuidedAnswerSheet_Ingredients_Name'] = `£Nom de l'article`;
trad['GuidedAnswerSheet_Ingredients_RequiredQuantity'] = `£Quantité nécessaire`;
trad['GuidedAnswerSheet_Ingredients_AvailableQuantity'] = `£Quantité disponible`;
trad['GuidedAnswerSheet_Ingredients_NeededQuantity'] = `£Quantité à acheter`;
trad['EvalCategory_Name'] = `£Cat. sémantique`;
trad['EvalCategory_FullName'] = `£Eval. catégorisation sémantique`;
trad['EvalCategory_Summary'] = `£Identifier et annoncer la catégorie sémantique commune à plusieurs éléments.`;
trad['EvalNback_Name'] = `£Mém. travail visuelle`;
trad['EvalNback_FullName'] = `£Eval. mémoire travail visuelle`;
trad['EvalNback_Summary'] = `£Mémoriser puis annoncer une succession d'éléments en sens inverse.`;
trad['EvalVerbal_Name'] = `£Mém. trv. visuo-verbale`;
trad['EvalVerbal_FullName'] = `£Eval. mémoire travail visuo-verbale`;
trad['EvalVerbal_Summary'] = `£Mémoriser puis annoncer une succession de lettres en sens inverse.`;
trad['ShoppingList_Name'] = `£La liste`;
trad['ShoppingList_FullName'] = `£La liste`;
trad['ShoppingList_Summary'] = `£Etablir une liste de courses.`;
trad['ShoppingList_Control_ValidateToolTip'] = `£Valider la liste de courses du patient et terminer l'exercice.`;
trad[
  'ShoppingList_Control_AcceptToolTip'
] = `£Valider partiellement la liste de courses du patient et terminer l'exercice.`;
trad['ShoppingList_Control_RejectToolTip'] = `£Refuser la liste de courses du patient et terminer l'exercice.`;
trad['Planning_Name'] = `£Le plan de ville`;
trad['Planning_FullName'] = `£Le plan de ville`;
trad['Planning_Summary'] = `£Planifier un déplacement sur un plan de ville.`;
trad['Planning_PackageTypeMaps'] = `£Données cartographiques`;
trad['Planning_PackageTypeScenario'] = `£Scénarios pour l'activité 'Le plan de ville'`;
trad['Planning_Control_ValidateToolTip'] = `£Valider le trajet du patient et terminer l'exercice.`;
trad['Planning_Map_SearchLabel'] = `£Rechercher une adresse`;
trad['Planning_PoiPanel_Title'] = `£Points d'intérêt`;
trad['Planning_PoiPanel_HideButton'] = `£Masquer les points d'intérêt`;
trad['Planning_Parameter_InfoNetworks'] = `£Informations transports`;
trad['Planning_Parameter_InfoPOI'] = `£Informations points d'intérêts`;
trad['Planning_Parameter_Info_eNone'] = `£Aucune`;
trad['Planning_Parameter_Info_eComplex'] = `£Complexe`;
trad['Planning_Parameter_Info_eAverage'] = `£Moyenne`;
trad['Planning_Parameter_Info_eSimple'] = `£Simple`;
trad['Planning_Parameter_Info_eSolution'] = `£Solution`;
trad['Driving_Control_ValidateToolTip'] = `£Valider la séquence de conduite du patient et terminer.`;
trad['Driving_Control_StartoverToolTip'] = `£Relancer l'exercice depuis le début.`;
trad['GPS_Name'] = `£GPS`;
trad['GPS_FullName'] = `£GPS`;
trad['GPS_Summary'] = `£Suivre des consignes GPS en situation de conduite.`;
trad['GPS_Parameter_Duration'] = `£Durée de l'exercice`;
trad['GPS_Parameter_Duration§Reduced'] = `£Durée`;
trad['GPS_Parameter_Duration§Short'] = `£Durée`;
trad[
  'GPS_Parameter_Duration§ToolTip'
] = `£Ce paramètre permet de définir la durée totale de l'exercice, après laquelle l'exercice se terminera automatiquement.`;
trad['Roadbook_Name'] = `£Roadbook`;
trad['Roadbook_FullName'] = `£Roadbook`;
trad['Roadbook_Summary'] = `£Suivre un itinéraire écrit en situation conduite.`;
trad['Market_Name'] = `£Les courses`;
trad['Market_FullName'] = `£Les courses`;
trad['Market_Summary'] = `£Suivre une liste de courses dans un supermarché urbain.`;
trad['Market_RuntimeView_Command_MsgSend'] = `£Message SMS`;
trad['Market_RuntimeView_Command_MsgSendCustom'] = `£Message SMS personnalisé`;
trad['Market_Parameter_Frequency_eLowToolTip'] = `£Une annonce est diffusée toutes les 4 minutes.`;
trad['Market_Parameter_Frequency_eMediumToolTip'] = `£Une annonce est diffusée toutes les 2 minutes.`;
trad['Market_Parameter_Frequency_eHighToolTip'] = `£Une annonce est diffusée toutes les minutes.`;
trad['MarketShelf_Name'] = `£Le rayon`;
trad['MarketShelf_FullName'] = `£Le rayon de supermarché`;
trad['MarketShelf_Summary'] = `£Trouver des articles dans un rayon de supermarché.`;
trad['Categorize_Name'] = `£Le bon groupe`;
trad['Categorize_FullName'] = `£Le bon groupe sémantique`;
trad['Categorize_Summary'] = `£Grouper les éléments par groupe sémantique.`;
trad[
  'Categorize_Parameter_ItemProximityToolTip'
] = `£Ce paramètre indique la proximité sémantique entre les catégories selectionnées (éloignées, proches ou indifférents).`;
trad['Categorize_Control_ValidateToolTip'] = `£Valider la catégorisation du patient et terminer l'exercice.`;
trad[
  'Categorize_Control_AcceptToolTip'
] = `£Accepter partiellement la catégorisation du patient et terminer l'exercice.`;
trad['Categorize_Control_RejectToolTip'] = `£Refuser la catégorisation du patient et terminer l'exercice.`;
trad['Categorize_Parameter_NbItemToolTip'] = `£Ce paramètre indique le nombre d'éléments à catégoriser.`;
trad['Categorize_RuntimeView_Caption_ExplanationNarrative'] = `£Les categories proposées, et leurs mots, sont`;
trad['Categorize_ResultView_Caption_ExplanationNarrative'] = `£Les categories proposées, et leurs mots, étaient`;
trad['Conf_Parameter_Complexity'] = `£Complexité de la composition`;
trad[
  'Conf_Parameter_Complexity§ToolTip'
] = `£Ce paramètre permet de préciser la complexité de l'exercice, qui définit le nombre d'emplacements proposés dans chaque composition (très simple, simple, moyenne, difficile ou très difficile).`;
trad['Conf_Parameter_Depth'] = `£Profondeur de la composition à mémoriser`;
trad[
  'Conf_Parameter_Depth§ToolTip'
] = `£Ce paramètre indique le nombre de compositions successifs à mémoriser avant l'annonce.`;
trad['Conf_Parameter_Depth_eN1'] = `£Configuration précédente (N - 1)`;
trad['Conf_Parameter_Depth_eN2'] = `£Configuration N - 2`;
trad['Conf_Parameter_Depth_eN3'] = `£Configuration N - 3`;
trad['Conf_Parameter_Depth_eN4'] = `£Configuration N - 4`;
trad['Conf_Control_SkipToolTip'] = `£Passer à la configuration suivante, sans compter ni succès ni echec.`;
trad['CNback_Name'] = `£N-Back de Config`;
trad['CNback_FullName'] = `£N-Back de Configurations`;
trad['CNback_Summary'] = `£Mémoriser puis remémorer des successions d'agencements visuels.`;
trad['CAdd_Name'] = `£Ajout de Config`;
trad['CAdd_FullName'] = `£Ajout de Configurations`;
trad['CAdd_Summary'] = `£Mémoriser, remémorer puis combiner des agencements visuels.`;
trad['CAdd_Parameter_NbConfigs'] = `£Nombre de compositions à superposer`;
trad[
  'CAdd_Parameter_NbConfigs§ToolTip'
] = `£Ce paramètre indique le nombre de compositions successivement affichées dans une série et qui doivent être ajoutées les unes aux autres.`;
trad['CSub_Name'] = `£Retrait de Config`;
trad['CSub_FullName'] = `£Retrait de Configurations`;
trad['CSub_Summary'] = `£Mémoriser, remémorer puis combiner des agencements visuels.`;
trad['CSub_Parameter_NbConfigs'] = `£Nombre de compositions à retrancher`;
trad[
  'CSub_Parameter_NbConfigs§ToolTip'
] = `£Ce paramètre indique le nombre de compositions affichées à la suite de la première et qui devront être retranchées à celle-ci.`;
trad['OddAuto_Name'] = `£Trouver l'intrus`;
trad['OddAuto_FullName'] = `£Trouver l'intrus sémantique / Auto`;
trad['OddAuto_Summary'] = `£Trouver l'intrus sémantique parmi un ensemble d'éléments (sans supervision).`;
trad['GoodPair_Name'] = `£La bonne paire`;
trad['GoodPair_FullName'] = `£La bonne paire`;
trad['GoodPair_Summary'] = `£Associer un élément à un autre élément de même catégorie sémantique.`;
trad[
  'GoodPair_Parameter_NbItemsToolTip'
] = `£Ce paramètre indique le nombre total de candidats proposés à chaque tirage en plus de l'élément à associer.`;
trad['GoodPair_Parameter_Display_Candidate'] = `£Affichage candidats`;
trad['GoodPair_Parameter_Display_CandidateDesc'] = `£Affichage des candidats`;
trad[
  'GoodPair_Parameter_Display_CandidateToolTip'
] = `£Ce paramètre indique le type d'affichage des candidats à l'association pendant l'activité (image, nom ou les deux).`;
trad['GoodPair_Parameter_Display'] = `£Affichage élément`;
trad['GoodPair_Parameter_DisplayDesc'] = `£Affichage de l'élément à associer`;
trad[
  'GoodPair_Parameter_DisplayToolTip'
] = `£Ce paramètre indique le type d'affichage de l'élément à associer pendant l'activité (image, nom ou les deux).`;
trad[
  'GoodPair_Parameter_Tab_SpecsToolTip'
] = `£Cet ensemble de paramètres permet de définir les attributs des éléménts principaux à utiliser dans l'exercice (ils ne s'appliquent pas aux candidats qui seront proposés à l'association).`;
trad['GoodPair_RuntimeView_Command_PrototypeShow'] = `£Afficher un élément représentatif de la catégorie`;
trad['GoodPair_RuntimeView_Command_PrototypeHide'] = `£Cacher l'élément représentatif de la catégorie`;
trad['GoodPair_RuntimeView_Command_CategoryShow'] = `£Afficher la catégorie`;
trad['GoodPair_RuntimeView_Command_CategoryHide'] = `£Cacher la catégorie`;
trad[
  'GoodPair_RuntimeView_Command_PrototypeToolTip'
] = `£Cette commande permet de fournir au patient un exemple particulièrement représentatif de la catégorie de l'élément à associer.`;
trad['GoodPair_RuntimeView_Caption_ExplanationNarrativeElement'] = `£Élément présenté`;
trad['GoodPair_RuntimeView_Caption_ExplanationNarrativeAssociatedElement'] = `£Candidats proposés`;
trad['Bell_Name'] = `£Le barrage`;
trad['Bell_FullName'] = `£Le barrage`;
trad['Bell_Summary'] = `£Identifier une cible dans un ensemble d'éléments.`;
trad['Bell_RuntimeView_Caption_Instruction_withTarget'] = `£Cliquez sur : `;
trad['Bell_RuntimeView_Caption_Instruction_withoutTarget'] = `£Cliquez sur l'élément présenté au début`;
trad['Bell_RuntimeView_Caption_Instruction_withoutTargets'] = `£Cliquez sur les éléments présentés au début`;
trad['Memory_Name'] = `£Memory`;
trad['Memory_FullName'] = `£Memory`;
trad['Memory_Summary'] = `£Mémoriser puis assembler par paire des éléments identiques ou associés.`;
trad[
  'Memory_DisplayToolTip'
] = `£Ce paramètre indique le type d'affichage des éléments pendant l'activité (couleur, image, nom ou les deux).`;
trad[
  'Memory_Parameter_ItemTypeToolTip'
] = `£Ce paramètre permet de définir le type d'élément qui sera affiché pour le patient (Caractère ou substantif).`;
trad[
  'Memory_Parameter_ItemType_eLettersTypeToolTip'
] = `£Ce paramètre permet de définir le type de caractères qui constituent des paires (majuscules, minuscules ou les deux).`;
trad[
  'Memory_Parameter_ItemType_eLettersType_eLowerToolTip'
] = `£Pour chaque caractère tiré aléatoirement est générée une paire de cartes contenant chacune ce caractère minuscule.`;
trad[
  'Memory_Parameter_ItemType_eLettersType_eUpperToolTip'
] = `£Pour chaque caractère tiré aléatoirement est générée une paire de cartes contenant chacune ce caractère majuscule.`;
trad[
  'Memory_Parameter_ItemType_eLettersType_eBothToolTip'
] = `£Pour chaque caractère tiré aléatoirement sont générées deux paires de cartes, l'une avec ce caractère minuscule, l'autre avec ce même caractère majuscule.`;
trad[
  'Memory_Parameter_ItemType_eLettersType_eEitherToolTip'
] = `£Pour chaque caractère tiré aléatoirement est générée une seule paire de cartes, aléatoirement majuscule ou minuscule.`;
trad[
  'Memory_Parameter_ItemType_eCharType_ePunctuationToolTip'
] = `£Des paires de cartes sont construites en utilisant les caractères de ponctuation classiques du clavier AZERTY.`;
trad[
  'Memory_Parameter_ItemType_eCharType_eSpecialToolTip'
] = `£Des paires de cartes sont construites en utilisant des caractères spéciaux issus de langues étrangères (hebreux, grec, arménien, cyrillique), favorisant la mémoire visuelle.`;
trad[
  'Memory_Parameter_CategoryToolTip'
] = `£Ce paramètre permet de selectionner la catégorie dans lesquelles tirer les substantifs.`;
trad['Memory_RuntimeView_Caption_AssociatedCardDescription'] = `£Carte associée`;
trad[
  'Memory_Result_SupportToolTip'
] = `£Nombre total d'aides apportées par le thérapeute (indication de ligne / colonne ou masquage de mauvaises options).`;
trad['Memory_Result_ChallengesToolTip'] = `£Nombre total d'échanges de cartes en cours d'exercice.`;
trad['Memory_RuntimeView_Tab_SelectedCard'] = `£Carte selectionnée`;
trad['Memory_RuntimeView_Tab_AssociatedCard'] = `£Carte associée`;
trad['Denomination_Name'] = `£Le bon mot`;
trad['Denomination_FullName'] = `£Le bon mot`;
trad['Denomination_Summary'] = `£Donner le mot associé à une image.`;
trad[
  'Denomonation_Parameter_DisplayToolTip'
] = `£Ce paramètre indique le type d'affichage des aides pendant l'activité (image, nom ou les deux).`;
trad[
  'Denomination_Parameter_CategoryToolTip'
] = `£Ce paramètre permet de selectionner la catégorie dans lesquelles tirer les substantifs.`;
trad['Denomination_RuntimeView_Caption_AssociatedCardDescription'] = `£Carte associée`;
trad['Denomination_Result_AvgRightAnswerTime'] = `£Temps moyen par item réussis`;
trad['Denomination_Result_AvgRightAnswerTimeShort'] = `£Tps succès`;
trad['Denomination_Result_AvgRightAnswerTimeToolTip'] = `£Temps passé en moyenne sur un item identifié avec succès.`;
trad['Denomination_Result_AvgWrongAnswerTime'] = `£Temps moyen par item échoué`;
trad['Denomination_Result_AvgWrongAnswerTimeShort'] = `£Tps echec`;
trad[
  'Denomination_Result_AvgWrongAnswerTimeToolTip'
] = `£Temps passé en moyenne sur un item sans l'identifier convenablement.`;
trad['Denomination_RuntimeView_Tab_SelectedCard'] = `£Carte en cours`;
trad['Denomination_RuntimeView_Tab_PhonoSupport'] = `£Aides phonologiques disponibles`;
trad['Denomination_RuntimeView_Tab_SemanticSupport'] = `£Aides sémantiques disponibles`;
trad['Singular_Name'] = `£L'élément singulier`;
trad['Singular_FullName'] = `£L'élément singulier`;
trad['Singular_Summary'] = `£Identifier un élément unique dans un ensemble d'éléments.`;
trad['Singular_RuntimeView_Caption_Instruction'] = `£Cliquez sur l'élément unique`;
trad['Singular_Parameter_Tab_ItemsToolTip'] = `£Description des éléments que l'utilisateur doit analyser.`;
trad['Mindmap_Parameter_Tab_Specs'] = `£Spécification du substantif`;
trad[
  'Mindmap_Parameter_Tab_SpecsToolTip'
] = `£Ensemble de paramètres décrivant le substantif à utiliser dans l'exercice.`;
trad['MindMap_Parameter_Display'] = `£Affichage de l'élément`;
trad['MindMap_Parameter_DisplayToolTip'] = `£Ce paramètre indique le type d'affichage de l'élément à faire deviner.`;
trad[
  'MindMap_Parameter_SoundComplexityToolTip'
] = `£Ce paramètre indique la complexité phonologique du substantif utilisé (simple, complexe ou indifférent).`;
trad['MindMap_Parameter_WordFrequencyDesc'] = `£Fréquence du substantif`;
trad[
  'MindMap_Parameter_WordFrequencyToolTip'
] = `£Ce paramètre indique la fréquence du substantif utilisé (fréquent, rare ou indifférent).`;
trad['MindMap_Parameter_LengthDesc'] = `£Longueur du substantif`;
trad[
  'MindMap_Parameter_LengthToolTip'
] = `£Ce paramètre indique la longueur du substantif utilisé (long, court ou indifférent).`;
trad['MindMap_Control_ValidateToolTip'] = `£Valider la caractérisation du patient et passer au tirage suivant.`;
trad['MindMap_Control_RejectToolTip'] = `£Refuser la caractérisation du patient et passer au tirage suivant.`;
trad['MindMap_Control_Accept'] = `£Réussite après répetition`;
trad['MindMap_Control_AcceptToolTip'] = `£Accepter avec répétition et passer au tirage suivant`;
trad['MindMap_RuntimeView_Caption_ExplanationNarrative'] = `£Le mot en cours est`;
trad['MindMap_RuntimeView_Caption_SuggestionsNarrative'] = `£Les caractéristiques proposées sont`;
trad['Phonology_Name'] = `£Des sons au mot`;
trad['Phonology_FullName'] = `£Des sons au mot`;
trad['Phonology_Summary'] = `£Travailler les traits phonologiques d’un mot.`;
trad['FeaturesToWord_Name'] = `£Des traits au mot`;
trad['FeaturesToWord_FullName'] = `£Des traits au mot`;
trad['FeaturesToWord_Summary'] = `£Evoquer un mot selon ses traits sémantiques.`;
trad[
  'FeaturesToWord_Parameter_Guiding_eGuidedToolTip'
] = `£Le thérapeute est guidé dans sa manière de présenter le substantif au patient, d'abord en validant la catégorie, puis en explorant sémantiquement le substantif.`;
trad['WordToFeatures_Name'] = `£Du mot aux traits`;
trad['WordToFeatures_FullName'] = `£Du mot aux traits`;
trad['WordToFeatures_Summary'] = `£Evoquer les traits sémantiques d'un mot.`;
trad[
  'WordToFeatures_Parameter_Guiding_eGuidedToolTip'
] = `£Des outils d'assistance guident le patient dans sa définition du mot, d'abord en validant la catégorie puis en explorant sémantiquement le substantif.`;
trad['Counting_Name'] = `£Le bon chiffre`;
trad['Counting_FullName'] = `£Le bon chiffre`;
trad['Counting_Summary'] = `£Indiquer le nombre d'éléments (carrés ou chiffres) affichés à l'écran.`;
trad['Counting_RuntimeView_Caption_Instruction_eSquare'] = `£Annoncez le nombre de carrés`;
trad['Counting_RuntimeView_Caption_Instruction_eNumbers'] = `£Annoncez le nombre de chiffres`;
trad[
  'Counting_Parameter_Tab_AutoDistractorsToolTip'
] = `£Vous pouvez configurer des distracteurs automatiques. Le patient devra les inhiber et maintenir son attention sur la tâche de comptage.`;
trad[
  'Counting_Parameter_LayoutToolTip'
] = `£Ce paramètre précise la façon dont sont disposés les éléments (En ligne, répartis sur la constellation des points d'un dé ou éparpillés).`;
trad[
  'Counting_Parameter_ShapeFrequency_eLowToolTip'
] = `£Cette option a jusqu'à 30% de chance de faire apparaître des Cercles en plus des carrés.`;
trad[
  'Counting_Parameter_ShapeFrequency_eMediumToolTip'
] = `£Cette option a jusqu'à 50% de chance de faire apparaître des Cercles en plus des carrés.`;
trad[
  'Counting_Parameter_ShapeFrequency_eHighToolTip'
] = `£Cette option a jusqu'à 90% de chance de faire apparaître des Cercles en plus des carrés.`;
trad['Counting_Parameter_ColorFrequency_eLowToolTip'] = `£Cette option colore jusqu'à 30% des formes.`;
trad['Counting_Parameter_ColorFrequency_eMediumToolTip'] = `£Cette option colore jusqu'à 50% des formes.`;
trad['Counting_Parameter_ColorFrequency_eHighToolTip'] = `£Cette option colore jusqu'à 70% des formes.`;
trad['NBack_Name'] = `£N-Back`;
trad['NBack_FullName'] = `£Mémoire de travail visuelle N-Back`;
trad['NBack_Summary'] = `£Mémoriser puis rappeler une succession d'éléments.`;
trad['NBack_Parameter_Tab_ItemsToolTip'] = `£Description des éléments que l'utilisateur doit mémoriser.`;
trad[
  'NBack_Parameter_Tab_AutoDistractorsToolTip'
] = `£Vous pouvez configurer des distracteurs automatiques. Le patient devra les inhiber et maintenir son attention sur la tâche de mémorisation.&#xD;Les distracteurs visuels et la double tâche font usage des pictogrames des moyens de transport : &#xD;Ils seront donc plus difficile à inhiber si le type de forme à retenir est configuré sur 'Pictogrames de moyens de transport'.`;
trad['NBack_Parameter_ItemType'] = `£Type d'élément à mémoriser`;
trad[
  'NBack_Parameter_ItemType§ToolTip'
] = `£Cet ensemble d'options permet de définir le type d'éléments que le patient devra mémoriser`;
trad[
  'NBack_Parameter_ItemType_eShapes§ToolTip'
] = `£Cette option permet d'indiquer que le patient doit mémoriser des formes (abstraites ou concrètes).`;
trad[
  'NBack_Parameter_ItemType_eColors§ToolTip'
] = `£Cette option permet d'indiquer que le patient doit mémoriser des couleurs (rouge, vert, bleu, blanc ou noir).`;
trad[
  'NBack_Parameter_ItemType_eChars§ToolTip'
] = `£Cette option permet d'indiquer que le patient doit mémoriser des caractères (parmi des chiffres, lettres ou les deux).`;
trad[
  'NBack_Parameter_ItemType_eChar_Type§ToolTip'
] = `£Ce paramètre permet de définir le type de caractère à utiliser (des chiffres, lettres ou les deux).`;
trad[
  'NBack_Parameter_AutoDistractorToolTip'
] = `£Ce paramètre permet de définir l'utilisation de distracteurs automatiques. Le patient devra les inhiber et maintenir son attention sur la tâche de mémorisation.&#xD;Les distracteurs visuels et la double tâche font usage des pictogrames des moyens de transport :&#xD;Ils seront donc plus difficile à inhiber si le type de forme à retenir est configuré sur 'Pictogrames de moyens de transport'.`;
trad['NBack_Control_ValidateToolTip'] = `£Valider la carte actuelle, et basculer sur la carte suivante.`;
trad['NBack_Control_RejectToolTip'] = `£Refuser la carte actuelle, et basculer sur la carte suivante.`;
trad['NBack_Control_StartOverToolTip'] = `£Redémarrer un nouveau cycle.`;
trad['NBack_Control_SkipToolTip'] = `£Passer à la carte suivante, sans compter ni succès ni echec.`;
trad['Odd_Name'] = `£Trouver l'intrus`;
trad['Odd_FullName'] = `£Identification d'intrus sémantique`;
trad['Odd_Summary'] = `£Trouver l'intrus sémantique parmi un ensemble d'éléments.`;
trad['Odd_RuntimeView_Caption_Instruction'] = `£Cliquez sur l'intrus`;
trad['Odd_Control_ValidateToolTip'] = `£Valider la série actuelle, et basculer sur la série suivante.`;
trad['Odd_Control_RejectToolTip'] = `£Marquer la série actuelle en erreur, et basculer sur la série suivante.`;
trad[
  'Odd_Parameter_ItemProximityToolTip'
] = `£Ce paramètre indique la proximité sémantique entre l'intrus et l'ensemble des autres éléments (éloignés comme Végétaux et Outils, ou proches comme Arbres et Fleurs).`;
trad[
  'Odd_Parameter_CategoryToolTip'
] = `£Ce paramètre indique la catégorie sémantique des substantifs de même catégorie.`;
trad[
  'Odd_Parameter_Layout§ToolTip'
] = `£Ce paramètre précise la façon dont sont disposés les éléments (en ligne, en colonne, éparpillées aléatoirement ou alignés sur une grille).`;

export { trad };
